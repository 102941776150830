import { PopulatedPageWithPopulatedTabs } from '@tickr/sequelize-models';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useTidsMutations } from '../../Providers/TidsMutationsProvider/useTidsMutations';
import { useUser } from '../../Providers/UserProvider/useUser';
import { getFirstTabUuid } from '../../helpers/getFirstTabId';
import { useCurrentProject } from '../useCurrentProject';
import { useHandleError } from '../useHandleError';

interface LaunchSubstitutionsProps {
    banner?: string;
    datasources: string;
    gtin: string;
    marketSummary: string;
    productDescription: string;
}

export function useLaunchSubstitutions() {
    const { t } = useTranslation();
    const { currentProject } = useCurrentProject();
    const { postSummary } = useTidsMutations();
    const { createPageFromTemplate } = useCollectionMutations();
    const navigate = useNavigate();
    const handleError = useHandleError();
    const { isNumerator } = useUser();

    return async ({
        banner,
        datasources,
        gtin,
        marketSummary,
        productDescription,
    }: LaunchSubstitutionsProps) => {
        const solutionType = 'substitutions';
        let summaryId: string;

        try {
            summaryId = (
                await postSummary({
                    ...(banner && banner !== 'all' && { banner }),
                    gtins: [gtin],
                    summaryName: solutionType,
                    weeks: 12,
                })
            ).id;
        } catch (e) {
            handleError(e, t('error.launchAnalysis.createAnalysis'));
            return;
        }

        try {
            const newPage = await createPageFromTemplate({
                banner,
                dataset: isNumerator ? 'numerator' : 'synth',
                datasources,
                gtin,
                marketSummary,
                productDescription,
                projectUuid: currentProject.uuid,
                summaryId,
                type: solutionType,
            }) as PopulatedPageWithPopulatedTabs;

            navigate(`/page/${newPage.uuid}/${getFirstTabUuid(newPage.tabs)}`);
        } catch (e) {
            handleError(e, t('error.launchAnalysis.updateTabs'));
            return;
        }
    };
}
