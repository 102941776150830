import { useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Flex } from '../../../Core/Flex';

interface OuterToolbarWrapperProps {
    background?: string;
    id: string;
}

export function OuterToolbarWrapper({
    background,
    id,
    children,
}: PropsWithChildren<OuterToolbarWrapperProps>) {
    const { palette, shape } = useTheme();

    const isChatBox = id === 'chatCpg';

    const border = !isChatBox
        ? `1px solid ${background ? 'inherit' : palette.background.tertiary}`
        : '1px solid transparent';

    return (
        <Flex
            align="center"
            direction="column"
            gap="4px"
            style={{
                background: background ?? palette.background.default,
                borderTop: border,
                borderRight: border,
                borderLeft: border,
                borderRadius: `${shape.borderRadius}px ${shape.borderRadius}px 0 0`,
                padding: '2px 8px 0 8px',
            }}
            width="100%"
        >
            {children}
        </Flex>
    );
}
