import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, styled, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { StreamingIcon } from '../ChatCpg/StreamingIcon';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useTidsMutations } from '../Providers/TidsMutationsProvider/useTidsMutations';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useUser } from '../Providers/UserProvider/useUser';
import { SolutionContent } from '../Solutions/SolutionContent';
import { SolutionFooter } from '../Solutions/SolutionFooter';
import { SolutionGrid } from '../Solutions/SolutionGrid';
import { SolutionHeader } from '../Solutions/SolutionHeader';
import { SolutionPanel } from '../Solutions/SolutionPanel';
import { SolutionPanelHeader } from '../Solutions/SolutionPanelHeader';
import { SolutionPanelSection } from '../Solutions/SolutionPanelSection';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useDocumentStream } from '../hooks/useDocumentStream';
import { useForecastInProgress } from '../hooks/useForecastInProgress';
import { useHandleError } from '../hooks/useHandleError';
import { useStagedPageFns } from '../hooks/useStagedPageFns';
import { ConnectYourDataSolutionPanelSection } from './ConnectYourDataSolutionPanelSection';
import { SampleDataIcon } from './SampleDataIcon';
import { useStagedPageCommitListener } from './useStagedPageCommitListener';

export function ConnectYourData() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { errorMsg, forecast, isDocumentComplete, isLoading } = useForecastInProgress();
    const { sendAlert } = useToaster();
    const { postDocument } = useTidsMutations();
    const { initStagedPage, resetStagedPage, updateStagedPage } = useStagedPageFns(forecast?.uuid ?? '');
    const { isDemo } = useUser();
    const handleError = useHandleError();

    useStagedPageCommitListener(forecast?.uuid ?? '');

    const [processing, setProcessing] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'text/csv': ['.csv'],
        },
        maxFiles: 1,
        maxSize: 20000000,
        onDrop: () => {
            setProcessing(true);
        },
        onDropAccepted: async (acceptedFiles) => {
            resetStagedPage();

            if (!forecast) {
                const msg = t('forecast.error.missingForecastPage');
                handleError({ msg }, msg);
                navigate('/welcome/forecast');
                return;
            }

            let documentId: string;
            let file: File;

            try {
                file = acceptedFiles[0];

                initStagedPage({
                    ...forecast,
                    settings: {
                        document: {
                            name: file.name,
                        },
                    },
                });

                const { id } = await postDocument({ file });

                documentId = id;
            } catch (err) {
                setProcessing(false);

                resetStagedPage();

                return handleError(err, t('forecast.error.failedToUpload'));
            }

            try {
                updateStagedPage({
                    commit: true,
                    preventReset: true,
                    settings: {
                        document: {
                            id: documentId,
                        },
                    },
                });
            } catch (err) {
                handleError(err, t('forecast.error.failedToAddDocumentToPage'));
                setProcessing(false);
                return;
            }
        },
        onDropRejected(fileRejections) {
            const error = fileRejections[0]?.errors?.[0]?.code;
            let errorMessage: string;

            switch (error) {
                case 'file-too-large':
                    errorMessage = t('chatCpg.fileUpload.fileTooLarge');
                    break;
                case 'file-invalid-type':
                    errorMessage = t('chatCpg.fileUpload.invalidFileType');
                    break;
                default:
                    errorMessage = t('error.unknown');
                    break;
            }

            sendAlert({
                severity: 'error',
                text: errorMessage,
            });

            setProcessing(false);
        },
        onError: (err) => {
            handleError(err, t('error.unknown'));
            setProcessing(false);
        },
    });

    const onError = useCallback(() => setProcessing(false), []);

    useDocumentStream({
        onError,
    });

    const isProcessing = processing || isLoading;

    return (
        <SolutionGrid>
            <SolutionHeader
                description={t('forecast.connectYourData.description')}
                title={isDemo ? t('forecast.firstConnectYourData.demo') : t('forecast.firstConnectYourData')}
            />
            <SolutionContent>
                <Flex direction="column" gap="1.5rem">
                    <Typography secondary variant="p-22-r">{t('forecast.selectASampleFile')}</Typography>
                    <Flex gap="3rem" padding="0 0 0 2rem">
                        <SampleDataIcon
                            disabled={isProcessing}
                            label="Single Product"
                            onClick={() => {
                            }}
                        />
                        <SampleDataIcon
                            disabled={isProcessing}
                            label="Product + Competitors"
                            onClick={() => {
                            }}
                        />
                        <SampleDataIcon
                            disabled={isProcessing}
                            label="Category"
                            onClick={() => {
                            }}
                        />
                    </Flex>
                </Flex>
                <Flex direction="column" gap="1.5rem">
                    <Typography secondary variant="p-22-r">{t('forecast.uploadYourOwn')}</Typography>

                    <Flex direction="column" gap="0.8rem">
                        <Flex
                            align="center"
                            gap="1rem"
                            width="100%"
                        >
                            <DropZoneContainer
                                {...getRootProps()}
                                align="center"
                                as="button"
                                direction="column"
                                disabled={isProcessing}
                                height="140px"
                                justify="center"
                                width="min(600px, 100%)"
                            >
                                <input {...getInputProps()} />
                                <Flex
                                    align="center"
                                    direction="column"
                                    gap="1rem"
                                >
                                    <Flex
                                        align="center"
                                        direction="column"
                                        gap="0.5rem"
                                    >
                                        <Typography
                                            nowrap
                                            secondary
                                            variant="p-18-r"
                                        >
                                            {isDocumentComplete && !processing ? t('forecast.replaceExistingFile') : t('forecast.dragAndDropFile')}
                                        </Typography>
                                        <Typography secondary variant="p-18-r">{t('forecast.csvOrXlsx')}</Typography>
                                    </Flex>
                                    {errorMsg && (
                                        <Flex align="center" gap="0.5rem">
                                            <ErrorOutlineIcon color="error" />
                                            <Typography
                                                color={palette.error.main}
                                                variant="p-14-r"
                                            >
                                                {t('forecast.error.validation.dateColumn')}
                                            </Typography>
                                        </Flex>
                                    )}
                                </Flex>
                            </DropZoneContainer>
                            {isProcessing && <StreamingIcon isStreaming size="24px" />}
                        </Flex>
                        <Typography
                            multiline
                            secondary
                            variant="p-14-r"
                            width="min(600px, 100%)"
                        >
                            {t('forecast.fileRequirements')}
                        </Typography>
                        <InfoTooltip
                            component={() => (
                                <Flex
                                    direction="column"
                                    gap="1rem"
                                >
                                    <Flex direction="column" gap="0.5rem">
                                        <Typography variant="h-16-b">{t('util.required')}</Typography>
                                        <Flex
                                            as="ul"
                                            direction="column"
                                            gap="0.4rem"
                                            padding="0 0 0 1.2rem"
                                        >
                                            <Typography as="li" variant="p-14-r">{t('forecast.dataValidation.dateColumn')}</Typography>
                                            <Typography as="li" variant="p-14-r">{t('forecast.dataValidation.metricColumn')}</Typography>
                                            <Typography as="li" variant="p-14-r">{t('forecast.dataValidation.noDuplicateColumnHeaders')}</Typography>
                                            <Typography as="li" variant="p-14-r">{t('forecast.dataValidation.sizeLimit')}</Typography>
                                        </Flex>
                                    </Flex>
                                    <Flex direction="column" gap="0.5rem">
                                        <Typography variant="h-16-b">{t('util.recommended')}</Typography>
                                        <Flex
                                            as="ul"
                                            direction="column"
                                            gap="0.4rem"
                                            padding="0 0 0 1.2rem"
                                        >
                                            <Typography as="li" variant="p-14-r">{t('forecast.dataValidation.2years')}</Typography>
                                            <Typography as="li" variant="p-14-r">{t('forecast.dataValidation.columnNames')}</Typography>
                                        </Flex>
                                    </Flex>

                                </Flex>
                            )}
                            containerStyle={{
                                maxWidth: '500px',
                            }}
                            leaveOpenOnHover
                            placement="right"
                        >
                            <Button
                                startIcon={<HelpOutlineIcon />}
                                style={{
                                    letterSpacing: '0',
                                    textTransform: 'none',
                                }}
                            >
                                {t('forecast.seeDetailedFileRequirements')}
                            </Button>
                        </InfoTooltip>
                    </Flex>
                </Flex>
            </SolutionContent>
            <SolutionFooter>
                {isDocumentComplete && !processing && (
                    <Button
                        component={Link}
                        size="small"
                        style={{ width: '200px' }}
                        to="/solutions/forecast/select-forecast"
                        variant="contained"
                    >
                        {t('util.next')}
                    </Button>
                )}
            </SolutionFooter>
            <SolutionPanel>
                <SolutionPanelHeader
                    text={t('solution.firstOfTwoSteps')}
                />
                <ConnectYourDataSolutionPanelSection active processing={processing} />
                <SolutionPanelSection
                    active={false}
                    done={false}
                    title={t('forecast.2selectScope')}
                />
            </SolutionPanel>
        </SolutionGrid>
    );
}

const DropZoneContainer = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'as' }
)(({ theme }) => ({
    border: `1px dashed ${theme.palette.text.secondary}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
}));
