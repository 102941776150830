import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, useTheme } from '@mui/material';
import { Frequency, Interval } from '@tickr/sequelize-models';
import { Formik } from 'formik';
import omit from 'lodash/omit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { DoubleSelectDropdown } from '../FormElements/DoubleSelectDropdown';
import { SelectDropdown } from '../FormElements/SelectDropdown';
import { SelectDropdownValue } from '../FormElements/SelectDropdownContent';
import { TextArea } from '../FormElements/TextArea';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useTidsMutations } from '../Providers/TidsMutationsProvider/useTidsMutations';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { LogoLoadingMessage } from '../Solutions/RunAnalysisLoading';
import { SolutionContent } from '../Solutions/SolutionContent';
import { SolutionFooter } from '../Solutions/SolutionFooter';
import { SolutionGrid } from '../Solutions/SolutionGrid';
import { SolutionHeader } from '../Solutions/SolutionHeader';
import { SolutionPanel } from '../Solutions/SolutionPanel';
import { SolutionPanelHeader } from '../Solutions/SolutionPanelHeader';
import { SolutionPanelSection } from '../Solutions/SolutionPanelSection';
import { useCovariatesStream } from '../hooks/useCovariateStream';
import { useForecastInProgress } from '../hooks/useForecastInProgress';
import { useYupValidation } from '../hooks/useYupValidation';
import { ConnectYourDataSolutionPanelSection } from './ConnectYourDataSolutionPanelSection';
import { useStagedPageCommitListener } from './useStagedPageCommitListener';

const forecastPeriodDict: Record<Interval, SelectDropdownValue[]> = {
    M: Array.from({ length: 12 }, (_, i) => ({
        title: String(i + 1),
        value: i + 1,
    })),
    W: Array.from({ length: 52 }, (_, i) => ({
        title: String(i + 1),
        value: i + 1,
    })),
    D: Array.from({ length: 180 }, (_, i) => ({
        title: String(i + 1),
        value: i + 1,
    })),
};

const intervals: { title: string; value: Interval }[] = [
    {
        title: 'Months',
        value: 'M',
    },
    {
        title: 'Weeks',
        value: 'W',
    },
    {
        title: 'Days',
        value: 'D',
    },
];

const periodDefaults: Record<Interval, number> = {
    M: 3,
    W: 6,
    D: 14,
};

export function SelectForecast() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();

    const {
        dateColumn,
        documentId,
        enableCovariateSearch,
        errorMsg,
        forecast,
        forecastColumn,
        frequency,
        periods,
        isCovariateSearchComplete,
        userContext,
    } = useForecastInProgress();

    const { postSearchCovariates } = useTidsMutations();
    const { token4096 } = useYupValidation();
    const { sendAlert } = useToaster();
    const { updatePage } = useCollectionMutations();

    const forecastMetrics = forecast?.settings?.document?.numeric_columns?.map(
        (column) => ({
            title: column,
            value: column,
        })
    );

    const [processing, setProcessing] = useState(false);
    const [forecastColumnState, setForecastColumnState] = useState(forecastColumn);

    useStagedPageCommitListener(forecast?.uuid ?? '');

    const { setStatus, status } = useCovariatesStream({
        onError: () => {
            setProcessing(false);
        },
        onComplete: () => {
            navigate('/solutions/forecast/select-covariates');
        },
    });

    if (
        !forecast ||
        !forecastColumn ||
        !forecast.settings.document?.data ||
        !dateColumn ||
        !documentId ||
        !frequency ||
        !forecastMetrics
    ) {
        return null;
    }

    const interval = frequency[0] as Interval;

    const isStreamOpen = processing || (!!documentId && !isCovariateSearchComplete && enableCovariateSearch);
    const formDisabled = isStreamOpen || isCovariateSearchComplete || !!errorMsg;

    return (
        <SolutionGrid hideFooter>
            <SolutionHeader
                title={t('forecast.nextTellUsWhatToForecast')}
            />
            <Formik
                initialValues={{
                    forecastColumn,
                    periods: periods ?? periodDefaults[interval],
                    interval,
                    userContext: userContext ?? '',
                }}
                onSubmit={async (values) => {
                    setProcessing(true);
                    setStatus(t('forecast.loading.searchingForInfluentialFactors'));

                    if (
                        interval === values.interval &&
                        periods === values.periods &&
                        forecastColumn === values.forecastColumn &&
                        userContext === values.userContext
                    ) {
                        navigate('/solutions/forecast/select-covariates');
                        return;
                    }

                    try {
                        await postSearchCovariates({
                            dateColumn,
                            documentId,
                            forecastColumn: values.forecastColumn,
                            interval: values.interval,
                            periods: values.periods,
                            frequency: forecast.settings?.document?.frequency as Frequency,
                            frequencyDays: forecast.settings?.document?.frequency_days as number,
                            userContext: values.userContext,
                        });

                        await updatePage({
                            page: {
                                ...forecast,
                                settings: {
                                    ...forecast.settings,
                                    document: {
                                        ...omit(forecast.settings.document, ['topDocCovariates', 'topFredCovariates']),
                                        enableCovariateSearch: true,
                                        forecastColumn: values.forecastColumn,
                                        periods: values.periods,
                                        userContext: values.userContext,
                                    },
                                },
                            },
                        });
                    } catch (error) {
                        setProcessing(false);
                        setStatus('');

                        sendAlert({
                            severity: 'error',
                            text: JSON.stringify(values),
                        });
                    }
                }}
                validationSchema={Yup.object().shape({
                    userContext: token4096,
                })}
            >
                {({ errors, values, handleChange, handleSubmit, isValid, setFieldValue }) => (
                    <>
                        <SolutionContent style={{ justifyContent: 'space-between' }}>
                            <Flex
                                direction="column"
                                gap="2rem"
                                width="100%"
                            >
                                <Flex gap="2rem" wrap="wrap">
                                    <SelectDropdown
                                        disabled={formDisabled}
                                        onChange={(value) => {
                                            setForecastColumnState(value as string);
                                            setFieldValue('forecastColumn', value);
                                        }}
                                        selected={values.forecastColumn}
                                        title={t('forecast.metricToForecast')}
                                        titleColor={palette.text.secondary}
                                        values={forecastMetrics}
                                        width="325px"
                                    />
                                    <DoubleSelectDropdown
                                        disabled={formDisabled}
                                        onChange1={(value) => setFieldValue('periods', value)}
                                        onChange2={(value) => {
                                            const options = forecastPeriodDict[value as Interval];

                                            const periods = options.find(({ value }) => value === values.periods)
                                                ? values.periods
                                                : options[0].value;

                                            setFieldValue('periods', periods);
                                            setFieldValue('interval', value);
                                        }}
                                        selected1={values.periods}
                                        selected2={values.interval}
                                        textOnly2
                                        title={t('forecast.selectedTheForecastTimeFrame')}
                                        titleColor={palette.text.secondary}
                                        values1={forecastPeriodDict[values.interval]}
                                        values2={intervals}
                                        width="calc(300px + 0.2rem)"
                                        width1="80px"
                                        width2="200px"
                                    />
                                </Flex>
                                <Flex
                                    direction="column"
                                    gap="4px"
                                    width="100%"
                                >
                                    <Typography
                                        secondary
                                        style={{ paddingLeft: '8px' }}
                                        variant="p-14-r"
                                    >
                                        {t('forecast.provideContext')}
                                    </Typography>
                                    <TextArea
                                        containerStyle={{
                                            maxWidth: '100%',
                                        }}
                                        disabled={formDisabled}
                                        error={errors.userContext}
                                        id="userContext"
                                        max={4096}
                                        name="userContext"
                                        onChange={handleChange}
                                        placeholder={isCovariateSearchComplete ? undefined : t('forecast.context.placeholder')}
                                        resize="vertical"
                                        style={{
                                            minHeight: '60px',
                                            height: '300px',
                                            maxHeight: '500px',
                                        }}
                                        title={t('forecast.thisWillHelp')}
                                        titleColor={palette.text.secondary}
                                        tokenize
                                        value={values.userContext}
                                    />
                                </Flex>
                                {errorMsg && (
                                    <Flex align="center" gap="0.5rem">
                                        <ErrorOutlineIcon color="error" />
                                        <Typography
                                            color={palette.error.main}
                                            variant="p-14-r"
                                        >
                                            {t('forecast.error.covariateSearchError')}
                                        </Typography>
                                    </Flex>
                                )}
                            </Flex>
                        </SolutionContent>
                        <SolutionFooter>
                            <Flex align="center" gap="1rem">
                                <Button
                                    color="middleGray"
                                    disabled={isStreamOpen}
                                    onClick={() => {
                                        navigate('/solutions/forecast/connect-your-data');
                                    }}
                                    size="small"
                                    style={{ width: '200px' }}
                                    variant="contained"
                                >
                                    {t('util.back')}
                                </Button>
                                <Button
                                    disabled={isStreamOpen || (!values.periods) || !isValid || !!errorMsg}
                                    onClick={() => handleSubmit()}
                                    size="small"
                                    style={{ width: '200px' }}
                                    variant="contained"
                                >
                                    {t('util.next')}
                                </Button>
                                {status && <LogoLoadingMessage message={status} />}
                            </Flex>
                        </SolutionFooter>
                    </>
                )}
            </Formik>
            <SolutionPanel>
                <SolutionPanelHeader
                    text={t('solution.secondOfTwoSteps')}
                />
                <ConnectYourDataSolutionPanelSection forecastColumn={forecastColumnState} />
                <SolutionPanelSection
                    active
                    done={false}
                    title={t('forecast.2selectScope')}
                />
            </SolutionPanel>
        </SolutionGrid>
    );
}

