import { Placement, UseFloatingReturn, arrow, autoUpdate, flip, offset, safePolygon, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole } from '@floating-ui/react';
import { MutableRefObject, useMemo, useState } from 'react';
import { UseInteractionsReturn } from '../Popover/types';

export interface TooltipOptions {
    initialOpen?: boolean;
    placement?: Placement;
    fallbackPlacements?: Placement[];
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    arrowRef?: MutableRefObject<HTMLDivElement | null>;
    crossAxisOffset?: number;
    mainAxisOffset?: number;
    zindex?: number;
    leaveOpenOnHover?: boolean;
    background?: string;
}

interface UseTooltipReturn extends UseFloatingReturn, UseInteractionsReturn {
    background?: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    arrowRef?: MutableRefObject<HTMLDivElement | null>;
}

export function useTooltip({
    background,
    initialOpen = false,
    placement = 'bottom',
    fallbackPlacements = [
        'right-start',
        'left-start',
        'bottom',
        'top',
    ],
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    arrowRef,
    crossAxisOffset = -5,
    mainAxisOffset = 10,
    leaveOpenOnHover = false,
}: TooltipOptions = {}): UseTooltipReturn {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset({
                mainAxis: mainAxisOffset,
                crossAxis: crossAxisOffset,
            }),
            shift({ padding: 10 }),
            flip({
                fallbackAxisSideDirection: 'end',
                fallbackPlacements,
            }),
            arrowRef && arrow({ element: arrowRef }),
        ],
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        ...(leaveOpenOnHover && { handleClose: safePolygon() }),
    });

    const focus = useFocus(context, {
        enabled: controlledOpen == null,
    });

    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const interactions = useInteractions([
        hover,
        focus,
        dismiss,
        role,
    ]);

    return useMemo(
        () => ({
            background,
            open,
            setOpen,
            ...interactions,
            ...data,
            arrowRef,
        }),
        [
            background,
            open,
            setOpen,
            interactions,
            data,
            arrowRef,
        ]
    );
}
