import { useTheme } from '@mui/material';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function HtmlIcon(props: IconProps) {
    const { palette } = useTheme();

    return (
        <IconWrapper {...props} color="">
            <svg
                fill="none"
                height="60"
                viewBox="0 0 50 60"
                width="50"
            >
                <path
                    clipRule="evenodd"
                    d="M7.15381 25.3627V3.59351H31.2968V14.2968C31.2968 15.9537 32.6399 17.2968 34.2968 17.2968H45V55.0111H7.15381V42.3627H32C33.6569 42.3627 35 41.0196 35 39.3627V28.3627C35 26.7059 33.6569 25.3627 32 25.3627H7.15381ZM4.15381 25.3627V3.59351C4.15381 1.93665 5.49696 0.593506 7.15381 0.593506H31.7278C32.7886 0.593506 33.806 1.01493 34.5562 1.76508L34.6978 1.90672L34.711 1.91979L46.6738 13.8826L46.6868 13.8957L46.8284 14.0373C47.5786 14.7875 48 15.8049 48 16.8658V55.0111C48 56.668 46.6569 58.0111 45 58.0111H7.15381C5.49696 58.0111 4.15381 56.668 4.15381 55.0111V42.3627H3C1.34315 42.3627 0 41.0196 0 39.3627V28.3627C0 26.7059 1.34315 25.3627 3 25.3627H4.15381ZM34.2968 14.2968V5.74832L42.8453 14.2968H34.2968Z"
                    fill={palette.salmon.main}
                    fillRule="evenodd"
                    opacity="0.7"
                />
                <path
                    d="M8.03256 30.0543H9.67656V38.4543H8.03256V34.9743H4.91256V38.4543H3.25656V30.0543H4.91256V33.3903H8.03256V30.0543ZM16.793 30.0543V31.6383H14.525V38.4543H12.869V31.6383H10.613V30.0543H16.793ZM25.9375 30.0543V38.4543H24.2935V33.0063L21.9295 36.9063H21.7375L19.3735 33.0183V38.4543H17.7175V30.0543H19.4215L21.8335 34.0263L24.2335 30.0543H25.9375ZM29.1587 36.8703H32.3627V38.4543H27.5027V30.0543H29.1587V36.8703Z"
                    fill={palette.background.default}
                />
            </svg>
        </IconWrapper>
    );
}
