import { Theme } from '@mui/material';

export const gradientWrapStyles = (theme: Theme) => `
    position: relative;
    justify-content: center;
    display: flex;
    bottom: 0;
    border-radius: ${theme.shape.borderRadius}px;
    padding: 1px;
    background: ${theme.palette.gradient.chatCpg};
`;
