import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button, Radio, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeIndicator } from '../Core/ChangeIndicator';
import { Flex } from '../Core/Flex';
import { NumberFormatter } from '../Core/NumberFormatter';
import { TickrErrorBoundary } from '../Core/TickrErrorBoundary';
import { Typography } from '../Core/Typography';
import { Header } from '../Navigation/Header';
import { PageLayout } from '../PageLayout/PageLayout';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useApp } from '../Providers/AppProvider/useApp';
import { useUser } from '../Providers/UserProvider/useUser';
import { LogoLoadingMessage } from '../Solutions/RunAnalysisLoading';
import { HeadCell, TickrHeadCell } from '../Table/TickrHeadCell';
import { TickrTable } from '../Table/TickrTable';
import { TickrTableBody } from '../Table/TickrTableBody';
import { TickrTableCell } from '../Table/TickrTableCell';
import { TickrTableContainer } from '../Table/TickrTableContainer';
import { TickrTableHead } from '../Table/TickrTableHead';
import { TickrTableRow } from '../Table/TickrTableRow';
import { TickrTableSortLabel } from '../Table/TickrTableSortLabel';
import { useTableSort } from '../Table/useTableSort';
import { CurrencyChangeCell } from '../Tables/Cells/CurrencyChangeCell';
import { ProductCell } from '../Tables/Cells/ProductCell';
import { HEADER_HEIGHT } from '../helpers/constants';
import { numberStyleHelper } from '../helpers/numberStyleHelper';
import { useLaunchOptimizePricing } from '../hooks/onLaunchAnalysis/useLaunchOptimizePricing';
import { useCaptureTickrException } from '../hooks/useCaptureTickrException';

interface PricingAlertData {
    banner: string;
    categoryLWRevenueTotalForecast: number;
    categoryRevenueChange: number;
    gap: number;
    gtin: string;
    LWRevenueTotalForecast: number;
    pRevenueChange: number;
    price: number;
    productDescription: string;
    recommended_price: number;
}

interface PricingAlertTableData {
    id: string;
    gtin: string;
    name: string;
    banner: string;
    salesForecast: number;
    salesForecastChange: number;
    restOfCategoryForecast: number;
    restOfCategoryForecastChange: number;
    growthGap: number;
    price: number;
    priceChange: number;
    recommendedPrice: number;
}

export function PricingAlert() {
    const captureTickrException = useCaptureTickrException();
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { pricingApiRoute } = useApp();
    const { tidsApi } = useApi();
    const { isNumerator } = useUser();

    const { data: products = [] } = useQuery({
        queryKey: ['static-pricing-alert'],
        queryFn: async ({ signal }) => {
            const { data } = await tidsApi.get<PricingAlertData[]>(`v1${pricingApiRoute}/synth/executive-view`, {
                params: {
                    ids: [
                        '00016000122543',
                        '00016000123991',
                        '00016000124790',
                    ],
                },
                signal,
            });

            return data
                .map((item) => ({
                    id: `${item.gtin}-${item.banner}`,
                    gtin: item.gtin,
                    name: item.productDescription.split(' ').slice(1).join(' '),
                    banner: item.banner,
                    salesForecast: item.LWRevenueTotalForecast,
                    salesForecastChange: item.pRevenueChange,
                    restOfCategoryForecast: item.categoryLWRevenueTotalForecast,
                    restOfCategoryForecastChange: item.categoryRevenueChange,
                    growthGap: item.pRevenueChange - item.categoryRevenueChange,
                    price: item.price,
                    priceChange: item.recommended_price - item.price,
                    recommendedPrice: item.recommended_price,
                }))
                .filter(({ recommendedPrice }) => recommendedPrice !== 0);
        },
    });

    const launchOptimizePricing = useLaunchOptimizePricing();

    const [selectedProduct, setSelectedProduct] = useState<PricingAlertTableData>();

    useEffect(() => {
        if (!selectedProduct && products.length > 0) {
            setSelectedProduct(products[0]);
        }
    }, [products, selectedProduct]);

    const headCells: HeadCell<PricingAlertTableData>[] = useMemo(() => [
        {
            value: 'gtin',
            label: t('metrics.product'),
        },
        {
            value: 'banner',
            label: t('metrics.retailer'),
        },
        {
            value: 'salesForecast',
            label: t('metrics.salesForecast'),
        },
        {
            value: 'restOfCategoryForecast',
            label: t('metrics.restOfCategoryForecast'),
        },
        {
            align: 'right',
            value: 'growthGap',
            label: t('metrics.growthGap'),
        },
        {
            align: 'right',
            value: 'price',
            label: t('metrics.currentPrice'),
        },
        {
            value: 'priceChange',
            label: t('metrics.recommendedPrice'),
        },
    ], [t]);

    const [isRunning, setIsRunning] = useState(false);

    const { order, setOrder, orderBy, setOrderBy, sortedRows } = useTableSort({
        data: products,
        initOrder: 'asc',
        initOrderBy: 'growthGap',
    });

    return (
        <PageLayout
            pageTitle={t('page.pricingAlert')}
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header pageTitle="Pricing Alert" />
            <TickrErrorBoundary message={t('errorBoundary.page', { pageName: t('page.pricingAlert') })} padding="1rem">
                <Flex
                    direction="column"
                    gap="2rem"
                    grow="1"
                    height={`calc(100vh - ${HEADER_HEIGHT}px)`}
                    padding="3rem 4rem"
                    width="100%"
                >
                    <Flex direction="column" gap="1rem">
                        <Typography as="h1">{t('executiveView.title')}</Typography>
                        <Typography
                            multiline
                            secondary
                            style={{ maxWidth: '495px' }}
                            variant="p-16-r"
                        >
                            {t('executiveView.description')}
                        </Typography>
                    </Flex>
                    <TickrTableContainer>
                        <TickrTable>
                            <TickrTableHead>
                                <TickrTableRow background={palette.background.default}>
                                    <TickrHeadCell padding="checkbox" />
                                    {headCells.map(({ align, value, label }) => (
                                        <TickrHeadCell
                                            align={align}
                                            key={value}
                                            order={order}
                                            orderBy={orderBy}
                                            value={value}
                                        >
                                            <TickrTableSortLabel
                                                id={value}
                                                label={label}
                                                order={order}
                                                orderBy={orderBy}
                                                setOrder={setOrder}
                                                setOrderBy={setOrderBy}
                                            />
                                        </TickrHeadCell>
                                    ))}
                                </TickrTableRow>
                            </TickrTableHead>
                            <TickrTableBody>
                                {sortedRows.map((row) => {
                                    const isSelected = selectedProduct?.id === row.id;

                                    return (
                                        <TickrTableRow
                                            aria-checked={isSelected}
                                            hover
                                            key={row.id}
                                            onClick={() => setSelectedProduct(row)}
                                            role="radio"
                                            selected={isSelected}
                                            sx={{ cursor: 'pointer' }}
                                            tabIndex={-1}
                                        >
                                            <TickrTableCell padding="checkbox">
                                                <Radio checked={isSelected} />
                                            </TickrTableCell>
                                            <TickrTableCell
                                                component="th"
                                                id={`pricing-alert-table-${row.id}`}
                                                scope="row"
                                            >
                                                <ProductCell id={row.gtin} name={row.name} />
                                            </TickrTableCell>
                                            <TickrTableCell>{row.banner}</TickrTableCell>
                                            <TickrTableCell>
                                                <CurrencyChangeCell
                                                    change={row.salesForecastChange}
                                                    value={row.salesForecast}
                                                />
                                            </TickrTableCell>
                                            <TickrTableCell>
                                                <CurrencyChangeCell
                                                    borderColor={palette.text.secondary}
                                                    change={row.restOfCategoryForecastChange}
                                                    textColor={palette.text.secondary}
                                                    value={row.restOfCategoryForecast}
                                                />
                                            </TickrTableCell>
                                            <TickrTableCell>
                                                <Flex justify="flex-end" width="100%">
                                                    <ChangeIndicator
                                                        borderColor={palette.text.primary}
                                                        numberStyle="percentagePoint"
                                                        val={row.growthGap}
                                                    />
                                                </Flex>
                                            </TickrTableCell>
                                            <TickrTableCell align="right">
                                                <NumberFormatter
                                                    as="span"
                                                    color={palette.text.secondary}
                                                    numberStyle="currency"
                                                    val={row.price}
                                                    variant="p-14-b"
                                                />
                                            </TickrTableCell>
                                            <TickrTableCell>
                                                <Flex align="center" gap="0.4rem">
                                                    {row.priceChange > 0 ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
                                                    <Typography
                                                        color={palette.text.primary}
                                                        nowrap
                                                        variant="p-14-b"
                                                    >
                                                        {row.priceChange > 0
                                                            ? t('metrics.recommendedPriceChange.increase', {
                                                                priceChange: numberStyleHelper(row.priceChange, 'currency').display(),
                                                                recommendedPrice: numberStyleHelper(row.recommendedPrice, 'currency').display(),
                                                            })
                                                            : t('metrics.recommendedPriceChange.decrease', {
                                                                priceChange: numberStyleHelper(row.priceChange, 'currency').display(),
                                                                recommendedPrice: numberStyleHelper(row.recommendedPrice, 'currency').display(),
                                                            })}
                                                    </Typography>
                                                </Flex>
                                            </TickrTableCell>
                                        </TickrTableRow>
                                    );
                                })}
                            </TickrTableBody>
                        </TickrTable>
                    </TickrTableContainer>
                    <Flex gap="1rem">

                        <Button
                            disabled={!selectedProduct || isRunning}
                            onClick={async () => {
                                setIsRunning(true);

                                if (!selectedProduct || !launchOptimizePricing) return;

                                try {
                                    await launchOptimizePricing({
                                        banner: selectedProduct.banner,
                                        datasources: isNumerator ? 'Numerator': 'Synthetic',
                                        gtin: selectedProduct.gtin,
                                        marketSummary: selectedProduct.banner,
                                        productDescription: selectedProduct.name,
                                    });

                                    setIsRunning(false);
                                } catch (e) {
                                    captureTickrException(e);
                                }
                            }}
                            size="small"
                            style={{ width: '210px' }}
                            variant="contained"
                        >
                            {t('util.openAnalysis')}
                        </Button>

                        {isRunning && <LogoLoadingMessage message={t('solution.openingAnalysis')} />}
                    </Flex>
                </Flex>
            </TickrErrorBoundary>
        </PageLayout>
    );
}
