import { useKeycloak } from '@react-keycloak/web';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { PropsWithChildren, useMemo } from 'react';
import { useApp } from '../AppProvider/useApp';
import { ApiContext } from './ApiContext';

export function ApiProvider({ children }: PropsWithChildren) {
    const { keycloak } = useKeycloak();
    const { dashboardApiUrl, publicApiUrl, tidsApiUrl } = useApp();

    let dashboardApiBaseUrl = dashboardApiUrl;
    let publicApiBaseUrl = publicApiUrl;
    let tidsApiBaseUrl = tidsApiUrl;

    const host = window.location.host;

    if (host.endsWith('.nip.io') || host.includes('.k8s.')) {
        // Supports running the frontend within kubernetes
        dashboardApiBaseUrl = `//api.${window.location.host.replace(/.*?\./, '')}`;
        publicApiBaseUrl = `//api.${window.location.host.replace(/.*?\./, '')}`;
        tidsApiBaseUrl = `//api.${window.location.host.replace(/.*?\./, '')}`;
    }

    const dashboardApi = axios.create({
        baseURL: dashboardApiBaseUrl,
        headers: {
            Authorization: `Bearer ${keycloak.token}`,
        },
    });

    const publicApi = axios.create({
        baseURL: publicApiBaseUrl,
        headers: {
            Authorization: `Bearer ${keycloak.token}`,
        },
    });

    const tidsApi = axios.create({
        baseURL: tidsApiBaseUrl,
        headers: {
            Authorization: `Bearer ${keycloak.token}`,
        },
    });

    async function updateTokenInterceptor(config: InternalAxiosRequestConfig) {
        try {
            await keycloak.updateToken(5);
        } catch (err) {
            keycloak.logout();
        }

        config.headers.Authorization = `Bearer ${keycloak.token}`;
        return config;
    }

    dashboardApi.interceptors.request.use(updateTokenInterceptor);
    publicApi.interceptors.request.use(updateTokenInterceptor);
    tidsApi.interceptors.request.use(updateTokenInterceptor);

    const apiContextValue = useMemo(
        () => ({
            dashboardApi,
            publicApi,
            tidsApi,
        }),
        [
            dashboardApi,
            publicApi,
            tidsApi,
        ]
    );

    return (
        <ApiContext.Provider value={apiContextValue}>
            {children}
        </ApiContext.Provider>
    );
}
