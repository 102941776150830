import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Accordion, AccordionDetails, AccordionSummary, Button, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { IconProps } from '../Core/IconWrapper';
import { SearchBar } from '../Core/SearchBar';
import { Typography } from '../Core/Typography';
import { TickrDialog } from '../Dialog/TickrDialog';
import { TickrDialogActions } from '../Dialog/TickrDialogActions';
import { TickrDialogContent } from '../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../Dialog/TickrDialogTitle';
import { Mp4Icon } from '../Icons/Mp4Icon';
import { PdfIcon } from '../Icons/PdfIcon';
import { PptxIcon } from '../Icons/PptxIcon';
import { XlsxIcon } from '../Icons/XlsxIcon';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';
import { useHighlightSearchTerm } from '../hooks/useHighlightSearchTerm';
import { ChatInput } from './ChatInput';
import { SuggestionChip } from './SuggestionChip';

interface File {
    type: 'pdf' | 'pptx' | 'mp4' | 'xlsx';
    title: string;
}

interface ResourceCenterDialogProps {
    open: boolean;
    onClose: () => void;
}

export function ResourceCenterDialog({ open, onClose }: ResourceCenterDialogProps) {
    const { t } = useTranslation();
    const { knowledgeBaseChatInputRef } = useChatBoxForm();

    const [search, setSearch] = useState('');

    const files: File[] = [
        {
            title: t('chatCpg.100.files.substitutionAnalysis'),
            type: 'pdf',
        },
        {
            type: 'pptx',
            title: t('chatCpg.100.files.pricingAnalysis'),
        },
        {
            type: 'mp4',
            title: t('chatCpg.100.files.presentationVideo'),
        },
    ];

    const myFiles: File[] = [
        {
            title: t('chatCpg.100.files.productAttributesDemo'),
            type: 'xlsx',
        },
        {
            type: 'pdf',
            title: t('chatCpg.100.files.pdfDemo'),
        },
        {
            type: 'xlsx',
            title: t('chatCpg.100.files.xlsxDemoNielsen'),
        },
        {
            type: 'xlsx',
            title: t('chatCpg.100.files.xlsxDemoIRI'),
        },
    ];

    return (
        <TickrDialog
            maxWidth="lg"
            onClose={onClose}
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    padding: '1px',
                    background: ({ palette }) => palette.gradient.chatCpg,
                },
            }}
        >
            <TickrDialogTitle
                onClose={onClose}
                sx={{
                    borderRadius: ({ shape }) => `${shape.borderRadius}px ${shape.borderRadius}px 0 0`,
                    backgroundColor: ({ palette }) => palette.background.default,
                }}
            >
                {t('chatCpg.resourceCenter')}
            </TickrDialogTitle>
            <TickrDialogContent
                sx={{ backgroundColor: ({ palette }) => palette.background.default }}
            >
                <Flex direction="column" gap="2rem">
                    <SearchBar
                        autofocus
                        inputId="100-questions-search"
                        inputWidth="400px"
                        onSearch={setSearch}
                        placeholder={t('chatCpg.100.searchPlaceholder')}
                        poll
                        search={search}
                    />

                    <ResourceCenterSection search={search} title={t('chatCpg.popularCpgBusinessQuestions')}>
                        <Flex direction="column" width="100%">
                            <QuestionSection
                                defaultExpanded
                                questions={[
                                    t('chatCpg.suggestion.demographicMakeup'),
                                    t('chatCpg.suggestion.marketShare'),
                                    t('chatCpg.100.customerResponse'),
                                    t('chatCpg.100.successRate'),
                                    t('chatCpg.100.newCompetition'),
                                    t('chatCpg.100.priceChangesOfCompetitors'),
                                ]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.marketShare')}
                            />

                            <QuestionSection
                                questions={[
                                    t('chatCpg.100.successRate'),
                                    t('chatCpg.100.newCompetition'),
                                    t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                                    t('chatCpg.100.channelLevelPricing'),
                                ]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.distribution')}
                            />
                            <QuestionSection
                                questions={[
                                    t('chatCpg.100.successRate'),
                                    t('chatCpg.100.newCompetition'),
                                    t('chatCpg.100.priceChangesOfCompetitors'),
                                    t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                                    t('chatCpg.100.channelLevelPricing'),
                                    t('chatCpg.100.complimentaryItems'),
                                    t('chatCpg.100.productsLeadingLagging'),
                                    t('chatCpg.100.redundantProducts'),
                                    t('chatCpg.100.lowSellingDraws'),
                                    t('chatCpg.100.trialAndRepeat'),
                                    t('chatCpg.100.householdsHighestTrial'),
                                    t('chatCpg.100.topProductsSameBasket'),
                                    t('chatCpg.100.averageBasketSize'),
                                ]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.assortment')}
                            />
                            <QuestionSection
                                questions={[
                                    t('chatCpg.100.complimentaryItems'),
                                    t('chatCpg.100.topProductsSameBasket'),
                                    t('chatCpg.100.averageBasketSize'),
                                    t('chatCpg.100.highlyCorrelatedCategories'),
                                ]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.basket')}
                            />
                            <QuestionSection
                                questions={[
                                    t('chatCpg.100.pricesOverTime'),
                                    t('chatCpg.100.priceTrendsVsCompetitors'),
                                    t('chatCpg.100.priceTrendAcrossRetailers'),
                                    t('chatCpg.100.priceTrendComparedToInflation'),
                                    t('chatCpg.100.priceChangesOfCompetitors'),
                                    t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                                    t('chatCpg.100.channelLevelPricing'),
                                    t('chatCpg.100.complimentaryItems'),
                                    t('chatCpg.100.productsLeadingLagging'),
                                ]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.promotion')}
                            />
                            <QuestionSection
                                questions={[
                                    t('chatCpg.100.successRate'),
                                    t('chatCpg.100.pricesOverTime'),
                                    t('chatCpg.100.priceTrendsVsCompetitors'),
                                    t('chatCpg.100.priceTrendAcrossRetailers'),
                                    t('chatCpg.100.priceTrendComparedToInflation'),
                                    t('chatCpg.100.priceChangesOfCompetitors'),
                                    t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                                    t('chatCpg.100.channelLevelPricing'),
                                    t('chatCpg.100.productsLeadingLagging'),
                                ]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.pricing')}
                            />
                            <QuestionSection
                                questions={[t('chatCpg.100.productsLeadingLagging')]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.supplyChain')}
                            />
                            <QuestionSection
                                questions={[
                                    t('chatCpg.100.successRate'),
                                    t('chatCpg.100.productsLeadingLagging'),
                                    t('chatCpg.100.pricesOverTime'),
                                    t('chatCpg.100.priceTrendsVsCompetitors'),
                                    t('chatCpg.100.priceTrendAcrossRetailers'),
                                    t('chatCpg.100.priceTrendComparedToInflation'),
                                    t('chatCpg.100.priceChangesOfCompetitors'),
                                    t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                                    t('chatCpg.100.channelLevelPricing'),
                                    t('chatCpg.100.complimentaryItems'),
                                    t('chatCpg.100.redundantProducts'),
                                    t('chatCpg.100.lowSellingDraws'),
                                    t('chatCpg.100.trialAndRepeat'),
                                    t('chatCpg.100.householdsHighestTrial'),
                                    t('chatCpg.100.topProductsSameBasket'),
                                    t('chatCpg.100.averageBasketSize'),
                                    t('chatCpg.100.highlyCorrelatedCategories'),
                                ]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.shopperBehaviors')}
                            />
                            <QuestionSection
                                questions={[
                                    t('chatCpg.100.successRate'),
                                    t('chatCpg.100.newCompetition'),
                                    t('chatCpg.100.productsLeadingLagging'),
                                    t('chatCpg.100.pricesOverTime'),
                                    t('chatCpg.100.priceTrendsVsCompetitors'),
                                    t('chatCpg.100.priceTrendAcrossRetailers'),
                                    t('chatCpg.100.priceTrendComparedToInflation'),
                                    t('chatCpg.100.priceChangesOfCompetitors'),
                                    t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                                    t('chatCpg.100.channelLevelPricing'),
                                    t('chatCpg.100.complimentaryItems'),
                                    t('chatCpg.100.trialAndRepeat'),
                                    t('chatCpg.100.householdsHighestTrial'),
                                    t('chatCpg.100.topProductsSameBasket'),
                                    t('chatCpg.100.averageBasketSize'),
                                    t('chatCpg.100.highlyCorrelatedCategories'),
                                ]}
                                search={search}
                                sectionTitle={t('chatCpg.popularCpgBusinessQuestions')}
                                title={t('chatCpg.100.section.brandHealthAndStrategy')}
                            />
                        </Flex>
                    </ResourceCenterSection>
                    <FileSection
                        files={files}
                        search={search}
                        title={t('chatCpg.100.presentationBestPractices')}
                    />
                    <DataSourceGuidesSection datasources={['Numerator', 'IRI', 'NielsenIQ', 'Ibotta']} search={search} />
                    <FileSection
                        files={myFiles}
                        search={search}
                        title={t('chatCpg.100.myFiles')}
                    />
                </Flex>
            </TickrDialogContent>
            <TickrDialogActions
                sx={{
                    backgroundColor: ({ palette }) => palette.background.default,
                    borderRadius: ({ shape }) => `0 0 ${shape.borderRadius}px ${shape.borderRadius}px`,
                }}

            >
                <ChatInput
                    onSubmit={onClose}
                    ref={knowledgeBaseChatInputRef}
                />
            </TickrDialogActions>
        </TickrDialog>
    );
}

interface ResourceCenterSectionProps {
    children: ReactNode;
    search: string;
    title: string;
}

function ResourceCenterSection({ children, search, title }: ResourceCenterSectionProps) {
    const highlightSearchTerm = useHighlightSearchTerm();

    return (
        <Flex
            direction="column"
            gap="1rem"
            width="100%"
        >
            <Typography style={{ letterSpacing: '1px' }} variant="p-14-r">
                {highlightSearchTerm(title, search)}
            </Typography>
            {children}
        </Flex>
    );
}

interface QuestionSectionProps {
    defaultExpanded?: boolean;
    sectionTitle: string;
    title: string;
    questions: string[];
    search: string;
}

function QuestionSection({ defaultExpanded, sectionTitle, title, questions, search }: QuestionSectionProps) {
    const [expanded, setExpanded] = useState(false);
    const { onClickSuggestionKnowledgeBase } = useChatBoxForm();
    const highlightSearchTerm = useHighlightSearchTerm();

    const searchRegex = new RegExp(search, 'i');

    const displayQuestions = search
        ? questions.filter(
            (question) => searchRegex.test(question) || searchRegex.test(title) || searchRegex.test(sectionTitle)
        )
        : questions;

    if (!displayQuestions.length) return <></>;

    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            expanded={expanded || !!search}
            onChange={() => setExpanded((expanded) => !expanded)}
            sx={{
                width: '100%',
                backgroundColor: ({ palette }) => palette.background.default,
            }}
        >
            <AccordionSummary expandIcon={!search && <ExpandLessIcon style={{ transform: 'rotate(180deg)' }} />}>
                <Typography style={{ letterSpacing: '1px' }} variant="p-14-r">
                    {highlightSearchTerm(title, search)}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Flex direction="column" gap="0.5rem">
                    {displayQuestions.map((question) => (
                        <SuggestionChip
                            key={title + question}
                            onClick={onClickSuggestionKnowledgeBase}
                            text={question}
                            textAlign="left"
                        >
                            {highlightSearchTerm(question, search)}
                        </SuggestionChip>
                    ))}
                </Flex>
            </AccordionDetails>
        </Accordion>
    );
}

interface DataSourceGuidesSectionProps {
    search: string;
    datasources: string[];
}

function DataSourceGuidesSection({ search, datasources: datasources }: DataSourceGuidesSectionProps) {
    const { t } = useTranslation();
    const highlightSearchTerm = useHighlightSearchTerm();

    const searchRegex = new RegExp(search, 'i');

    const matches = search
        ? datasources.filter((ds) => searchRegex.test(ds) || searchRegex.test(t('resourceCenter.datasourceGuides')))
        : datasources;

    if (!matches.length) return <></>;

    return (
        <ResourceCenterSection
            search={search}
            title={t('resourceCenter.datasourceGuides')}
        >
            <Flex gap="1rem">
                {matches.map((button) => {
                    return (
                        <Button
                            color="info"
                            key={button}
                            size="small"
                            variant="outlined"
                        >
                            {highlightSearchTerm(button, search)}
                        </Button>
                    );
                })}
            </Flex>
        </ResourceCenterSection>

    );
}

interface FileSectionProps {
    title: string;
    search: string;
    files: File[];
}

function FileSection({ title, search, files }: FileSectionProps) {
    const searchRegex = new RegExp(search, 'i');

    const fileMatches = search
        ? files.filter((file) => searchRegex.test(file.title) || searchRegex.test(file.type) || searchRegex.test(title))
        : files;

    if (!fileMatches.length) return <></>;

    return (
        <ResourceCenterSection
            search={search}
            title={title}
        >
            <Flex gap="0.5rem">
                {fileMatches.map(({ title, type }) => (
                    <ProjectTileBlank
                        key={title}
                        search={search}
                        title={title}
                        type={type}
                    />
                ))}
            </Flex>
        </ResourceCenterSection>
    );
}

const sharedProps: IconProps = {
    size: '50px',
    style: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -20%)',
    },
};

interface ProjectTileBlankProps {
    search: string;
    title: string;
    type: 'pdf' | 'pptx' | 'mp4' | 'xlsx';
}

function ProjectTileBlank({
    search,
    title,
    type,
}: ProjectTileBlankProps) {
    const { palette, shape } = useTheme();
    const highlightSearchTerm = useHighlightSearchTerm();

    return (
        <Flex
            as="button"
            direction="column"
            gap="0.2rem"
            padding="0.5rem"
            style={{
                background: palette.background.secondary,
                border: `1px solid ${palette.background.tertiary}`,
                borderRadius: shape.borderRadius,
                height: '140px',
                width: '190px',
                textAlign: 'left',
            }}
        >
            <Typography as="h4">
                {highlightSearchTerm(title, search)}
            </Typography>
            <Typography secondary variant="h-12-b">
                .
                {highlightSearchTerm(type, search)}
            </Typography>
            {type === 'pdf' && <PdfIcon {...sharedProps} />}
            {type === 'pptx' && <PptxIcon {...sharedProps} />}
            {type === 'mp4' && <Mp4Icon {...sharedProps} />}
            {type === 'xlsx' && <XlsxIcon {...sharedProps} />}
        </Flex>
    );
}
