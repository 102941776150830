import { useQuery } from '@tanstack/react-query';
import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { NewsCard, ParsedObject } from '@tickr/sequelize-models/src/types/libBiTypes';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Grid } from '../Core/Grid';
import { Typography } from '../Core/Typography';
import { useActiveFilters } from '../Filters/ActiveFiltersProvider/useActiveFilters';
import { SentimentDonut } from '../Graphs/SentimentDonut';
import { useApi } from '../Providers/ApiProvider/useApi';
import { NewsPost } from './NewsPost';
import { ProgressBar } from './ProgressBar';
import { SentimentBar } from './SentimentBar';

interface NewsReportProps {
    newsCard: NewsCard;
}

export function NewsReport({ newsCard }: NewsReportProps) {
    const { t } = useTranslation();
    const { publicApi } = useApi();
    const { combinedFilters } = useActiveFilters();

    const { data: newsStories = [] } = useQuery({
        queryKey: ['news cluster', combinedFilters, newsCard],
        queryFn: async () => {
            const { data } = await publicApi.post<ParsedObject<NewsCard[]>>(
                'data/news/list',
                {
                    filters: {
                        clusterId: newsCard.clusterId,
                        searches: String(newsCard.topicId),
                        ...combinedFilters,
                    },
                    options: {
                        obj: true,
                        types: ['news'],
                    },
                }
            );

            return data.news.primary;
        },
        enabled: newsCard.clusterSize > 1,
    });

    const currentNewsStory = newsStories[0] ?? newsCard;

    const sentimentFilters: AppFilters = {
        search_platforms: 'only_news',
        searches: String(newsCard.topicId),
    };

    const metrics: [string, number][] = [
        [t('metrics.impact'), currentNewsStory.metrics.impact],
        [t('metrics.relevance'), currentNewsStory.metrics.relevance],
        [t('metrics.influence'), currentNewsStory.metrics.influence],
        [t('metrics.trust'), currentNewsStory.metrics.trust],
        [t('metrics.velocity'), currentNewsStory.metrics.velocity],
    ];

    return (
        <Grid
            colGap="3rem"
            style={{ overflowY: 'auto' }}
            templateColumns="1fr 1fr 1fr"
        >
            <Flex
                direction="column"
                gap="1.5rem"
                style={{ overflowY: 'auto' }}
            >
                <Typography as="h2">{t('cardReport.news')}</Typography>
                <NewsPost
                    news={currentNewsStory}
                    textLength={2000}
                />
            </Flex>
            <Flex
                direction="column"
                gap="1.5rem"
                height="100%"
                style={{ overflowY: 'auto' }}
            >
                <Typography as="h2">{t('cardReport.metricBreakdown')}</Typography>
                <Flex
                    direction="column"
                    gap="2rem"
                    height="calc(100% + 20px)"
                    style={{ overflowY: 'auto' }}
                    width="100%"
                >
                    {metrics.map(([name, amount]) => (
                        <ProgressBar
                            amount={amount}
                            key={name}
                            name={name}
                        />
                    ))}
                    <SentimentBar amount={currentNewsStory.metrics.sentiment} />
                </Flex>
            </Flex>
            <Flex
                direction="column"
                gap="1.5rem"
                style={{ overflowY: 'auto' }}
            >
                <Typography as="h2">
                    {t('cardReport.newsSentiment')}
                    {' '}
                    -
                    {currentNewsStory.topic}
                </Typography>
                <SentimentDonut filters={sentimentFilters} />
            </Flex>
        </Grid>
    );
}
