import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ElementAttributes } from '../../../../sequelize-models/src/main';
import { ChatCpgTimestamp } from '../../ChatCpg/ChatCpgTimestamp';
import { StreamCursor } from '../../ChatCpg/StreamCursor';
import { StreamingIcon } from '../../ChatCpg/StreamingIcon';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { ForecastChartPopover } from '../../Popovers/ForecastChartPopover/ForecastChartPopover';
import { InfoTooltip } from '../../Tooltips/InfoTooltip';
import { useChatCpgData } from '../../hooks/useChatCpgData';
import { StreamingText } from '../../hooks/useChatCpgStore';
import { usePage } from '../../hooks/usePage';
import { EditableTitle } from './Components/EditableTitle';
import { OuterToolbarWrapper } from './Components/OuterToolbarWrapper';
import { ToolbarDivider } from './Components/ToolbarDivider';
import { ToolbarWrapper } from './Components/ToolbarWrapper';

interface ForecastChartToolbarProps {
    element: ElementAttributes;
    setEditingTitle: (editing: boolean) => void;
    title: string;
    toolbarControls: ReactNode;
}

export function ForecastChartToolbar({
    element, setEditingTitle, title, toolbarControls,
}: ForecastChartToolbarProps) {
    const { t } = useTranslation();
    const { forecastSummaryData, isStreaming: isStreamingPage, summaryId, summaryCreatedAt } = usePage();

    const scopeStream = useChatCpgData<StreamingText>(['forecast-scope', summaryId ?? '']);

    const isStreaming = isStreamingPage && scopeStream?.isStreaming !== false;

    return (
        <OuterToolbarWrapper
            id={element.uuid}
        >
            <ToolbarWrapper toolbarControls={toolbarControls}>
                <ForecastChartPopover element={element} />
                <EditableTitle
                    element={element}
                    setEditingTitle={setEditingTitle}
                    title={title}
                />
                <InfoTooltip
                    component={() => (
                        <Flex direction="column" gap="1rem">
                            <Flex align="center" gap="0.5rem">
                                <StreamingIcon isStreaming={isStreamingPage} size="24px" />
                                <Typography variant="h-22-b">{t('forecast.scope')}</Typography>
                                <ChatCpgTimestamp
                                    containerStyle={{ paddingLeft: '0.3rem' }}
                                    isStreaming={isStreaming}
                                    timestamp={summaryCreatedAt}
                                />
                            </Flex>
                            <Typography
                                style={{
                                    whiteSpace: 'pre-line',
                                }}
                                variant="p-14-b"
                            >
                                {scopeStream?.text ?? forecastSummaryData?.forecastScope}
                                {isStreaming !== false && <StreamCursor />}
                            </Typography>
                        </Flex>
                    )}
                    leaveOpenOnHover
                    maxWidth="700px"
                >
                    <IconButton
                        size="small"
                        style={{
                            width: '24px',
                            height: '24px',
                        }}
                    >
                        <HelpOutlineIcon fontSize="inherit" />
                    </IconButton>
                </InfoTooltip>
            </ToolbarWrapper>
            <ToolbarDivider />
        </OuterToolbarWrapper>
    );
}
