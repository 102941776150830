import { useTheme } from '@mui/material';
import { ChatBubbleHeader } from '../ChatCpg/ChatBubbleHeader';
import { StreamCursor } from '../ChatCpg/StreamCursor';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';

interface ResponseBubbleV2Props {
    error?: boolean;
    isStreaming?: boolean;
    messageId: string;
    text: string | null;
    timestamp?: number;
}

export function ResponseBubbleV2({
    messageId,
    text,
    timestamp,
}: ResponseBubbleV2Props) {
    const { palette, shape } = useTheme();
    const textStream = useChatCpgData<StreamingText>(['message-stream', messageId]);

    const isStreaming = text === null || (!!textStream?.isStreaming && !!messageId && text === null);
    const displayText = isStreaming ? textStream?.text ?? null : text;

    return (
        <Flex
            direction="column"
            gap="1rem"
            padding="1rem"
            style={{
                borderRadius: shape.borderRadius,
                backgroundColor: palette.background.default,
                maxWidth: '90%',
            }}
        >
            <ChatBubbleHeader
                date={timestamp}
                hideFeedback
                id={messageId}
                isStreaming={isStreaming}
                text=""
            />
            <Typography style={{ whiteSpace: 'pre-line' }} variant="p-14-b">
                {displayText}
                {isStreaming && <StreamCursor />}
            </Typography>
        </Flex>
    );
}
