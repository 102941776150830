import { Typography } from '../Core/Typography';

interface SolutionPanelHeaderProps {
    text: string;
}

export function SolutionPanelHeader({ text }: SolutionPanelHeaderProps) {
    return (
        <Typography variant="p-18-r">
            {text}
        </Typography>
    );
}
