import { useMergeRefs } from '@floating-ui/react';
import { HTMLProps, ReactNode, cloneElement, forwardRef, isValidElement } from 'react';
import { usePopoverContext } from './usePopoverContext';

interface PopoverTriggerProps {
    children: ReactNode;
    asChild?: boolean;
}

export const PopoverTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & PopoverTriggerProps>(
    function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
        const context = usePopoverContext();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const childrenRef = (children as any).ref;

        const ref = useMergeRefs([
            context.refs.setReference,
            propRef,
            childrenRef,
        ]);

        // `asChild` allows the user to pass any element as the anchor
        if (asChild && isValidElement(children)) {
            return cloneElement(
                children,
                context.getReferenceProps({
                    ref,
                    ...props,
                    ...children.props,
                    'data-state': context.open ? 'open' : 'closed',
                })
            );
        }

        return (
            <button
                data-state={context.open ? 'open' : 'closed'}
                ref={ref}
                type="button"
                {...context.getReferenceProps(props)}
            >
                {children}
            </button>
        );
    }
);
