import { styled } from '@mui/material';

export function StreamCursor() {
    return <StyledSpan>▊</StyledSpan>;
}

const StyledSpan = styled('span')`
    animation: cursor-blink 0.8s infinite;
    padding-left: 3px;

    @keyframes cursor-blink {
        0% {
        opacity: 0;
        }
    }
`;
