import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useForecastInProgress } from '../useForecastInProgress';
import { useForecastInProgressLink } from '../useForecastInProgressLink';
import { useHandleError } from '../useHandleError';

export function useLaunchForecastInProgress() {
    const { t } = useTranslation();
    const { createPage } = useCollectionMutations();
    const navigate = useNavigate();
    const { forecast } = useForecastInProgress();
    const handleError = useHandleError();
    const link = useForecastInProgressLink();
    const [isLoading, setIsLoading] = useState(false);

    return {
        launchForecastInProgress: async () => {
            setIsLoading(true);

            try {
                if (!forecast) {
                    await createPage({
                        filterDef: { activeFilters: {} },
                        inProgress: true,
                        isTemplate: false,
                        layoutType: 'standard',
                        settings: {
                            document: {},
                        },
                        solutionType: 'forecast',
                        title: t('forecast.smartForecastInProgress'),
                    });
                }

                navigate(link);
            } catch (err) {
                handleError(err, t('error.unknown'));
            }

            setIsLoading(false);
        },
        isLoading,
    };
}
