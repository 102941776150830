import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatButton } from '../ChatCpg/ChatButton';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { TickrDialog } from '../Dialog/TickrDialog';
import { TickrDialogContent } from '../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../Dialog/TickrDialogTitle';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { useTidsMutations } from '../Providers/TidsMutationsProvider/useTidsMutations';
import { ChatHistoryV2 } from './ChatHistoryV2';
import { ChatInputV2 } from './ChatInputV2';
import { ChatPopover } from './ChatPopover';
import { SampleQuestions } from './SampleQuestions';
import { useChats } from './useChats';

export function ChatDialog() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [chatId, setChatId] = useState('');
    const [messageStreamId, setMessageStreamId] = useState('');
    const { postChatV2 } = useTidsMutations();

    const queryClient = useQueryClient();

    const { chats } = useChats();
    const onClose = () => setOpen(false);

    return (
        <>
            <ChatButton onClick={() => setOpen(true)} />
            <TickrDialog
                fullWidth
                maxWidth="xl"
                onClose={onClose}
                open={open}
            >
                <TickrDialogTitle
                    onClose={onClose}
                >
                    <Flex align="center" gap="1rem">
                        <ChatCpgIcon size="24px" />
                        {t('chatCpg')}
                    </Flex>
                </TickrDialogTitle>
                <TickrDialogContent
                    sx={{
                        display: 'flex',
                        gap: '1rem',
                        overflow: 'hidden',
                    }}
                >
                    <List
                        sx={{
                            background: ({ palette }) => palette.background.tertiary,
                            borderRadius: ({ shape }) => `${shape.borderRadius}px`,
                            p: 0,
                            overflow: 'scroll',
                            minWidth: '350px',
                            width: '350px',
                        }}
                    >
                        <ListItem>
                            <ListItemButton
                                onClick={() => {
                                    setChatId('');
                                    setMessageStreamId('');
                                }}
                                sx={{
                                    borderRadius: ({ shape }) => `${shape.borderRadius}px`,
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: '32px' }}>
                                    <ChatCpgIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('chatCpg.newChat')} />
                            </ListItemButton>
                        </ListItem>
                        {chats.map(({ uuid, name }) => (
                            <ListItem
                                disablePadding
                                key={uuid}
                                secondaryAction={<ChatPopover chatId={uuid} />}
                                sx={{
                                    px: 1,
                                }}
                            >
                                <ListItemButton
                                    onClick={() => {
                                        setChatId(uuid);
                                        queryClient.invalidateQueries({ queryKey: ['chat-history', uuid] });
                                    }}
                                    selected={chatId === uuid}
                                    sx={{
                                        borderRadius: ({ shape }) => `${shape.borderRadius}px`,
                                        py: 1.5,
                                    }}
                                >
                                    <Typography truncate variant="p-14-b">{uuid}</Typography>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Flex
                        align="center"
                        direction="column"
                        width="100%"
                    >
                        <Flex
                            align="center"
                            direction="column"
                            gap="2rem"
                            grow={1}
                            justify="space-between"
                            overflow="scroll"
                            style={{
                                maxWidth: '800px',
                            }}
                            width="100%"
                        >
                            {
                                chatId
                                    ? (
                                        <ChatHistoryV2
                                            chatId={chatId}
                                            messageStreamId={messageStreamId}
                                            setMessageStreamId={setMessageStreamId}
                                        />
                                    )
                                    : (
                                        <SampleQuestions
                                            onClick={(message) => {
                                                postChatV2({
                                                    message,
                                                    onChatCreation: setChatId,
                                                    onMessageCreation: setMessageStreamId,
                                                });
                                            }}
                                        />
                                    )
                            }
                            <ChatInputV2
                                chatId={chatId}
                                setChatId={setChatId}
                                setMessageStreamId={setMessageStreamId}
                            />
                        </Flex>
                    </Flex>
                </TickrDialogContent>
            </TickrDialog>
        </>
    );
}

