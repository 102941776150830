import { faker } from '@faker-js/faker';

export interface OosTableData {
    category: string;
    brand: string;
    product: {
        upc: string;
        name: string;
    };

    state: string;
    metroArea: string;
    store: string;

    banner: string;
    retailer: string;

    oosEvents: number;
    oosTrend: {
        historical: [number, number][];
        forecast: [number, number][];
    };

    oosStoreLevelHistorical?: boolean[];
    oosStoreLevelForecast?: boolean[];

    test?: string;
}

export const fakeData: OosTableData[] = Array.from({ length: 10 }, () => ({
    category: faker.commerce.department(),
    brand: faker.company.name(),
    product: {
        upc: faker.string.fromCharacters('0123456789', 14),
        name: faker.commerce.productDescription(),
    },

    state: faker.location.state(),
    metroArea: faker.location.city(),
    store: faker.company.name(),

    banner: faker.company.name(),
    retailer: faker.company.name(),

    oosEvents: faker.number.int({
        min: 0,
        max: 500,
    }),

    oosTrend: {
        historical: Array.from({ length: 30 }, (_, i) => [
            i,
            faker.number.int({
                min: 0,
                max: 100,
            }),
        ]),
        forecast: Array.from({ length: 10 }, (_, i) => [
            i + 30,
            faker.number.int({
                min: 0,
                max: 100,
            }),
        ]),
    },

    oosStoreLevelHistorical: Array.from({ length: 10 }, () => faker.datatype.boolean()),
    oosStoreLevelForecast: Array.from({ length: 10 }, () => faker.datatype.boolean()),
}));
