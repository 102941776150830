import { styled, useTheme } from '@mui/material';
import { encodingForModel } from 'js-tiktoken';
import { CSSProperties, ChangeEvent, FocusEvent, HTMLInputTypeAttribute, KeyboardEvent, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../Core/Typography';
import { FormElementWrapper } from './FormElementWrapper';

const encoder = encodingForModel('gpt-4-turbo-preview');

export interface TextAreaProps {
    autoComplete?: string;
    autoFocus?: boolean;
    background?: string;
    className?: string;
    containerStyle?: CSSProperties;
    description?: string;
    disabled?: boolean;
    error?: string;
    height?: string;
    id: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    onSubmit?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
    placeholderColor?: string;
    max?: number;
    name: string;
    placeholder?: string;
    required?: boolean;
    resize?: CSSProperties['resize'];
    style?: CSSProperties;
    title?: string;
    titleColor?: string;
    tokenize?: boolean;
    type?: HTMLInputTypeAttribute;
    value?: string;
    width?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
    autoComplete,
    autoFocus,
    background,
    className,
    containerStyle,
    description,
    disabled,
    error,
    height,
    id,
    onChange,
    onKeyDown,
    onSubmit,
    onFocus,
    onBlur,
    max,
    name,
    placeholder,
    placeholderColor,
    required,
    resize = 'none',
    style,
    title,
    titleColor,
    tokenize = false,
    value = '',
    width = '100%',
}, ref) => {
    const { t }= useTranslation();
    const { palette } = useTheme();

    return (
        <FormElementWrapper
            description={description}
            error={error}
            style={containerStyle}
            title={title}
            titleColor={titleColor}
            width={width}
        >
            <StyledTextArea
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                background={background}
                className={className ?? (value ? 'p-16-r' : 'p-16-i')}
                disabled={disabled}
                error={error}
                height={height}
                id={id}
                name={name}
                onBlur={onBlur}
                onChange={(e) => {
                    onChange?.(e);
                }}
                onFocus={onFocus}
                onKeyDown={onKeyDown ??
                    ((e) => {
                        if (e.key === 'Enter') onSubmit?.(e);
                    })
                }
                placeholder={placeholder}
                placeholderColor={placeholderColor}
                ref={ref}
                required={required}
                resize={resize}
                style={style}
                value={value}
                width={width}
            />
            {max && (
                <Typography
                    color={error ? palette.error.main : palette.text.secondary}
                    style={{
                        position: 'absolute',
                        right: 12,
                        bottom: -18,
                    }}
                >
                    {tokenize
                        ? t('input.maxTokens', {
                            value: encoder.encode(value).length,
                            max,
                        })
                        : t('input.maxCharacters', {
                            value: value.length,
                            max,
                        })}
                </Typography>
            )}
        </FormElementWrapper>
    );
});

const StyledTextArea = styled('textarea')<TextAreaProps>(({
    background,
    error,
    height,
    placeholderColor,
    resize,
    theme,
    width,
}) => `
    background-color: ${background ?? theme.palette.background.secondaryMask};
    border: 1px solid ${error ? theme.palette.error.main : theme.palette.middleGray.main};
    border-radius: ${theme.shape.borderRadius}px;
    color: ${theme.palette.text.primary};
    height: ${height ?? '75px'};
    padding: 8px 12px;
    resize: ${resize};
    width: ${width};

    :focus {
        border: 1px solid ${error ? theme.palette.error.main : theme.palette.primary.main};
        outline: 1px solid ${error ? theme.palette.error.main : theme.palette.primary.main};
    }

    :disabled {
        opacity: 0.5;
    }

    ::placeholder {
        color: ${placeholderColor ?? theme.palette.text.secondary};
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${theme.palette.background.secondaryMask} inset;
        -webkit-text-fill-color: ${theme.palette.text.primary};
        border: 1px solid ${theme.palette.middleGray.main};
        caret-color: ${theme.palette.text.primary};
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`);
