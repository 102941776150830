import SendIcon from '@mui/icons-material/Send';
import { IconButton, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { TextArea } from '../FormElements/TextArea';
import { MessageItem } from '../Providers/ChatBoxProvider/ChatBoxContext';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';
import { usePage } from '../hooks/usePage';
import { StreamingIcon } from './StreamingIcon';

export interface ChatInputProps {
    onSubmit?(): void;
}

export const ChatInput = forwardRef<HTMLTextAreaElement, ChatInputProps>(
    ({ onSubmit }, ref) => {
        const { t } = useTranslation();
        const { palette } = useTheme();

        const {
            chatStreamId,
            conversation,
            fileStreamId,
            setChatInput,
        } = useChatBox();

        const { handleChange, handleSubmit, setMessage, values } = useChatBoxForm();
        const { isStreaming } = usePage();

        const disabled = !!fileStreamId || !!chatStreamId || isStreaming;

        return (
            <Flex
                direction="column"
                gap="0.5rem"
                width="100%"
            >
                {isStreaming && (
                    <Flex align="center" gap="0.5rem">
                        <StreamingIcon isStreaming={isStreaming} />
                        <Typography variant="p-12-i">{t('chatCpg.isStreaming.analysis')}</Typography>
                    </Flex>
                )}
                <Flex
                    align="center"
                    gap="8px"
                    width="100%"
                >
                    <TextArea
                        className={values.message ? 'p-14-b' : 'p-14-i'}
                        height="60px"
                        id="message"
                        name="message"
                        onChange={(e) => {
                            setChatInput(e.target.value);
                            handleChange?.(e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();

                                if (!disabled) {
                                    onSubmit?.();
                                    handleSubmit();
                                }
                            }

                            if (e.key === 'ArrowUp' && !values.message) {
                                const lastMessage = conversation.find(
                                    (item) => item.type === 'message' && item.sender === 'HUMAN'
                                ) as MessageItem | undefined;

                                if (lastMessage) {
                                    e.preventDefault();
                                    setMessage(lastMessage.text);
                                }
                            }
                        }}
                        placeholder={t('chatCpg.input.placeholder')}
                        placeholderColor={palette.primary.dark}
                        ref={ref}
                        resize="none"
                        style={{
                            padding: '12px 48px 12px 12px',
                        }}
                        value={values.message}
                    />
                    <IconButton
                        aria-label={t('iconButton.aria.sendChatMessage')}
                        color="primary"
                        disabled={disabled}
                        onClick={() => {
                            onSubmit?.();
                            handleSubmit();
                        }}
                        style={{
                            position: 'absolute',
                            right: '10px',
                        }}
                    >
                        <SendIcon />
                    </IconButton>
                </Flex>
            </Flex>
        );
    }
);
