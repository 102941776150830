import { Box, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Dispatch, SetStateAction } from 'react';
import { p12b } from '../Core/typographyVariants';
import { SortOrder } from './sortTableData';

interface TickrTableSortLabelProps<T> {
    id: T;
    label: string;
    nowrap?: boolean;
    order: SortOrder;
    orderBy: T;
    setOrder: (order: SortOrder) => void;
    setOrderBy: Dispatch<SetStateAction<T>>;
}

export function TickrTableSortLabel<T extends string>(
    { id, label, nowrap, order, orderBy, setOrder, setOrderBy }: TickrTableSortLabelProps<T>
) {
    return (
        <TableSortLabel
            active={orderBy === id}
            direction={orderBy === id ? order : 'asc'}
            onClick={() => {
                if (orderBy === id) {
                    setOrder(order === 'asc' ? 'desc' : 'asc');
                } else {
                    setOrder('asc');
                    setOrderBy(id);
                }
            }}
            sx={({ palette, shape }) => ({
                ':focus-visible': {
                    background: palette.action.focus,
                    borderRadius: `${shape.borderRadius}px`,
                    outline: `1px solid ${palette.info.main}`,
                },
                ...(nowrap && { whiteSpace: 'nowrap' }),
                ...p12b,
            })}
        >
            {label}
            {orderBy === id
                ? (
                    <Box
                        component="span"
                        sx={visuallyHidden}
                    >
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                )
                : null}
        </TableSortLabel>
    );
}
