import { PageAttributes } from '@tickr/sequelize-models';
import { PartialDeep } from 'type-fest';
import { create } from 'zustand';
import { pureMerge } from '../helpers/pureMerge';

export interface StagedPage extends PageAttributes {
    commit?: boolean;
    navigateTo?: string;
    preventReset?: boolean;
}

export interface StreamingText {
    text: string;
    isStreaming: boolean;
}

type StoreValues =
  | StreamingText
  | PageAttributes
  | ClearStreamTextSignature
  | UpdateStoreSignature
  | UpdateStreamTextSignature
  | UpdateStagedPageSignature
  | string
  | undefined;

type UpdateStreamTextSignature = (
    key: string | string[],
    value: StreamingText,
    format?: (value?: string) => string
) => void;
type UpdateStoreSignature = (key: string | string[], value: StoreValues) => void;
type ClearStreamTextSignature = (summaryId: string) => void;
type UpdateStagedPageSignature = (forecastUuid: string, update: PartialDeep<StagedPage>) => void;

interface ChatCpgStore {
    [key: string]: StoreValues;
    deleteStoreValue: (key: string) => void;
    clearStreamText: (partialKey: string) => void;
    deleteStagedDocument: (forecastUuid: string) => void;
    updateStreamText: UpdateStreamTextSignature;
    updateStore: UpdateStoreSignature;
    updateStagedPageStoreValue: UpdateStagedPageSignature;
}

export const useChatCpgStore = create<ChatCpgStore>((set) => ({
    clearStreamText: (partialKey) => set((state) => {
        const newState = { ...state };

        if (!partialKey) {
            return state;
        }

        Object.keys(newState).forEach((key) => {
            if (key.includes(partialKey)) {
                newState[key] = {
                    text: '',
                    isStreaming: false,
                };
            }
        });

        return newState;
    }),

    deleteStoreValue: (key) => set((state) => {
        const newState = { ...state };
        newState[JSON.stringify(key)] = undefined;
        return newState;
    }),

    deleteStagedDocument: (forecastUuid: string) => set((state) => {
        const existingPage = state[JSON.stringify(forecastUuid)] as StagedPage;

        if (existingPage?.settings?.document) {
            delete existingPage.settings.document;
        }

        return {
            ...state,
            [JSON.stringify(forecastUuid)]: {
                commit: true,
                ...existingPage,
            },
        };
    }),

    updateStreamText: (key, value, format = (val?: string) => val ?? '') => set((state) => {
        const existingText = (state[JSON.stringify(key)] as StreamingText)?.text ?? '';

        const formattedText = existingText.slice(0, existingText.length - 16) +
            format(existingText.slice(existingText.length - 16) + value.text);

        return {
            ...state,
            [JSON.stringify(key)]: {
                isStreaming: value.isStreaming,
                text: formattedText,
            },
        };
    }),

    updateStagedPageStoreValue: (forecastUuid: string, update: PartialDeep<StagedPage>) => set((state) => {
        const existingPage = state[JSON.stringify(forecastUuid)] || {};

        return {
            ...state,
            [JSON.stringify(forecastUuid)]: pureMerge(existingPage, update) as StagedPage,
        };
    }),

    updateStore: (key, value) => set((state) => ({
        ...state,
        [JSON.stringify(key)]: value,
    })),
}));
