import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { createContext } from 'react';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';

export interface ActiveFiltersContextProps {
    activeFilters: AppFilters;
    combinedFilters: AppFilters;
    outerFilters: AppFilters;
    typeOverrides?: Record<string, FilterDefinition['type']>;
}

export const ActiveFiltersContext = createContext<ActiveFiltersContextProps>({
    activeFilters: {},
    combinedFilters: {},
    outerFilters: {},
});
