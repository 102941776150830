import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, styled } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { TickrDialog } from '../Dialog/TickrDialog';
import { TickrDialogContent } from '../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../Dialog/TickrDialogTitle';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useCaptureTickrException } from '../hooks/useCaptureTickrException';

interface ProductAttributesDialogProps {
    open: boolean;
    onClose: () => void;
}

export function ProductAttributesDialog({ open, onClose }: ProductAttributesDialogProps) {
    const { t } = useTranslation();
    const captureTickrException = useCaptureTickrException();
    const { dashboardApi } = useApi();
    const queryClient = useQueryClient();
    const { sendAlert } = useToaster();

    const uploadFile = useMutation({
        mutationFn: (file: File) => {
            return new Promise((resolve, reject) => {
                if (!file) return;

                const reader = new FileReader();

                reader.onload = async () => {
                    try {
                        await dashboardApi.post<never, AxiosResponse<unknown>, { csv: string }>(
                            'upload/product-attributes',
                            {
                                csv: reader.result?.toString().replace(/^data:.*?;base64,/, '') ?? '',
                            }
                        );
                    } catch (e) {
                        captureTickrException(e);
                        reject(e);
                    }

                    sendAlert({
                        text: t('productAttributes.uploadSuccess'),
                        severity: 'success',
                    });

                    resolve(null);
                };

                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['my-products'] });
            onClose();
        },
        onError(e: AxiosError<{ msg: string }>) {
            captureTickrException(e);

            sendAlert({
                text: e.response?.data.msg ?? '',
                severity: 'error',
            });
        },
    });

    return (
        <TickrDialog onClose={onClose} open={open}>
            <TickrDialogTitle onClose={onClose}>{t('productAttributes')}</TickrDialogTitle>
            <TickrDialogContent>
                <Flex align="center">
                    <Button
                        component="label"
                        role={undefined}
                        startIcon={<CloudUploadIcon />}
                        tabIndex={-1}
                        variant="contained"
                    >
                        {t('util.uploadCSV')}
                        <VisuallyHiddenInput
                            onChange={(e) => {
                                const file = e.target.files?.[0];

                                if (!file) {
                                    sendAlert({
                                        text: t('error.unknown'),
                                        severity: 'error',
                                    });

                                    return;
                                }

                                uploadFile.mutateAsync(file);
                            }}
                            type="file"
                        />
                    </Button>
                </Flex>
            </TickrDialogContent>
        </TickrDialog>
    );
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
