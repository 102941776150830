import { useTheme } from '@mui/material';

export function useInsightCardBackgrounds(storeKey: string) {
    const { palette } = useTheme();

    const backgrounds = {
        'recommendations-insights': `${palette.cornflowerBlue.main}80`, // 50% opacity hex
    };

    const focusBackgrounds = {
    };

    return {
        background: backgrounds[storeKey as keyof typeof focusBackgrounds] ??
            palette.background.secondary,
        focusBackground: focusBackgrounds[storeKey as keyof typeof focusBackgrounds] ??
            palette.background.secondaryMask,
    };
}
