import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ChatBubbleHeader } from './ChatBubbleHeader';
import { DotLoader } from './DotLoader';
import { StreamCursor } from './StreamCursor';
import { SuggestionChip } from './SuggestionChip';

interface ChatCpgBubbleProps {
    chips?: string[];
    error?: boolean;
    fileName?: string;
    id: string;
    isStreaming?: boolean;
    text?: string;
    timestamp?: number;
}

export function ChatCpgBubble({
    chips,
    error,
    fileName,
    id,
    isStreaming,
    text,
    timestamp,
}: ChatCpgBubbleProps) {
    const { t } = useTranslation();
    const { palette, shape } = useTheme();

    return (
        <Flex
            direction="column"
            gap="1rem"
            padding="1rem"
            style={{
                borderRadius: shape.borderRadius,
                backgroundColor: palette.background.secondary,
                maxWidth: '90%',
                border: `1px solid ${error ? palette.error.main : 'transparent'}`,
            }}
        >
            <ChatBubbleHeader
                date={timestamp}
                hideFeedback={!!fileName}
                id={id}
                isStreaming={isStreaming}
                text={text ?? null}
            />
            {!error && fileName && !isStreaming && (
                <Typography style={{ whiteSpace: 'pre-line' }} variant="p-14-b">
                    {t('chatCpg.fileSummary.hereIsAFileSummary', { fileName })}
                </Typography>
            )}
            <Typography style={{ whiteSpace: 'pre-line' }} variant="p-14-b">
                {!error ? text : t('chatCpg.chat.error')}
                {!error && isStreaming && !fileName && <StreamCursor />}
                {!error && isStreaming && fileName && (
                    <Flex align="baseline" gap="4px">
                        <Typography variant="p-14-b">{t('chatCpg.fileUpload.analyzing')}</Typography>
                        <DotLoader />
                    </Flex>
                )}
            </Typography>
            {chips && chips?.length > 0 && (
                <>
                    <Typography variant="p-14-b">{t('chatCpg.fileSummary.youCanAskQuestions')}</Typography>
                    <Flex direction="column" gap="0.5rem">
                        {chips?.map((chip) => (
                            <SuggestionChip
                                key={chip}
                                text={chip}
                                textAlign="left"
                            />
                        ))}
                    </Flex>
                </>
            )}
        </Flex>
    );
}
