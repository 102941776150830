import { Covariate, Frequency, Interval } from '@tickr/sequelize-models';
import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import { createContext } from 'react';

export interface IdResponse {
    id: string;
}

type Model = 'davinci' | 'ada' | 'gpt-4';

export interface PostSummaryProps {
    banner?: string;
    channel?: string;
    gtins: string[];
    model?: Model;
    summaryName: Exclude<SolutionType, 'forecast'>;
    weeks?: number;
}

export interface PostForecastSummaryProps {
    covariates: Covariate[];
    dateColumn: string;
    documentId: string;
    forecastColumn: string;
    frequency: Frequency;
    frequencyDays: number;
    periods: number;
    summaryName: 'forecast';
    userContext: string;
}

export interface PostChatV2Props {
    message: string;
    onChatCreation: (chatId: string) => void;
    onMessageCreation: (messageId: string) => void;
}

export interface PostMessageV2Props {
    chatId: string;
    message: string;
    onMessageCreation: (messageId: string) => void;
    context?: string[];
}

export interface PostChatProps {
    message: string;
    model?: Model;
    summaryId: string;
    weeks?: number;
}

export type FeedbackLabel = 'good' | 'bad';
export type FeedbackType = 'headline' | 'summary';

interface SharedFeedbackProps {
    category: string;
    explanation: string;
    feedback: string;
    label: FeedbackLabel;
}

export interface ChatFeedbackProps extends SharedFeedbackProps {
    chatMessageId: string;
}

export interface PostSummaryFeedbackProps extends SharedFeedbackProps {
    talkingPointId: string;
    type: FeedbackType;
    summaryId: string;
}

export interface PostDocumentProps {
    file: File;
    summaryId?: string;
}

export type FeedbackResponse = unknown;

export interface PostSearchCovariatesProps {
    dateColumn: string;
    documentId: string;
    forecastColumn: string;
    periods: number;
    interval: Interval;
    frequency: Frequency;
    frequencyDays: number;
    userContext: string;
}

interface PostChatV2Response {
    chatId: string;
    message: string;
    onMessageCreation: (messageId: string) => void;
}

interface PostMessageV2Response {
    messageId: string;
    chatId: string;
}

export interface TidsMutationsContextProps {
    deleteChatV2(id: string): Promise<string>;
    postChat(props: PostChatProps): Promise<IdResponse>;
    postChatFeedback(props: ChatFeedbackProps): Promise<FeedbackResponse>;
    postChatV2(props: PostChatV2Props): Promise<PostChatV2Response>;
    postDocument(props: PostDocumentProps): Promise<IdResponse>;
    postForecastSummary(props: PostForecastSummaryProps): Promise<IdResponse>;
    postMessageV2(props: PostMessageV2Props): Promise<PostMessageV2Response>;
    postSearchCovariates(props: PostSearchCovariatesProps): Promise<IdResponse>;
    postSummary(props: PostSummaryProps): Promise<IdResponse>;
    postSummaryFeedback(props: PostSummaryFeedbackProps): Promise<FeedbackResponse>;
}

export const TidsMutationsContext = createContext<TidsMutationsContextProps>({
    deleteChatV2: async () => '',
    postChat: async () => ({ id: '' }),
    postChatFeedback: async () => ({} as FeedbackResponse),
    postChatV2: async () => ({
        chatId: '',
        message: '',
        onMessageCreation: () => void 0,
    }),
    postDocument: async () => ({ id: '' }),
    postForecastSummary: async () => ({ id: '' }),
    postMessageV2: async () => ({
        chatId: '',
        messageId: '',
    }),
    postSearchCovariates: async () => ({ id: '' }),
    postSummary: async () => ({ id: '' }),
    postSummaryFeedback: async () => ({} as FeedbackResponse),
});
