import { createContext } from 'react';

export interface UserContextProps {
    hasSmartScorecard: boolean;
    hasSubstitutions: boolean;
    integrations: string[];
    isAdmin: boolean;
    isDemo: boolean;
    isNumerator: boolean;
    msId?: number;
    userId?: number;
}

export const UserContext = createContext<UserContextProps>({
    hasSmartScorecard: false,
    hasSubstitutions: false,
    integrations: [],
    isAdmin: false,
    isDemo: false,
    isNumerator: false,
});
