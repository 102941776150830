import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, List, ListItem, ListItemButton, Popover, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useTidsMutations } from '../Providers/TidsMutationsProvider/useTidsMutations';

interface ChatPopoverProps {
    chatId: string;
}

export function ChatPopover({ chatId }: ChatPopoverProps) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { deleteChatV2 } = useTidsMutations();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const open = !!anchorEl;

    const id = open ? 'chat-popover' : undefined;

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton
                aria-describedby={id}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                size="small"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
                open={open}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
            >
                <List
                    sx={{
                        gap: 0,
                        px: 1,
                    }}
                >
                    {/* <ChatPopoverListItem icon={<EditIcon fontSize="small" />} text={t('util.edit')} /> */}
                    <ChatPopoverListItem
                        color={palette.error.main}
                        icon={<DeleteOutlineIcon color="error" fontSize="small" />}
                        onClick={() => {
                            deleteChatV2(chatId);
                            handleClose();
                        }}
                        text={t('util.delete')}
                    />
                </List>
            </Popover>
        </>
    );
}

interface ChatPopoverListItemProps {
    color?: string;
    icon: ReactNode;
    onClick: () => void;
    text: string;
}

function ChatPopoverListItem({ color, icon, onClick, text }: ChatPopoverListItemProps) {
    return (
        <ListItem
            sx={{
                py: 0,
                px: 0,
            }}
        >
            <ListItemButton
                onClick={onClick}
                sx={{ borderRadius: ({ shape }) => `${shape.borderRadius}px` }}
            >
                <Flex align="center" gap="1rem">
                    {icon}
                    <Typography
                        color={color}
                        variant="p-16-r"
                    >
                        {text}
                    </Typography>
                </Flex>
            </ListItemButton>
        </ListItem>
    );
}
