import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useDateFormatter } from '../hooks/useDateFormatter';

interface ChatCpgTimestampProps {
    containerStyle?: React.CSSProperties;
    isEdited?: boolean;
    isStreaming: boolean;
    onRestore?: () => void;
    timestamp?: number;
}

export function ChatCpgTimestamp({
    containerStyle,
    isEdited,
    isStreaming,
    onRestore,
    timestamp,
}: ChatCpgTimestampProps) {
    const { t } = useTranslation();
    const dateFormatter = useDateFormatter();

    return (
        <Flex
            align="center"
            gap="0.3rem"
            style={{
                minHeight: '24px',
                ...containerStyle,
            }}
        >
            <Typography secondary>
                {isEdited
                    ? t('chatCpg.editedByUser')
                    : isStreaming
                        ? t('chatCpg.generating')
                        : t('chatCpg.byChatCpg')}
            </Typography>
            <Typography secondary>·</Typography>
            <Typography secondary>
                {isStreaming || !timestamp ? t('util.now') : dateFormatter(timestamp, 'fromNow')}
            </Typography>
            {isEdited && onRestore && (
                <IconButton
                    aria-label={t('iconButton.aria.restore')}
                    color="secondaryText"
                    onClick={onRestore}
                    size="small"
                    style={{
                        width: '24px',
                        height: '24px',
                        ...(isStreaming && { visibility: 'hidden' }),
                    }}
                >
                    <SettingsBackupRestoreIcon fontSize="inherit" />
                </IconButton>
            )}
        </Flex>
    );
}
