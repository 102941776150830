import { useActiveFilters } from '../Filters/ActiveFiltersProvider/useActiveFilters';
import { SalesPerformanceInsight, SalesPerformanceInsights } from './SalesPerformanceInsights';

const DUMMY_INSIGHTS: SalesPerformanceInsight[] = [
    {
        id: '1',
        gtin: '0016000123991',
        type: 'risk',
        value: 395000,
        numberStyle: 'currencyEstimate',
        description:
      'Lucky Charms Cereal, 10.5oz in Cold Cereal Kids Large subcategory is predicted to lag your competition by 20.8pp.',
        retailer: 'Kroger',
    },
    {
        id: '2',
        gtin: '0016000125414',
        type: 'opportunity',
        value: 187000,
        numberStyle: 'currencyEstimate',
        description:
      'Dollar Sales from Cheerios 20oz Breakfast Cereal are predicted to be 3.3% higher in latest 8 week forecast v YA and outpace your competitors by 12.3pp.',
        retailer: 'Kroger',
    },
    {
        id: '3',
        gtin: '0016000103719',
        type: 'risk',
        value: 130000,
        numberStyle: 'currencyEstimate',
        description:
      'The Dollar Sales decline for Cinnamon Chex 12oz are predicted to accelerate over the next 8 weeks. You are forecasted to lag your competitors by 27.4pp.',
        retailer: 'Kroger',
    },
    {
        id: '4',
        gtin: '16000125933',
        type: 'opportunity',
        value: 92200,
        numberStyle: 'currencyEstimate',
        description:
      'Cinnamon Toast Crunch, 16.8oz in Cold Cereal Kids Family subcategory is predicted to outpace the growth of your competitors by 8.6pp.',
        retailer: 'Kroger',
    },

    {
        id: '5',
        gtin: '16000163898',
        type: 'risk',
        value: 775178,
        numberStyle: 'currencyEstimate',
        description:
      'Cinnamon Toast Crunch in Adult Giant subcategory is predicted to lag competition by 46pp in the next 8 weeks, reversing a 52 week trend of growing faster than the competition',
        retailer: 'Walmart',
    },
    {
        id: '6',
        gtin: '16000163836',
        type: 'risk',
        value: 784655.172413793,
        numberStyle: 'currencyEstimate',
        description:
      'Honey Nut Cheerios Mega Size 29.4 Oz is predicted to lag the competition by nearly 50pp over the next 8 weeks',
        retailer: 'Walmart',
    },
    {
        id: '7',
        gtin: '16000432680',
        type: 'opportunity',
        value: 9859.97666277713,
        numberStyle: 'currencyEstimate',
        description: 'Nature Valley Oats \'N Honey Granola Crunch 16oz is predicted to lead the competition by 2.5pp over the next 8 weeks.',
        retailer: 'Walmart',
    },
    {
        id: '8',
        gtin: '16000124790',
        type: 'opportunity',
        value: 498331.838565022,
        numberStyle: 'currencyEstimate',
        description:
      'Cheerios Honey Nut Cereal 10.8 Oz is predicted to grow (11.5% YoY) while the competition is loosing ground (-36.4% YoY) over the next 8 weeks.',
        retailer: 'Walmart',
    },

    {
        id: '9',
        gtin: '16000275324',
        type: 'risk',
        value: 3078.37837837837,
        numberStyle: 'currencyEstimate',
        description:
      'Trix Cereal 10.7 Oz in the Adult Large subcategory is predicted to lag the competition by 5.1pp over the next 8 weeks.',
        retailer: 'Albertsons',
    },
    {
        id: '10',
        gtin: '16000487697',
        type: 'risk',
        value: 4896,
        numberStyle: 'currencyEstimate',
        description:
      'Cheerios Multigrain in the Adult Large subcategory is predicted to lag the competition by 9.7pp over the next 8 weeks.',
        retailer: 'Albertsons',
    },
    {
        id: '11',
        gtin: '16000487727',
        type: 'opportunity',
        value: 23444,
        numberStyle: 'currencyEstimate',
        description:
      'Cheerios 12 Oz Box Pack of 14 in the Adult Large subcategory is predicted to outperform the competition by 18.7pp and showing strong YoY growth over the next 8 weeks.',
        retailer: 'Albertsons',
    },
    {
        id: '12',
        gtin: '16000487963',
        type: 'opportunity',
        value: 5805,
        numberStyle: 'currencyEstimate',
        description:
      'Chex 12.0 Oz in the Adult Large subcategory is predicted to outgrow the competition by 14.1pp over the next 8 weeks',
        retailer: 'Albertsons',
    },

    {
        id: '13',
        gtin: '16000487925',
        type: 'risk',
        value: 3193,
        numberStyle: 'currencyEstimate',
        description:
      'Chex Honey Nut 12.5 Oz in the Audlt Large subcategory is predicted to lag the competition by 6.7pp over the next 8 weeks.',
        retailer: 'Target',
    },
    {
        id: '14',
        gtin: '16000126855',
        type: 'risk',
        value: 9786,
        numberStyle: 'currencyEstimate',
        description:
      'Cinnamon Toast Crunch 27oz in the Adult Giant subcategory is predicted to lag the competition by 2.5pp over the next 8 weeks',
        retailer: 'Target',
    },
    {
        id: '15',
        gtin: '16000437791',
        type: 'opportunity',
        value: 43324,
        numberStyle: 'currencyEstimate',
        description: 'Nature Valley Protein Cereal Oats \'n Honey 11 Oz is predicted to outperform the competition by 32.7pp over the next 8 weeks, reversing a trend of lagging the competition',
        retailer: 'Target',
    },
    {
        id: '16',
        gtin: '16000124790',
        type: 'opportunity',
        value: 80549,
        numberStyle: 'currencyEstimate',
        description:
      'Cheerios Honey Nut Cereal 10.8 Oz in the Adult Large subcategory is predicted to show 76.2% YoY growth over the next 8 week period, outperforming its competition by 90pp',
        retailer: 'Target',
    },
];

export function SalesPerformanceInsightsWeb() {
    // const { data } = useQuery([], async () => {});
    const { combinedFilters } = useActiveFilters();

    const insights = DUMMY_INSIGHTS;

    return (
        <SalesPerformanceInsights
            insights={insights
                .filter(({ retailer }) => retailer === combinedFilters.retailer)
                .sort((a, b) => b.value - a.value)}
        />
    );
}
