import { MosaicLayout } from '../MosaicLayout/MosaicLayout';
import { MosaicStateProvider } from '../MosaicLayout/MosaicStateProvider.tsx/MosaicStateProvider';
import { isNodeInvalid } from '../MosaicLayout/isNodeInvalid';
import { HEADER_HEIGHT } from '../helpers/constants';
import { useCurrentElements } from '../hooks/useCurrentElements';
import { useElementMap } from '../hooks/useElementMap';
import { useNoDuplicateNodes } from '../hooks/useNoDuplicateNodes';
import { usePage } from '../hooks/usePage';
import { useUpdatePageMosaic } from '../hooks/useUpdatePageMosaic';

export function StandardPage() {
    const { mosaicNode } = usePage();
    const elements = useCurrentElements();
    const updatePageMosaic = useUpdatePageMosaic();

    useNoDuplicateNodes({
        defaultNode: 'chatCpg',
        currentNode: mosaicNode ?? null,
        update: updatePageMosaic,
    });

    const elementMap = useElementMap(elements);

    if (!mosaicNode || !elements) return null;

    return (
        <div
            style={{
                padding: '0 8px 8px 8px',
                width: '100vw',
                height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            }}
        >
            <MosaicStateProvider>
                <MosaicLayout
                    elementMap={elementMap}
                    initialNode={mosaicNode}
                    onRelease={(currentNode) => {
                        if (isNodeInvalid(currentNode)) {
                            return;
                        }

                        updatePageMosaic({ node: currentNode });
                    }}
                />
            </MosaicStateProvider>
        </div>
    );
}
