import { captureException } from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { useCallback } from 'react';
import { useUser } from '../Providers/UserProvider/useUser';

export function useCaptureTickrException() {
    const user = useUser();

    return useCallback((e: unknown, context?: CaptureContext) => captureException(e, {
        user: {
            id: user.userId,
        },
        ...context,
    }), [user.userId]);
}
