import { ReactNode } from 'react';
import { ElementAttributes } from '../../../../sequelize-models/src/main';
import { EditableTitle } from './Components/EditableTitle';
import { OuterToolbarWrapper } from './Components/OuterToolbarWrapper';
import { ToolbarDivider } from './Components/ToolbarDivider';
import { ToolbarWrapper } from './Components/ToolbarWrapper';

interface DefaultElementToolbarProps {
    background?: string;
    element: ElementAttributes;
    setEditingTitle: (editing: boolean) => void;
    title: string;
    toolbarControls: ReactNode;
}

export function DefaultElementToolbar({
    background, element, setEditingTitle, title, toolbarControls,
}: DefaultElementToolbarProps) {
    return (
        <OuterToolbarWrapper
            background={background}
            id={element.uuid}
        >
            <ToolbarWrapper toolbarControls={toolbarControls}>
                <EditableTitle
                    element={element}
                    setEditingTitle={setEditingTitle}
                    title={title}
                />
            </ToolbarWrapper>
            <ToolbarDivider />
        </OuterToolbarWrapper>
    );
}
