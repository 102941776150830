import { TFunction } from 'i18next';
import powerbi from 'powerbi-visuals-api';
import { PropsWithChildren } from 'react';
import { LocalizationContext } from './LocalizationContext';

interface LocalizationProviderProps {
    t?: TFunction<'translation', undefined>;
    localizationManager?: powerbi.extensibility.ILocalizationManager;
}

export function LocalizationProvider({
    localizationManager,
    t,
    children,
}: PropsWithChildren<LocalizationProviderProps>) {
    return (
        <LocalizationContext.Provider
            value={
                t ??
        ((key: string, options?: Record<string, string | number>) => injectString(
            localizationManager?.getDisplayName(key) ?? key, options
        ) ?? key) ??
        ((key: string) => key)
            }
        >
            {children}
        </LocalizationContext.Provider>
    );
}

function injectString(translatedString: string, injections?: Record<string, string | number>) {
    if (!injections) return translatedString;

    return Object.entries(injections).reduce((acc, [key, value]) => {
        return acc.replace(`{{${key}}}`, value.toString());
    }, translatedString);
}
