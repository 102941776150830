import { styled } from '@mui/material';
import { ElementType, MouseEventHandler, ReactNode } from 'react';

export interface IconProps {
    alt?: string;
    as?: ElementType;
    background?: string;
    className?: string;
    children?: ReactNode;
    color?: string;
    disabled?: boolean;
    height?: string;
    hoverBackground?: string;
    hoverFill?: string;
    onClick?: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
    onMouseDown?: MouseEventHandler<HTMLDivElement>;
    onMouseUp?: MouseEventHandler<HTMLDivElement>;
    size?: string;
    src?: string;
    svgWidth?: string;
    svgHeight?: string;
    style?: React.CSSProperties;
    transform?: string;
    transition?: string;
    type?: string;
    width?: string;
}

export const IconWrapper = styled('div')<IconProps>(({
    as,
    background,
    color,
    height,
    hoverBackground,
    hoverFill,
    size = '16px',
    svgHeight,
    svgWidth,
    theme,
    transform,
    transition,
    width,
}) => {
    return `
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-grow: 0;

    rect {
        fill: ${color ?? theme.palette.text.primary};
    }

    line {
        stroke: ${color ?? theme.palette.text.primary};
    }

    path {
        fill: ${color ?? theme.palette.text.primary};
        transition: ${transition};
    }

    svg {
        height: ${svgHeight ?? size};
        width: ${svgWidth ?? size};
        transform: ${transform !== 'none' ? transform : 'none'};
        transition: ${transition};
    }

    :disabled {
        opacity: 0.5;
        cursor: default;
    }

    :hover {
        ${hoverBackground && `background: ${hoverBackground};`}

        path {
            ${hoverFill && `fill: ${hoverFill};`}
        }
    }

    background: ${background ?? 'unset'};

    ${as === 'button' &&
      `
    min-width: ${width ?? '24px'};
    min-height: ${height ?? '24px'};
    border-radius: ${theme.shape.borderRadius}px;
    `}
`;
});
