import { Button } from '@mui/material';
import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { CSSProperties, ChangeEvent, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../Core/Flex';
import { SearchBar } from '../../Core/SearchBar';
import { ControlLabel, ControlLabelProps } from '../../FormElements/ControlLabel';
import { FormGroupSwitch } from '../../FormElements/FormGroupSwitch';
import { useStagedFilters } from '../StagedFiltersProvider/useStagedFilters';
import { useStagedFiltersDispatch } from '../StagedFiltersProvider/useStagedFiltersDispatch';

export interface FilterGroupItem {
    component?: (props: ControlLabelProps) => JSX.Element;
    disabled?: boolean;
    title: string;
    subtitle?: string;
    value: string;
}

type FilterGroupType = 'checkbox' | 'radio';

interface FilterGroupProps {
    containerStyle?: CSSProperties;
    filterId: keyof AppFilters;
    searchPlaceholder?: string;
    searchWidth?: string;
    type: FilterGroupType;
    values: FilterGroupItem[];
}

export function FilterGroup({
    containerStyle,
    filterId,
    searchPlaceholder,
    searchWidth,
    type,
    values,
}: FilterGroupProps) {
    const { t } = useTranslation();
    const dispatchStagedFilters = useStagedFiltersDispatch();
    const { stagedFilters } = useStagedFilters();

    const [search, setSearch] = useState('');

    const onChangeCheckbox = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            dispatchStagedFilters({
                type: e.target.checked ? 'selectCheckbox' : 'deselectCheckbox',
                payload: {
                    filterId,
                    value: e.target.id,
                },
            });
        },
        [dispatchStagedFilters, filterId]
    );

    const onChangeRadio = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            dispatchStagedFilters({
                type: 'add',
                payload: {
                    [filterId]: e.target.id,
                },
            });
        },
        [dispatchStagedFilters, filterId]
    );

    return (
        <Flex
            direction="column"
            gap="1rem"
            style={containerStyle}
            width="100%"
        >
            {values.length > 10 && (
                <Flex
                    align="center"
                    gap="2rem"
                    width="100%"
                >
                    {type === 'checkbox' && (
                        <Flex gap="1rem">
                            <Button
                                onClick={() => {
                                    dispatchStagedFilters({
                                        type: 'add',
                                        payload: {
                                            [filterId]: values
                                                .filter(({ disabled }) => !disabled)
                                                .map(({ value }) => value)
                                                .join(','),
                                        },
                                    });
                                }}
                                size="small"
                                style={{
                                    textWrap: 'nowrap',
                                    textTransform: 'none',
                                    letterSpacing: 'unset',
                                }}
                                variant="text"
                            >
                                {t('button.selectAll')}
                            </Button>
                            <Button
                                onClick={() => {
                                    dispatchStagedFilters({
                                        type: 'deselectAll',
                                        payload: filterId,
                                    });
                                }}
                                size="small"
                                style={{
                                    textWrap: 'nowrap',
                                    textTransform: 'none',
                                    letterSpacing: 'unset',
                                }}
                                variant="text"
                            >
                                {t('button.deselectAll')}
                            </Button>
                        </Flex>
                    )}

                    <SearchBar
                        inputId={`${filterId}-search`}
                        inputWidth={searchWidth ?? '100%'}
                        onSearch={setSearch}
                        placeholder={searchPlaceholder ?? t('util.search')}
                        poll
                        search={search}
                    />
                </Flex>
            )}
            <FormGroupSwitch type={type}>
                {values
                    .filter(({ title, subtitle }) => {
                        if (!search) return true;

                        return title.toLowerCase().includes(search.toLowerCase()) ||
                            subtitle?.toLowerCase().includes(search.toLowerCase());
                    })
                    .map(({
                        component,
                        disabled,
                        subtitle,
                        title,
                        value,
                    }) => (
                        component
                            ? (
                                <Fragment key={value}>
                                    {component({
                                        disabled,
                                        onChange: type === 'checkbox' ? onChangeCheckbox : onChangeRadio,
                                        search,
                                        subtitle,
                                        title,
                                        type,
                                        value,
                                    })}
                                </Fragment>
                            )
                            : (
                                <ControlLabel
                                    checked={type === 'checkbox'
                                        ? stagedFilters[filterId].split(',').includes(value)
                                        : stagedFilters[filterId] === value}
                                    disabled={disabled}
                                    key={value}
                                    onChange={type === 'checkbox'
                                        ? onChangeCheckbox
                                        : onChangeRadio}
                                    search={search}
                                    subtitle={subtitle}
                                    title={title}
                                    type={type}
                                    value={value}
                                />
                            )))
                }
            </FormGroupSwitch>
        </Flex>
    );
}

