import { StyledComponent } from '@emotion/styled';
import { TableCell, TableCellProps, styled } from '@mui/material';

export const TickrTableCell: StyledComponent<TableCellProps> = styled(TableCell)<TableCellProps>(({ theme }) => ({
    borderBottom: 'none',
    // ':first-child': {
    //     borderTopLeftRadius: `${theme.shape.borderRadius}px`,
    //     borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
    // },
    // ':last-child': {
    //     borderTopRightRadius: `${theme.shape.borderRadius}px`,
    //     borderBottomRightRadius: `${theme.shape.borderRadius}px`,
    // },
}));
