import { useTheme } from '@mui/material';
import { Typography } from '../Core/Typography';

interface InputErrorMessageProps {
    errorMessage: string;
}

export function InputErrorMessage({ errorMessage }: InputErrorMessageProps) {
    const { palette } = useTheme();

    return (
        <Typography
            color={palette.error.main}
            style={{
                visibility: errorMessage ? 'visible' : 'hidden',
                paddingLeft: '8px',
                position: 'absolute',
                bottom: -18,
            }}
            variant="p-12-r"
        >
            *
            {errorMessage}
        </Typography>
    );
}
