import { useContext } from 'react';
import { TooltipContext, TooltipContextProps } from './TooltipContext';

export function useTooltipContext(): Exclude<TooltipContextProps, null> {
    const context = useContext(TooltipContext);

    if (context == null) {
        throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }

    return context;
}
