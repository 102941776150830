import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { usePage } from './usePage';
import { UpdateMosaicProps } from './useUpdateTabMosaic';

export const useUpdatePageMosaic = () => {
    const { updatePage } = useCollectionMutations();
    const { page } = usePage();

    return async ({ node, updateDefaultMosaic }: UpdateMosaicProps) => {
        if (!page) return;

        let updatedNode = node;

        if (node === null) {
            updatedNode = 'chatCpg';
        }

        const updatedPage = await updatePage({
            page: {
                ...page,
                settings: {
                    ...page.settings,
                    mosaicNode: updatedNode,
                    ...(updateDefaultMosaic && { defaultMosaic: updatedNode }),
                },
            },
        });

        return updatedPage;
    };
};
