import { useTheme } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useMosaicState } from '../MosaicLayout/MosaicStateProvider.tsx/useMosaicState';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';
import { useTidsMutations } from '../Providers/TidsMutationsProvider/useTidsMutations';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useUser } from '../Providers/UserProvider/useUser';
import { usePage } from '../hooks/usePage';
import { ChatHistory } from './ChatHistory';
import { ChatInput } from './ChatInput';

export function ChatCpgElement() {
    const { t } = useTranslation();
    const { setFileStreamId } = useChatBox();
    const { postDocument } = useTidsMutations();
    const { sendAlert } = useToaster();
    const { inputRef } = useChatBoxForm();
    const { summaryId = '' } = usePage();
    const { isDemo } = useUser();
    const { palette, shape } = useTheme();
    const { isDragging } = useMosaicState();

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/octet-stream': ['.eml'],
            'application/pdf': ['.pdf'],
            'text/*': ['.html', '.txt'],
        },
        maxFiles: 1,
        maxSize: 20000000,
        onDropAccepted: async (acceptedFiles) => {
            const file = acceptedFiles[0];

            const { id } = await postDocument({
                file,
                summaryId,
            });

            setFileStreamId(id);
        },
        onDropRejected(fileRejections) {
            const error = fileRejections[0]?.errors?.[0]?.code;
            let errorMessage: string;

            switch (error) {
                case 'file-too-large':
                    errorMessage = t('chatCpg.fileUpload.fileTooLarge');
                    break;
                case 'file-invalid-type':
                    errorMessage = t('chatCpg.fileUpload.invalidFileType');
                    break;
                default:
                    errorMessage = t('error.unknown');
                    break;
            }

            sendAlert({
                severity: 'error',
                text: errorMessage,
            });
        },
    });

    const [dragCounter, setDragCounter] = useState(0);

    const maskEnabled = isDemo && !isDragging;

    return (
        <Flex
            direction="column"
            gap="1rem"
            height="100%"
            justify="flex-end"
            onDragEnter={maskEnabled ? () => setDragCounter((prev) => prev + 1) : undefined}
            onDragLeave={maskEnabled ? () => setDragCounter((prev) => prev - 1): undefined}
            onDrop={isDemo ? () => setDragCounter(0) : undefined}
            padding="0.6rem"
            style={{
                maxHeight: '100%',
                minHeight: 0,
                backgroundColor: palette.background.default,
                borderRadius: `0 0 ${shape.borderRadius}px ${shape.borderRadius}px`,
                overflow: 'hidden',
            }}
            width="100%"
        >
            {maskEnabled && (
                <Flex
                    align="center"
                    direction="column"
                    gap="1rem"
                    justify="center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        background: `${palette.background.default}E6`, // 90% opacity
                        width: '100%',
                        height: '100%',
                        zIndex: dragCounter === 0 ? -1 : 1,
                    }}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <Typography textAlign="center" variant="h-30-b">
                        {t('chatCpg.upload')}
                    </Typography>
                    <Typography
                        multiline
                        secondary
                        style={{ maxWidth: '300px' }}
                        textAlign="center"
                        variant="p-16-r"
                    >
                        {t('chatCpg.upload.description')}
                    </Typography>
                </Flex>
            )}
            <ChatHistory />
            <Flex
                direction="column"
                gap="0.4rem"
                style={{ maxWidth: '100%' }}
                width="100%"
            >
                <ChatInput ref={inputRef} />
            </Flex>
        </Flex>
    );
}
