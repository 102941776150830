import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NumberFormatter } from '../Core/NumberFormatter';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { HeadCell, TickrHeadCell } from '../Table/TickrHeadCell';
import { TickrTable } from '../Table/TickrTable';
import { TickrTableBody } from '../Table/TickrTableBody';
import { TickrTableCell } from '../Table/TickrTableCell';
import { TickrTableHead } from '../Table/TickrTableHead';
import { TickrTableRow } from '../Table/TickrTableRow';
import { TickrTableSortLabel } from '../Table/TickrTableSortLabel';
import { useTableSort } from '../Table/useTableSort';
import { usePage } from '../hooks/usePage';
import { ProductCell } from './Cells/ProductCell';
import { ScoreCell } from './Cells/ScoreCell';

interface Substitute {
    id: string;
    name: string;
    substitutionScore: number;
    averagePrice: number;
    totalSales: number;
    changeVsPreviousPeriod: number;
}

export function SubstitutesTable() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { updatePage } = useCollectionMutations();
    const { pageUuid, substitutionSummaryData, isStreaming } = usePage();

    const substitutes: Substitute[] = substitutionSummaryData?.substitutions?.map(
        ({ gtin, itemDescription, score, L12WPriceAvg, L12WRevenue, changeVsPreviousPeriod }) => ({
            id: gtin,
            name: itemDescription,
            substitutionScore: score,
            averagePrice: L12WPriceAvg,
            totalSales: L12WRevenue,
            changeVsPreviousPeriod,
        })
    ) ?? [];

    const headCells: HeadCell<Substitute>[] = [
        {
            value: 'id',
            label: t('chatCpg.substitutions.substitute'),
        },
        {
            value: 'substitutionScore',
            label: t('chatCpg.substitutions.substitutionScore'),
            align: 'right',
        },
        {
            value: 'averagePrice',
            label: t('chatCpg.substitutions.l12wAveragePrice'),
            align: 'right',
        },
        {
            value: 'totalSales',
            label: t('chatCpg.substitutions.l12wTotalSales'),
            align: 'right',
        },
        {
            value: 'changeVsPreviousPeriod',
            label: t('chatCpg.substitutions.changeVsPreviousPeriod'),
            align: 'right',
        },
    ];

    const { order, orderBy, setOrder, setOrderBy, sortedRows } = useTableSort({
        data: substitutes,
        initOrder: 'desc',
        initOrderBy: 'substitutionScore',
    });

    if (!pageUuid) {
        return null;
    }

    return (
        <TickrTable>
            <TickrTableHead>
                <TickrTableRow background={palette.background.default}>
                    {headCells.map(({ value, label, align }) => (
                        <TickrHeadCell
                            align={align}
                            key={value}
                            order={order}
                            orderBy={orderBy}
                            value={value}
                        >
                            <TickrTableSortLabel
                                id={value}
                                label={label}
                                nowrap
                                order={order}
                                orderBy={orderBy}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                            />
                        </TickrHeadCell>
                    ))}
                </TickrTableRow>
            </TickrTableHead>
            <TickrTableBody>
                {sortedRows.map((row) => {
                    const isSelected = row.id === substitutionSummaryData?.activeSubstitutionGtin;

                    return (
                        <TickrTableRow
                            aria-checked={isSelected}
                            highlight={isSelected}
                            highlightColor={palette.primary.main}
                            hover={!isStreaming}
                            key={row.id}
                            onClick={isStreaming
                                ? undefined
                                : () => updatePage({
                                    mergeProps: {
                                        settings: {
                                            summary: {
                                                substitutions: {
                                                    activeSubstitutionGtin: row.id,
                                                },
                                            },
                                        },
                                    },
                                    pageUuid,
                                })}
                            sx={{ cursor: isStreaming ? 'default' : 'pointer' }}
                        >
                            <TickrTableCell
                                component="th"
                                id={`substitute-${row.id}`}
                                scope="row"
                            >
                                <ProductCell
                                    id={row.id}
                                    maxWidth="350px"
                                    name={row.name}
                                />
                            </TickrTableCell>
                            <TickrTableCell align="right">
                                <ScoreCell score={row.substitutionScore} />
                            </TickrTableCell>
                            <TickrTableCell align="right">
                                <NumberFormatter
                                    as="p"
                                    numberStyle="currency"
                                    val={row.averagePrice}
                                    variant="h-16-b"
                                />
                            </TickrTableCell>
                            <TickrTableCell align="right">
                                <NumberFormatter
                                    as="p"
                                    numberStyle="currencyEstimate"
                                    val={row.totalSales}
                                    variant="h-16-b"
                                />
                            </TickrTableCell>
                            <TickrTableCell align="right">
                                <NumberFormatter
                                    as="p"
                                    numberStyle="percentage"
                                    val={row.changeVsPreviousPeriod}
                                    variant="h-16-b"
                                />
                            </TickrTableCell>
                        </TickrTableRow>
                    );
                })}
            </TickrTableBody>
        </TickrTable>
    );
}
