import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useApp } from '../Providers/AppProvider/useApp';

export interface Message {
    sent: string;
    response_text: string | null;
    user_message: string;
    uuid: string;
}

interface ChatHistory {
    uuid: string;
    messages: Message[];
    creator_id: string;
}

interface UseChatHistoryProps {
    chatId: string;
    setMessageStreamId: Dispatch<SetStateAction<string>>;
}

export function useChatHistory({ chatId, setMessageStreamId }: UseChatHistoryProps) {
    const { chatApiRoute } = useApp();
    const { tidsApi } = useApi();

    const { data, isLoading } = useQuery({
        queryKey: ['chat-history', chatId],
        queryFn: async ({ signal }) => {
            const { data } = await tidsApi.get<ChatHistory>(`v2${chatApiRoute}/chat/${chatId}`, { signal });

            const streamingMessage = data.messages.find(({ response_text }) => response_text === null);
            setMessageStreamId(streamingMessage?.uuid ?? '');

            return data;
        },
    });

    return {
        data,
        isLoading,
    };
}
