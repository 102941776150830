import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useApp } from '../Providers/AppProvider/useApp';
import { debugLogger } from '../helpers/debugLogger';
import { useCaptureTickrException } from '../hooks/useCaptureTickrException';
import { useChatCpgStore } from '../hooks/useChatCpgStore';
import { Message } from './useChatHistory';

interface SimpleEvent {
    type: 'started' | 'finished';
}

interface ChunkEvent {
    type: 'chunk';
    chunk: string;
}

interface CompleteEvent {
    meta: 'COMPLETE';
    data: Omit<Message, 'response_text'> & { response_text: string };
}

type StreamEvent = SimpleEvent | ChunkEvent | CompleteEvent;

interface UseMessageStreamV2Props {
    chatHistory: Message[];
    chatId: string;
    messageId: string;
}

export function useMessageStreamV2({ chatId, messageId, chatHistory = [] }: UseMessageStreamV2Props) {
    const { tidsApiUrl, chatApiRoute } = useApp();
    const updateStreamText = useChatCpgStore((state) => state.updateStreamText);
    const clearStreamText = useChatCpgStore((state) => state.clearStreamText);
    const captureTickrException = useCaptureTickrException();

    const queryClient = useQueryClient();

    useQuery({
        queryKey: ['message-stream', chatId, messageId, chatHistory],
        queryFn({ signal }) {
            if (!chatId || !messageId || !chatHistory.find(({ uuid }) => uuid === messageId)) return false;

            fetchEventSource(
                `${tidsApiUrl}/v2${chatApiRoute}/chat/${chatId}/message/${messageId}`,
                {
                    method: 'GET',
                    signal,
                    async onopen() {
                        debugLogger('MESSAGE STREAM OPEN');

                        updateStreamText(
                            ['message-stream', messageId],
                            {
                                text: '',
                                isStreaming: true,
                            },
                        );
                    },
                    onmessage(event) {
                        if (event.event === 'ping' || event.data === '') return;

                        const streamData: StreamEvent = JSON.parse(event.data);

                        if (
                            ('meta' in streamData && streamData.meta === 'COMPLETE') ||
                            ('type' in streamData && streamData.type === 'finished')
                        ) {
                            updateStreamText(
                                ['message-stream', messageId],
                                {
                                    text: '',
                                    isStreaming: false,
                                },
                            );
                        }

                        if ('chunk' in streamData) {
                            const { chunk } = streamData;

                            updateStreamText(
                                ['message-stream', messageId],
                                {
                                    text: chunk,
                                    isStreaming: true,
                                },
                            );
                        }
                    },
                    onclose() {
                        debugLogger('MESSAGE STREAM CLOSED');
                        queryClient.invalidateQueries({ queryKey: ['chat-history', chatId] });
                    },
                    onerror(error) {
                        debugLogger('MESSAGE STREAM ERROR', { error });
                        captureTickrException(error);
                        clearStreamText(messageId);
                        queryClient.invalidateQueries({ queryKey: ['chat-history', chatId] });
                    },
                }
            );

            return true;
        },
        enabled: !!chatId && !!messageId,
        staleTime: Infinity,
    });
}
