import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { usePage } from '../hooks/usePage';
import { ChatCpgBubble } from './ChatCpgBubble';

interface FileStreamBubbleProps {
    fileName: string;
}

export function FileStreamBubble({ fileName }: FileStreamBubbleProps) {
    const { summaryId = '' } = usePage();

    const { fileStreamId } = useChatBox();

    const fileStream = useChatCpgData<StreamingText>([fileStreamId, summaryId]) ?? {
        text: '',
        isStreaming: false,
    };

    return (
        <ChatCpgBubble
            fileName={fileName}
            id="file-stream-bubble"
            isStreaming
            text={fileStream.text}
        />
    );
}
