import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import last from 'lodash/last';
import meanBy from 'lodash/meanBy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { ValueBox } from '../Core/ValueBox';
import { useElement } from '../Element/useElement';
import { usePage } from '../hooks/usePage';
import { HighchartsContainer } from './HighchartsContainer';

export function ProjectedRevenue() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { substitutionSummaryData } = usePage();
    const element = useElement();

    const { type, secondaryTrendOptions } = element.settings?.projectedRevenue || {};
    const activeGtin = type === 'substitution' ? substitutionSummaryData?.activeSubstitutionGtin : substitutionSummaryData?.activeComplementGtin;
    const secondaryProducts = type === 'substitution' ? substitutionSummaryData?.substitutions : substitutionSummaryData?.complements;
    const secondaryLineColor = type === 'substitution' ? palette.primary.main : palette.indicator.main;
    const myProduct = substitutionSummaryData?.myProduct;
    const secondaryProduct = secondaryProducts?.find(({ gtin }) => gtin === activeGtin);

    const chartData = useMemo(() => {
        return {
            primaryProduct: {
                historicRevenue: myProduct?.actualRevenueTrend ?? [],
                revenueProjection: myProduct?.predictedRevenueTrend ?? [],
                revenueProjectionArea: myProduct?.predictedRange ?? [],
            },
            secondaryProduct: {
                historicRevenue: secondaryProduct?.actualRevenueTrend ?? [],
                revenueProjection: secondaryProduct?.predictedRevenueTrend ?? [],
                revenueProjectionArea: secondaryProduct?.predictedRange ?? [],
            },
        };
    }, [myProduct, secondaryProduct]);

    const lastPrimaryHistoric = useMemo(
        () => last(chartData.primaryProduct.historicRevenue) ?? [0, 0],
        [chartData.primaryProduct.historicRevenue]
    );

    const lastSecondaryHistoric = useMemo(
        () => last(chartData.secondaryProduct.historicRevenue) ?? [0, 0],
        [chartData.secondaryProduct.historicRevenue]
    );

    const options = useMemo(() => {
        const highchartsOptions: Highcharts.Options = {
            tooltip: {
                shared: true,
                formatter: function() {
                    const isHistoric = (this.x as number) <= (lastPrimaryHistoric?.[0] ?? 0);

                    const primaryLabel = isHistoric
                        ? this.points?.[0]?.series.name
                        : t('chatCpg.substitutions.primaryProductsRevenueForecast');

                    const secondaryLabel = isHistoric
                        ? this.points?.[1]?.series.name
                        : secondaryTrendOptions?.forecastLabel;

                    return `
                        <tspan style="fontSize: 10px">${Highcharts.dateFormat(`${t('util.weekEnding')} %a, %b %e, %Y`, this.x as number)}</tspan>
                        <br>
                        <tspan style="color: ${this.points?.[0]?.color}; fill: ${this.points?.[0]?.color};">●</tspan>
                        <tspan style="fontSize: 12px">${primaryLabel}:
                            <tspan style="fontSize: 12px; fontWeight: bold;">$${Highcharts.numberFormat(this.y as number, 2)}</tspan>
                        </tspan>
                        <br>
                        <tspan style="color: ${this.points?.[1]?.color}; fill: ${this.points?.[1]?.color};">●</tspan>
                        <tspan style="fontSize: 12px">${secondaryLabel}:
                            <tspan style="fontSize: 12px; fontWeight: bold;">$${Highcharts.numberFormat(this.points?.[1]?.y as number, 2)}</tspan>
                        </tspan>
                    `;
                },
            },
            xAxis: {
                type: 'datetime',
                startOfWeek: 6,
                crosshair: true,
            },
            yAxis: {
                type: 'linear',
                opposite: true,
                labels: {
                    formatter: function() {
                        return `$${this.axis.defaultLabelFormatter.call(this)}`;
                    },
                },
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    name: t('chatCpg.substitutions.primaryProductsRevenue'),
                    type: 'line',
                    color: palette.success.main,
                    dashStyle: 'Solid',
                    data: [...chartData.primaryProduct.historicRevenue, ...chartData.primaryProduct.revenueProjection],
                    zIndex: 1,
                    lineWidth: 2,
                    zoneAxis: 'x',
                    zones: [
                        {
                            value: lastPrimaryHistoric[0],
                            dashStyle: 'Solid',
                        },
                        {
                            dashStyle: 'Dash',
                        },
                    ],
                },
                {
                    name: 'primary-confidence-interval',
                    type: 'arearange',
                    data: [
                        [
                            lastPrimaryHistoric[0],
                            lastPrimaryHistoric[1],
                            lastPrimaryHistoric[1],
                        ],
                        ...chartData.primaryProduct.revenueProjectionArea,
                    ],
                    linkedTo: ':previous',
                    color: palette.confidenceInterval.main,
                    marker: {
                        enabled: false,
                    },
                    zoneAxis: 'x',
                    enableMouseTracking: false,
                },
                {
                    name: secondaryTrendOptions?.label,
                    type: 'line',
                    color: secondaryLineColor,
                    dashStyle: 'Solid',
                    data: [
                        ...chartData.secondaryProduct.historicRevenue,
                        ...chartData.secondaryProduct.revenueProjection,
                    ],
                    zIndex: 1,
                    lineWidth: 2,
                    zoneAxis: 'x',
                    zones: [
                        {
                            value: lastSecondaryHistoric[0],
                            dashStyle: 'Solid',
                        },
                        {
                            dashStyle: 'Dash',
                        },
                    ],
                },
                {
                    name: 'substitute-confidence-interval',
                    type: 'arearange',
                    data: [
                        [
                            lastSecondaryHistoric[0],
                            lastSecondaryHistoric[1],
                            lastSecondaryHistoric[1],
                        ],
                        ...chartData.secondaryProduct.revenueProjectionArea,
                    ],
                    linkedTo: ':previous',
                    color: palette.confidenceInterval.main,
                    marker: {
                        enabled: false,
                    },
                    zoneAxis: 'x',
                    enableMouseTracking: false,
                },
            ],
        };

        return highchartsOptions;
    }, [
        t,
        chartData,
        lastPrimaryHistoric,
        lastSecondaryHistoric,
        secondaryLineColor,
        secondaryTrendOptions,
        palette.confidenceInterval.main,
        palette.success.main,
    ]);

    return (
        <Flex
            align="stretch"
            direction="column"
            height="100%"
            justify="stretch"
            width="100%"
        >
            <Flex
                gap="2rem"
                padding="0 0 1rem 0"
                width="100%"
            >
                <ValueBox
                    color={palette.success.main}
                    label={t('chatCpg.substitutions.primaryProductsRevenue')}
                    secondary
                    value={meanBy(chartData.primaryProduct.historicRevenue, ([, y]) => y)}
                    valueStyle="currencyEstimate"
                    valueType="weeklyAverage"
                />
                <ValueBox
                    color={secondaryLineColor}
                    label={secondaryTrendOptions?.label}
                    secondary
                    value={meanBy(chartData.secondaryProduct.historicRevenue, ([, y]) => y)}
                    valueStyle="currencyEstimate"
                    valueType="weeklyAverage"
                />
            </Flex>
            <HighchartsContainer options={options} />
        </Flex>
    );
}
