import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, useMemo } from 'react';
import { useApi } from '../ApiProvider/useApi';
import { UserContext } from './UserContext';

interface Integration {
    id: number;
    owner: string;
    team_id: number;
    api_name: string;
    name: string;
}

interface Team {
    brand_ids?: number[];
    company_name?: string;
    id?: number;
    integrations?: Integration[];
    settings?: {
        capabilities?: string[];
    };
    username?: string;
}

interface User {
    app_roles?: string[];
    id?: number;
    teams?: Team[];
}

export function UserProvider({ children }: PropsWithChildren) {
    const { publicApi } = useApi();
    const { keycloak } = useKeycloak();

    const { data } = useQuery<User>({
        queryKey: ['user'],
        queryFn: async ({ signal }) => {
            const { data } = await publicApi.get<User>('/users/current', { signal });
            return data;
        },
    });

    const msId = data?.teams?.[0]?.id;
    const integrations = useMemo(() => data?.teams?.[0]?.integrations?.map(({ name }) => name) ?? [], [data?.teams]);
    const capabilities = useMemo(() => data?.teams?.[0]?.settings?.capabilities ?? [], [data?.teams]);

    const userContextValue = useMemo(
        () => ({
            hasSmartScorecard: integrations.includes('synth'),
            hasSubstitutions: capabilities.includes('substitutions'),
            integrations,
            isAdmin: data?.app_roles?.includes('team-admin') ?? false,
            isDemo: keycloak.tokenParsed?.realm_access?.roles.includes('demo') ?? false,
            isNumerator: integrations.includes('numerator'),
            msId,
            userId: data?.id,
        }),
        [
            data,
            capabilities,
            integrations,
            keycloak.tokenParsed?.realm_access?.roles,
            msId,
        ]
    );

    return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
}
