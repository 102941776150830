import { useQuery } from '@tanstack/react-query';
import { useActiveFilters } from '../../Filters/ActiveFiltersProvider/useActiveFilters';
import { useApi } from '../../Providers/ApiProvider/useApi';
import { SalesPerformance, SalesPerformanceRow } from './SalesPerformance';
import { getIdFromPath } from './getIdFromPath';

interface ApiSalesPerformanceRow {
    hierarchy: string;
    retailer: string;
    vlp_diff_historic: number;
    vlp_diff_forecast: number;
    sales_trend_competitor_historic: string;
    sales_trend_competitor_forecast: string;
    sales_competitor_historic: number;
    sales_owned_historic: number;
    sales_trend_owned_historic: string;
    sales_trend_owned_forecast: string;
    sales_competitor_forecast: number;
    sales_owned_forecast: number;
    sales_competitor_previous: number;
    sales_owned_previous: number;
    vlp_competitor_historic: number;
    vlp_competitor_forecast: number;
    vlp_owned_historic: number;
    vlp_owned_forecast: number;
}

export function SalesPerformanceWeb() {
    const { publicApi } = useApi();
    const { combinedFilters } = useActiveFilters();

    const { data = [], isLoading } = useQuery<SalesPerformanceRow[]>({
        queryKey: ['sales-performance-acosta'],
        queryFn: async ({ signal }) => {
            const { data } = await publicApi.get<ApiSalesPerformanceRow[]>(
                'data/purchases/synthetic-agg?weeks_historic=52&weeks_forecast=8',
                { signal }
            );

            return data.reduce(
                (
                    acc,
                    {
                        hierarchy,
                        retailer,
                        sales_owned_historic,
                        vlp_owned_historic,
                        vlp_competitor_historic,
                        vlp_competitor_forecast,
                        vlp_diff_historic,
                        vlp_diff_forecast,
                        sales_owned_forecast,
                        vlp_owned_forecast,
                        sales_competitor_forecast,
                        sales_competitor_historic,
                        sales_trend_competitor_historic,
                        sales_trend_competitor_forecast,
                        sales_trend_owned_historic,
                        sales_trend_owned_forecast,
                    }
                ) => {
                    let path = [];
                    let salesTrendOwnedHistoric = [];
                    let salesTrendOwnedForecast = [];
                    let salesTrendCompetitorHistoric = [];
                    let salesTrendCompetitorForecast = [];

                    try {
                        path = JSON.parse(hierarchy);
                    } catch (error) {
                        // Do not add row if path is not valid
                        return acc;
                    }

                    try {
                        if (sales_trend_owned_historic) {
                            salesTrendOwnedHistoric = JSON.parse(sales_trend_owned_historic as string);
                        }
                    } catch (error) {
                        salesTrendOwnedHistoric = [];
                    }

                    try {
                        if (sales_trend_owned_forecast) {
                            salesTrendOwnedForecast = JSON.parse(sales_trend_owned_forecast as string);
                        }
                    } catch (error) {
                        salesTrendOwnedForecast = [];
                    }

                    try {
                        if (sales_trend_competitor_historic) {
                            salesTrendCompetitorHistoric = JSON.parse(sales_trend_competitor_historic);
                        }
                    } catch (error) {
                        salesTrendCompetitorHistoric = [];
                    }

                    try {
                        if (sales_trend_competitor_forecast) {
                            salesTrendCompetitorForecast = JSON.parse(sales_trend_competitor_forecast as string);
                        }
                    } catch (error) {
                        salesTrendCompetitorForecast = [];
                    }

                    const isOwned = salesTrendOwnedHistoric.length > 0;

                    return [
                        ...acc,
                        {
                            id: getIdFromPath(path, isOwned),
                            retailer,
                            path,
                            salesTrends: {
                                owned: {
                                    historicTrend: salesTrendOwnedHistoric,
                                    forecastTrend: salesTrendOwnedForecast,
                                },
                                competitors: {
                                    historicTrend: salesTrendCompetitorHistoric,
                                    forecastTrend: salesTrendCompetitorForecast,
                                },
                            },
                            salesOwnedHistoric: sales_owned_historic,
                            vlpOwnedHistoric: vlp_owned_historic,
                            salesCompetitorHistoric: sales_competitor_historic,
                            vlpCompetitorHistoric: vlp_competitor_historic,
                            vlpDiffHistoric: vlp_diff_historic,
                            isOwned,
                            salesOwnedForecast: sales_owned_forecast,
                            vlpOwnedForecast: vlp_owned_forecast,
                            salesCompetitorForecast: sales_competitor_forecast,
                            vlpCompetitorForecast: vlp_competitor_forecast,
                            vlpDiffForecast: vlp_diff_forecast,
                        },
                    ];
                },
                [] as SalesPerformanceRow[]
            );
        },
    });

    return (
        <SalesPerformance
            isLoading={isLoading}
            rows={data.filter(({ retailer }) => retailer === combinedFilters.retailer)}
        />
    );
}
