import { Button, useTheme } from '@mui/material';
import { PropsWithChildren, useContext, useEffect } from 'react';
import { useMeasure } from 'react-use';
import { TabContext } from './TabsContext';

interface TabProps {
    name: string; // should match respective TabPanel name
    index: number;
    className?: string;
}

export function Tab({ name, index, className, children }: PropsWithChildren<TabProps>) {
    const { activeTab, setActiveTab, setTabWidth } = useContext(TabContext);
    const { palette } = useTheme();

    const [ref, { width }] = useMeasure<HTMLButtonElement>();

    useEffect(() => {
        if (width) setTabWidth(width + 10);
    }, [width, setTabWidth]);

    const isActive = activeTab === index;

    return (
        <Button
            aria-controls={`panel-${name.toLowerCase().replace(/\s/g, '-')}`}
            aria-selected={isActive}
            className={className}
            id={`tab-${name.toLowerCase().replace(/\s/g, '-')}`}
            onClick={() => setActiveTab(index)}
            ref={ref}
            role="tab"
            size="small"
            style={{
                color: isActive ? palette.text.primary : palette.text.secondary,
                height: '100%',
                overflowWrap: 'break-word',
                width: '100%',
                zIndex: 1,
            }}
        >
            {children}
        </Button>
    );
}
