import { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { useMeasure } from 'react-use';
import { Flex } from '../Core/Flex';
import { GraphContext } from './GraphContext';

export interface NewGraphProps {
    children?: ReactNode;
    containerHeight?: CSSProperties['height'];
    containerPadding?: CSSProperties['padding'];
}

export function GraphProvider({ children, containerHeight, containerPadding }: PropsWithChildren<NewGraphProps>) {
    const [ref, { width, height }] = useMeasure<HTMLDivElement>();

    return (
        <GraphContext.Provider
            value={{
                width,
                height,
            }}
        >
            <Flex
                ref={ref}
                style={{
                    width: '100%',
                    height: containerHeight ?? '100%',
                    flex: '1 1 auto',
                    padding: containerPadding,
                }}
            >
                <svg overflow="hidden" viewBox={`0 0 ${width} ${height}`}>
                    {children}
                </svg>
            </Flex>
        </GraphContext.Provider>
    );
}
