import { PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';

interface SolutionContentProps {
    style?: React.CSSProperties;
}

export function SolutionContent({ style, children }: PropsWithChildren<SolutionContentProps>) {
    return (
        <Flex
            direction="column"
            gap="2rem"
            height="100%"
            padding="0 6.5% 0 6.5%"
            style={{
                gridRow: '2',
                gridColumn: '1',
                overflow: 'auto',
                ...style,
            }}
            width="100%"
        >
            {children}
        </Flex>
    );
}
