import { Button, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import { Circle } from '../Core/Circle';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { TickrDialog } from '../Dialog/TickrDialog';
import { TickrDialogContent } from '../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../Dialog/TickrDialogTitle';
import { TickrHeadCell } from '../Table/TickrHeadCell';
import { TickrTable } from '../Table/TickrTable';
import { TickrTableBody } from '../Table/TickrTableBody';
import { TickrTableCell } from '../Table/TickrTableCell';
import { TickrTableContainer } from '../Table/TickrTableContainer';
import { TickrTableHead } from '../Table/TickrTableHead';
import { TickrTableRow } from '../Table/TickrTableRow';
import { TickrTableSortLabel } from '../Table/TickrTableSortLabel';
import { TickrToolbar } from '../Table/TickrToolbar';
import { sortTableData } from '../Table/sortTableData';
import { useForecastInProgress } from '../hooks/useForecastInProgress';

interface PreviewDataDialogProps {
    forecastCol?: string;
}

export function PreviewDataDialog({ forecastCol: forecastColProp }: PreviewDataDialogProps) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { forecast, dateColumn, forecastColumn, fileName } = useForecastInProgress();

    const [ref, { width }] = useMeasure<HTMLTableElement>();
    const [previewDataOpen, setPreviewDataOpen] = useState(false);
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState('updatedAt');

    const sortedRows = useMemo(() => {
        return sortTableData(forecast?.settings.document?.data ?? [], orderBy, order);
    }, [order, orderBy, forecast?.settings.document?.data]);

    const columns = Object.keys(sortedRows[0] ?? {});

    if (!forecast || !forecast.settings.document?.data) {
        return null;
    }

    const forecastCol = forecastColProp ?? forecastColumn;

    const onClose = () => setPreviewDataOpen(false);

    return (
        <>
            <Button
                onClick={() => setPreviewDataOpen(true)}
                style={{
                    paddingLeft: '1rem',
                    textTransform: 'capitalize',
                    letterSpacing: 'unset',
                }}
                variant="text"
            >
                {t('forecast.previewData', { file: fileName })}
            </Button>
            <TickrDialog
                maxWidth="xl"
                onClose={onClose}
                open={previewDataOpen}
            >
                <TickrDialogTitle
                    onClose={onClose}
                >
                    {t('forecast.previewData', { file: fileName })}
                </TickrDialogTitle>
                <TickrDialogContent>
                    <TickrTableContainer
                        style={{
                            height: '75vh',
                        }}
                    >
                        <TickrToolbar
                            containerBackground={palette.background.secondary}
                            size="small"
                            style={{
                                backgroundColor: palette.background.default,
                                width: `${width}px`,
                            }}
                        >
                            <Flex
                                gap="2rem"
                                padding="1rem"
                            >
                                <Flex align="center" gap="0.4rem">
                                    <Circle color={palette.info.main} size="8px" />
                                    <Typography secondary variant="p-14-r">{t('forecast.dateColumn')}</Typography>
                                </Flex>
                                <Flex align="center" gap="0.4rem">
                                    <Circle color={palette.secondary.main} size="8px" />
                                    <Typography secondary variant="p-14-r">{t('forecast.valueToForecast')}</Typography>
                                </Flex>
                            </Flex>
                        </TickrToolbar>
                        <TickrTable
                            ref={ref}
                            size="small"
                            style={{
                                overflowY: 'scroll',
                                zIndex: 0,
                            }}
                        >
                            <TickrTableHead top={64}>
                                <TickrTableRow>
                                    {columns.map((column) => (
                                        <TickrHeadCell
                                            key={column}
                                            order={order}
                                            orderBy={orderBy}
                                            value={column}
                                        >
                                            <Flex align="center" gap="0.4rem">

                                                {dateColumn === column && <Circle color={palette.info.main} size="8px" />}
                                                {forecastCol === column && <Circle color={palette.secondary.main} size="8px" />}
                                                <TickrTableSortLabel
                                                    id={column}
                                                    label={column}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    setOrder={setOrder}
                                                    setOrderBy={setOrderBy}
                                                />
                                            </Flex>
                                        </TickrHeadCell>
                                    ))}
                                </TickrTableRow>
                            </TickrTableHead>
                            <TickrTableBody>
                                {sortedRows.map((row, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                    <TickrTableRow invertStripes key={`row-${i}-document-data`}>
                                        {columns.map((column) => (
                                            <TickrTableCell key={column}>
                                                <Typography variant="p-14-r">{row[column]}</Typography>
                                            </TickrTableCell>
                                        ))}
                                    </TickrTableRow>
                                ))}
                            </TickrTableBody>
                        </TickrTable>
                    </TickrTableContainer>
                </TickrDialogContent>
            </TickrDialog>
        </>
    );
}
