import { Dispatch, SetStateAction, createContext } from 'react';

export type MessageStatus = 'generating' | 'complete' | 'error';

export interface RawMessage {
    id: string;
    sender: 'HUMAN' | 'AI';
    status: MessageStatus;
    text: string;
    timestamp: number;
}

export interface FileSummary {
    summary: string;
    questions: string[];
}

export type FileStatus =
  | 'uploading'
  | 'uploaded'
  | 'indexing'
  | 'indexed'
  | 'summarizing'
  | 'complete'
  | 'error';

export interface RawFile {
    id: string;
    mime: string;
    name: string;
    status: FileStatus;
    summary: string | null;
    timestamp: number;
}

export interface MessageItem extends RawMessage {
    type: 'message';
}

export interface FileItem extends Omit<RawFile, 'summary'> {
    type: 'file';
    summary: FileSummary | null;
}

export type Conversation = (MessageItem | FileItem)[];

export interface ChatBoxContextProps {
    chatInput: string;
    chatScrollPosition: number;
    chatStreamId: string;
    conversation: Conversation;
    fileList: FileItem[];
    fileStreamId: string;
    setChatInput: Dispatch<SetStateAction<string>>;
    setChatScrollPosition: Dispatch<SetStateAction<number>>;
    setChatStreamId: Dispatch<SetStateAction<string>>;
    setFileStreamId: Dispatch<SetStateAction<string>>;
}

export const ChatBoxContext = createContext<ChatBoxContextProps>({
    chatInput: '',
    chatScrollPosition: 0,
    chatStreamId: '',
    conversation: [],
    fileList: [],
    fileStreamId: '',
    setChatInput: () => null,
    setChatScrollPosition: () => null,
    setChatStreamId: () => null,
    setFileStreamId: () => null,
});
