import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import { useUpdateTabMosaic } from '../../hooks/useUpdateTabMosaic';
import { useMosaic } from '../useMosaic';
import { useMosaicWindow } from '../useMosaicWindow';

export function RemoveButton() {
    const { mosaicActions } = useMosaic();
    const { mosaicWindowActions } = useMosaicWindow();
    const updateTabMosaic = useUpdateTabMosaic();

    return (
        <IconButton
            onClick={() => {
                const root = mosaicActions.getRoot();
                if (root === 'chatCpg') return;

                if (typeof root === 'string') {
                    updateTabMosaic({ node: 'chatCpg' });
                    return;
                }

                const path = mosaicWindowActions.getPath();
                mosaicActions.remove(path);
            }}
            size="small"
            style={{
                width: '24px',
                height: '24px',
            }}
        >

            <ClearIcon />
        </IconButton>
    );
}
