import { styled } from '@mui/material';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { forwardRef, useMemo } from 'react';
import { useMeasure } from 'react-use';
import { Flex } from '../Core/Flex';
import { useChartOptions } from '../Providers/ChartOptionsProvider/useChartOptions';
import { pureMerge } from '../helpers/pureMerge';

const StyledFlex = styled(Flex, { shouldForwardProp: (prop) => !!prop })`
    max-height: 100%;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
`;

interface HighchartsContainerProps {
    options: Highcharts.Options;
}

export const HighchartsContainer = forwardRef<HighchartsReact.RefObject, HighchartsContainerProps>(
    ({ options }, ref) => {
        const chartOptions = useChartOptions();

        const [containerRef, { width, height }] = useMeasure<HTMLDivElement>();

        const combinedOptions = useMemo(() => {
            return pureMerge(
                chartOptions,
                {
                    ...options,
                    chart: {
                        ...(options.chart ?? {}),
                        width,
                        height,
                    },
                }
            );
        }, [
            width,
            height,
            options,
            chartOptions,
        ]);

        return (
            <StyledFlex ref={containerRef}>
                {height > 0 && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={combinedOptions}
                        ref={ref}
                    />
                )}
            </StyledFlex>
        );
    }
);
