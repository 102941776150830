import { encodingForModel } from 'js-tiktoken';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const encoder = encodingForModel('gpt-4-turbo-preview');

export function useYupValidation() {
    const { t } = useTranslation();
    const password = Yup.string().min(6, t('error.password.short')).required(t('util.required'));

    return {
        commaSeparatedEmails: Yup.string().test('email', t('error.invalidEmail'), (email) => {
            const emailRegex =
        /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(\s*,\s*[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/;

            return emailRegex.test(email ?? '') || email === undefined;
        }),
        email: Yup.string().email(t('error.invalidEmail')),
        string: Yup.string(),
        string255: Yup.string().max(255, t('form.error.max', { max: 255 })),
        string1000: Yup.string().max(1000, t('form.error.max', { max: 1000 })),
        stringRequired: Yup.string().required(t('util.required')),
        token4096: Yup.string().test(
            'token',
            () => t('error.invalidToken'),
            (value) => {
                if (!value) return true;
                return encoder.encode(value).length <= 4096;
            }
        ),
        boolean: Yup.boolean(),
        rating: Yup.number().typeError(t('collections.ratings.pleaseSelectAStarRating')),
        password,
    };
}
