import { PropsWithChildren, useReducer } from 'react';
import { DashboardSettingsContext } from './DashboardSettingsContext';

export function DashboardSettingsProvider({ children }: PropsWithChildren) {
    const [presentationMode, togglePresentationMode] = useReducer((state) => !state, false);

    return (
        <DashboardSettingsContext.Provider
            value={{
                presentationMode,
                togglePresentationMode,
            }}
        >
            {children}
        </DashboardSettingsContext.Provider>
    );
}
