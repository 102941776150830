import dayjs from 'dayjs';

import { useDateFormatter } from './useDateFormatter';

export function useReplaceReportingWeek() {
    const dateFormatter = useDateFormatter();

    return (text?: string) => text?.replace(/(\d{4})-RW(\d+)/g, (_, year, week) => {
        const unixDate =
        dayjs().utc(true).year(year)
            .isoWeek(week)
            .endOf('isoWeek')
            .subtract(1, 'day')
            .unix() * 1000;

        return `week ${week} (ending ${dateFormatter(Number(unixDate), 'full')})`;
    }) ?? '';
}
