import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { ElementAttributes } from '@tickr/sequelize-models';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { ForecastChartPopoverContent } from './ForecastChartPopoverContent';
interface ForecastChartPopoverProps {
    element: ElementAttributes;
}

export function ForecastChartPopover({ element }: ForecastChartPopoverProps) {
    const covariateCount = (element.settings?.forecastChart?.fredCovariates?.length ?? 0) +
    (element.settings?.forecastChart?.commodityCovariates?.length ?? 0) +
    (element.settings?.forecastChart?.docCovariates?.length ?? 0);

    if (covariateCount < 6) {
        return null;
    }

    return (
        <PopoverProvider placement="bottom-start">
            <PopoverTrigger asChild>
                <IconButton
                    size="small"
                    style={{
                        width: '24px',
                        height: '24px',
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
            </PopoverTrigger>
            <ForecastChartPopoverContent element={element} />
        </PopoverProvider>
    );
}
