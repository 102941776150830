import { styled } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import '@tickr/ui/src/Charts/HighchartsConfig';
import { AppProvider } from '@tickr/ui/src/Providers/AppProvider/AppProvider';
import { ChartOptionsProvider } from '@tickr/ui/src/Providers/ChartOptionsProvider/ChartOptionsProvider';
import { WebAppLocalizationProvider } from '@tickr/ui/src/Providers/LocalizationProvider/WebAppLocalizationProvider';
import { i18n } from '@tickr/ui/src/i18n';
import '@tickr/ui/styles/App.css';
import { useEffect } from 'react';
import 'react-grid-layout/css/styles.css';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { Pages } from './Pages';
import { queryClient } from './queryClient';

init({
    dsn: 'https://f1f7fb0ef08746d3bc97f53912d68b2d@o4505036936839168.ingest.us.sentry.io/4505347760979968',
    enabled: import.meta.env.MODE !== 'development',
    environment: import.meta.env.MODE,
    integrations: [
        replayIntegration(),
        reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],

    // tracePropagationTargets: [
    //     'localhost',
    //     /^https:\/\/dashboard-api-qa\.tickr\.com/,
    //     /^https:\/\/api-qa\.tickr\.com/,
    //     /^https:\/\/qa\.tids\.tickr\.com/,
    //     /^https:\/\/dashboard-api\.tickr\.com/,
    //     /^https:\/\/api\.tickr\.com/,
    //     /^https:\/\/tids\.tickr\.com/,
    // ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

export function App() {
    const { keycloak } = useKeycloak();

    return (
        <GlobalClasses>
            <HelmetProvider>
                <ChartOptionsProvider>
                    <AppProvider
                        dashboardApiUrl={import.meta.env.VITE_DASHBOARD_API_URL}
                        envMode={import.meta.env.MODE === 'production' ? 'prod' : 'qa'}
                        publicApiUrl={import.meta.env.VITE_PUBLIC_API_URL}
                        tidsApiUrl={import.meta.env.VITE_TIDS_API_URL}
                    >
                        <I18nextProvider i18n={i18n}>
                            <WebAppLocalizationProvider>
                                <QueryClientProvider client={queryClient}>
                                    <BrowserRouter>
                                        {keycloak.authenticated && <Pages />}
                                    </BrowserRouter>
                                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                                </QueryClientProvider>
                            </WebAppLocalizationProvider>
                        </I18nextProvider>
                    </AppProvider>
                </ChartOptionsProvider>
            </HelmetProvider>
            <div id="modal-portal" />
        </GlobalClasses>
    );
}

const GlobalClasses = styled('div')(({ theme }) => `
    /* mapbox */
    .mapboxgl-popup-content {
        background: ${theme.palette.info.main};
        z-index: 0;
    }
    
    .mapboxgl-ctrl-attrib-inner, .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
        background-color: ${theme.palette.background.secondary};
        a {
            color: ${theme.palette.middleGray.main};
        }
    }
    
    .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
        border-top-color: ${theme.palette.info.main};
        border-bottom-color: ${theme.palette.info.main};
        z-index: 1;
    }
    
    .mapboxgl-canvas {
        cursor: move;
    }

    /* react-loading-skeleton */
    .flex-skeleton {
        overflow: hidden;
        width: 100%;
        flex-grow: 1;
    }

    /* react-grid-layout */
    .react-grid-item.react-grid-placeholder {
        border-radius: ${theme.shape.borderRadius}px;
        background: ${theme.palette.middleGray.main};
        opacity: 0.3;
    }
`);
