import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import { useCallback } from 'react';

export function useFormatYAxisOptions() {
    const theme = useTheme();

    const formatYAxisOptions = useCallback(
        (options: Highcharts.YAxisOptions): Highcharts.YAxisOptions => ({
            type: 'linear',
            gridLineColor: theme.palette.background.secondary,
            className: 'h-12-b',
            ...options,
            labels: {
                ...options.labels,
                style: {
                    fontSize: '12px',
                    ...options.labels?.style,
                },
            },
            title: {
                text: '',
                ...options.title,
            },
        }),
        [theme.palette.background.secondary]
    );

    return formatYAxisOptions;
}
