import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { FeedbackType } from '../Providers/TidsMutationsProvider/TidsMutationsContext';
import { useTidsMutations } from '../Providers/TidsMutationsProvider/useTidsMutations';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useCaptureTickrException } from '../hooks/useCaptureTickrException';
import { usePage } from '../hooks/usePage';
import { FeedbackDialog } from './FeedbackDialog';

interface FeedbackButtonsProps {
    chatMessageId?: string;
    feedbackType?: FeedbackType;
    isEdited: boolean;
    isStreaming: boolean;
    talkingPointId?: string;
    text: string;
}

export function FeedbackButtons({
    chatMessageId,
    feedbackType,
    isEdited,
    isStreaming,
    talkingPointId,
    text,
}: FeedbackButtonsProps) {
    const { t } = useTranslation();
    const { summaryId } = usePage();
    const { postChatFeedback, postSummaryFeedback } = useTidsMutations();
    const { sendAlert } = useToaster();
    const captureTickrException = useCaptureTickrException();

    if (!summaryId) return null;

    return (
        <Flex gap="0.2rem">
            {!isEdited && !isStreaming && (
                <>
                    <IconButton
                        color="secondaryText"
                        onClick={async () => {
                            try {
                                if (chatMessageId) {
                                    await postChatFeedback({
                                        category: '',
                                        chatMessageId,
                                        feedback: '',
                                        explanation: '',
                                        label: 'good',
                                    });
                                } else if (talkingPointId && feedbackType) {
                                    await postSummaryFeedback({
                                        category: '',
                                        feedback: '',
                                        explanation: '',
                                        talkingPointId,
                                        label: 'good',
                                        type: feedbackType,
                                        summaryId,
                                    });
                                } else {
                                    throw new Error('No talkingPointId or chatMessageId');
                                }

                                sendAlert({
                                    severity: 'success',
                                    text: t('feedback.thankYou'),
                                });
                            } catch (e) {
                                captureTickrException(e);

                                sendAlert({
                                    severity: 'error',
                                    text: t('feedback.error'),
                                });
                            }
                        }}
                        size="small"
                    >
                        <ThumbUpOffAltIcon fontSize="inherit" />
                    </IconButton>
                    <FeedbackDialog
                        chatMessageId={chatMessageId}
                        feedbackType={feedbackType}
                        talkingPointId={talkingPointId}
                        text={text}
                    />
                </>
            )}
        </Flex>
    );
}
