import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';

interface SolutionHeaderProps {
    title: string;
    description?: string;
}

export function SolutionHeader({ title, description }: SolutionHeaderProps) {
    return (
        <Flex
            direction="column"
            gap="0.5rem"
            padding="0 6.5%"
            style={{
                gridRow: '1',
                gridColumn: '1',
            }}
            width="100%"
        >
            <Typography as="h1">{title}</Typography>
            {description && (
                <Typography secondary variant="p-14-r">
                    {description}
                </Typography>
            )}
        </Flex>
    );
}
