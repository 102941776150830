import { StyledComponent } from '@emotion/styled';
import { TableHead, TableHeadProps, styled } from '@mui/material';

interface TickrTableHeadProps {
    top?: number;
}

export const TickrTableHead: StyledComponent<TableHeadProps, TickrTableHeadProps> = styled(
    TableHead
)<TickrTableHeadProps>(
    ({ top, theme }) => ({
        position: 'sticky',
        top: top ?? 0,
        zIndex: 100,
        backgroundColor: theme.palette.background.default,
    })
);
