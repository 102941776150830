import ClearIcon from '@mui/icons-material/Clear';
import PushPinIcon from '@mui/icons-material/PushPin';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { IconButton, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { Flex } from '../Core/Flex';
import { NumberFormatter } from '../Core/NumberFormatter';
import { Typography } from '../Core/Typography';
import { useApp } from '../Providers/AppProvider/useApp';
import { useLocalization } from '../Providers/LocalizationProvider/useLocalization';
import { NumberStyle } from '../helpers/numberStyleHelper';

export interface SalesPerformanceInsight {
    id: string;
    gtin: string;
    type: 'risk' | 'opportunity';
    value: number;
    numberStyle: NumberStyle;
    description: string;
    retailer: string;
}

interface SalesPerformanceInsightProps {
    insights: SalesPerformanceInsight[];
}

export function SalesPerformanceInsights({ insights }: SalesPerformanceInsightProps) {
    const t = useLocalization();
    const { publicApiUrl } = useApp();
    const [pinnedInsights, setPinnedInsights] = useState<string[]>([]);
    const [hiddenInsights, setHiddenInsights] = useState<string[]>([]);

    return (
        <Flex
            {...(!publicApiUrl && { padding: '0.5rem' })}
            direction="column"
            gap="0.5rem"
            height="100%"
            style={{ overflow: 'hidden' }}
            width="100%"
        >
            {!publicApiUrl && <Typography variant="h-16-b">{t('salesPerformance.insights')}</Typography>}
            <Flex
                direction="column"
                gap="0.5rem"
                height="100%"
                style={{ overflowY: 'auto' }}
                width="100%"
            >
                {pinnedInsights.map((id) => {
                    const insight = insights.find((insight) => insight.id === id) as SalesPerformanceInsight;

                    return (
                        <SalesPerformanceInsightCard
                            key={id}
                            {...insight}
                            pinned
                            setHiddenInsights={setHiddenInsights}
                            setPinnedInsights={setPinnedInsights}
                        />
                    );
                })}
                {insights
                    .filter(({ id }) => !pinnedInsights.includes(id) && !hiddenInsights.includes(id))
                    .map((insight) => (
                        <SalesPerformanceInsightCard
                            key={insight.id}
                            {...insight}
                            setHiddenInsights={setHiddenInsights}
                            setPinnedInsights={setPinnedInsights}
                        />
                    ))}
            </Flex>
        </Flex>
    );
}

interface SalesPerformanceInsightCardProps extends SalesPerformanceInsight {
    setPinnedInsights: Dispatch<SetStateAction<string[]>>;
    setHiddenInsights: Dispatch<SetStateAction<string[]>>;
    pinned?: boolean;
}

function SalesPerformanceInsightCard({
    id,
    gtin,
    pinned,
    value,
    numberStyle,
    description,
    type,
    setPinnedInsights,
    setHiddenInsights,
}: SalesPerformanceInsightCardProps) {
    const t = useLocalization();
    const { palette } = useTheme();
    const isRisk = type === 'risk';

    const color = isRisk ? palette.negative.main : palette.success.main;

    const typeText = isRisk
        ? t('salesPerformance.valueAtStake')
        : t('salesPerformance.forecastedGain');

    return (
        <Flex
            align="center"
            gap="2rem"
            justify="space-between"
            padding="0.6rem 1.2rem"
            style={{
                background: `linear-gradient(to right, ${color} 0%, ${color} 12px, ${palette.background.secondary} 12px)`,
                borderRadius: '6px',
            }}
            width="100%"
        >
            <Flex direction="column" width="100px">
                <Flex align="center" gap="0.5rem">
                    <NumberFormatter
                        as="p"
                        numberStyle={numberStyle}
                        val={value}
                        variant="n-24-b"
                    />
                    {isRisk
                        ? <TrendingDownIcon color={isRisk ? 'negative' : 'success'} />
                        : <TrendingUpIcon color={isRisk ? 'negative' : 'success'} />
                    }
                </Flex>
                <Typography secondary style={{ whiteSpace: 'pre-line' }}>
                    {typeText.toLocaleLowerCase()}
                </Typography>
            </Flex>
            <Flex
                direction="column"
                gap="0.4rem"
                grow="1"
            >
                <Typography variant="h-14-b">{`GTIN ${gtin}`}</Typography>
                <Typography variant="p-14-r">{description}</Typography>
            </Flex>
            <Flex gap="0.2rem">
                <IconButton
                    color="secondaryText"
                    onClick={() => {
                        setHiddenInsights((hiddenInsights) => [...hiddenInsights, id]);
                        setPinnedInsights((pinnedInsights) => pinnedInsights.filter((pinnedId) => pinnedId !== id));
                    }}
                    size="small"
                >
                    <ClearIcon />
                </IconButton>
                <IconButton
                    color={pinned ? 'primary' : 'secondaryText'}
                    onClick={() => {
                        setPinnedInsights((pinnedInsights) => (pinnedInsights.includes(id)
                            ? pinnedInsights.filter((pinnedId) => pinnedId !== id)
                            : [...pinnedInsights, id]));
                    }}
                    size="small"
                >
                    <PushPinIcon />
                </IconButton>
            </Flex>
        </Flex>
    );
}
