import { useTheme } from '@mui/material';

export function useHeaderIconStyles() {
    const { palette, shape } = useTheme();

    return {
        background: palette.background.tertiary,
        borderRadius: shape.borderRadius,
        height: '28px',
        width: '36px',
    };
}
