import { PageAttributes } from '@tickr/sequelize-models';
import { OPTIMISTIC_PAGE_ID } from '../Providers/CollectionMutationsProvider/CollectionMutationsProvider';
import { useChatCpgData } from './useChatCpgData';
import { useCurrentForecast } from './useCurrentForecast';

export function useForecastInProgress() {
    const currentForecast = useCurrentForecast();
    const stagedForecast = useChatCpgData<PageAttributes>(currentForecast?.uuid ?? '');

    const forecast = stagedForecast ?? currentForecast;

    const isUploaded = !!forecast?.settings?.document?.id;
    const isUploading = !!(forecast?.settings?.document?.name && !isUploaded);

    const isValidating = isUploaded && !forecast?.settings?.document?.isValidated;
    const isValidated = !!forecast?.settings?.document?.isValidated;

    const isSummarized = !!forecast?.settings?.document?.summary;
    const isSummarizing = isValidated && !isSummarized;

    const isDocumentComplete = !!forecast?.settings?.document?.data;
    const isCovariateSearchComplete = !!forecast?.settings?.document?.docCovariates;

    return {
        docCovariates: forecast?.settings.document?.docCovariates,
        fredCovariates: forecast?.settings.document?.fredCovariates,
        commodityCovariates: forecast?.settings.document?.commodityCovariates,
        dateColumn: forecast?.settings.document?.date_columns?.[0],
        documentId: forecast?.settings?.document?.id,
        enableCovariateSearch: !!forecast?.settings.document?.enableCovariateSearch,
        errorMsg: forecast?.settings.document?.errorMsg,
        forecastColumn: forecast?.settings.document?.forecastColumn ??
            forecast?.settings.document?.numeric_columns?.[0],
        fileName: forecast?.settings?.document?.name,
        forecast,
        frequency: forecast?.settings.document?.frequency,
        frequencyDays: forecast?.settings.document?.frequency_days,
        isCovariateSearchComplete,
        isDocumentComplete,
        isLoading: forecast?.uuid === OPTIMISTIC_PAGE_ID,
        isStreamingCovariates: !!forecast?.settings.document?.periods,
        isSummarized,
        isSummarizing,
        isUploaded,
        isUploading,
        isValidated,
        isValidating,
        periods: forecast?.settings.document?.periods,
        summary: forecast?.settings.document?.summary,
        summaryId: forecast?.settings.document?.summaryId,
        userContext: forecast?.settings.document?.userContext ?? forecast?.settings.document?.summary,
    };
}
