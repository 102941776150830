import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { Header } from '../Navigation/Header';
import { PageLayout } from '../PageLayout/PageLayout';

export function WelcomeChatCpg() {
    const { t } = useTranslation();

    return (
        <PageLayout pageTitle={t('page.welcome')}>
            <Header pageTitle={t('page.welcome')} />
            <Flex
                direction="column"
                gap="5rem"
                padding="0 1rem 3rem max(20%, 1rem)"
                style={{
                    margin: 'auto 0',
                    overflow: 'auto',
                }}
            >
                <Flex direction="column" gap="2rem">
                    <ChatCpgIcon size="150px" />
                    <Flex direction="column" gap="1rem">
                        <Flex direction="column">
                            <Typography as="h1">{t('chatCpg.newUser.welcome')}</Typography>
                            <Typography as="h2" variant="h-22-b">
                                {t('chatCpg.newUser.tickrMostAdvancedAISystem')}
                            </Typography>
                        </Flex>
                        <Typography style={{ maxWidth: '350px' }} variant="p-14-r">
                            {t('chatCpg.newUser.revolutionizing')}
                        </Typography>
                        <Button
                            component={Link}
                            size="small"
                            style={{ width: '200px' }}
                            to="/home"
                            variant="contained"
                        >
                            {t('util.letsGo')}
                        </Button>
                    </Flex>
                </Flex>
                <Typography
                    multiline
                    style={{ maxWidth: '350px' }}
                    variant="p-14-r"
                >
                    {t('chatCpg.newUser.description')}
                </Typography>
            </Flex>
        </PageLayout>
    );
}
