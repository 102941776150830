import { styled, useTheme } from '@mui/material';
import { CSSProperties, ElementType, FocusEventHandler, FormEventHandler, KeyboardEventHandler, MouseEventHandler, PropsWithChildren, forwardRef } from 'react';
import { multilineCSS } from '../helpers/multilineCSS';
import { truncateCSS } from './truncateCSS';

/* STYLE VARIANTS */
type Headline =
    | 'h-36-b'
    | 'h-30-b'
    | 'h-26-b'
    | 'h-22-b'
    | 'h-18-b'
    | 'h-16-b'
    | 'h-14-b'
    | 'h-12-b';

type Caption = 'c-14' | 'c-11' | 'c-10';

export type Paragraph =
    | 'p-22-b'
    | 'p-22-r'
    | 'p-18-r'
    | 'p-18-i'
    | 'p-16-b'
    | 'p-16-r'
    | 'p-16-i'
    | 'p-14-b'
    | 'p-14-r'
    | 'p-14-i'
    | 'p-12-r'
    | 'p-12-i';

type Button = 'button-16' | 'button-12' | 'button-10';

export type NumberType =
    | 'n-64-b'
    | 'n-42-b'
    | 'n-32-b'
    | 'n-24-b'
    | 'n-20-b'
    | 'n-16-b'
    | 'n-14-b'
    | 'n-14-r'
    | 'n-12-b'
    | 'n-12-r';

type Table = 't-13-b' | 't-13-r';

export type Variant = Headline | Caption | Paragraph | Button | NumberType | Table;

/* STYLE MODIFICATIONS */

const underlineCSS: CSSProperties = {
    textDecoration: 'underline',
};

const nowrapCSS: CSSProperties = {
    whiteSpace: 'nowrap',
};

const lineClampCSS = (lineClamp: number): CSSProperties => ({
    display: '-webkit-box',
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
});

export interface TypographyProps {
    as?: ElementType;
    className?: string;
    color?: string;
    contentEditable?: boolean;
    lineClamp?: number;
    multiline?: boolean;
    nowrap?: boolean;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onInput?: FormEventHandler<HTMLDivElement> ;
    onKeyDown?: KeyboardEventHandler<HTMLDivElement> ;
    secondary?: boolean;
    style?: CSSProperties;
    tabIndex?: number;
    tertiary?: boolean;
    textAlign?: CSSProperties['textAlign'];
    title?: string;
    truncate?: boolean;
    underline?: boolean;
    variant?: Variant;
    width?: string;
}

const defaultVariants = {
    p: 'p-12-r',
    li: 'p-12-r',
    h1: 'h-22-b',
    h2: 'h-16-b',
    h3: 'h-14-b',
    h4: 'h-12-b',
    h5: 'h-12-b',
    h6: 'h-12-b',
    th: 't-13-r',
};

type DefaultVariants = keyof typeof defaultVariants;

export const Typography = forwardRef<HTMLDivElement, PropsWithChildren<TypographyProps>>(
    (
        {
            as = 'p',
            className,
            color,
            contentEditable,
            lineClamp,
            multiline,
            nowrap,
            onBlur,
            onClick,
            onFocus,
            onInput,
            onKeyDown,
            secondary,
            tabIndex,
            title,
            tertiary,
            textAlign,
            truncate,
            style,
            underline,
            variant,
            width,
            children,
        },
        ref
    ) => {
        const { palette } = useTheme();

        const styles: CSSProperties = {
            color: color ?? palette.text.primary,
            ...(contentEditable && { cursor: 'text' }),
            ...(lineClamp && lineClampCSS(lineClamp)),
            ...(multiline && { lineHeight: multilineCSS[(variant as Paragraph) ?? 'p-12-r'] }),
            ...(nowrap && nowrapCSS),
            ...(secondary && { color: palette.text.secondary }),
            ...(tertiary && { color: palette.text.disabled }),
            ...(textAlign && { textAlign }),
            ...(truncate && truncateCSS),
            ...(underline && underlineCSS),
            ...(width && { width }),
            ...style,
        };

        const variantClass = variant ?? defaultVariants[as as DefaultVariants];

        const classes = [className, variantClass].filter((c) => c);

        return (
            <HtmlElement
                as={as}
                className={classes.join(' ')}
                contentEditable={contentEditable}
                onBlur={onBlur}
                onClick={onClick}
                onFocus={onFocus}
                onInput={onInput}
                onKeyDown={onKeyDown}
                ref={ref}
                style={styles}
                suppressContentEditableWarning={contentEditable}
                tabIndex={tabIndex}
                title={title}
            >
                {children}
            </HtmlElement>
        );
    }
);

const HtmlElement = styled(
    'div',
    // { shouldForwardProp: () => true }
)``;
