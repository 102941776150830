import { ThemeOptions } from '@mui/material';

export const baseTheme: ThemeOptions = {
    shape: {
        borderRadius: 6,
    },
    typography: {
        fontFamily: 'Gilroy-Regular, sans-serif',
        // h1: {},
        // h2: {},
        // h3: {},
        // h4: {},
        // h5: {},
        // h6: {},
        // subtitle1: {},
        // subtitle2: {},
        // body1: {},
        // body2: {},
        // button: {},
        // caption: {},
        // overline: {},
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Gilroy-Bold, sans-serif',
                    letterSpacing: '0.08em',
                },
            },
        },
    },
};
