import { styled, useTheme } from '@mui/material';
import { DotLoader } from '@tickr/ui/src/ChatCpg/DotLoader';
import { Flex } from '@tickr/ui/src/Core/Flex';
import { Typography } from '@tickr/ui/src/Core/Typography';

interface LoginLoaderProps {
    text?: string;
}

export function LoginLoader({
    text = 'TICKR',
}: LoginLoaderProps) {
    const { palette } = useTheme();

    return (
        <StyledBackgroundFlex
            align="center"
            direction="column"
            gap="1rem"
            height="100vh"
            justify="center"
            width="100%"
        >
            <Typography secondary variant="h-22-b">{text}</Typography>
            <DotLoader color={palette.secondary.dark} width="100px" />
        </StyledBackgroundFlex>
    );
}

const StyledBackgroundFlex = styled(Flex, { shouldForwardProp: () => true })(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));
