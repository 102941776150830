import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { SlideCarousel } from '../Core/SlideCarousel';
import { ValueBox } from '../Core/ValueBox';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { initialComparisonChartData } from '../helpers/comparisonChartHelpers';
import { useDateTimeLabelFormats } from '../hooks/useDateTimeLabelFormats';
import { useLegendClick } from '../hooks/useLegendClick';
import { usePage } from '../hooks/usePage';
import { HighchartsContainer } from './HighchartsContainer';
import { formatTrend } from './chartHelpers';
import { useFormatYAxisOptions } from './useFormatYAxisOptions';

export function CompetitorsPriceVsMyVolume() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const dateTimeLabelFormats = useDateTimeLabelFormats();
    const { optimizePricingSummaryData } = usePage();
    const formatYAxisOptions = useFormatYAxisOptions();
    const influencerData = optimizePricingSummaryData?.influencers;
    const priceChartData = optimizePricingSummaryData?.priceChart;
    const pvsChartData = optimizePricingSummaryData?.pvsChart;

    const chartData = useMemo(() => {
        return influencerData && priceChartData
            ? {
                myProduct: {
                    id: influencerData.id,
                    name: influencerData.name,
                    trend: formatTrend({
                        trend: pvsChartData?.volume,
                        daysToAdd: 6,
                    }),
                },
                competitor1: {
                    id: influencerData.influencers[0].id,
                    name: influencerData.influencers[0].name,
                    trend: formatTrend({
                        trend: priceChartData[influencerData.influencers[0].id]?.price,
                        daysToAdd: 6,
                    }),
                },
                competitor2: {
                    id: influencerData.influencers[1].id,
                    name: influencerData.influencers[1].name,
                    trend: formatTrend({
                        trend: priceChartData[influencerData.influencers[1].id]?.price,
                        daysToAdd: 6,
                    }),
                },
            }
            : initialComparisonChartData;
    }, [
        influencerData,
        priceChartData,
        pvsChartData,
    ]);

    const options = useMemo(() => {
        const series: Highcharts.Options['series'] = [
            {
                name: t('metrics.myVolume'),
                type: 'column',
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1,
                    },
                    stops: [
                        [0, palette.salmon.main],
                        [0.05, `${palette.salmon.main}B3`], // 70% opacity
                        [0.2, `${palette.salmon.main}80`], // 50% opacity
                        [1, 'transparent'],
                    ],
                },
                borderWidth: 0,
                yAxis: 0,
                data: chartData.myProduct.trend,
                tooltip: {
                    valueDecimals: 0,
                },
            },
        ];

        if (chartData.competitor1) {
            series.push({
                name: t('metrics.competitor1'),
                type: 'line',
                color: palette.secondary.main,
                dashStyle: 'Solid',
                lineWidth: 2,
                yAxis: 1,
                data: chartData.competitor1?.trend ?? [],
                tooltip: {
                    valuePrefix: '$',
                },
            });
        }

        if ('competitor2' in chartData) {
            series.push({
                name: t('metrics.competitor2'),
                type: 'line',
                color: palette.tickleMePink.main,
                dashStyle: 'Solid',
                lineWidth: 2,
                yAxis: 1,
                data: chartData.competitor2?.trend ?? [],
                tooltip: {
                    valuePrefix: '$',
                },
            });
        }

        const highchartsOptions: Highcharts.Options = {
            tooltip: {
                shared: true,
                dateTimeLabelFormats,
                borderColor: palette.info.main,
            },
            chart: {
                animation: false,
            },
            xAxis: {
                type: 'datetime',
                startOfWeek: 6,
                crosshair: true,
            },
            legend: {
                enabled: false,
            },
            yAxis: [
                formatYAxisOptions({
                    opposite: true,
                    labels: {
                        style: {
                            color: palette.salmon.main,
                        },
                    },
                }),
                formatYAxisOptions({
                    labels: {
                        style: {
                            color: palette.text.primary,
                        },
                        formatter: function() {
                            return `$${Highcharts.numberFormat(this.value as number, 2)}`;
                        },
                    },
                }),
            ],
            series,
        };

        return highchartsOptions;
    }, [
        t,
        palette.salmon.main,
        palette.info.main,
        palette.secondary.main,
        palette.tickleMePink.main,
        palette.text.primary,
        chartData,
        dateTimeLabelFormats,
        formatYAxisOptions,
    ]);

    const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick({
        trendCount: 2,
    });

    return (
        <Flex
            align="stretch"
            direction="column"
            height="100%"
            justify="stretch"
            style={{
                minHeight: 0,
                minWidth: 0,
            }}
            width="100%"
        >
            <SlideCarousel
                alignItems="flex-start"
                gap="0.2rem"
                height="45px"
                width="100%"
            >
                <InfoTooltip
                    isHidden={hiddenTrends.includes('myVolume')}
                    leaveOpenOnHover
                    text={chartData.myProduct.name}
                >
                    <ValueBox
                        allNeutral
                        color={palette.salmon.main}
                        indicator="column"
                        label={t('metrics.myVolume')}
                        onClick={() => handleLegendClick('myVolume', 0)}
                        secondary
                        style={{
                            opacity: hiddenTrends.includes('myVolume') ? 0.5 : 1,
                        }}
                        subtitle={chartData.myProduct.id}
                    />
                </InfoTooltip>
                {'competitor1' in chartData && (
                    <InfoTooltip
                        isHidden={hiddenTrends.includes('competitor1Price')}
                        leaveOpenOnHover
                        text={chartData.competitor1?.name}
                    >
                        <ValueBox
                            allNeutral
                            color={palette.secondary.main}
                            label={t('metrics.competitor1')}
                            onClick={() => handleLegendClick('competitor1Price', 1)}
                            secondary
                            style={{
                                opacity: hiddenTrends.includes('competitor1Price') ? 0.5 : 1,
                            }}
                            subtitle={chartData.competitor1?.id}
                        />
                    </InfoTooltip>
                )}
                {'competitor2' in chartData && (
                    <InfoTooltip
                        isHidden={hiddenTrends.includes('competitor2Price')}
                        leaveOpenOnHover
                        text={chartData.competitor2?.name}
                    >
                        <ValueBox
                            allNeutral
                            color={palette.tickleMePink.main}
                            label={t('metrics.competitor2')}
                            onClick={() => handleLegendClick('competitor2Price', 1)}
                            secondary
                            style={{
                                opacity: hiddenTrends.includes('competitor2Price') ? 0.5 : 1,
                            }}
                            subtitle={chartData.competitor2?.id}
                        />
                    </InfoTooltip>
                )}
            </SlideCarousel>
            <HighchartsContainer options={options} ref={chartRef} />
        </Flex>
    );
}
