import { Dispatch, SetStateAction, createContext } from 'react';

export interface ErrorContextProps {
    error: string | null;
    setError: Dispatch<SetStateAction<string | null>>;
}

export const ErrorContext = createContext<ErrorContextProps>({
    error: null,
    setError: () => null,
});
