import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { FilterGroup, FilterGroupItem } from './FilterGroup';

interface GTINFilterProps {
    filterId: keyof AppFilters;
    values?: FilterGroupItem[];
}

export function GTINFilter({ filterId, values = [] }: GTINFilterProps) {
    return (
        <FilterGroup
            filterId={filterId}
            type="checkbox"
            values={values}
        />
    );
}
