import { Button, useTheme } from '@mui/material';
import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import uniqBy from 'lodash/uniqBy';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { FilterDefinition } from '../Filters/AvailableFiltersProvider/AvailableFiltersContext';
import { useAvailableFilters } from '../Filters/AvailableFiltersProvider/useAvailableFilters';
import { FilterGroup, FilterGroupItem } from '../Filters/Components/FilterGroup';
import { useStagedFilters } from '../Filters/StagedFiltersProvider/useStagedFilters';
import { useStagedFiltersDispatch } from '../Filters/StagedFiltersProvider/useStagedFiltersDispatch';
import { ControlLabel, ControlLabelProps } from '../FormElements/ControlLabel';
import { DatasemblyIcon } from '../Icons/DatasemblyIcon';
import { useProductAttributes } from '../Providers/ProductAttributesProvider/useProductAttributes';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useLaunchOptimizePricing } from '../hooks/onLaunchAnalysis/useLaunchOptimizePricing';
import { useLaunchSubstitutions } from '../hooks/onLaunchAnalysis/useLaunchSubstitutions';
import { useFilterSummary } from '../hooks/useFilterSummary';
import { LogoLoadingMessage } from './RunAnalysisLoading';
import { SolutionContent } from './SolutionContent';

interface ProductDataRetailerFiltersProps {
    activeFilter: keyof AppFilters;
    setStep: Dispatch<SetStateAction<1 | 2 | 3>>;
    solutionType: Exclude<SolutionType, 'forecast'>;
    step: 1 | 2 | 3;
}

export function ProductDataRetailerFilters({
    activeFilter,
    setStep,
    solutionType,
    step,
}: ProductDataRetailerFiltersProps) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const dispatchStagedFilters = useStagedFiltersDispatch();
    const navigate = useNavigate();
    const { stagedFilters } = useStagedFilters();
    const getFilterSummary = useFilterSummary();
    const { myProducts } = useProductAttributes();

    const {
        datasourceFilters,
        allUserDatasources,
        datasourceDisplayNames,
        userDatasources,
        universalFilters,
    } = useAvailableFilters();

    const [isRunning, setIsRunning] = useState(false);

    // This is a hack for the substitution analysis not all owned products have
    // substitution data so this is slightly better than adding a new filter or
    // data type to the analysis flow.
    const filteredProductValues = getFilteredProductValues(myProducts, solutionType);

    const launchOptimizePricing = useLaunchOptimizePricing();
    const launchSubstitutions = useLaunchSubstitutions();

    const [datasemblyDisabled, setDatasemblyDisabled] = useState(true);

    return (
        <>
            <SolutionContent>
                {activeFilter === 'cpg_owned_products' && (
                    <FilterGroup
                        containerStyle={{
                            padding: '1px',
                            overflow: 'hidden',
                        }}
                        filterId="cpg_owned_products"
                        searchPlaceholder={t('util.searchForAProduct')}
                        searchWidth="400px"
                        type="radio"
                        values={filteredProductValues.map(({ value, title }) => {
                            const [gtin, ...description] = title.split(' ');
                            return {
                                value,
                                title: gtin,
                                subtitle: description.join(' '),
                            };
                        })}
                    />
                )}
                {activeFilter === 'cpg_dataset' && (
                    <FilterGroup
                        containerStyle={{
                            padding: '1px',
                            overflow: 'hidden',
                        }}
                        filterId="cpg_dataset"
                        type="checkbox"
                        values={uniqBy(
                            [
                                ...allUserDatasources,
                                {
                                    value: 'fred',
                                    title: t('filters.cpg_dataset.fred'),
                                },
                                {
                                    value: 'datasembly',
                                    title: t('filters.cpg_dataset.datasembly'),
                                    disabled: datasemblyDisabled,
                                    component: datasemblyDisabled
                                        ? (props: ControlLabelProps) => (
                                            <InfoTooltip
                                                background={palette.middleGray.main}
                                                component={() => (
                                                    <Flex
                                                        align="center"
                                                        direction="column"
                                                        gap="1.5rem"
                                                        padding="1rem"
                                                    >
                                                        <Flex align="center" gap="0.6rem">
                                                            <DatasemblyIcon />
                                                            <Typography variant="h-16-b">{t('datasembly.connectDatasemblyData')}</Typography>
                                                        </Flex>
                                                        <Typography variant="p-14-r">{t('util.clickBelowToStartAFreeTrial')}</Typography>
                                                        <Button
                                                            onClick={() => {
                                                                setDatasemblyDisabled(false);

                                                                dispatchStagedFilters({
                                                                    type: 'selectCheckbox',
                                                                    payload: {
                                                                        filterId: 'cpg_dataset',
                                                                        value: 'datasembly',
                                                                    },
                                                                });
                                                            }}
                                                            size="small"
                                                            variant="contained"
                                                        >
                                                            {t('util.startFreeTrial')}
                                                        </Button>
                                                    </Flex>
                                                )}
                                                leaveOpenOnHover
                                                placement="right-start"
                                            >
                                                <div
                                                    style={{
                                                        width: '130px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <ControlLabel {...props} />
                                                </div>
                                            </InfoTooltip>
                                        )
                                        : undefined,
                                },
                                {
                                    value: 'circana',
                                    title: t('filters.cpg_dataset.circana'),
                                    disabled: true,
                                },
                                {
                                    value: 'ibotta',
                                    title: t('filters.cpg_dataset.ibotta'),
                                    disabled: true,
                                },
                                {
                                    value: 'numerator',
                                    title: t('filters.cpg_dataset.numerator'),
                                    disabled: true,
                                },
                                {
                                    value: 'newsroom.newsroom',
                                    title: t('filters.cpg_dataset.newsroom'),
                                },
                                {
                                    value: 'nielsen',
                                    title: t('filters.cpg_dataset.nielsen'),
                                    disabled: true,
                                },
                                {
                                    value: 'spins',
                                    title: t('filters.cpg_dataset.spins'),
                                    disabled: true,
                                },
                                {
                                    value: '84.51',
                                    title: t('filters.cpg_dataset.84.51'),
                                    disabled: true,
                                },
                                {
                                    value: 'walmart',
                                    title: t('filters.cpg_dataset.walmartLuminate'),
                                    disabled: true,
                                },
                            ],
                            'value'
                        )}
                    />
                )}
                {activeFilter === 'cpg_banner' && (
                    <FilterGroup
                        filterId="cpg_banner"
                        type="radio"
                        values={[
                            {
                                title: t('util.allBanners'),
                                value: 'all',
                            },
                            ...((datasourceFilters[
                                stagedFilters.cpg_dataset?.includes('numerator') ? 'numerator' : 'synth'
                            ]?.cpg_banner?.values ?? []) as FilterGroupItem[]),
                        ]}
                    />
                )}
            </SolutionContent>
            <Flex
                gap="0.5rem"
                padding="1rem 0 1rem 6.5%"
                style={{
                    gridRow: '3',
                    gridColumn: '1',
                    paddingLeft: '6.5%',
                }}
                width="100%"
            >
                <Button
                    color="middleGray"
                    disabled={isRunning}
                    onClick={() => (step === 1
                        ? navigate('/home')
                        : setStep(step === 2 ? 1 : 2))}
                    size="small"
                    style={{ width: '200px' }}
                    variant="contained"
                >
                    {t('util.back')}
                </Button>
                <Button
                    disabled={isRunning || !stagedFilters[activeFilter]}
                    onClick={async () => {
                        if (step === 1) {
                            setStep(2);

                            if (!stagedFilters.cpg_dataset) {
                                dispatchStagedFilters({
                                    type: 'add',
                                    payload: { cpg_dataset: allUserDatasources.map(({ value }) => value).join(',') },
                                });
                            }
                        }

                        if (step === 2) {
                            setStep(3);

                            if (!stagedFilters.cpg_banner) {
                                dispatchStagedFilters({
                                    type: 'add',
                                    payload: { cpg_banner: 'all' },
                                });
                            }
                        }

                        if (step === 3) {
                            if (!launchOptimizePricing) return;
                            setIsRunning(true);

                            const marketSummary = getFilterSummary({
                                filterValue: stagedFilters.cpg_banner,
                                filterDefinition: datasourceFilters.numerator?.cpg_banner as FilterDefinition,
                            }) || t('util.allBanners');

                            const productSummary = getFilterSummary({
                                filterValue: stagedFilters.cpg_owned_products,
                                filterDefinition: {
                                    ...universalFilters.cpg_owned_products,
                                    values: myProducts,
                                } as FilterDefinition,
                            });

                            const sharedProps ={
                                banner: stagedFilters.cpg_banner,
                                gtin: stagedFilters.cpg_owned_products as string,
                                marketSummary,
                                productDescription: productSummary.split(' ').slice(1).join(' '),
                            };

                            if (solutionType === 'optimizePricing') {
                                await launchOptimizePricing({
                                    ...sharedProps,
                                    datasources: stagedFilters.cpg_dataset?.split(',')
                                        .map((d) => datasourceDisplayNames[d])
                                        .join(', ') ?? userDatasources.map(({ title }) => title).join(', '),
                                });
                            } else if (solutionType === 'substitutions') {
                                await launchSubstitutions({
                                    ...sharedProps,
                                    datasources: 'Numerator',
                                });
                            }

                            setIsRunning(false);
                        }
                    }}
                    size="small"
                    style={{ width: '200px' }}
                    variant="contained"
                >
                    {step === 3 ? t('util.runAnalysis') : t('util.next')}
                </Button>
                {isRunning && <LogoLoadingMessage message={t('solution.generatingAnalysis')} />}
            </Flex>
        </>
    );
}

function getFilteredProductValues(myProducts: FilterGroupItem[], solutionType: string) {
    if (solutionType === 'substitutions') {
        return myProducts.filter(({ value }) => {
            return [
                '00034000002405',
                '00034000004409',
                '00040000424314',
                '00034000002467',
                '00099900100873',
                '00034000491087',
                '00040000000327',
                '00034000405688',
                '00040000004356',
                '00034000114702',
                '00034000560028',
                '00034000004805',
                '00040000513056',
                '00070346000095',
                '00070462431230',
                '00010700024404',
                '00010700021526',
                '00034000002412',
                '00040000464082',
                '00040000001607',
                '00040000002635',
                '00040000000310',
                '00034000002399',
                '00040000512943',
                '00070970471254',
                '00034000070152',
                '00000980000052',
                '00070970471230',
                '00040000004325',
                '00034000402113',
                '00071720539446',
                '00099900100255',
                '00040000464280',
                '00022000011893',
                '00040000497523',
                '00070462098358',
                '00040000497547',
                '00040000464105',
                '00034000560431',
                '00079200558338',
                '00040000513018',
                '00011110072153',
                '00070970473562',
                '00040000422082',
                '00099900908363',
                '00073390162009',
                '00034000170388',
                '00099900738076',
                '00010700858405',
                '00042238302419',
            ].includes(value);
        });
    }

    return myProducts;
}
