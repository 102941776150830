import { Box, useTheme } from '@mui/material';
import { useMeasure } from 'react-use';
import { Flex } from '../../Core/Flex';
import { NumberFormatter } from '../../Core/NumberFormatter';

interface OosEventsBarGraphProps {
    max: number;
    value: number;
}

export function OosEventsBarGraph({ max, value }: OosEventsBarGraphProps) {
    const { palette } = useTheme();

    const [boxRef, { width: boxWidth }] = useMeasure<HTMLDivElement>();
    const [numberRef, { width: numberWidth }] = useMeasure<HTMLDivElement>();

    const ratio = value / max;
    const isInside = numberWidth + 5 < boxWidth;

    return (
        <Flex
            align="center"
            gap="8px"
            width="100%"
        >
            <div
                ref={numberRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: isInside ? 5 : boxWidth + 5,
                }}
            >
                <NumberFormatter
                    as="p"
                    color={isInside ? palette.common.black : palette.text.primary}
                    val={value}
                    variant="p-14-b"
                />
            </div>
            <Box
                ref={boxRef}
                sx={{
                    backgroundColor: palette.info.main,
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px',
                    width: `calc(100% * ${ratio})`,
                    height: '20px',
                }}
            />
        </Flex>

    );
}

