import { ElementAttributes } from '@tickr/sequelize-models';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from '../../Core/ContextMenu';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { Checkboxes } from '../../FormElements/Checkboxes';
import { PopoverContent } from '../../Popover/PopoverContent';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useToaster } from '../../Providers/ToasterProvider/useToaster';
import { usePage } from '../../hooks/usePage';

interface ForecastChartPopoverContentProps {
    element: ElementAttributes;
}

export function ForecastChartPopoverContent({ element }: ForecastChartPopoverContentProps) {
    const { t } = useTranslation();
    const { updateElement } = useCollectionMutations();
    const { sendAlert } = useToaster();

    const { page } = usePage();
    const fredCovariates = element.settings?.forecastChart?.fredCovariates;
    const commodityCovariates = element.settings?.forecastChart?.commodityCovariates;
    const docCovariates = element.settings?.forecastChart?.docCovariates;
    const onChartCovariates = element.settings?.forecastChart?.onChartCovariates ?? [];

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChartCovariates.length >= 5 && e.target.checked) {
            sendAlert({
                severity: 'warning',
                text: t('forecast.onlySixTrends'),
            });

            return;
        }

        if (e.target.checked) {
            updateElement({
                mergeProps: {
                    settings: {
                        forecastChart: {
                            onChartCovariates: [...onChartCovariates, e.target.id],
                        },
                    },
                },
                elementUuid: element.uuid,
                queryKey: element.tabUuid ?? element.pageUuid,
            });
        } else {
            updateElement({
                mergeProps: {
                    settings: {
                        forecastChart: {
                            onChartCovariates: onChartCovariates
                                .filter((key) => key !== e.target.id),
                        },
                        hiddenTrends: element.settings?.hiddenTrends
                            ?.filter((key) => key !== e.target.id),
                    },
                },
                elementUuid: element.uuid,
                queryKey: element.tabUuid ?? element.pageUuid,
            });
        }
    };

    if (!page?.settings.document?.forecastColumn) {
        return null;
    }

    return (
        <PopoverContent>
            <ContextMenu
                direction="column"
                gap="1.5rem"
                padding="1rem"
                style={{ overflow: 'visible' }}
                width="100%"
            >
                <Typography variant="p-14-i">{t('forecast.selectSix')}</Typography>
                <Flex
                    direction="column"
                    gap="2rem"
                    style={{
                        maxHeight: '90vh',
                        overflowY: 'auto',
                    }}
                    width="100%"
                >
                    <Checkboxes
                        selected={[page.settings.document.forecastColumn]}
                        title={t('forecast.forecastMetric')}
                        values={[
                            {
                                disabled: true,
                                title: page.settings.document.forecastColumn,
                                value: page.settings.document.forecastColumn,
                            },
                        ]}
                    />
                    {docCovariates && (
                        <Checkboxes
                            onChange={onChange}
                            selected={onChartCovariates}
                            title={t('forecast.fromYourFile')}
                            values={docCovariates.map((covariate) => ({
                                title: covariate.title,
                                value: covariate.seriesId,
                            }))}
                        />
                    )}
                    {fredCovariates && (
                        <Checkboxes
                            onChange={onChange}
                            selected={onChartCovariates}
                            title={t('forecast.externalEconomicInfluencers')}
                            values={fredCovariates.map((covariate) => ({
                                title: covariate.title,
                                value: covariate.seriesId,
                            }))}
                        />
                    )}
                    {commodityCovariates && (
                        <Checkboxes
                            onChange={onChange}
                            selected={onChartCovariates}
                            title={t('forecast.commodityInfluencers')}
                            values={commodityCovariates.map((covariate) => ({
                                title: covariate.title,
                                value: covariate.seriesId,
                            }))}
                        />
                    )}
                </Flex>
            </ContextMenu>
        </PopoverContent>
    );
}
