import { DispatchWithoutAction, createContext } from 'react';

export interface DashboardSettingsContextProps {
    presentationMode: boolean;
    togglePresentationMode: DispatchWithoutAction;
}

export const DashboardSettingsContext = createContext<DashboardSettingsContextProps>({
    presentationMode: false,
    togglePresentationMode: () => null,
});
