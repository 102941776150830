import { Autocomplete, TextField } from '@mui/material';
import { Flex } from '../../Core/Flex';
import { TickrToggleGroup } from './TickrToggleGroup';

interface InputWithToggleGroupProps<T> {
    id: string;
    onChange: (newValue: string[]) => void;
    onChangeToggle: (newValue: T) => void;
    options: string[];
    toggleAriaLabel: string;
    textFieldLabel: string;
    toggleOptions: { label: string; value: T }[];
    toggleValue: T;
    value: string[];
}

export function InputWithToggleGroup<T extends string>({
    id,
    onChange,
    onChangeToggle,
    options,
    textFieldLabel,
    toggleAriaLabel,
    toggleOptions,
    value,
    toggleValue,
}: InputWithToggleGroupProps<T>) {
    return (
        <Flex
            align="center"
            gap="0.4rem"
        >
            <Autocomplete
                componentsProps={{
                    paper: {
                        sx: {
                            wordBreak: 'break-word',
                        },
                    },
                }}
                disableListWrap
                id={id}
                multiple
                onChange={(_, newValue) => {
                    onChange(newValue);
                }}
                options={options}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={textFieldLabel}
                        size="small"
                    />
                )}
                size="small"
                sx={{
                    minWidth: '200px',
                }}
                value={value}
            />
            <TickrToggleGroup
                ariaLabel={toggleAriaLabel}
                onChange={onChangeToggle}
                options={toggleOptions}
                value={toggleValue}
            />
        </Flex>
    );
}
