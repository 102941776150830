import { useQuery } from '@tanstack/react-query';
import pick from 'lodash/pick';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../Providers/UserProvider/useUser';
import { AvailableFiltersContext, AvailableFiltersProviderContextProps, DatasourceFilters, UniversalFilters } from './AvailableFiltersContext';

const SAMPLE_AVAILABLE: DatasourceFilters = {
    universal: { },
    newsroom: {
        brand_entity_search_groups: {
            filterId: 'brand_entity_search_groups',
            displayName: 'Topics',
            values: [],
            type: 'checkbox',
            isLoading: true,
        },
        nsfw: {
            filterId: 'nsfw',
            displayName: 'NSFW',
            values: [
                {
                    title: 'Hide Unsafe Content',
                    value: 'remove',
                },
                {
                    title: 'Show Unsafe Content',
                    value: 'include',
                },
            ],
            type: 'radio',
            defaultValue: 'remove',
            isLoading: false,
        },
    },
    numerator: {
        cpg_banner: {
            filterId: 'cpg_banner',
            displayName: 'Retailer',
            values: [
                {
                    title: 'Albertsons',
                    value: 'albertsons',
                },
                {
                    title: "Fry's Marketplace",
                    value: 'frys_marketplace',
                },
                {
                    title: 'Jewel-Osco',
                    value: 'jewel_osco',
                },
                {
                    title: 'Kroger',
                    value: 'kroger',
                },
                {
                    title: 'Publix',
                    value: 'publix',
                },
                {
                    title: 'Target',
                    value: 'target',
                },
                {
                    title: 'Vons',
                    value: 'vons',
                },
                {
                    title: 'Walmart',
                    value: 'walmart',
                },
            ],
            type: 'radio',
            defaultValue: 'walmart',
            isLoading: false,
        },
    },
    synth: {
        cpg_banner: {
            filterId: 'cpg_banner',
            displayName: 'Retailer',
            values: [
                {
                    title: 'Albertsons',
                    value: 'albertsons',
                },
                {
                    title: 'CVS',
                    value: 'CVS',
                },
                {
                    title: 'Dollar Tree',
                    value: 'Dollar Tree',
                },
                {
                    title: 'Kroger',
                    value: 'kroger',
                },
                {
                    title: 'Publix',
                    value: 'publix',
                },
                {
                    title: 'Target',
                    value: 'target',
                },
                {
                    title: 'Ahold Delhaize',
                    value: 'Ahold Delhaize',
                },
                {
                    title: 'Walmart',
                    value: 'walmart',
                },
            ],
            type: 'radio',
            defaultValue: 'walmart',
            isLoading: false,
        },
    },
    ibotta: {
        cpg_channel: {
            filterId: 'cpg_channel', // retailer_channel
            displayName: 'Channel',
            values: [
                {
                    title: 'Big Box',
                    value: 'Big Box',
                },
                {
                    title: 'Club',
                    value: 'Club',
                },
                {
                    title: 'Convenience',
                    value: 'Convenience',
                },
                {
                    title: 'Dollar',
                    value: 'Dollar',
                },
                {
                    title: 'Grocery',
                    value: 'Grocery',
                },
                {
                    title: 'Liquor',
                    value: 'Liquor',
                },
                {
                    title: 'Online',
                    value: 'Online',
                },
                {
                    title: 'Other',
                    value: 'Other',
                },
                {
                    title: 'Pharmacy',
                    value: 'Pharmacy',
                },
            ],
            type: 'radio',
            isLoading: false,
        },
    },
};

export function AvailableFiltersProvider({ children }: PropsWithChildren) {
    const { integrations } = useUser();
    const { t } = useTranslation();

    const datasourceDisplayNames: Record<string, string> = useMemo(
        () => ({
            84.51: t('filters.cpg_dataset.84.51'),
            circana: t('filters.cpg_dataset.circana'),
            datasembly: t('filters.cpg_dataset.datasembly'),
            fred: t('filters.cpg_dataset.fred'),
            ibotta: t('filters.cpg_dataset.ibotta'),
            iri: t('filters.cpg_dataset.iri'),
            'newsroom.newsroom': t('filters.cpg_dataset.newsroom'),
            nielsen: t('filters.cpg_dataset.nielsen'),
            numerator: t('filters.cpg_dataset.numerator'),
            productAttributes: t('filters.cpg_dataset.productAttributes'),
            spins: t('filters.cpg_dataset.spins'),
            synth: t('filters.cpg_dataset.synth'),
            universal: t('filters.cpg_dataset.universal'),
            walmart: t('filters.cpg_dataset.walmartLuminate'),
        }),
        [t]
    );

    const staticFilters: UniversalFilters = useMemo(
        () => ({
            dates: {
                filterId: 'dates',
                displayName: t('filters.dates'),
                type: 'dates',
                values: [
                    {
                        value: '30d/d',
                        title: t('dates.30d/d'),
                    },
                    {
                        value: '90d/d',
                        title: t('dates.90d/d'),
                    },
                    {
                        value: '12w/w',
                        title: t('dates.12w/w'),
                    },
                    {
                        value: '26w/w',
                        title: t('dates.26w/w'),
                    },
                    {
                        value: '52w/w',
                        title: t('dates.52w/w'),
                    },
                ],
                defaultValue: '52w/w',
                isLoading: false,
            },
            cpg_owned_products: {
                filterId: 'cpg_owned_products',
                displayName: t('filters.cpg_owned_products'),
                type: 'gtin',
                isLoading: false,
            },
            cpg_competitor_products: {
                filterId: 'cpg_competitor_products',
                displayName: t('filters.cpg_competitor_products'),
                type: 'gtin',
                isLoading: false,
            },
        }),
        [t]
    );

    const { data: datasourceFiltersData = {} } = useQuery({
        queryKey: ['datasource-filters-query', integrations],
        queryFn: async () => {
            return pick(SAMPLE_AVAILABLE, ...integrations);
        },
    });

    const datasourceFilters = useMemo(() => {
        const filtersWithoutUniversal = { ...datasourceFiltersData };
        delete filtersWithoutUniversal.universal;
        return filtersWithoutUniversal;
    }, [datasourceFiltersData]);

    const universalFilters = useMemo(
        () => ({
            ...datasourceFiltersData.universal,
            ...staticFilters,
        }),
        [datasourceFiltersData, staticFilters]
    );

    const userDatasources = useMemo(
        () => Object.keys(datasourceFilters)
            .filter((key) => key !== 'universal')
            .map((value) => ({
                title: datasourceDisplayNames[value],
                value,
            }))
            .sort((a, b) => a.title.localeCompare(b.title)),
        [datasourceFilters, datasourceDisplayNames]
    );

    const allUserDatasources = useMemo(
        () => integrations
            .filter((key) => key !== 'universal')
            .map((value) => ({
                title: datasourceDisplayNames[value],
                value,
            })),
        [integrations, datasourceDisplayNames]
    );

    const memoizedContextValue = useMemo<AvailableFiltersProviderContextProps>(
        () => ({
            allUserDatasources,
            datasourceDisplayNames,
            datasourceFilters,
            universalFilters,
            userDatasources,
        }),
        [
            allUserDatasources,
            datasourceDisplayNames,
            datasourceFilters,
            userDatasources,
            universalFilters,
        ]
    );

    return (
        <AvailableFiltersContext.Provider value={memoizedContextValue}>
            {children}
        </AvailableFiltersContext.Provider>
    );
}
