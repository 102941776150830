import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, styled, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import isNumber from 'lodash/isNumber';
import { CSSProperties, PropsWithChildren, useEffect, useState } from 'react';
import { useMeasure } from 'react-use';

const Viewport = styled('div')<SlideCarouselProps>`
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: ${({ width }) => width ?? '100%'};
    height: ${({ height }) => height};
    min-width: 0;
`;

const InnerFlex = styled(
    motion.div,
    { shouldForwardProp: (prop) => prop !== 'gap' && prop !== 'alignItems' }
)<{ gap: string; alignItems: string }>`
    display: flex;
    height: 100%;
    align-items: ${({ alignItems }) => alignItems};
    gap: ${({ gap }) => gap};
`;

interface SlideCarouselProps {
    width?: string;
    height?: number | string;
    gap?: number | string;
    buttonWidth?: number | string;
    scrollPercent?: number;
    cardWidth?: number;
    alignItems?: CSSProperties['alignItems'];
    style?: CSSProperties;
}

const stringify = (prop: number | string) => {
    return isNumber(prop) ? `${prop}px` : prop;
};

export function SlideCarousel({
    width,
    height = 'unset',
    gap = '1rem',
    scrollPercent = 0.9,
    buttonWidth = 30,
    cardWidth,
    alignItems = 'center',
    style,
    children,
}: PropsWithChildren<SlideCarouselProps>) {
    const { palette } = useTheme();
    const [left, setLeft] = useState(0);
    const [viewportRef, { width: viewportWidth = 0 }] = useMeasure<HTMLDivElement>();
    const [innerRef, { width: innerWidth = 0 }] = useMeasure<HTMLDivElement>();

    let initScroll: number | undefined;
    let nextScroll: number | undefined;

    if (isNumber(cardWidth) && isNumber(gap) && isNumber(buttonWidth)) {
        const nCards = Math.floor((viewportWidth - 2 * buttonWidth) / (cardWidth + gap));
        const isOdd = nCards % 2 === 1;

        initScroll = isOdd
            ? (nCards + 0.5) * cardWidth + gap * nCards - viewportWidth / 2
            : nCards * cardWidth + gap * (nCards - 0.5) - viewportWidth / 2;

        nextScroll = nCards * (cardWidth + gap);
    }

    const isCarousel = viewportWidth < innerWidth;
    const maxScroll = -(innerWidth - viewportWidth);

    const scrollAmount =
    (left == 0 || left === maxScroll ? initScroll : nextScroll) ?? viewportWidth * scrollPercent;

    useEffect(() => {
        if (!isCarousel) setLeft(0);
        if (left < maxScroll) setLeft(0);
    }, [
        isCarousel,
        left,
        maxScroll,
    ]);

    return (
        <AnimatePresence>
            <Viewport
                height={stringify(height)}
                ref={viewportRef}
                style={style}
                width={width}
            >
                {isCarousel && left !== 0 && (
                    <IconButton
                        onClick={() => setLeft((left) => (left + scrollAmount > 0 ? 0 : left + scrollAmount))}
                        style={{
                            background: palette.gradient.slideCarouselLeft,
                            borderRadius: 0,
                            position: 'absolute',
                            height: stringify(height),
                            width: stringify(buttonWidth),
                            zIndex: 1,
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                )}
                <InnerFlex
                    alignItems={alignItems}
                    animate={{ translateX: `${left}px` }}
                    gap={stringify(gap)}
                    initial={{ translateX: 0 }}
                    ref={innerRef}
                    transition={{ duration: 0.5 }}
                >
                    {children}
                </InnerFlex>
                {isCarousel && left !== maxScroll && (
                    <IconButton
                        onClick={() => setLeft(
                            (left) => (left - scrollAmount < maxScroll ? maxScroll : left - scrollAmount)
                        )}
                        style={{
                            background: palette.gradient.slideCarouselRight,
                            borderRadius: 0,
                            height: stringify(height),
                            position: 'absolute',
                            right: 0,
                            width: stringify(buttonWidth),
                        }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                )}
            </Viewport>
        </AnimatePresence>
    );
}
