import { styled, useTheme } from '@mui/material';
import { Circle } from './Circle';
import { Flex } from './Flex';
import { Typography } from './Typography';

export type LegendIndicator = 'circle' | 'shortDash' | 'dash' | 'area' | 'line' | 'column';

interface LegendSeriesProps {
    circleBorder?: string;
    color: string;
    indicator?: LegendIndicator;
    indicatorBackground?: string;
    label: string;
    selected?: string;
}

export function LegendSeries({
    circleBorder,
    color,
    indicator,
    indicatorBackground,
    label,
    selected,
}: LegendSeriesProps) {
    const { palette } = useTheme();

    return (
        <Flex align="center" gap="0.3rem">
            {indicator && (
                <Flex
                    align="center"
                    gap="3px"
                    height="20px"
                    style={{ background: indicatorBackground }}
                    width="20px"
                >
                    {indicator === 'circle' && (
                        <Circle
                            border={circleBorder || `1px solid ${palette.background.default}`}
                            color={color}
                            size="7px"
                        />
                    )}
                    {indicator === 'shortDash' && <ShortDashIndicator color={color} />}
                    {indicator === 'dash' && <DashIndicator color={color} />}
                    {indicator === 'area' && <AreaIndicator color={color} />}
                    {indicator === 'line' && <StyledRect color={color} width="15px" />}
                    {indicator === 'column' && <ColumnIndicator color={color} />}
                </Flex>
            )}
            <Typography
                color={selected === label?.toLowerCase() ? palette.common.black : palette.text.primary}
                nowrap
                variant="c-11"
            >
                {label}
            </Typography>
        </Flex>
    );
}

interface IndicatorProps {
    color: string;
}

function ShortDashIndicator({ color }: IndicatorProps) {
    return (
        <Flex gap="1px">
            <StyledRect color={color} width="6px" />
            <StyledRect color={color} width="6px" />
            <StyledRect color={color} width="6px" />
        </Flex>
    );
}

function DashIndicator({ color }: IndicatorProps) {
    return (
        <Flex gap="5px">
            <StyledRect color={color} width="8px" />
            <StyledRect color={color} width="8px" />
        </Flex>
    );
}

function AreaIndicator({ color }: IndicatorProps) {
    return (
        <Flex direction="column">
            <StyledRect
                borderRadius="0"
                color={color}
                height="3px"
                width="15px"
            />
            <StyledRect
                borderRadius="0"
                color={color.replace(')', '-50)')}
                height="10px"
                width="15px"
            />
        </Flex>
    );
}

function ColumnIndicator({ color }: IndicatorProps) {
    return (
        <Flex align="flex-end" gap="2px">
            <Flex align="baseline" direction="column">
                <StyledRect
                    borderRadius="0"
                    color={color}
                    height="3px"
                    width="5px"
                />
                <StyledRect
                    borderRadius="0"
                    color={color.replace(')', '-50)')}
                    height="6px"
                    width="5px"
                />
            </Flex>
            <Flex align="baseline" direction="column">
                <StyledRect
                    borderRadius="0"
                    color={color}
                    height="3px"
                    width="5px"
                />
                <StyledRect
                    borderRadius="0"
                    color={color.replace(')', '-50)')}
                    height="10px"
                    width="5px"
                />
            </Flex>
            <Flex align="baseline" direction="column">
                <StyledRect
                    borderRadius="0"
                    color={color}
                    height="3px"
                    width="5px"
                />
                <StyledRect
                    borderRadius="0"
                    color={color.replace(')', '-50)')}
                    height="8px"
                    width="5px"
                />
            </Flex>
        </Flex>
    );
}

const StyledRect = styled('div')<{
    color: string;
    width: string;
    height?: string;
    borderRadius?: string;
}>`
    background-color: ${({ color }) => color};
    width: ${({ width }) => width};
    height: ${({ height }) => height ?? '2px'};
    border-radius: ${({ borderRadius }) => borderRadius ?? '3px'};
`;
