import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface StagedFiltersContextProps {
    stagedFilters: AppFilters;
    invalidFilters: (keyof AppFilters)[];
    setInvalidFilters: Dispatch<SetStateAction<(keyof AppFilters)[]>>;
}

export const StagedFiltersContext = createContext<StagedFiltersContextProps>({
    stagedFilters: {},
    invalidFilters: [],
    setInvalidFilters: () => null,
});
