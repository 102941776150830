import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Autocomplete, Button, TextField, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { Sparkline } from '../../Graphs/Sparkline';
import { HeadCell, TickrHeadCell } from '../../Table/TickrHeadCell';
import { TickrTable } from '../../Table/TickrTable';
import { TickrTableBody } from '../../Table/TickrTableBody';
import { TickrTableCell } from '../../Table/TickrTableCell';
import { TickrTableContainer } from '../../Table/TickrTableContainer';
import { TickrTableHead } from '../../Table/TickrTableHead';
import { TickrTableRow } from '../../Table/TickrTableRow';
import { TickrTableSortLabel } from '../../Table/TickrTableSortLabel';
import { useTableSort } from '../../Table/useTableSort';
import { ProductCell } from '../Cells/ProductCell';
import { InputWithToggleGroup } from './InputWithToggleGroup';
import { OosEventsBarGraph } from './OosEventsBarGraph';
import { StoreLevelOosGraph } from './StoreLevelOosGraph';
import { TickrToggleGroup } from './TickrToggleGroup';
import { OosTableData, fakeData } from './sampleData';

type CategoryFilter = 'upc' | 'brand' | 'category';
type GeoFilter = 'state' | 'metroArea' | 'store';
type RetailerFilter = 'retailer' | 'banner';
type OwnedFilter = 'owned' | 'competitors' | 'both';

const categoryOptions = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6', 'Category 7', 'Category 8', 'Category 9', 'Category 10', 'Category 11'];
const brandOptions = ['Brand 1', 'Brand 2', 'Brand 3'];
const upcOptions = ['UPC 1', 'UPC 2', 'UPC 3'];

const stateOptions = ['State 1', 'State 2', 'State 3'];
const metroAreaOptions = ['Metro Area 1', 'Metro Area 2', 'Metro Area 3'];
const storeOptions = ['Store 1', 'Store 2', 'Store 3'];

const retailerOptions = ['Retailer 1', 'Retailer 2', 'Retailer 3'];
const bannerOptions = ['Banner 1', 'Banner 2', 'Banner 3'];

const predictionOptions = [
    {
        label: '1 Week',
        value: '1w',
    }, {
        label: '2 Weeks',
        value: '2w',
    }, {
        label: '3 Weeks',
        value: '3w',
    }, {
        label: '4 Weeks',
        value: '4w',
    },
];

const historicalOptions = [
    {
        label: '1 Week',
        value: '1w',
    }, {
        label: '2 Weeks',
        value: '2w',
    }, {
        label: '3 Weeks',
        value: '3w',
    }, {
        label: '4 Weeks',
        value: '4w',
    },
];

export function OutOfStockPredictions() {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const [categoryFilter, setCategoryFilter] = useState<CategoryFilter>('category');
    const [categoryFilterValue, setCategoryFilterValue] = useState<string[]>([]);
    const [brandFilterValue, setBrandFilterValue] = useState<string[]>([]);
    const [upcFilterValue, setUpcFilterValue] = useState<string[]>([]);

    const [geoFilter, setGeoFilter] = useState<GeoFilter>('state');
    const [stateFilterValue, setStateFilterValue] = useState<string[]>([]);
    const [metroAreaFilterValue, setMetroAreaFilterValue] = useState<string[]>([]);
    const [storeFilterValue, setStoreFilterValue] = useState<string[]>([]);

    const [retailerFilter, setRetailerFilter] = useState<RetailerFilter>('retailer');
    const [retailerFilterValue, setRetailerFilterValue] = useState<string[]>([]);
    const [bannerFilterValue, setBannerFilterValue] = useState<string[]>([]);

    const [predictionFilter, setPredictionFilter] = useState<{ label: string; value: string }>({
        label: '1 Week',
        value: '1w',
    });

    const [historicalFilter, setHistoricalFilter] = useState<{ label: string; value: string }>({
        label: '1 Week',
        value: '1w',
    });

    const [ownedFilter, setOwnedFilter] = useState<OwnedFilter>('both');

    const headCells: HeadCell<OosTableData>[] = [
        {
            label: t('util.upc'),
            value: 'product',
        },
        {
            label: t('util.category'),
            value: 'category',
        },
        {
            label: t('util.brand'),
            value: 'brand',
        },
        {
            label: t('util.state'),
            value: 'state',
        },
        {
            label: t('util.metroArea'),
            value: 'metroArea',
        },
        {
            label: t('util.store'),
            value: 'store',
        },
        {
            label: t('util.retailer'),
            value: 'retailer',
        },
        {
            label: t('util.banner'),
            value: 'banner',
        },
        {
            label: t('outOfStock.predictedEvents', { date: 'March 30th' }),
            value: 'oosEvents',
        },
        {
            label: t('outOfStock.oosTrend'),
            value: 'oosTrend',
        },
        {
            label: t('outOfStock.historicalEvents.store', { weeks: historicalFilter.label }),
            value: 'oosStoreLevelHistorical',
        },
        {
            label: t('outOfStock.predictedEvents.store'),
            value: 'oosStoreLevelForecast',
        },
    ];

    const [tableColumns, setTableColumns] = useState<HeadCell<OosTableData>[]>([
        {
            label: t('util.upc'),
            value: 'product',
        },
        {
            label: t('util.brand'),
            value: 'brand',
        },
        {
            label: t('util.state'),
            value: 'state',
        },
        {
            label: t('util.banner'),
            value: 'banner',
        },
        {
            label: t('outOfStock.predictedEvents', { date: 'March 30th' }),
            value: 'oosEvents',
        },
        {
            label: t('outOfStock.oosTrend'),
            value: 'oosTrend',
        },
        // {
        //     label: t('outOfStock.historicalEvents.store', { weeks: historicalFilter.label }),
        //     value: 'oosStoreLevelHistorical',
        // },
        // {
        //     label: t('outOfStock.predictedEvents.store'),
        //     value: 'oosStoreLevelForecast',
        // },
    ]);

    const { order, setOrder, orderBy, setOrderBy, sortedRows } = useTableSort({
        data: fakeData,
        initOrder: 'desc',
        initOrderBy: 'oosEvents',
    });

    const maxOosEvents = Math.max(...fakeData.map((row) => row.oosEvents)); // Memoize eventually

    return (
        <Flex
            direction="column"
            gap="2rem"
            padding="1px"
            width="100%"
        >
            <Flex
                direction="column"
                gap="0.5rem"
                width="100%"
            >
                <Flex
                    align="center"
                    justify="space-between"
                    width="100%"
                >
                    <Typography variant="p-16-r">
                        {t('util.filters')}
                    </Typography>
                    <Flex align="center" gap="1rem">
                        <Button
                            color="secondaryText"
                            onClick={() => {
                            }}
                            startIcon={<SettingsBackupRestoreIcon />}
                            style={{ textTransform: 'none' }}
                        >
                            {t('util.back')}
                        </Button>
                        <Button
                            color="secondaryText"
                            onClick={() => {
                            }}
                            startIcon={<BookmarkIcon />}
                            style={{ textTransform: 'none' }}
                        >
                            {t('util.saveView')}
                        </Button>
                        <Button
                            color="secondaryText"
                            onClick={() => {
                            }}
                            startIcon={<BookmarksIcon />}
                            style={{ textTransform: 'none' }}
                        >
                            {t('util.openSavedView')}
                        </Button>
                    </Flex>
                </Flex>
                <Flex
                    align="center"
                    style={{
                        rowGap: '1.5rem',
                        columnGap: '2rem',
                    }}
                    wrap="wrap"
                >
                    <InputWithToggleGroup
                        id={`${categoryFilter}-autocomplete`}
                        onChange={categoryFilter === 'category' ? setCategoryFilterValue : categoryFilter === 'brand' ? setBrandFilterValue : setUpcFilterValue}
                        onChangeToggle={setCategoryFilter}
                        options={categoryFilter === 'category' ? categoryOptions : categoryFilter === 'brand' ? brandOptions : upcOptions}
                        textFieldLabel={categoryFilter === 'category' ? t('util.category') : categoryFilter === 'brand' ? t('util.brand') : t('util.upc')}
                        toggleAriaLabel={t('util.categoryFilter.ariaLabel')}
                        toggleOptions={[
                            {
                                label: t('util.category'),
                                value: 'category',
                            },
                            {
                                label: t('util.brand'),
                                value: 'brand',
                            },
                            {
                                label: t('util.upc'),
                                value: 'upc',
                            },
                        ]}
                        toggleValue={categoryFilter}
                        value={categoryFilter === 'category' ? categoryFilterValue : categoryFilter === 'brand' ? brandFilterValue : upcFilterValue}
                    />

                    <InputWithToggleGroup
                        id={`${geoFilter}-autocomplete`}
                        onChange={geoFilter === 'state' ? setStateFilterValue : geoFilter === 'metroArea' ? setMetroAreaFilterValue : setStoreFilterValue}
                        onChangeToggle={setGeoFilter}
                        options={geoFilter === 'state' ? stateOptions : geoFilter === 'metroArea' ? metroAreaOptions : storeOptions}
                        textFieldLabel={geoFilter === 'state' ? t('util.state') : geoFilter === 'metroArea' ? t('util.metroArea') : t('util.store')}
                        toggleAriaLabel={t('util.geoFilter.ariaLabel')}
                        toggleOptions={[
                            {
                                label: t('util.state'),
                                value: 'state',
                            },
                            {
                                label: t('util.metroArea'),
                                value: 'metroArea',
                            },
                            {
                                label: t('util.store'),
                                value: 'store',
                            },
                        ]}
                        toggleValue={geoFilter}
                        value={geoFilter === 'state' ? stateFilterValue : geoFilter === 'metroArea' ? metroAreaFilterValue : storeFilterValue}
                    />

                    <InputWithToggleGroup
                        id={`${retailerFilter}-autocomplete`}
                        onChange={retailerFilter === 'retailer' ? setRetailerFilterValue : setBannerFilterValue}
                        onChangeToggle={setRetailerFilter}
                        options={retailerFilter === 'retailer' ? retailerOptions : bannerOptions}
                        textFieldLabel={retailerFilter === 'retailer' ? t('util.retailer') : t('util.banner')}
                        toggleAriaLabel={t('util.retailerFilter.ariaLabel')}
                        toggleOptions={[
                            {
                                label: t('util.retailer'),
                                value: 'retailer',
                            },
                            {
                                label: t('util.banner'),
                                value: 'banner',
                            },
                        ]}
                        toggleValue={retailerFilter}
                        value={retailerFilter === 'retailer' ? retailerFilterValue : bannerFilterValue}
                    />

                    <Autocomplete
                        clearIcon={false}
                        componentsProps={{
                            paper: {
                                sx: {
                                    wordBreak: 'break-word',
                                },
                            },
                        }}
                        defaultValue={predictionOptions[0]}
                        getOptionLabel={(option) => option.label}
                        id="prediction-time-frame-autocomplete"
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, newValue) => {
                            if (newValue !== null) {
                                setPredictionFilter(newValue);
                            }
                        }}
                        options={predictionOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('util.predictionTimeFrame')}
                                size="small"
                            />
                        )}
                        size="small"
                        sx={{
                            minWidth: '200px',
                        }}
                        value={predictionFilter}
                    />

                    <Autocomplete
                        clearIcon={false}
                        componentsProps={{
                            paper: {
                                sx: {
                                    wordBreak: 'break-word',
                                },
                            },
                        }}
                        getOptionLabel={(option) => option.label}
                        id="historical-time-frame-autocomplete"
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, newValue) => {
                            if (newValue !== null) {
                                setHistoricalFilter(newValue);
                            }
                        }}
                        options={historicalOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('util.historicalTimeFrame')}
                                size="small"
                            />
                        )}
                        size="small"
                        sx={{
                            minWidth: '200px',
                        }}
                        value={historicalFilter}
                    />

                    <TickrToggleGroup
                        ariaLabel={t('util.ownedFilter.ariaLabel')}
                        onChange={setOwnedFilter}
                        options={[
                            {
                                label: t('util.owned'),
                                value: 'owned',
                            },
                            {
                                label: t('util.competitors'),
                                value: 'competitors',
                            },
                            {
                                label: t('util.both'),
                                value: 'both',
                            },
                        ]}
                        value={ownedFilter}
                    />

                    <Button startIcon={<AddCircleOutlineIcon />}>
                        {t('util.showMore')}
                    </Button>
                </Flex>
            </Flex>
            <Flex
                direction="column"
                gap="0.5rem"
                width="100%"
            >
                {/* <Typography variant="p-16-r">
                    {t('util.tableColumns')}
                </Typography> */}
                <Autocomplete
                    componentsProps={{
                        paper: {
                            sx: {
                                wordBreak: 'break-word',
                            },
                        },
                    }}
                    disableListWrap
                    getOptionLabel={(option) => option.label}
                    id="table-columns-autocomplete"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    multiple
                    onChange={(event, newValue) => {
                        setTableColumns(newValue);
                    }}
                    options={headCells}
                    // options={headCells.filter(({ value }) => (
                    //     value !== 'oosEvents' &&
                    //     value !== 'oosTrend' &&
                    //     value !== 'oosStoreLevelHistorical' &&
                    //     value !== 'oosStoreLevelForecast'
                    // ))}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('util.tableColumns')}
                            size="small"
                        />
                    )}
                    size="small"
                    sx={{
                        minWidth: '200px',
                    }}
                    value={tableColumns}
                />
            </Flex>
            <TickrTableContainer>
                <TickrTable>
                    <TickrTableHead>
                        <TickrTableRow style={{ background: palette.background.default }}>
                            {headCells.filter(({ value: id }) => {
                                if (tableColumns.find(({ value }) => value === id)) {
                                    return true;
                                }

                                // if (tableColumns.includes())

                                return false;
                            }).map(({ value: id, label, align }) => (
                                <TickrHeadCell
                                    align={align}
                                    key={id}
                                    order={order}
                                    orderBy={orderBy}
                                    value={id}
                                >
                                    <TickrTableSortLabel
                                        id={id}
                                        label={label}
                                        order={order}
                                        orderBy={orderBy}
                                        setOrder={setOrder}
                                        setOrderBy={setOrderBy}
                                    />
                                </TickrHeadCell>
                            ))}
                        </TickrTableRow>
                    </TickrTableHead>
                    <TickrTableBody>
                        {sortedRows.map((row) => (
                            <TickrTableRow key={JSON.stringify(row)}>
                                {tableColumns.some(({ value }) => value === 'product') && (
                                    <TickrTableCell
                                        // component="th"
                                        id={row.product.upc}
                                        // scope="row"
                                    >
                                        <ProductCell
                                            id={row.product.upc}
                                            name={row.product.name}
                                        />
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'category') && (
                                    <TickrTableCell>
                                        <Typography nowrap variant="p-14-b">
                                            {row.category}
                                        </Typography>
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'brand') && (
                                    <TickrTableCell>
                                        <Typography nowrap variant="p-14-b">
                                            {row.brand}
                                        </Typography>
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'state') && (
                                    <TickrTableCell>
                                        <Typography nowrap variant="p-14-b">
                                            {row.state}
                                        </Typography>
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'metroArea') && (
                                    <TickrTableCell>
                                        <Typography nowrap variant="p-14-b">
                                            {row.metroArea}
                                        </Typography>
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'store') && (
                                    <TickrTableCell>
                                        <Typography nowrap variant="p-14-b">
                                            {row.store}
                                        </Typography>
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'retailer') && (
                                    <TickrTableCell>
                                        <Typography nowrap variant="p-14-b">
                                            {row.retailer}
                                        </Typography>
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'banner') && (
                                    <TickrTableCell>
                                        <Typography nowrap variant="p-14-b">
                                            {row.banner}
                                        </Typography>
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'oosEvents') && (
                                    <TickrTableCell>
                                        <OosEventsBarGraph max={maxOosEvents} value={row.oosEvents} />
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'oosTrend') && (
                                    <TickrTableCell
                                        sx={{
                                            minWidth: '150px',
                                        }}
                                    >
                                        <Sparkline
                                            containerHeight="40px"
                                            sparklines={[
                                                {
                                                    id: 'out-of-stock-trend',
                                                    historicData: row.oosTrend.historical,
                                                    forecastData: [
                                                        row.oosTrend.historical[row.oosTrend.historical.length - 1],
                                                        ...row.oosTrend.forecast,
                                                    ],
                                                    color: palette.text.primary,
                                                    strokeWidth: 2,
                                                },
                                            ]}
                                        />
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'oosStoreLevelHistorical') && (
                                    <TickrTableCell>
                                        {row.oosStoreLevelHistorical
                                            ? <StoreLevelOosGraph outOfStockWeeks={row.oosStoreLevelHistorical} />
                                            : <Typography>-</Typography>}
                                    </TickrTableCell>
                                )}
                                {tableColumns.some(({ value }) => value === 'oosStoreLevelForecast') && (
                                    <TickrTableCell>
                                        {row.oosStoreLevelForecast
                                            ? <StoreLevelOosGraph outOfStockWeeks={row.oosStoreLevelForecast} />
                                            : <Typography>-</Typography>}
                                    </TickrTableCell>
                                )}
                            </TickrTableRow>
                        ))}
                    </TickrTableBody>
                </TickrTable>
            </TickrTableContainer>
        </Flex>
    );
}

function includesColumn(columns: { label: string; value: string }[], column: string) {
    return columns.some((col) => col.value === column);
}
