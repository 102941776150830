import { styled, useTheme } from '@mui/material';
import isNil from 'lodash/isNil';
import { CSSProperties, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberStyle } from '../helpers/numberStyleHelper';
import { ChangeIndicator } from './ChangeIndicator';
import { Flex } from './Flex';
import { LegendIndicator, LegendSeries } from './LegendSeries';
import { NumberFormatter } from './NumberFormatter';
import { Typography } from './Typography';

export type ValueType = 'average' | 'latest' | 'total' | 'current' | 'weeklyAverage';

export interface ValueBoxProps {
    allNeutral?: boolean;
    background?: boolean;
    border?: boolean;
    circleBorder?: string;
    color?: string;
    indicator?: LegendIndicator;
    indicatorBackground?: string;
    label?: string;
    onClick?: (val: string) => void;
    primaryClassName?: string;
    secondary?: boolean;
    selected?: string;
    smallSuffix?: string;
    style?: CSSProperties;
    subtitle?: string;
    value?: number;
    valueStyle?: NumberStyle;
    valueSmall?: number;
    valueSmallStyle?: NumberStyle;
    valueType?: ValueType;
}

export const ValueBox = forwardRef<HTMLDivElement, ValueBoxProps>(
    (
        {
            allNeutral = false,
            background = false,
            border,
            circleBorder,
            color: colorProp,
            indicator = 'line',
            indicatorBackground = 'inherit',
            label,
            onClick,
            primaryClassName,
            secondary = false,
            selected,
            smallSuffix,
            style,
            subtitle,
            value,
            valueStyle,
            valueSmall,
            valueSmallStyle,
            valueType = 'total',
        },
        ref
    ) => {
        const { t } = useTranslation();
        const { palette } = useTheme();

        const color = colorProp ?? palette.text.primary;

        const valueTypeDict: Record<ValueType, string> = {
            average: t('valueType.average'),
            latest: t('valueType.latest'),
            total: t('valueType.total'),
            current: t('valueType.current'),
            weeklyAverage: t('valueType.weeklyAverage'),
        };

        return (
            <FlexContainer
                background={background}
                border={border}
                className={selected === label?.toLowerCase() ? 'selected' : ''}
                color={color}
                data-testid="value-box-container"
                direction="column"
                gap="0.4rem"
                onClick={
                    label && onClick
                        ? () => {
                            const selectedValue = label.toLowerCase();
                            onClick(selectedValue);
                        }
                        : undefined
                }
                ref={ref}
                secondary={secondary}
                style={style}
            >
                {label && (
                    <LegendSeries
                        circleBorder={circleBorder}
                        color={color}
                        indicator={indicator}
                        indicatorBackground={indicatorBackground}
                        label={label}
                        selected={selected}
                    />
                )}
                {subtitle && (
                    <Typography
                        secondary
                        style={{ paddingLeft: 'calc(20px + 0.625rem)' }}
                        variant="c-10"
                    >
                        {subtitle}
                    </Typography>
                )}
                {value !== undefined && (
                    <Flex
                        align="center"
                        gap="1rem"
                        style={{ paddingLeft: secondary ? (indicator === 'circle' ? '16px' : '26px') : 0 }}
                    >
                        <Flex direction="column" gap="2px">
                            {valueType && (
                                <Typography
                                    color={color}
                                    nowrap
                                    variant="h-12-b"
                                >
                                    {valueTypeDict[valueType]}
                                </Typography>
                            )}
                            <Flex align="center" gap="1rem">
                                <NumberFormatter
                                    as="h2"
                                    className={primaryClassName}
                                    numberStyle={valueStyle}
                                    val={value}
                                    variant={!secondary ? 'n-24-b' : 'n-16-b'}
                                />
                                {!isNil(valueSmall) && valueSmallStyle !== undefined && (
                                    <ChangeIndicator
                                        allNeutral={allNeutral}
                                        numberStyle={valueSmallStyle}
                                        suffix={smallSuffix}
                                        val={valueSmall}
                                    />
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                )}
            </FlexContainer>
        );
    }
);

const FlexContainer = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'color' && prop !== 'secondary' && prop !== 'background' && prop !== 'border' }
)<{
    color: string;
    secondary: boolean;
    background: boolean;
    border?: boolean;
}>(({ theme, background, border, color, secondary, onClick }) => `
    background-color: ${background ? theme.palette.background.secondary : 'none'};
    border: ${border ? `${color} solid 1px` : 'none'};
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${secondary ? '0.2rem' : '0'};

    .selected {
        background-color: ${background ? theme.palette.text.primary : 'none'};
    }

    :hover {
        cursor: ${onClick ? 'pointer' : 'auto'};
    }
`);
