
export type SortOrder = 'asc' | 'desc';

export function sortTableData<DataItem>(
    data: DataItem[],
    field: keyof DataItem | null,
    sortOrder: SortOrder
) {
    if (!field) return data;

    return data.slice().sort((a, b) => {
        if (sortOrder === 'asc') return a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0;
        if (sortOrder === 'desc') return a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0;
        return 0;
    });
}
