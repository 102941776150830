import { PageAttributes } from '@tickr/sequelize-models';
import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import isEqual from 'lodash/isEqual';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';

interface UseSavePageFilterProps {
    page?: PageAttributes;
    onSuccess?(): void;
}

export function useSavePageFilter({ page, onSuccess }: UseSavePageFilterProps) {
    const { updatePage } = useCollectionMutations();

    return async (stagedFilters: AppFilters) => {
        if (!page) return;
        if (isEqual(stagedFilters, page.filterDef.activeFilters)) return;

        await updatePage({
            page: {
                ...page,
                filterDef: {
                    activeFilters: stagedFilters,
                },
            },
        });

        onSuccess?.();
    };
}
