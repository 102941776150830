import { StreamingIcon } from '../ChatCpg/StreamingIcon';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';

interface LogoLoadingMessageProps {
    message?: string;
}

export function LogoLoadingMessage({ message }: LogoLoadingMessageProps) {
    return (
        <Flex align="center" gap="0.6rem">
            <StreamingIcon
                isStreaming
                size="24px"
            />
            {message && <Typography secondary variant="p-16-r">{message}</Typography>}
        </Flex>
    );
}
