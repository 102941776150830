import { AppFilters, CustomDate, RangeFilter } from '@tickr/sequelize-models/src/types/filterTypes';
import { createContext } from 'react';
import { FilterGroupItem } from '../Components/FilterGroup';

export type FilterType = 'checkbox' | 'radio' | 'range' | 'search' | 'dates' | 'gtin';

interface SharedFilterDefinition {
    filterId: keyof AppFilters;
    displayName: string;
    isLoading: boolean;
}

type SpecificFilterDefinition =
  | {
      type: 'checkbox' | 'gtin';
      values?: FilterGroupItem[];
      defaultValue?: string;
  }
  | {
      type: 'range';
      values?: RangeFilter;
      defaultValue?: RangeFilter;
  }
  | {
      type: 'radio';
      values?: FilterGroupItem[];
      defaultValue?: string;
  }
  | {
      type: 'search';
      values?: never;
      defaultValue?: never;
  }
  | {
      type: 'dates';
      values?: FilterGroupItem[];
      defaultValue?: string | CustomDate;
  };

export type FilterDefinition = SharedFilterDefinition & SpecificFilterDefinition;

export type UniversalFilters = Record<string, FilterDefinition>;
export type ProductAttributes = Record<string, FilterDefinition>;
export type DatasourceFilters = Partial<
    Record<string, Partial<Record<keyof AppFilters, FilterDefinition>>>
>;

export interface AvailableFiltersProviderContextProps {
    allUserDatasources: FilterGroupItem[];
    datasourceDisplayNames: Record<string, string>;
    datasourceFilters: DatasourceFilters;
    userDatasources: FilterGroupItem[];
    universalFilters: UniversalFilters;
    // productAttributes: ProductAttributes;
}

export const AvailableFiltersContext = createContext<AvailableFiltersProviderContextProps>({
    allUserDatasources: [],
    datasourceDisplayNames: {},
    datasourceFilters: {},
    universalFilters: {},
    userDatasources: [],
    // productAttributes: {},
});
