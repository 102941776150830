import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { t } from 'i18next';
import { DotLoader } from '../ChatCpg/DotLoader';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { SolutionPanelSection } from '../Solutions/SolutionPanelSection';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { useForecastInProgress } from '../hooks/useForecastInProgress';
import { PreviewDataDialog } from './PreviewDataDialog';

interface ConnectYourDataSolutionPanelSectionProps {
    active?: boolean;
    forecastColumn?: string;
    processing?: boolean;
}

export function ConnectYourDataSolutionPanelSection({
    active, forecastColumn, processing,
}: ConnectYourDataSolutionPanelSectionProps) {
    const { documentId } = useForecastInProgress();

    const {
        errorMsg,
        fileName,
        forecast,
        isDocumentComplete,
        isSummarizing,
        isUploaded,
        isUploading,
        isValidated,
        isValidating,
        summary,
    } = useForecastInProgress();

    const textStream = useChatCpgData<StreamingText>(['document-summary', documentId ?? '']);

    if (!forecast) {
        return null;
    }

    const isProcessing = processing || !isDocumentComplete;

    return (
        <SolutionPanelSection
            active={active ?? isProcessing}
            description={summary ?? textStream?.text}
            done={!!summary}
            isStreaming={textStream?.isStreaming}
            title={t('forecast.1connectYourData')}
        >
            {isProcessing && !textStream?.text && !summary && (
                <Flex
                    direction="column"
                    gap="1rem"
                    padding="0 0 0 2rem"
                    width="100%"
                >
                    {isUploading && (
                        <Flex align="center" gap="1rem">
                            <Typography secondary variant="p-14-r">{t('forecast.uploadingFile', { file: fileName })}</Typography>
                            <DotLoader />
                        </Flex>
                    )}
                    {isUploaded && (
                        <Flex align="center" gap="1rem">
                            <Typography secondary variant="p-14-r">{t('forecast.uploaded')}</Typography>
                            <DoneIcon color="success" />
                        </Flex>
                    )}
                    {isValidating && (
                        <Flex align="center" gap="1rem">
                            <Typography secondary variant="p-14-r">{t('forecast.validating', { file: fileName })}</Typography>
                            {errorMsg ? <ClearIcon color="error" /> : <DotLoader />}
                        </Flex>
                    )}
                    {isValidated && (
                        <Flex align="center" gap="1rem">
                            <Typography secondary variant="p-14-r">{t('forecast.validated')}</Typography>
                            <DoneIcon color="success" />
                        </Flex>
                    )}
                    {isSummarizing && (
                        <Flex align="center" gap="1rem">
                            <Typography secondary variant="p-14-r">{t('forecast.summarizing', { file: fileName })}</Typography>
                            <DotLoader />
                        </Flex>
                    )}
                </Flex>
            )}
            {!isProcessing && isDocumentComplete && <PreviewDataDialog forecastCol={forecastColumn} />}
        </SolutionPanelSection>
    );
}
