import { styled } from '@mui/material/styles';
import { DataGridPro, DataGridProProps, gridClasses } from '@mui/x-data-grid-pro';
import { useLocalization } from '../Providers/LocalizationProvider/useLocalization';
import { Flex } from './Flex';
import { Typography } from './Typography';

const StyledDataGridPro = styled(DataGridPro)<DataGridProProps>(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.shape.borderRadius,
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: theme.palette.background.default,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within':
    {
        outline: 'none',
    },
    '&>.MuiDataGrid-main': {
        '&>.MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
        },

        '& div div div div >.MuiDataGrid-cell': {
            borderBottom: 'none',
        },
    },
    border: 'none',
}));

export function DataGridTree(props: DataGridProProps) {
    const t = useLocalization();

    return (
        <StyledDataGridPro
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
            getTreeDataPath={({ path }) => path}
            slots={{
                noRowsOverlay: () => (
                    <Flex
                        align="center"
                        height="100%"
                        justify="center"
                    >
                        <Typography variant="p-14-b">{t('salesPerformance.noDataAvailable')}</Typography>
                    </Flex>
                ),
            }}
            treeData
            {...props}
        />
    );
}
