import { styled, useTheme } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';

interface CircleProps {
    className?: string;
    onClick?: () => void;
    border?: string;
    color?: string;
    size?: string;
    style?: CSSProperties;
}

const StyledDiv = styled('div')<{
    color: string;
    size?: string;
    border?: string;
}>`
    position: relative;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 0;
    background: ${({ color }) => color};
    min-width: ${({ size }) => size || '30px'};
    min-height: ${({ size }) => size || '30px'};
    border: ${({ border }) => border || 'none'};
`;

export function Circle({
    className,
    color,
    size,
    border,
    onClick,
    style,
    children,
}: PropsWithChildren<CircleProps>) {
    const { palette } = useTheme();

    return (
        <StyledDiv
            border={border}
            className={className}
            color={color ?? palette.text.primary}
            onClick={onClick}
            size={size}
            style={style}
        >
            {children}
        </StyledDiv>
    );
}
