import { useTheme } from '@mui/material';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function XlsxIcon(props: IconProps) {
    const { palette } = useTheme();

    return (
        <IconWrapper {...props} color="">
            <svg
                fill="none"
                height="59"
                viewBox="0 0 50 59"
                width="50"
            >
                <path
                    clipRule="evenodd"
                    d="M7.15381 24.9997V3.23047H31.2968V13.9338C31.2968 15.5906 32.6399 16.9338 34.2968 16.9338H45V54.6481H7.15381V41.9997H32C33.6569 41.9997 35 40.6565 35 38.9997V27.9997C35 26.3428 33.6569 24.9997 32 24.9997H7.15381ZM4.15381 24.9997V3.23047C4.15381 1.57361 5.49696 0.230469 7.15381 0.230469H31.7278C32.7886 0.230469 33.806 0.651896 34.5562 1.40204L34.6978 1.54368L34.711 1.55675L46.6738 13.5196L46.6868 13.5326L46.8284 13.6743C47.5786 14.4244 48 15.4419 48 16.5027V54.6481C48 56.305 46.6569 57.6481 45 57.6481H7.15381C5.49696 57.6481 4.15381 56.305 4.15381 54.6481V41.9997H3C1.34315 41.9997 0 40.6565 0 38.9997V27.9997C0 26.3428 1.34315 24.9997 3 24.9997H4.15381ZM34.2968 13.9338V5.38529L42.8453 13.9338H34.2968Z"
                    fill={palette.sulu.main}
                    fillRule="evenodd"
                    opacity="0.7"
                />
                <path
                    d="M10.6467 37.9997L8.0667 33.6797L10.5027 29.5997H8.6307L7.1307 32.1197L5.6307 29.5997H3.7587L6.1947 33.6917L3.6267 37.9997H5.4987L7.1307 35.2637L8.7627 37.9997H10.6467ZM13.2309 36.4157V29.5997H11.5749V37.9997H16.4349V36.4157H13.2309ZM20.0914 38.1677C21.8794 38.1677 23.1394 37.2077 23.1394 35.6357C23.1394 33.9197 21.7234 33.4757 20.3914 33.0557C19.1194 32.6717 18.7954 32.3957 18.7954 31.8797C18.7954 31.3997 19.2034 31.0397 19.8994 31.0397C20.7514 31.0397 21.2074 31.4837 21.5074 32.1197L22.8994 31.3157C22.3354 30.1397 21.2794 29.4317 19.8994 29.4317C18.4594 29.4317 17.1394 30.3437 17.1394 31.9277C17.1394 33.5237 18.3874 34.0637 19.6714 34.4357C20.9194 34.7957 21.4834 35.0357 21.4834 35.6597C21.4834 36.1277 21.1354 36.5477 20.1394 36.5477C19.0954 36.5477 18.5074 36.0437 18.2074 35.2637L16.7914 36.0917C17.2474 37.3397 18.3754 38.1677 20.0914 38.1677ZM30.3811 37.9997L27.8011 33.6797L30.2371 29.5997H28.3651L26.8651 32.1197L25.3651 29.5997H23.4931L25.9291 33.6917L23.3611 37.9997H25.2331L26.8651 35.2637L28.4971 37.9997H30.3811Z"
                    fill={palette.background.default}
                />
            </svg>
        </IconWrapper>
    );
}
