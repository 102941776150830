import { AnimatePresence, motion } from 'framer-motion';
import { useAlerts } from '../Providers/ToasterProvider/useAlerts';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { Alert } from './Alert';
import { Expire } from './Expire';

export function ToasterDisplay() {
    const alerts = useAlerts();
    const { setAlerts } = useToaster();

    return (
        <AnimatePresence>
            {alerts.map((alert, i) => {
                const onDismiss = alert.onDismiss || alert.showX
                    ? () => {
                        setAlerts((items) => items.filter((item) => item.id !== alert.id));
                        alert.onDismiss?.();
                    }
                    : undefined;

                return (
                    <motion.div
                        animate={{
                            translateY: i * -75,
                            opacity: 1,
                        }}
                        exit={{
                            translateY: 150,
                            opacity: 0,
                        }}
                        initial={{
                            translateY: 150,
                            opacity: 0,
                        }}
                        key={alert.id}
                        style={{
                            position: 'fixed',
                            width: '100%',
                            padding: '2rem 30vw',
                            bottom: 0,
                            zIndex: 99999,
                        }}
                        transition={{
                            type: 'spring',
                            mass: 0.5,
                            damping: 11,
                            bounce: 0.09,
                        }}
                    >
                        {onDismiss && !alert.timeout
                            ? (
                                <Alert
                                    onDismiss={onDismiss}
                                    severity={alert?.severity}
                                    showX={alert.showX}
                                    text={alert?.text}
                                    title={alert.title}
                                />
                            )
                            : (
                                <Expire
                                    onExpire={() => setAlerts((items) => items.filter((item) => item.id !== alert.id))}
                                    until={alert.timeout ?? 3000}
                                >
                                    <Alert
                                        onDismiss={onDismiss}
                                        severity={alert?.severity}
                                        showX={alert.showX}
                                        text={alert?.text}
                                        title={alert.title}
                                    />
                                </Expire>
                            )}
                    </motion.div>
                );
            })}
        </AnimatePresence>
    );
}
