import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled, useTheme } from '@mui/material';
import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import omit from 'lodash/omit';
import { PropsWithChildren, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { ExpandIcon } from '../../Icons/ExpandIcon';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { useFilterSummary } from '../../hooks/useFilterSummary';
import { useActiveFiltersDispatch } from '../ActiveFiltersProvider/useActiveFiltersDispatch';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';
import { useStagedFilters } from '../StagedFiltersProvider/useStagedFilters';
import { useStagedFiltersDispatch } from '../StagedFiltersProvider/useStagedFiltersDispatch';
import { InvalidFilterIndicator } from './InvalidFilterIndicator';

interface CollapsibleFilterProps {
    filterDefinition: FilterDefinition;
    initiallyOpen?: boolean;
    isSingleFilter?: boolean;
    isEmpty?: boolean;
    onSave?(filters: AppFilters): Promise<void>;
}

export function CollapsibleFilter({
    filterDefinition,
    initiallyOpen = false,
    isSingleFilter = false,
    isEmpty,
    onSave,
    children,
}: PropsWithChildren<CollapsibleFilterProps>) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const dispatchActiveFilters = useActiveFiltersDispatch();
    const dispatchStagedFilters = useStagedFiltersDispatch();
    const { stagedFilters, invalidFilters } = useStagedFilters();
    const getFilterSummary = useFilterSummary();
    const [isOpen, setIsOpen] = useState(initiallyOpen || isSingleFilter);
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const { displayName, filterId } = filterDefinition;
    const isValid = !invalidFilters.includes(filterId);
    const { setOpen } = usePopoverContext();

    return (
        <Flex
            direction="column"
            ref={buttonRef}
            width="100%"
        >
            <CollapsibleButton
                align="center"
                gap="1rem"
                height="35px"
                justify="space-between"
                padding="0.5rem"
                ref={buttonRef}
                width="100%"
                {...(!isSingleFilter && {
                    as: 'button',
                    onClick: () => {
                        setIsOpen((isOpen) => {
                            if (!isOpen) {
                                setTimeout(() => {
                                    buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
                                }, 100);
                            }

                            return !isOpen;
                        });
                    },
                })}
            >
                <Flex align="center" gap="1rem">
                    {!isSingleFilter && <ExpandIcon open={isOpen} />}
                    <Typography
                        as="h4"
                        nowrap
                        variant="c-11"
                    >
                        {displayName ?? filterId}
                    </Typography>
                </Flex>
                <Flex
                    align="center"
                    gap="0.6rem"
                    style={{ overflow: 'hidden' }}
                >
                    {isEmpty && <InvalidFilterIndicator />}
                    <Typography
                        color={stagedFilters[filterId] && isValid
                            ? palette.indicator.main
                            : palette.warning.main}
                        truncate
                    >
                        {getFilterSummary({
                            filterDefinition,
                            filterValue: stagedFilters[filterId],
                        })}
                    </Typography>
                </Flex>
            </CollapsibleButton>
            <CollapsibleContent
                direction="column"
                isOpen={isOpen}
                ref={contentRef}
                scrollHeight={contentRef.current?.scrollHeight}
            >
                {children}
                <Flex
                    align="center"
                    as="button"
                    gap="0.5rem"
                    onClick={async () => {
                        if (isSingleFilter) {
                            setOpen(false);
                            await onSave?.(omit(stagedFilters, filterId));

                            dispatchActiveFilters({
                                type: 'remove',
                                payload: filterId,
                            });
                        } else {
                            dispatchStagedFilters({
                                type: 'remove',
                                payload: filterId,
                            });
                        }
                    }}
                    padding="0.5rem 1rem"
                    width="100%"
                >
                    <DeleteOutlineIcon color="error" fontSize="small" />
                    <Typography color={palette.error.main} variant="c-11">
                        {t('filterMenu.removeFilter')}
                    </Typography>
                </Flex>
            </CollapsibleContent>
        </Flex>
    );
}

const CollapsibleButton = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'as' }
)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondaryMask,
    borderRadius: theme.shape.borderRadius,
}));

const CollapsibleContent = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'scrollHeight' }
)<{ isOpen: boolean; scrollHeight?: number }>`
    max-height: ${({ isOpen, scrollHeight }) => (isOpen ? `${scrollHeight}px` : '0')};
    transition: max-height 0.1s ease-out;
    overflow: hidden;
    width: 100%;
`;
