import { Dispatch, ReactNode, SetStateAction } from 'react';
import { ElementAttributes } from '../../../../sequelize-models/src/main';
import { useInsightCardBackgrounds } from '../../Cards/useInsightCardBackgrounds';
import { ChatCpgTimestamp } from '../../ChatCpg/ChatCpgTimestamp';
import { StreamingIcon } from '../../ChatCpg/StreamingIcon';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useChatCpgData } from '../../hooks/useChatCpgData';
import { StreamingText } from '../../hooks/useChatCpgStore';
import { usePage } from '../../hooks/usePage';
import { EditableTitle } from './Components/EditableTitle';
import { OuterToolbarWrapper } from './Components/OuterToolbarWrapper';
import { ToolbarDivider } from './Components/ToolbarDivider';
import { ToolbarWrapper } from './Components/ToolbarWrapper';

interface InsightCardToolbarProps {
    element: ElementAttributes;
    setEditingTitle: Dispatch<SetStateAction<boolean>>;
    title: string;
    toolbarControls: ReactNode;
}

export function InsightCardToolbar({
    element, setEditingTitle, title, toolbarControls,
}: InsightCardToolbarProps) {
    const storeKey = element.settings?.insightCard?.storeKey ?? '';
    const { updateElement } = useCollectionMutations();

    const {
        isStreaming: isStreamingPage,
        summaryCreatedAt,
        summaryId,
    } = usePage();

    const insightsStream = useChatCpgData<StreamingText>([storeKey, summaryId ?? '']);
    const { background } = useInsightCardBackgrounds(storeKey);

    if (!summaryCreatedAt) return null;

    const isEdited = !!element.settings?.insightCard?.text;
    const timestamp = element.settings?.insightCard?.updatedAt ?? summaryCreatedAt;
    const isStreaming = isStreamingPage && insightsStream?.isStreaming !== false;

    return (
        <OuterToolbarWrapper
            background={background}
            id={element.uuid}
        >
            <ToolbarWrapper showGap toolbarControls={toolbarControls}>
                <StreamingIcon isStreaming={isStreaming} size="20px" />
                <EditableTitle
                    element={element}
                    setEditingTitle={setEditingTitle}
                    title={title}
                />
                <ChatCpgTimestamp
                    containerStyle={{ paddingLeft: '0.3rem' }}
                    isEdited={isEdited}
                    isStreaming={isStreaming}
                    onRestore={() => {
                        updateElement({
                            element: {
                                ...element,
                                settings: {
                                    ...element.settings,
                                    insightCard: {
                                        storeKey: element.settings?.insightCard?.storeKey,
                                    },
                                },
                            },
                        });
                    }}
                    timestamp={timestamp}
                />
            </ToolbarWrapper>
            <ToolbarDivider />
        </OuterToolbarWrapper>
    );
}
