import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateElementProps, CreatePageProps, CreateProjectProps, ElementAttributes, PageAttributes, PopulatedPage, PopulatedPageWithElements, PopulatedPageWithPopulatedTabs, PopulatedProject, ProjectAttributes } from '@tickr/sequelize-models';
import { CreateTabProps, TabAttributes } from '@tickr/sequelize-models/src/models/tab.model';
import { PageTemplateProps } from '@tickr/sequelize-models/src/types/pageTemplateTypes';
import { AxiosResponse } from 'axios';
import omit from 'lodash/omit';
import { PropsWithChildren, useMemo } from 'react';
import { pureMerge } from '../../helpers/pureMerge';
import { useCurrentProject } from '../../hooks/useCurrentProject';
import { useApi } from '../ApiProvider/useApi';
import { useUser } from '../UserProvider/useUser';
import { CollectionMutationsContext, DeleteElementProps, DeleteElementsProps, DeleteTabProps, UpdateElementProps, UpdateElementsProps, UpdatePageProps } from './CollectionMutationsContext';

export const OPTIMISTIC_PAGE_ID = '123456789';

export function CollectionMutationsProvider({ children }: PropsWithChildren) {
    const { msId } = useUser();
    const { dashboardApi } = useApi();
    const { currentProject } = useCurrentProject();
    const queryClient = useQueryClient();

    /* PROJECTS */
    const createProject = useMutation({
        mutationFn: async (projectToCreate: CreateProjectProps) => {
            const { data } = await dashboardApi.post<
                never,
                AxiosResponse<ProjectAttributes>,
                CreateProjectProps
            >('projects', projectToCreate);

            return data;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
    });

    const updateProject = useMutation({
        mutationFn: async (project: ProjectAttributes) => {
            const { data } = await dashboardApi.put<
                never,
                AxiosResponse<ProjectAttributes>,
                ProjectAttributes
            >(`projects/${project.uuid}`, project);

            return data;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
    });

    const deleteProject = useMutation({
        mutationFn: async (uuid: string) => {
            await dashboardApi.delete(`projects/${uuid}`);
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
    });

    /* PAGES */
    const createPage = useMutation({
        mutationFn: async (page: CreatePageProps) => {
            const { data } = await dashboardApi.post<
                never,
                AxiosResponse<PageAttributes>,
                { page: CreatePageProps; projectUuid: string }
            >(
                'pages',
                {
                    page,
                    projectUuid: currentProject.uuid,
                }
            );

            return data;
        },
        onMutate(page) {
            queryClient.cancelQueries({ queryKey: ['current-project'] });
            const currentProject = queryClient.getQueryData<PopulatedProject>(['current-project']);
            if (!currentProject) return;
            const currentPages = currentProject.pages;

            queryClient.setQueryData<PopulatedProject>(['current-project'], () => ({
                ...currentProject,
                isNew: page.inProgress ? currentProject.isNew : false,
                // this is necessary to prevent an automatic navigation to home when project pages are empty and a new page is created
                pages: [
                    ...currentPages,
                    {
                        ...page,
                        createdAt: (new Date()).toDateString(),
                        description: null,
                        elements: [],
                        isTickr: false,
                        msId: msId as number,
                        tabs: [],
                        templateUuid: null,
                        updatedAt: (new Date()).toDateString(),
                        userUuid: 'optimistic',
                        uuid: OPTIMISTIC_PAGE_ID,
                    },
                ],
            }));
        },
        async onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
            queryClient.invalidateQueries({ queryKey: ['recent-pages'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
    });

    const createPageFromTemplate = useMutation({
        mutationFn: async (props: PageTemplateProps) => {
            const { data } = await dashboardApi.post<
                never,
                AxiosResponse<PopulatedPageWithElements | PopulatedPageWithPopulatedTabs>,
                PageTemplateProps
            >('pages/template', props);

            return data;
        },
        onMutate() {
            queryClient.cancelQueries({ queryKey: ['current-project'] });
            const currentProject = queryClient.getQueryData<PopulatedProject>(['current-project']);
            if (!currentProject) return;

            queryClient.setQueryData<PopulatedProject>(['current-project'], () => ({
                ...currentProject,
                isNew: false,
            }));
        },
        onSuccess: (page) => {
            queryClient.invalidateQueries({ queryKey: ['recent-pages'] });
            queryClient.setQueryData(['current-page'], () => page);

            queryClient.cancelQueries({ queryKey: ['current-project'] });
            const currentProject = queryClient.getQueryData<PopulatedProject>(['current-project']);
            if (!currentProject) return;

            const currentPages = currentProject.pages;

            queryClient.setQueryData<PopulatedProject>(['current-project'], () => ({
                ...currentProject,
                pages: [...currentPages.filter((p) => p.uuid !== OPTIMISTIC_PAGE_ID), page],
            }));

            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
    });

    const updatePage = useMutation({
        mutationFn: async (props: UpdatePageProps) => {
            // WP NOTE: This happens before the call to avoid race conditions on the server related to mergeProps
            const page = onMutateUpdatePage(props, queryClient);
            if (!page) throw new Error('Page not found');

            const { data } = await dashboardApi.put<
                never, AxiosResponse<PageAttributes>, { page?: PageAttributes; mergeProps?: Partial<PageAttributes> }
            >(
                `pages/${page.uuid}`,
                { page }
            );

            return data;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['recent-pages'] });
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
    });

    const deletePage = useMutation({
        mutationFn: async (uuid: string) => {
            await dashboardApi.delete(`pages/${uuid}`);
        },
        onMutate(uuid) {
            queryClient.cancelQueries({ queryKey: ['current-project'] });
            queryClient.cancelQueries({ queryKey: ['recent-pages'] });

            const currentProject = queryClient.getQueryData<PopulatedProject>(['current-project']);

            const recentPages = queryClient.getQueryData<PageAttributes[]>(['recent-pages', '']);

            if (recentPages) {
                queryClient.setQueryData<PageAttributes[]>(['recent-pages', ''], () => recentPages.filter((page) => page.uuid !== uuid));
            }

            if (currentProject) {
                const updatedPages = currentProject.pages?.filter((page) => page.uuid !== uuid);

                queryClient.setQueryData<PopulatedProject>(['current-project'], () => ({
                    ...currentProject,
                    pages: updatedPages,
                }));
            }
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
            queryClient.invalidateQueries({ queryKey: ['recent-pages'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-project'] });
        },
    });

    const duplicatePage = useMutation({
        mutationFn: async (page: PageAttributes) => {
            const newPage = await createPage.mutateAsync({
                ...omit(page, 'uuid'),
                title: getDuplicateTitle(page.title),
            });

            return newPage;
        },
    });

    /* TABS */
    const createTab = useMutation({
        mutationFn: async (tabToCreate: CreateTabProps) => {
            const { data: tab } = await dashboardApi.post<TabAttributes>('tabs', tabToCreate);
            return tab;
        },
        onSuccess: (tab) => {
            const currentTabs = queryClient.getQueryData<TabAttributes[]>(['current-tabs', tab.pageUuid]) ?? [];
            const updatedTabs = [...currentTabs, tab];
            queryClient.setQueryData(['current-tabs', tab.pageUuid], updatedTabs);
            queryClient.invalidateQueries({ queryKey: ['current-tabs'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-tabs'] });
        },
    });

    const updateTab = useMutation({
        mutationFn: async (tab: TabAttributes) => {
            const { data } = await dashboardApi.put<TabAttributes>(`tabs/${tab.uuid}`, tab);
            return data;
        },
        onMutate(tab) {
            queryClient.cancelQueries({ queryKey: ['current-tabs'] });
            const currentTabs = queryClient.getQueryData<TabAttributes[]>(['current-tabs', tab.pageUuid]) ?? [];
            const updatedTabs = currentTabs.map((t) => (t.uuid === tab.uuid ? tab : t));
            queryClient.setQueryData(['current-tabs', tab.pageUuid], updatedTabs);
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-tabs'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-tabs'] });
        },
    });

    const updateTabs = useMutation({
        mutationFn: async (tabs: TabAttributes[]) => {
            const { data } = await dashboardApi.put<TabAttributes[]>('tabs', tabs);
            return data;
        },
        onMutate(tabs) {
            const pageUuid = tabs[0].pageUuid;
            queryClient.cancelQueries({ queryKey: ['current-tabs'] });
            const currentTabs = queryClient.getQueryData<TabAttributes[]>(['current-tabs', pageUuid]) ?? [];

            const updatedTabs = currentTabs.map(
                (tab) => tabs.find((updatedTab) => updatedTab.uuid === tab.uuid) ?? tab
            );

            queryClient.setQueryData(['current-tabs', pageUuid], updatedTabs);
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-tabs'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-tabs'] });
        },
    });

    const deleteTab = useMutation({
        mutationFn: async ({ uuid }: DeleteTabProps) => {
            await dashboardApi.delete(`tabs/${uuid}`);
            return uuid;
        },
        onMutate({ uuid, pageUuid, navToNearbyTab }) {
            navToNearbyTab?.();
            queryClient.cancelQueries({ queryKey: ['current-tabs'] });
            const currentTabs = queryClient.getQueryData<TabAttributes[]>(['current-tabs', pageUuid]) ?? [];
            const updatedTabs = currentTabs.filter((tab) => tab.uuid !== uuid);
            queryClient.setQueryData(['current-tabs', pageUuid], updatedTabs);
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-tabs'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-tabs'] });
        },
    });

    /* ELEMENTS */
    const createElements = useMutation({
        mutationFn: async (elements: CreateElementProps[]) => {
            const { data } = await dashboardApi.post<
                never,
                AxiosResponse<ElementAttributes[]>,
                CreateElementProps[]
            >('elements', elements);

            return data;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
        },
    });

    const duplicateElement = useMutation({
        mutationFn: async (element: ElementAttributes) => {
            return await createElements.mutateAsync([
                {
                    ...element,
                    title: getDuplicateTitle(element.title),
                },
            ]);
        },
    });

    const updateElement = useMutation({
        mutationFn: async (props: UpdateElementProps) => {
            // WP NOTE: This happens before the call to avoid race conditions on the server related to mergeProps
            const element = onMutateUpdateElement(props, queryClient);

            if (!element) throw new Error('Element not found');

            const { data } = await dashboardApi.put<
                never,
                AxiosResponse<ElementAttributes>,
                { element?: ElementAttributes; mergeProps?: Partial<ElementAttributes> }
            >(`elements/${element.uuid}`, { element });

            return data;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
        },
    });

    const updateElements = useMutation({
        mutationFn: async ({ elements }: UpdateElementsProps) => {
            const { data } = await dashboardApi.put<ElementAttributes[]>('elements', elements);

            return data;
        },
        onMutate({ elements: updatedElements, pageUuid, tabUuid }) {
            queryClient.cancelQueries({ queryKey: ['current-elements'] });
            const currentPageElements = queryClient.getQueryData<ElementAttributes[]>(['current-elements', tabUuid ?? pageUuid]) ?? [];

            queryClient.setQueryData<ElementAttributes[]>(
                ['current-elements', tabUuid ?? pageUuid],
                () => currentPageElements.map(
                    (element) => updatedElements.find((el) => el.uuid === element.uuid) ?? element
                )
            );
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
            queryClient.invalidateQueries({ queryKey: ['template-elements'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
        },
    });

    const deleteElement = useMutation({
        mutationFn: async ({ uuid }: DeleteElementProps) => {
            await dashboardApi.delete(`elements/${uuid}`);
        },
        onMutate({ uuid, pageUuid, tabUuid }) {
            queryClient.cancelQueries({ queryKey: ['current-elements'] });

            const currentPageElements = queryClient.getQueryData<ElementAttributes[]>(['current-elements', tabUuid ?? pageUuid]) ?? [];

            queryClient.setQueryData<ElementAttributes[]>(
                ['current-elements', tabUuid ?? pageUuid],
                () => currentPageElements.filter((el) => el.uuid !== uuid)
            );
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
        },
    });

    const deleteElements = useMutation({
        mutationFn: async ({ uuids }: DeleteElementsProps) => {
            await dashboardApi.delete('elements', {
                data: { uuids },
            });
        },
        onMutate({ uuids: ids, pageUuid, tabUuid }) {
            queryClient.cancelQueries({ queryKey: ['current-elements'] });

            const currentPageElements = queryClient.getQueryData<ElementAttributes[]>(
                ['current-elements', tabUuid ?? pageUuid]
            ) ?? [];

            queryClient.setQueryData<ElementAttributes[]>(
                ['current-elements', tabUuid ?? pageUuid],
                () => currentPageElements.filter((el) => !ids.includes(el.uuid))
            );
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
        },
        onError() {
            queryClient.invalidateQueries({ queryKey: ['current-elements'] });
        },
    });

    const collectionMutations = useMemo(
        () => ({
            createProject: createProject.mutateAsync,
            updateProject: updateProject.mutateAsync,
            deleteProject: deleteProject.mutateAsync,

            createPage: createPage.mutateAsync,
            createPageFromTemplate: createPageFromTemplate.mutateAsync,
            updatePage: updatePage.mutateAsync,
            deletePage: deletePage.mutateAsync,
            duplicatePage: duplicatePage.mutateAsync,

            createTab: createTab.mutateAsync,
            updateTab: updateTab.mutateAsync,
            updateTabs: updateTabs.mutateAsync,
            deleteTab: deleteTab.mutateAsync,

            createElements: createElements.mutateAsync,
            updateElement: updateElement.mutateAsync,
            updateElements: updateElements.mutateAsync,
            deleteElement: deleteElement.mutateAsync,
            deleteElements: deleteElements.mutateAsync,
            duplicateElement: duplicateElement.mutateAsync,
        }),
        [
            createProject.mutateAsync,
            updateProject.mutateAsync,
            deleteProject.mutateAsync,

            createPage.mutateAsync,
            createPageFromTemplate.mutateAsync,
            updatePage.mutateAsync,
            deletePage.mutateAsync,
            duplicatePage.mutateAsync,

            createTab.mutateAsync,
            updateTab.mutateAsync,
            updateTabs.mutateAsync,
            deleteTab.mutateAsync,

            createElements.mutateAsync,
            updateElement.mutateAsync,
            updateElements.mutateAsync,
            deleteElement.mutateAsync,
            deleteElements.mutateAsync,
            duplicateElement.mutateAsync,
        ]
    );

    return (
        <CollectionMutationsContext.Provider value={collectionMutations}>
            {children}
        </CollectionMutationsContext.Provider>
    );
}

const duplicateRegex = /\(Copy (\d+)\)/;

function getDuplicateTitle(title: string) {
    const copyMatch = title.match(duplicateRegex);

    let newTitle = '';

    if (copyMatch) {
        const copyNumber = parseInt(copyMatch[1]);
        newTitle = `${title.replace(duplicateRegex, `(Copy ${copyNumber + 1})`)}`;
    } else {
        newTitle = `${title} (Copy 1)`;
    }

    return newTitle;
}

function onMutateUpdateElement(props: UpdateElementProps, queryClient: QueryClient): ElementAttributes | null {
    queryClient.cancelQueries({ queryKey: ['current-elements'] });

    let element: ElementAttributes | null = 'element' in props ? props.element : null;
    const elementUuid = 'element' in props ? props.element.uuid : props.elementUuid;
    const queryKey = 'element' in props ? props.element.tabUuid ?? props.element.pageUuid : props.queryKey;

    const currentPageElements = queryClient.getQueryData<ElementAttributes[]>(['current-elements', queryKey]);

    if (currentPageElements) {
        queryClient.setQueryData<ElementAttributes[]>(
            ['current-elements', queryKey],
            () => currentPageElements.map((e) => {
                if (e.uuid !== elementUuid) return e;

                if ('mergeProps' in props) {
                    element = pureMerge(e, props.mergeProps);
                    return element;
                }

                return props.element;
            })
        );
    }

    return element;
}

function onMutateUpdatePage(props: UpdatePageProps, queryClient: QueryClient): PageAttributes | null {
    let page: PageAttributes | null = 'page' in props ? props.page : null;

    const pageUuid = 'page' in props ? props.page.uuid : props.pageUuid;

    queryClient.cancelQueries({ queryKey: ['current-project'] });
    const currentProject = queryClient.getQueryData<PopulatedProject>(['current-project']);

    if (currentProject) {
        queryClient.setQueryData<PopulatedProject>(['current-project'], () => ({
            ...currentProject,
            pages: currentProject.pages.map(
                (p) => {
                    if (p.uuid !== pageUuid) return p;

                    if ('mergeProps' in props) {
                        page = pureMerge(p, props.mergeProps);
                        return page as PopulatedPage;
                    }

                    return {
                        ...p,
                        ...props.page,
                    };
                }
            ),
        }));
    }

    return page;
}
