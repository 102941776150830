import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { FilterGroupItem } from '../../Filters/Components/FilterGroup';
import { useApi } from '../ApiProvider/useApi';
import { useUser } from '../UserProvider/useUser';
import { ProductAttributesContext } from './ProductAttributesContext';

export function ProductAttributesProvider({ children }: PropsWithChildren): JSX.Element {
    const { dashboardApi } = useApi();
    const { isDemo } = useUser();

    const { data: myProducts = [] } = useQuery(
        {
            queryKey: ['my-products'],
            queryFn: async ({ signal }) => {
                const { data } = await dashboardApi.get<FilterGroupItem[]>('product-attributes', {
                    params: {
                        isOwned: true,
                    },
                    signal,
                });

                return data;
            },
            enabled: isDemo,
        }
    );

    return (
        <ProductAttributesContext.Provider
            value={{
                myProducts,
                myCompetitors: [],
            }}
        >
            {children}
        </ProductAttributesContext.Provider>
    );
}
