import { Button, Dialog } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../Core/Typography';
import { TickrDialogActions } from '../Dialog/TickrDialogActions';
import { TickrDialogContent } from '../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../Dialog/TickrDialogTitle';

interface DeletePageDialogProps {
    open: boolean;
    onCancel: () => void;
    onDelete: () => void;
    multiple?: boolean;
}

export function DeletePageDialog({ open, onCancel, onDelete, multiple }: DeletePageDialogProps) {
    const { t } = useTranslation();
    const [clicked, setClicked] = useState(false);

    return (
        <Dialog onClose={onCancel} open={open}>
            <TickrDialogTitle>
                {multiple ? t('deletePages.modal.title') : t('deletePage.modal.title')}
            </TickrDialogTitle>
            <TickrDialogContent>
                <Typography secondary>{t('delete.youCantUndo')}</Typography>
            </TickrDialogContent>
            <TickrDialogActions>
                <Button
                    color="info"
                    onClick={onCancel}
                    size="small"
                    style={{ width: '150px' }}
                >
                    {t('util.cancel')}
                </Button>
                <Button
                    color="error"
                    onClick={() => {
                        if (clicked) return;
                        setClicked(true);
                        onDelete();
                    }}
                    size="small"
                    style={{ width: '150px' }}
                >
                    {t('util.delete')}
                </Button>
            </TickrDialogActions>
        </Dialog>
    );
}
