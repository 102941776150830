import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LaunchIcon from '@mui/icons-material/Launch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, IconButton, styled } from '@mui/material';
import { NewsCard } from '@tickr/sequelize-models/src/types/libBiTypes';
import truncate from 'lodash/truncate';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { date } from 'yup';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { PopoverProvider } from '../Popover/PopoverProvider';
import { PopoverTrigger } from '../Popover/PopoverTrigger';
import { useDateFormatter } from '../hooks/useDateFormatter';
import { ImageWithFallback } from './ImageWithFallback';
import { MediaImage } from './MediaImage';
import { NewsReportDialog } from './NewsReportDialog';
import { PostPopoverContent } from './PostPopoverContent';

interface NewsPostProps {
    news: NewsCard;
    report?: ReactNode;
    textLength?: number;
}

export function NewsPost({ news, report, textLength = 250 }: NewsPostProps) {
    const { t } = useTranslation();
    const format = useDateFormatter();
    const [reportOpen, setReportOpen] = useState(false);
    const postSrc = news.whitelisted && news.url;

    return (
        <PostContainer
            direction="column"
            gap="1rem"
            padding="0.6rem"
            width="100%"
        >
            <Flex
                align="center"
                justify="space-between"
                width="100%"
            >
                <ImpactButton as={report ? 'button' : 'div'} onClick={report ? () => setReportOpen(true) : undefined}>
                    <Flex align="center">
                        <ImpactScore
                            align="center"
                            gap="0.6rem"
                            padding="0.3rem 0.5rem"
                        >
                            <Typography variant="n-32-b">{Math.round(news?.metrics?.impact)}</Typography>
                            <Flex direction="column">
                                <Typography secondary variant="n-12-b">
                                    /100
                                </Typography>
                                <Typography secondary variant="n-12-b">
                                    {t('metrics.impact')}
                                </Typography>
                            </Flex>
                        </ImpactScore>
                        {report && <ChevronRightIcon />}
                    </Flex>
                </ImpactButton>
                {report && (
                    <NewsReportDialog
                        onClose={() => setReportOpen(false)}
                        open={reportOpen}
                        report={report}
                    />
                )}

                <Flex
                    align="center"
                    direction="column"
                    gap="0.4rem"
                >
                    {report && (
                        <PopoverProvider placement="bottom-start">
                            <PopoverTrigger asChild>
                                <IconButton size="small">
                                    <MoreVertIcon />
                                </IconButton>
                            </PopoverTrigger>
                            <PostPopoverContent id={news.clusterId} />
                        </PopoverProvider>
                    )}
                </Flex>
            </Flex>
            <Flex gap="10px" width="100%">
                {news?.source?.logo?.src && <StyledImage alt="author avatar" src={news.source.logo.src} />}
                <Flex
                    direction="column"
                    gap="0.1rem"
                    width="calc(100% - 50px)"
                >
                    <Typography
                        nowrap
                        style={{ width: '100%' }}
                        truncate
                    >
                        {news?.source?.name}
                    </Typography>
                    <Flex gap="0.3rem" width="100%">
                        <Typography
                            nowrap
                            secondary
                            title={news?.source?.domain}
                            truncate
                        >
                            {news?.source?.domain}
                        </Typography>
                        {date && (
                            <>
                                <Typography secondary>·</Typography>
                                <Typography nowrap secondary>
                                    {format(date.toString(), 'fromNow')}
                                </Typography>
                            </>
                        )}
                    </Flex>
                    {news?.topic && (
                        <Typography
                            nowrap
                            secondary
                            style={{ width: '100%' }}
                            title={news.topic}
                            truncate
                        >
                            {news.topic}
                        </Typography>
                    )}
                </Flex>
            </Flex>
            <Flex
                direction="column"
                gap="0.5rem"
                width="100%"
            >
                {news.title && <Typography as="h3">{news.title}</Typography>}
                <Typography
                    secondary
                    style={{
                        width: '100%',
                        overflowWrap: 'break-word',
                    }}
                >
                    {truncate(news.body, { length: textLength })}
                </Typography>
                {news?.image?.src && <MediaImage alt="media image" src={news.image.src} />}
                {postSrc && (
                    <Button
                        color="secondaryText"
                        href={postSrc}
                        size="small"
                        startIcon={<LaunchIcon />}
                        target="_blank"
                    >
                        {t('util.readMore')}
                    </Button>
                )}
            </Flex>
        </PostContainer>
    );
}

const PostContainer = styled(
    Flex,
    { shouldForwardProp: (prop) => !!prop }
)(({ theme }) => `
    border: 1px solid ${theme.palette.background.default};
    background: ${theme.palette.gradient.secondaryBackground};
    border-radius: ${theme.shape.borderRadius}px;
    overflow-y: auto;
`);

const ImpactButton = styled('div')(({ theme }) => `
    display: flex;
    border-radius: ${theme.shape.borderRadius}px;
    border: 2px solid ${theme.palette.background.secondary};
    background-color: ${theme.palette.background.secondary};
`);

const ImpactScore = styled(Flex, { shouldForwardProp: (prop) => !!prop })(({ theme }) => `
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.background.secondaryMask};
`);

const StyledImage = styled(ImageWithFallback, { shouldForwardProp: (prop) => !!prop })`
    width: 40px;
    height: 40px;
    border-radius: 100%;
`;
