import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { usePage } from '../hooks/usePage';
import { ChatCpgBubble } from './ChatCpgBubble';

export function ChatStreamBubble() {
    const { summaryId = '' } = usePage();
    const { chatStreamId } = useChatBox();

    const chatStream = useChatCpgData<StreamingText>([chatStreamId, summaryId]) ?? {
        text: '',
        isStreaming: false,
    };

    return (
        <ChatCpgBubble
            id="chat-stream-bubble"
            isStreaming
            text={chatStream.text}
        />
    );
}
