import { CSSProperties, PropsWithChildren, useContext } from 'react';
import { TabContext } from './TabsContext';

interface TabPanelProps {
    name: string; // should match respective Tab name
    index: number;
    style?: CSSProperties;
}

export function TabPanel({ name, index, style, children }: PropsWithChildren<TabPanelProps>) {
    const { activeTab } = useContext(TabContext);

    return (
        <div
            aria-labelledby={`tab-${name.toLowerCase().replace(/\s/g, '-')}`}
            hidden={activeTab !== index}
            id={`panel-${name.toLowerCase().replace(/\s/g, '-')}`}
            role="tabpanel"
            style={activeTab === index ? style : {}}
        >
            {children}
        </div>
    );
}
