import { Dispatch, Fragment, SetStateAction } from 'react';
import { Flex } from '../Core/Flex';
import { isoToUnixTimestamp } from '../helpers/isoToUnixTimestamp';
import { ResponseBubbleV2 } from './ResponseBubbleV2';
import { UserBubbleV2 } from './UserBubbleV2';
import { useChatHistory } from './useChatHistory';
import { useMessageStreamV2 } from './useMessageStreamV2';

interface ChatHistoryV2Props {
    chatId: string;
    messageStreamId: string;
    setMessageStreamId: Dispatch<SetStateAction<string>>;
}

export function ChatHistoryV2({ chatId, messageStreamId, setMessageStreamId }: ChatHistoryV2Props) {
    const { data } = useChatHistory({
        chatId,
        setMessageStreamId,
    });

    useMessageStreamV2({
        chatHistory: data?.messages ?? [],
        chatId,
        messageId: messageStreamId,
    });

    return (
        <Flex
            direction="column-reverse"
            gap="1rem"
            overflow="scroll"
            width="100%"
        >
            {data?.messages.map(({ uuid, response_text, user_message, sent }) => (
                <Fragment key={uuid}>
                    <ResponseBubbleV2
                        messageId={uuid}
                        text={response_text}
                        timestamp={isoToUnixTimestamp(sent)}
                    />
                    <UserBubbleV2
                        text={user_message}
                        timestamp={isoToUnixTimestamp(sent)}
                    />
                </Fragment>
            ))}
        </Flex>
    );
}

