import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Button, FormControl, IconButton, MenuItem, Select, SelectChangeEvent, TextField, styled, useTheme } from '@mui/material';
import { Formik } from 'formik';
import uniqBy from 'lodash/uniqBy';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import * as Yup from 'yup';
import { AuthorAvatar } from '../../Core/AuthorAvatar';
import { ContextMenu } from '../../Core/ContextMenu';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { CsvIcon } from '../../Icons/CsvIcon';
import { ExcelIcon } from '../../Icons/ExcelIcon';
import { PowerPointIcon } from '../../Icons/PowerPointIcon';
import { PopoverContent } from '../../Popover/PopoverContent';
import { useToaster } from '../../Providers/ToasterProvider/useToaster';
import { usePage } from '../../hooks/usePage';
import { useYupValidation } from '../../hooks/useYupValidation';

type Role = 'view' | 'edit';

interface AssignedRole {
    email: string;
    color: string;
    role: Role;
}

export function SharingPopoverContent() {
    const { t } = useTranslation();
    const { sendAlert } = useToaster();
    const { palette, shape } = useTheme();
    const { pageUuid } = usePage();

    const { commaSeparatedEmails } = useYupValidation();

    const [assignedRoles, setAssignedRoles] = useLocalStorage<AssignedRole[]>(`${pageUuid}-assigned-roles`, []);
    const [isFirst, setIsFirst] = useState(true);

    return (
        <PopoverContent>
            <ContextMenu
                direction="column"
                gap="1.5rem"
                padding="0.5rem"
                style={{ overflow: 'visible' }}
                width="475px"
            >
                <Typography as="h2" variant="h-18-b">{t('sharing.shareThisAnalysis')}</Typography>

                <Flex
                    direction="column"
                    gap="1.5rem"
                    padding="0.5rem"
                    style={{
                        background: palette.background.secondary,
                        borderRadius: shape.borderRadius,
                    }}
                    width="100%"
                >
                    <Flex
                        direction="column"
                        gap="1rem"
                        width="100%"
                    >
                        <Typography as="h3" variant="h-16-b">
                            {t('sharing.shareInTickr')}
                        </Typography>
                        <Formik
                            initialValues={{
                                emails: '',
                                role: 'view' as Role,
                            }}
                            onSubmit={(values, { resetForm }) => {
                                const emails = values.emails.split(',').map((e: string) => e.trim());

                                setAssignedRoles((roles) => {
                                    const newRoles = uniqBy([
                                        ...roles,
                                        ...emails.map((email) => ({
                                            email,
                                            color: palette.primary.main,
                                            role: values.role,
                                        })),
                                    ], 'email');

                                    return newRoles;
                                });

                                resetForm();
                                setIsFirst(true);
                            }}
                            validateOnChange={!isFirst}
                            validationSchema={Yup.object().shape({
                                emails: commaSeparatedEmails,
                            })}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                values,
                            }) => (

                                <Flex
                                    align="center"
                                    direction="column"
                                    gap="0.5rem"
                                    width="100%"
                                >
                                    <Flex gap="0.2rem" width="100%">
                                        <TextField
                                            error={!!errors.emails}
                                            helperText={errors.emails}
                                            id="emails"
                                            label={t('sharing.emailLabel')}
                                            name="emails"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            size="small"
                                            style={{
                                                width: '250px',
                                                flexGrow: 1,
                                            }}
                                            value={values.emails}
                                        />
                                        <RoleSelect
                                            role={values.role}
                                            setRole={(role) => setFieldValue('role', role)}
                                        />
                                    </Flex>
                                    <Button
                                        disabled={!isFirst && !!errors.emails}
                                        onClick={async () => {
                                            setIsFirst(false);

                                            if (!errors.emails && values.emails.length > 0) {
                                                handleSubmit();
                                            }
                                        }}
                                        size="small"
                                        style={{ width: '200px' }}
                                        variant="contained"
                                    >
                                        {values.emails.includes(',') ? t('sharing.sendInvites') : t('sharing.sendInvite')}
                                    </Button>
                                </Flex>
                            )}
                        </Formik>
                    </Flex>
                    <Flex
                        direction="column"
                        gap="0.5rem"
                        width="100%"
                    >
                        <Typography as="h4" variant="h-14-b">
                            {t('sharing.generalAccess')}
                        </Typography>
                        <AccessOptions />
                    </Flex>
                    <Flex
                        direction="column"
                        gap="0.5rem"
                        style={{
                            maxHeight: '30vh',
                            overflowY: 'auto',
                        }}
                        width="100%"
                    >
                        {assignedRoles.length > 0 && (
                            <Typography as="h4" variant="h-14-b">
                                {t('sharing.userAccess')}
                            </Typography>
                        )}
                        {assignedRoles.map(({ email, color, role }) => (
                            <UserRole
                                color={color}
                                email={email}
                                key={email}
                                removePerson={() => {
                                    setAssignedRoles((roles) => roles.filter((r) => r.email !== email));
                                }}
                                role={role}
                                setRole={(newRole) => {
                                    setAssignedRoles((roles) => {
                                        const index = roles.findIndex((r) => r.email === email);
                                        const newRoles = [...roles];

                                        newRoles[index] = {
                                            ...newRoles[index],
                                            role: newRole,
                                        };

                                        return newRoles;
                                    });
                                }}
                            />
                        ))}
                    </Flex>
                </Flex>
                <Button
                    color="info"
                    onClick={() => {
                        navigator.clipboard.writeText(window.location.href);

                        sendAlert({
                            text: t('sharing.linkCopied'),
                            severity: 'success',
                        });
                    }}
                    size="small"
                    startIcon={<LinkIcon />}
                    style={{
                        margin: '0.5rem',
                    }}
                    variant="outlined"
                >
                    {t('sharing.copyLink')}
                </Button>
                <Flex
                    direction="column"
                    gap="1rem"
                    padding="0.5rem"
                >
                    <Typography as="h3" variant="h-16-b">{t('util.export')}</Typography>
                    <Flex gap="1.5rem">
                        <ShareLink caption={t('export.excel')}>
                            <ExcelIcon size="48px" />
                        </ShareLink>
                        <ShareLink caption={t('export.powerPoint')}>
                            <PowerPointIcon size="48px" />
                        </ShareLink>
                        <ShareLink caption={t('export.csv')}>
                            <CsvIcon size="48px" />
                        </ShareLink>
                    </Flex>
                </Flex>
            </ContextMenu>
        </PopoverContent>
    );
}

interface ShareLinkProps {
    caption: string;
    children: ReactNode;
}

function ShareLink({ caption, children }: ShareLinkProps) {
    return (
        <StyledFlex
            align="center"
            as="button"
            direction="column"
            gap="0.4rem"
        >
            {children}
            <Typography variant="h-12-b">{caption}</Typography>
        </StyledFlex>
    );
}

const StyledFlex = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'as' }
)`
    :hover {
        transform: scale(1.05);
    }

    transition: all 0.2s ease-in-out;
`;

interface RoleSelectProps {
    role: Role;
    setRole: (role: Role) => void;
}

function RoleSelect({ role, setRole }: RoleSelectProps) {
    const { t } = useTranslation();

    return (
        <FormControl style={{ width: '100px' }}>
            <Select
                id="role-select"
                onChange={(event: SelectChangeEvent<Role>) => {
                    setRole(event.target.value as Role);
                }}
                size="small"
                value={role}
            >
                <MenuItem value="view">{t('sharing.viewer')}</MenuItem>
                <MenuItem value="edit">{t('sharing.editor')}</MenuItem>
            </Select>
        </FormControl>
    );
}

type Access = 'invited' | 'link';

function AccessOptions() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { pageUuid } = usePage();

    const [access, setAccess] = useLocalStorage<Access>(`${pageUuid}-access`, 'invited');
    const [role, setRole] = useLocalStorage<Role>(`${pageUuid}-access-role`, 'view');

    return (
        <Flex
            align="center"
            gap="0.2rem"
            justify="space-between"
            width="100%"
        >
            <Flex
                align="center"
                gap="0.5rem"
                grow={1}
            >
                {access === 'invited' ? <LockIcon style={{ color: palette.text.primary }} /> : <LanguageIcon style={{ color: palette.text.primary }} />}

                <FormControl style={{ flexGrow: 1 }}>
                    <Select
                        id="access-select"
                        onChange={(event: SelectChangeEvent<Access>) => {
                            setAccess(event.target.value as Access);
                        }}
                        size="small"
                        value={access}
                    >
                        <MenuItem value="invited">{t('sharing.access.invited')}</MenuItem>
                        <MenuItem value="link">{t('sharing.access.link')}</MenuItem>
                    </Select>
                </FormControl>
            </Flex>
            <RoleSelect role={role} setRole={setRole} />
        </Flex>
    );
}

interface UserRoleProps {
    email: string;
    color: string;
    removePerson: () => void;
    role: Role;
    setRole: (role: Role) => void;
}

function UserRole({ color, email, removePerson, role, setRole }: UserRoleProps) {
    return (
        <Flex
            align="center"
            justify="space-between"
            width="100%"
        >
            <Flex align="center" gap="0.8rem">
                <AuthorAvatar
                    author={{
                        username: email,
                    }}
                    background={color}
                    size="24px"
                />
                <Typography variant="p-14-b">{email}</Typography>
            </Flex>
            <Flex align="center" gap="0.5rem">
                <IconButton
                    color="warning"
                    onClick={removePerson}
                    size="small"
                >
                    <PersonRemoveIcon />
                </IconButton>
                <RoleSelect role={role} setRole={setRole} />
            </Flex>
        </Flex>
    );
}
