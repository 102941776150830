import { useTheme } from '@mui/material';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function CsvIcon(props: IconProps) {
    const { palette } = useTheme();

    return (
        <IconWrapper {...props} color="">
            <svg
                height="60"
                viewBox="0 0 50 60"
                width="50"
            >
                <path
                    clipRule="evenodd"
                    d="M7.15381 25.7269V3.95764H31.2968V14.6609C31.2968 16.3178 32.6399 17.6609 34.2968 17.6609H45V55.3753H7.15381V42.7269H32C33.6569 42.7269 35 41.3837 35 39.7269V28.7269C35 27.07 33.6569 25.7269 32 25.7269H7.15381ZM4.15381 25.7269V3.95764C4.15381 2.30079 5.49696 0.957642 7.15381 0.957642H31.7278C32.7886 0.957642 33.806 1.37907 34.5562 2.12921L34.6978 2.27086L34.711 2.28392L46.6738 14.2467L46.6868 14.2598L46.8284 14.4015C47.5786 15.1516 48 16.169 48 17.2299V55.3753C48 57.0321 46.6569 58.3753 45 58.3753H7.15381C5.49696 58.3753 4.15381 57.0321 4.15381 55.3753V42.7269H3C1.34315 42.7269 0 41.3837 0 39.7269V28.7269C0 27.07 1.34315 25.7269 3 25.7269H4.15381ZM34.2968 14.6609V6.11246L42.8453 14.6609H34.2968Z"
                    fill={palette.sulu.main}
                    fillRule="evenodd"
                    opacity="0.7"
                />
                <path
                    d="M10.1917 38.8949C7.64773 38.8949 5.82373 36.9749 5.82373 34.5269C5.82373 32.0669 7.64773 30.1589 10.1917 30.1589C11.7277 30.1589 13.0837 30.9389 13.8037 32.1389L12.3757 32.9669C11.9677 32.2229 11.1517 31.7669 10.1917 31.7669C8.54773 31.7669 7.46773 32.9069 7.46773 34.5269C7.46773 36.1349 8.54773 37.2749 10.1917 37.2749C11.1517 37.2749 11.9797 36.8189 12.3757 36.0869L13.8037 36.9149C13.0957 38.1149 11.7397 38.8949 10.1917 38.8949ZM17.689 38.8949C15.973 38.8949 14.845 38.0669 14.389 36.8189L15.805 35.9909C16.105 36.7709 16.693 37.2749 17.737 37.2749C18.733 37.2749 19.081 36.8549 19.081 36.3869C19.081 35.7629 18.517 35.5229 17.269 35.1629C15.985 34.7909 14.737 34.2509 14.737 32.6549C14.737 31.0709 16.057 30.1589 17.497 30.1589C18.877 30.1589 19.933 30.8669 20.497 32.0429L19.105 32.8469C18.805 32.2109 18.349 31.7669 17.497 31.7669C16.801 31.7669 16.393 32.1269 16.393 32.6069C16.393 33.1229 16.717 33.3989 17.989 33.7829C19.321 34.2029 20.737 34.6469 20.737 36.3629C20.737 37.9349 19.477 38.8949 17.689 38.8949ZM23.6587 38.7269L20.8387 30.3269H22.6387L24.6907 36.7829L26.7307 30.3269H28.5427L25.7107 38.7269H23.6587Z"
                    fill={palette.background.default}
                />
            </svg>

        </IconWrapper>
    );
}
