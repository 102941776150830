import { styled, useTheme } from '@mui/material';
import { TabAttributes } from '@tickr/sequelize-models/src/models/tab.model';
import { useNavigate, useParams } from 'react-router-dom';
import { OptimizePricingTalkingPoints } from '../../../sequelize-models/src/types/chatCpgTypes';
import { EditableTypography } from '../Core/EditableTypography';
import { Flex } from '../Core/Flex';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useDashboardSettings } from '../Providers/DashboardSettingsProvider.tsx/useDashboardSettings';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { usePage } from '../hooks/usePage';
import { ChatCpgTimestamp } from './ChatCpgTimestamp';
import { StreamingIcon } from './StreamingIcon';

interface TalkingPointProps {
    tab: TabAttributes;
}

export function TalkingPoint({ tab }: TalkingPointProps) {
    const theme = useTheme();
    const { presentationMode } = useDashboardSettings();
    const { tabUuid } = useParams();

    const {
        isStreaming,
        pageUuid,
        settings,
        solutionType,
        summaryCreatedAt,
        summaryId = '',
    } = usePage();

    const currentTab = useCurrentTab();
    const { updateTab } = useCollectionMutations();
    const navigate = useNavigate();

    const { text: headlineStream, isStreaming: isStreamingHeadline } = useChatCpgData<StreamingText>(
        [`${tab.settings?.talkingPoint}-headline`, summaryId]
    ) ?? {
        text: '',
        isStreaming: false,
    };

    if (!summaryId || !settings || !summaryCreatedAt || !solutionType || !tab.settings?.talkingPoint) return null;

    let pageText: string | undefined;

    if (solutionType === 'optimizePricing') {
        pageText = settings?.summary?.[solutionType]?.headlines?.[tab.settings.talkingPoint as Exclude<OptimizePricingTalkingPoints, 'intro'>];
    }

    const isActive = tabUuid === tab.uuid;
    const initialText = tab.text || pageText || headlineStream || '';
    const isEditable = isActive && !isStreaming && !presentationMode;

    return (
        <StyledFlex
            as={isActive ? 'div' : 'button'}
            direction="column"
            gap="6px"
            isActive={isActive}
            onClick={!isActive ? () => navigate(`/page/${pageUuid}/tab/${tab.uuid}`) : undefined}
            padding="6px"
            style={{
                overflow: 'auto',
                cursor: isActive ? 'default' : 'pointer',
            }}
            width="100%"
        >
            <Flex
                align="center"
                gap="0.6rem"
                width="100%"
            >
                <StreamingIcon
                    isStreaming={isStreaming}
                    size="24px"
                />
                <EditableTypography
                    as="h3"
                    color={isActive ? theme.palette.text.primary : theme.palette.text.secondary}
                    contentEditable={isEditable}
                    focusBackground={theme.palette.background.secondaryMask}
                    initialText={tab.title}
                    max={255}
                    onSave={(title) => {
                        updateTab({
                            ...tab,
                            title,
                            // settings: {
                            //     ...tab.settings,
                            //     textUpdatedAt: Date.now(),
                            // },
                        });
                    }}
                    singleLine
                    variant="c-14"
                />
            </Flex>
            <EditableTypography
                contentEditable={isEditable}
                focusBackground={theme.palette.background.secondaryMask}
                initialText={initialText}
                isStreaming={isStreaming && isStreamingHeadline !== false}
                max={255}
                onSave={(text) => {
                    updateTab({
                        ...tab,
                        text,
                        settings: {
                            ...tab.settings,
                            textUpdatedAt: Date.now(),
                        },
                    });
                }}
                style={{
                    flexGrow: 1,
                    overflow: 'auto',
                    width: 'calc(100% - 8px)',
                }}
                variant="p-14-b"
            />
            {isActive && (
                <ChatCpgTimestamp
                    containerStyle={{ paddingLeft: '0.3rem' }}
                    isEdited={!!tab.settings?.textUpdatedAt}
                    isStreaming={isStreaming}
                    onRestore={() => {
                        if (!tabUuid || !currentTab) return;

                        const tabToUpdate: TabAttributes = {
                            ...currentTab,
                            text: '',
                            settings: {
                                ...currentTab?.settings,
                                textUpdatedAt: null,
                            },
                        };

                        updateTab(tabToUpdate);
                    }}
                    timestamp={tab.settings?.textUpdatedAt ?? summaryCreatedAt}
                />
            )}

        </StyledFlex>
    );
}

const StyledFlex = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'isActive' }
)<{ isActive: boolean }>(({ theme, isActive }) => `
    border: ${isActive ? `1px solid ${theme.palette.text.primary}` : `1px solid ${theme.palette.background.tertiary}`};
    border-radius: ${theme.shape.borderRadius}px;
    min-height: 154px;
    max-height: 154px;
    width: 250px;

    background-color: ${isActive ? theme.palette.background.secondary : theme.palette.background.default};
    filter: brightness(${isActive ? '1' : '0.8'});

    :hover:enabled {
        filter: brightness(1);
        opacity: 1;
    }

    :active:enabled {
        filter: brightness(0.7);
    }
`);
