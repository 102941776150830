import { useQuery } from '@tanstack/react-query';
import { PageAttributes } from '@tickr/sequelize-models';
import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import { useLocation, useParams } from 'react-router-dom';
import { useCurrentProject } from './useCurrentProject';
import { useStagedPage } from './useStagedPage';

export const usePage = () => {
    const { pathname } = useLocation();
    const { pageUuid } = useParams<{ pageUuid: string }>();
    const { currentProject } = useCurrentProject();
    const { stagedPage } = useStagedPage(pageUuid ?? '');

    const { data } = useQuery<PageAttributes | null>({
        queryKey: ['current-page'],
        queryFn: () => {
            return null;
        },
    });

    let dedicatedSolution: SolutionType | undefined;

    if (pathname === '/scorecard') {
        dedicatedSolution = 'scorecard';
    } else if (pathname === '/out-of-stock-predictions') {
        dedicatedSolution = 'outOfStockPredictions';
    }

    const pageOnServer = dedicatedSolution
        ? currentProject.pages.find((page) => page.solutionType === dedicatedSolution)
        : currentProject.pages.find((page) => page.uuid === pageUuid);

    const page = stagedPage ?? pageOnServer;

    return {
        defaultMosaic: page?.settings.defaultMosaic,
        error: page?.settings.summary?.error,
        forecastSummaryData: page?.settings.summary?.forecast,
        isOutOfStockPredictions: page?.solutionType === 'outOfStockPredictions',
        isScorecard: page?.solutionType === 'scorecard',
        isStreaming: !!page?.settings.summary?.isStreaming,
        layoutType: page?.layoutType,
        mosaicNode: page?.settings.mosaicNode,
        optimizePricingSummaryData: page?.settings.summary?.optimizePricing,
        page: data ?? page,
        settings: page?.settings,
        solutionType: page?.solutionType,
        substitutionSummaryData: page?.settings.summary?.substitutions,
        summaryCreatedAt: page?.settings.summary?.createdAt,
        summaryId: page?.settings.summary?.id,
        pageUuid,
        updatedAt: page?.updatedAt ? new Date(page?.updatedAt)?.getTime() : undefined,
    };
};
