import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, styled } from '@mui/material';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { NotificationsPopover } from '../Popovers/NotificationsPopover/NotificationsPopover';
import { useUser } from '../Providers/UserProvider/useUser';
import { HEADER_HEIGHT } from '../helpers/constants';
import { NavMenu } from './NavMenu';
import { SidePanelDrawer } from './SidePanelDrawer';

interface HeaderProps {
    endChildren?: ReactNode;
    middleChildren?: ReactNode;
    pageTitle?: string;
}

export function Header({
    children,
    endChildren,
    middleChildren,
    pageTitle,
}: PropsWithChildren<HeaderProps>) {
    const { t } = useTranslation();
    const { isDemo } = useUser();
    const [sidePanelOpen, setSidePanelOpen] = useState(false);

    return (
        <HeaderContainer>
            <Flex
                align="center"
                justify="space-between"
                style={{ minHeight: '32px' }}
                width="100%"
            >
                <Flex
                    align="center"
                    gap="0.6rem"
                    style={{ minWidth: 0 }}
                >
                    <IconButton onClick={() => setSidePanelOpen((open) => !open)}>
                        <MenuIcon />
                    </IconButton>
                    <SidePanelDrawer
                        onClose={() => setSidePanelOpen(false)}
                        onOpen={() => setSidePanelOpen(true)}
                        open={sidePanelOpen}
                    />
                    {pageTitle
                        ? (
                            <Typography
                                as="h1"
                                nowrap
                                style={{
                                    paddingRight: '2rem',
                                }}
                            >
                                {pageTitle}
                            </Typography>
                        )
                        : (
                            <NavMenu />
                        )}
                    {isDemo && <NotificationsPopover />}
                    {children}
                </Flex>
                {middleChildren}
                <Flex align="center" gap="1rem">
                    {endChildren}
                    <Typography nowrap variant="h-22-b">
                        {t('tickr').toUpperCase()}
                    </Typography>
                </Flex>
            </Flex>
        </HeaderContainer>
    );
}

const HeaderContainer = styled('div')`
    overflow: hidden;
    min-width: 0;
    min-height: ${HEADER_HEIGHT}px;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: inherit;
    width: 100vw;
    padding: 8px 16px 8px 8px;
`;
