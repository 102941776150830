import { useCallback } from 'react';
import { AlertProps } from '../Providers/ToasterProvider/ToasterContext';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useCaptureTickrException } from './useCaptureTickrException';

export function useHandleError() {
    const { sendAlert } = useToaster();
    const captureTickrException = useCaptureTickrException();

    const handleError = useCallback(
        (e: unknown, alert: string | AlertProps) => {
            captureTickrException(e);

            if (typeof alert === 'string') {
                sendAlert({
                    severity: 'error',
                    text: alert,
                });

                return;
            }

            sendAlert({
                severity: 'error',
                ...alert,
            });
        },
        [captureTickrException, sendAlert]
    );

    return handleError;
}
