import { useCallback, useMemo } from 'react';
import { PartialDeep } from 'type-fest';
import { StagedPage, useChatCpgStore } from './useChatCpgStore';

export function useStagedPageFns(pageUuid: string) {
    const deleteStoreValue = useChatCpgStore((state) => state.deleteStoreValue);
    const updateStagedPageStoreValue = useChatCpgStore((state) => state.updateStagedPageStoreValue);

    const resetStagedPage = useCallback(() => {
        deleteStoreValue(pageUuid);
    }, [pageUuid, deleteStoreValue]);

    const updateStagedPage = useCallback((update: PartialDeep<StagedPage>) => {
        updateStagedPageStoreValue(pageUuid ?? '', update);
    }, [pageUuid, updateStagedPageStoreValue]);

    const initStagedPage = useCallback((page: StagedPage) => {
        updateStagedPage(page);
    }, [updateStagedPage]);

    const stagedPageFns = useMemo(() => ({
        initStagedPage,
        resetStagedPage,
        updateStagedPage,
    }), [initStagedPage, resetStagedPage, updateStagedPage]);

    return stagedPageFns;
}
