import { PropsWithChildren, useMemo, useState } from 'react';
import { ErrorContext } from './ErrorContext';

export function ErrorProvider({ children }: PropsWithChildren) {
    const [error, setError] = useState<string | null>(null);

    const value = useMemo(() => ({
        error,
        setError,
    }), [error, setError]);

    return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
}
