import { useTheme } from '@mui/material';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function DocIcon(props: IconProps) {
    const { palette } = useTheme();

    return (
        <IconWrapper {...props} color="">
            <svg
                fill="none"
                height="60"
                viewBox="0 0 50 60"
                width="50"
            >
                <path
                    clipRule="evenodd"
                    d="M7.15381 25.1812V3.41199H31.2968V14.1153C31.2968 15.7721 32.6399 17.1153 34.2968 17.1153H45V54.8296H7.15381V42.1812H32C33.6569 42.1812 35 40.8381 35 39.1812V28.1812C35 26.5244 33.6569 25.1812 32 25.1812H7.15381ZM4.15381 25.1812V3.41199C4.15381 1.75513 5.49696 0.411987 7.15381 0.411987H31.7278C32.7886 0.411987 33.806 0.833415 34.5562 1.58356L34.6978 1.7252L34.711 1.73827L46.6738 13.7011L46.6868 13.7141L46.8284 13.8558C47.5786 14.606 48 15.6234 48 16.6842V54.8296C48 56.4865 46.6569 57.8296 45 57.8296H7.15381C5.49696 57.8296 4.15381 56.4865 4.15381 54.8296V42.1812H3C1.34315 42.1812 0 40.8381 0 39.1812V28.1812C0 26.5244 1.34315 25.1812 3 25.1812H4.15381ZM34.2968 14.1153V5.5668L42.8453 14.1153H34.2968Z"
                    fill={palette.primary.main}
                    fillRule="evenodd"
                    opacity="0.7"
                />
                <path
                    d="M7.73319 29.7812C10.0492 29.7812 11.7772 31.6172 11.7772 33.9812C11.7772 36.3452 10.0492 38.1812 7.73319 38.1812H4.39719V29.7812H7.73319ZM7.73319 36.5972C9.18519 36.5972 10.1932 35.5292 10.1932 33.9812C10.1932 32.4332 9.18519 31.3652 7.73319 31.3652H6.05319V36.5972H7.73319ZM16.9239 38.3492C14.4999 38.3492 12.5679 36.4292 12.5679 33.9812C12.5679 31.5212 14.4999 29.6132 16.9239 29.6132C19.3479 29.6132 21.2919 31.5212 21.2919 33.9812C21.2919 36.4292 19.3479 38.3492 16.9239 38.3492ZM16.9239 36.7292C18.4479 36.7292 19.6359 35.5892 19.6359 33.9812C19.6359 32.3612 18.4479 31.2212 16.9239 31.2212C15.3999 31.2212 14.2119 32.3612 14.2119 33.9812C14.2119 35.5892 15.3999 36.7292 16.9239 36.7292ZM26.4398 38.3492C23.8958 38.3492 22.0718 36.4292 22.0718 33.9812C22.0718 31.5212 23.8958 29.6132 26.4398 29.6132C27.9758 29.6132 29.3318 30.3932 30.0518 31.5932L28.6238 32.4212C28.2158 31.6772 27.3998 31.2212 26.4398 31.2212C24.7958 31.2212 23.7158 32.3612 23.7158 33.9812C23.7158 35.5892 24.7958 36.7292 26.4398 36.7292C27.3998 36.7292 28.2278 36.2732 28.6238 35.5412L30.0518 36.3692C29.3438 37.5692 27.9878 38.3492 26.4398 38.3492Z"
                    fill={palette.background.default}
                />
            </svg>
        </IconWrapper>
    );
}
