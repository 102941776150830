import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';

More(Highcharts);

// WP NOTE: Do not anything theme-dependent in here.
// Theme-dependent options should be set in the ChartOptionsProvider.
Highcharts.setOptions({
    accessibility: {
        enabled: false,
    },
    lang: {
        thousandsSep: ',',
    },
});
