import { Fragment } from 'react';
import { Flex } from '../Core/Flex';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';
import { usePage } from '../hooks/usePage';
import { ChatCpgBubble } from './ChatCpgBubble';
import { ChatStreamBubble } from './ChatStreamBubble';
import { FileStreamBubble } from './FileStreamBubble';
import { UserBubble } from './UserBubble';
import { ZeroStateChat } from './ZeroStateChat';

interface ChatHistoryProps {
    style?: React.CSSProperties;
}

export function ChatHistory({ style }: ChatHistoryProps) {
    const { isStreaming } = usePage();
    const { historyRef, viewConversation } = useChatBoxForm();

    const { conversation, chatScrollPosition, setChatScrollPosition } = useChatBox();

    if (!viewConversation || isStreaming || conversation.length === 0) {
        return <ZeroStateChat />;
    }

    return (
        <Flex
            direction="column-reverse"
            gap="1rem"
            onScroll={({ target }) => {
                setChatScrollPosition((target as Element).scrollTop);
            }}
            ref={historyRef}
            style={{
                minHeight: 0,
                overflow: 'auto',
                scrollBehavior: 'smooth',
                // When we are at the bottom, we want to allow the
                // chat keep shifting the scroll to be at the bottom.
                // When overflowAnchor is set to 'auto' (default),
                // the scroll may suddenly anchor to the current
                // position, causing the overflow to start printing
                // below the fold. BUT if the user scrolls up, we
                // want to stop the chat from shifting so reset to
                // 'auto'.
                overflowAnchor: chatScrollPosition > 0 ? 'none' : 'auto',
                ...style,
            }}
            // We need the scroll position state for the overflowAnchor style
            width="100%"
        >
            {conversation.map((item) => {
                if (item.type === 'file') {
                    const { id, name, status, summary, timestamp } = item;
                    return (
                        <Fragment key={id}>
                            {status === 'error' || status === 'complete'
                                ? (
                                    <ChatCpgBubble
                                        chips={summary?.questions}
                                        error={status === 'error'}
                                        fileName={name}
                                        id={id}
                                        text={summary?.summary}
                                        timestamp={timestamp}
                                    />
                                )
                                : (
                                    <FileStreamBubble fileName={name} />
                                )}

                            <UserBubble
                                fileName={name}
                                id={id}
                                timestamp={timestamp}
                            />
                        </Fragment>
                    );
                } else {
                    const { id, sender, status, text, timestamp } = item;

                    if (status === 'generating') {
                        return <ChatStreamBubble key={id} />;
                    }

                    return sender === 'HUMAN'
                        ? (
                            <UserBubble
                                id={id}
                                key={id}
                                text={text}
                                timestamp={timestamp}
                            />
                        )
                        : (
                            <ChatCpgBubble
                                error={status === 'error'}
                                id={id}
                                key={id}
                                text={text}
                                timestamp={timestamp}
                            />
                        );
                }
            })}
        </Flex>
    );
}
