import { Dispatch, SetStateAction, createContext } from 'react';

export type AlertLevel = 'info' | 'warning' | 'success' | 'error';

export interface AlertProps {
    onDismiss?(): void;
    severity?: AlertLevel;
    showX?: boolean;
    text: string;
    title?: string;
}

export interface AlertItem extends AlertProps {
    id?: string;
    timeout?: number;
}

export interface ToasterContextState {
    removeAlert(id: string): void;
    sendAlert(props: AlertItem): void;
    setAlerts: Dispatch<SetStateAction<AlertItem[]>>;
}

export const ToasterContext = createContext<ToasterContextState>({
    removeAlert: () => null,
    sendAlert: () => null,
    setAlerts: () => null,
});
