import { FormGroup, RadioGroup } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';

export type FormGroupType = 'checkbox' | 'radio';

interface GroupComponentProps {
    style?: CSSProperties;
    type: FormGroupType;
}

const FormGroupStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'auto',
    width: '100%',
};

export function FormGroupSwitch({ children, style, type }: PropsWithChildren<GroupComponentProps>) {
    return type === 'checkbox'
        ? (
            <FormGroup
                style={{
                    ...FormGroupStyles,
                    ...style,
                }}
            >
                {children}
            </FormGroup>
        )
        : (
            <RadioGroup
                style={{
                    ...FormGroupStyles,
                    ...style,
                }}
            >
                {children}
            </RadioGroup>
        );
}
