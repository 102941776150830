import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LaunchIcon from '@mui/icons-material/Launch';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Checkbox, IconButton, useTheme } from '@mui/material';
import { PopulatedPage, TabAttributes } from '@tickr/sequelize-models';
import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import { index } from 'd3';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { TickrErrorBoundary } from '../Core/TickrErrorBoundary';
import { Typography } from '../Core/Typography';
import { Header } from '../Navigation/Header';
import { DeletePageDialog } from '../Page/DeletePageDialog';
import { PageSettingsDialog } from '../Page/PageSettingsDialog';
import { PageLayout } from '../PageLayout/PageLayout';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { HeadCell, TickrHeadCell } from '../Table/TickrHeadCell';
import { TickrTable } from '../Table/TickrTable';
import { TickrTableBody } from '../Table/TickrTableBody';
import { TickrTableCell } from '../Table/TickrTableCell';
import { TickrTableContainer } from '../Table/TickrTableContainer';
import { TickrTableHead } from '../Table/TickrTableHead';
import { TickrTableRow } from '../Table/TickrTableRow';
import { TickrTableSortLabel } from '../Table/TickrTableSortLabel';
import { TickrToolbar } from '../Table/TickrToolbar';
import { useTableSort } from '../Table/useTableSort';
import { HEADER_HEIGHT } from '../helpers/constants';
import { getFirstTabUuid } from '../helpers/getFirstTabId';
import { useCurrentProject } from '../hooks/useCurrentProject';
import { useDateFormatter } from '../hooks/useDateFormatter';
import { useForecastInProgressLink } from '../hooks/useForecastInProgressLink';

interface PageRow {
    createdAt: string;
    id: string;
    page: PopulatedPage;
    solution: SolutionType;
    tabs: TabAttributes[] | null;
    title: string;
    updatedAt: string;
}

export function MyWork() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();
    const formatDate = useDateFormatter();
    const { currentProject } = useCurrentProject();
    const forecastInProgressLink = useForecastInProgressLink();
    const { deletePage } = useCollectionMutations();

    const [selected, setSelected] = useState<readonly string[]>([]);

    const solutionTypeMap: Record<SolutionType, string> = useMemo(
        () => ({
            forecast: t('forecast.smartForecast'),
            optimizePricing: t('solution.optimizePricing'),
            outOfStockPredictions: t('solution.outOfStockPredictions'),
            scorecard: t('solution.scorecard'),
            substitutions: t('solution.substitutions'),
        }),
        [t]
    );

    const rows = useMemo(
        () => currentProject.pages
            .filter((page) => page.solutionType !== 'scorecard' && page.solutionType !== 'outOfStockPredictions')
            .map((page) => ({
                createdAt: page.createdAt,
                id: page.uuid,
                page,
                solution: solutionTypeMap[page.solutionType],
                tabs: page.tabs,
                title: page.title,
                updatedAt: page.updatedAt,
            })),
        [currentProject.pages, solutionTypeMap]
    );

    const { order, setOrder, orderBy, setOrderBy, sortedRows } = useTableSort({
        data: rows,
        initOrder: 'desc',
        initOrderBy: 'updatedAt',
    });

    const headCells: HeadCell<PageRow>[] = useMemo(() => [
        {
            label: t('util.page.title'),
            value: 'title',
        },
        {
            label: t('chatCpg.solution'),
            value: 'solution',
        },
        {
            label: t('util.created'),
            value: 'createdAt',
        },
        {
            label: t('util.updated'),
            value: 'updatedAt',
        },
    ], [t]);

    const [deletePageUuid, setDeletePageUuid] = useState('');
    const [deleteSelectedOpen, setDeleteSelectedOpen] = useState(false);
    const [pageSettingsUuid, setPageSettingsUuid] = useState('');

    return (
        <PageLayout pageTitle={t('page.myWork')}>
            <Header />
            <TickrErrorBoundary message={t('errorBoundary.page', { pageName: t('page.myWork') })}>
                <Flex
                    direction="column"
                    gap="1rem"
                    height={`calc(100% - ${HEADER_HEIGHT}px)`}
                    padding="0 16px 16px 16px"
                    width="100%"
                >
                    <TickrTableContainer>
                        <TickrToolbar>
                            <Flex
                                align="center"
                                justify="space-between"
                                width="100%"
                            >
                                <Typography as="h2">{t('util.generatedAnalyses')}</Typography>
                                {selected.length > 0 && (
                                    <>
                                        <Button
                                            color="error"
                                            onClick={() => setDeleteSelectedOpen(true)}
                                            size="small"
                                            startIcon={<DeleteOutlineIcon />}
                                            variant="outlined"
                                        >
                                            {t('util.deleteSelected')}
                                        </Button>
                                        <DeletePageDialog
                                            multiple={selected.length > 1}
                                            onCancel={() => setDeleteSelectedOpen(false)}
                                            onDelete={() => {
                                                selected.forEach((pageUuid) => {
                                                    deletePage(pageUuid);
                                                });

                                                setSelected([]);
                                                setDeleteSelectedOpen(false);
                                            }}
                                            open={deleteSelectedOpen}
                                        />
                                    </>
                                )}
                            </Flex>
                        </TickrToolbar>
                        {sortedRows.length > 0 && (
                            <TickrTable>
                                <TickrTableHead top={64}>
                                    <TickrTableRow background={palette.background.default}>
                                        <TickrHeadCell padding="checkbox">
                                            <Checkbox
                                                checked={selected.length === rows.length}
                                                indeterminate={selected.length > 0 && selected.length < rows.length}
                                                inputProps={{ 'aria-label': 'select all solutions' }}
                                                onChange={(e) => {
                                                    e.target.checked
                                                        ? setSelected(rows.map(({ id }) => id))
                                                        : setSelected([]);
                                                }}
                                            />
                                        </TickrHeadCell>
                                        {headCells.map(({ value, label, align }) => (
                                            <TickrHeadCell
                                                align={align}
                                                key={value}
                                                order={order}
                                                orderBy={orderBy}
                                                value={value}
                                            >
                                                <TickrTableSortLabel
                                                    id={value}
                                                    label={label}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    setOrder={setOrder}
                                                    setOrderBy={setOrderBy}
                                                />
                                            </TickrHeadCell>
                                        ))}
                                        <TickrTableCell />
                                    </TickrTableRow>
                                </TickrTableHead>
                                <TickrTableBody
                                    style={{
                                        zIndex: 0,
                                        position: 'relative',
                                    }}
                                >
                                    {sortedRows.map(({ createdAt, id, page, solution, tabs, title, updatedAt }) => {
                                        const labelId = `my-work-table-checkbox-${index}`;
                                        const isSelected = selected.includes(id);
                                        const isScorecard = page.solutionType === 'scorecard';

                                        return (
                                            <TickrTableRow
                                                aria-checked={isSelected}
                                                hover
                                                key={id}
                                                onClick={() => {
                                                    setSelected((selected) => {
                                                        if (selected.includes(id)) {
                                                            return selected.filter((selectedId) => selectedId !== id);
                                                        }

                                                        return [...selected, id];
                                                    });
                                                }}
                                                role="checkbox"
                                                selected={isSelected}
                                                sx={{ cursor: 'pointer' }}
                                                tabIndex={-1}
                                            >
                                                <TickrTableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TickrTableCell>
                                                <TickrTableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                >
                                                    {title}
                                                </TickrTableCell>
                                                <TickrTableCell>{solution}</TickrTableCell>
                                                <TickrTableCell>{formatDate(createdAt, 'full-date-time')}</TickrTableCell>
                                                <TickrTableCell>{formatDate(updatedAt, 'full-date-time')}</TickrTableCell>
                                                <TickrTableCell
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                    sx={{
                                                        cursor: 'default',
                                                    }}
                                                >
                                                    <Flex align="center" gap="0.1rem">
                                                        <IconButton
                                                            color="secondaryText"
                                                            disabled={isScorecard || selected.length > 0}
                                                            onClick={() => setPageSettingsUuid(page.uuid)}
                                                            size="small"
                                                        >
                                                            <SettingsIcon fontSize="inherit" />
                                                        </IconButton>
                                                        <PageSettingsDialog
                                                            onClose={() => setPageSettingsUuid('')}
                                                            open={pageSettingsUuid === page.uuid}
                                                            page={page}
                                                        />
                                                        <IconButton
                                                            color="primary"
                                                            disabled={selected.length > 0}
                                                            onClick={() => {
                                                                navigate(
                                                                    page.inProgress
                                                                        ? forecastInProgressLink
                                                                        : isScorecard
                                                                            ? '/scorecard'
                                                                            : page.layoutType === 'talkingPoints' && tabs
                                                                                ? `/page/${page.uuid}/tab/${getFirstTabUuid(tabs)}`
                                                                                : `/page/${page.uuid}`
                                                                );
                                                            }}
                                                            size="small"
                                                        >
                                                            <LaunchIcon fontSize="inherit" />
                                                        </IconButton>
                                                        <IconButton
                                                            disabled={isScorecard || selected.length > 0}
                                                            onClick={() => deletePage(page.uuid)}
                                                            size="small"
                                                        >
                                                            <DeleteOutlineIcon color="error" fontSize="inherit" />
                                                        </IconButton>
                                                        {/* <DeletePageDialog
                                                            onCancel={() => setDeletePageUuid('')}
                                                            onDelete={() => {
                                                                deletePage(page.uuid);
                                                                setDeletePageUuid('');
                                                            }}
                                                            open={deletePageUuid === page.uuid}
                                                        /> */}
                                                    </Flex>
                                                </TickrTableCell>
                                            </TickrTableRow>
                                        );
                                    })}
                                </TickrTableBody>
                            </TickrTable>
                        )}
                        {sortedRows.length === 0 && (
                            <Typography
                                style={{
                                    padding: '1rem',
                                }}
                                variant="p-16-r"
                            >
                                {t('util.noAnalyses')}
                            </Typography>
                        )}
                    </TickrTableContainer>
                </Flex>
            </TickrErrorBoundary>
        </PageLayout>
    );
}
