import CategoryIcon from '@mui/icons-material/Category';
import ClearIcon from '@mui/icons-material/Clear';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Switch, useTheme } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { truncateCSS } from '../Core/truncateCSS';
import { p14b } from '../Core/typographyVariants';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { ExpandIcon } from '../Icons/ExpandIcon';
import { PageSettingsDialog } from '../Page/PageSettingsDialog';
import { useDashboardSettings } from '../Providers/DashboardSettingsProvider.tsx/useDashboardSettings';
import { useTogglePaletteMode } from '../Providers/MuiThemeProvider/useTogglePaletteMode';
import { useUser } from '../Providers/UserProvider/useUser';
import { usePage } from '../hooks/usePage';
import { ProductAttributesDialog } from './ProductAttributesDialog';
import { navIcons } from './navIcons';
import { useAppPageLinks } from './useAppPageLinks';
import { useDashboardLinks } from './useDashboardLinks';

interface SidePanelDrawerProps {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export function SidePanelDrawer({ open, onClose, onOpen }: SidePanelDrawerProps) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { pathname } = useLocation();
    const { keycloak } = useKeycloak();

    const { isAdmin, isDemo } = useUser();
    const { page, isScorecard, isOutOfStockPredictions } = usePage();
    const { presentationMode, togglePresentationMode } = useDashboardSettings();
    const togglePaletteMode = useTogglePaletteMode();

    const [recentAnalysesOpen, setRecentAnalysesOpen] = useState(true);
    const [pageSettingsOpen, setPageSettingsOpen] = useState(false);
    const [productAttributesOpen, setProductAttributesOpen] = useState(false);

    const appPageLinks = useAppPageLinks();
    const dashboardLinks = useDashboardLinks();

    return (
        <SwipeableDrawer
            onClose={onClose}
            onOpen={onOpen}
            open={open}
        >
            <Box
                height="100%"
                sx={{
                    background: ({ palette }) => palette.background.secondary,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0.4rem',
                }}
                width="min(90vw, 400px)"
            >
                <Flex
                    align="center"
                    gap="1rem"
                >
                    <IconButton onClick={onClose}>
                        <ClearIcon />
                    </IconButton>
                    <Typography variant="h-18-b">TICKR</Typography>
                </Flex>

                <List>
                    {appPageLinks.map(({ name, pageType, selected, to }) => (
                        <SidePanelButton
                            icon={navIcons[pageType]}
                            key={pageType}
                            onClick={onClose}
                            primary={name}
                            selected={selected}
                            to={to}
                        />
                    ))}
                </List>
                {dashboardLinks.length > 0 && (
                    <>
                        <Divider />
                        <SidePanelButton
                            icon={<ScheduleIcon />}
                            onClick={() => setRecentAnalysesOpen((open) => !open)}
                            primary={t('home.recentAnalyses')}
                            secondary={dashboardLinks.length === 0 ? t('sidePanel.noRecentAnalyses') : undefined}
                        >
                            <ExpandIcon open={recentAnalysesOpen} />
                        </SidePanelButton>
                        <Collapse
                            in={recentAnalysesOpen}
                            sx={{
                                overflow: 'scroll',
                                padding: '2px',
                            }}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List>
                                {dashboardLinks.map(({ pageType, pageUuid, name, selected, to }) => (
                                    <SidePanelButton
                                        icon={navIcons[pageType]}
                                        key={pageUuid}
                                        nested
                                        onClick={onClose}
                                        primary={name}
                                        selected={selected}
                                        to={to}
                                    />
                                ))}
                            </List>
                        </Collapse>
                    </>
                )}
                <Divider />
                <List>
                    {isDemo && (
                        <SidePanelButton
                            icon={<ChatCpgIcon size="24px" />}
                            onClick={onClose}
                            primary={t('chatCpg.newUser.welcome')}
                            selected={pathname === '/welcome/chatcpg'}
                            to="/welcome/chatcpg"
                        />
                    )}
                    {page && (
                        <>
                            {!isOutOfStockPredictions && !isScorecard && (
                                <SidePanelButton
                                    icon={<SettingsIcon />}
                                    onClick={() => setPageSettingsOpen(true)}
                                    primary={t('sidePanel.pageSettings')}
                                />
                            )}
                            <PageSettingsDialog
                                onClose={() => setPageSettingsOpen(false)}
                                open={pageSettingsOpen}
                            />
                            <SidePanelToggle
                                checked={presentationMode}
                                icon={<CoPresentIcon />}
                                label="presentation-mode-label"
                                onChange={togglePresentationMode}
                                text={t('sidePanel.presentationMode')}
                            />
                        </>
                    )}
                    <SidePanelToggle
                        checked={palette.mode === 'dark'}
                        icon={<DarkModeIcon />}
                        label="dark-mode-label"
                        onChange={togglePaletteMode}
                        text={t('sidePanel.darkMode')}
                    />
                    {isAdmin && (
                        <>
                            <SidePanelButton
                                icon={<CategoryIcon />}
                                onClick={() => setProductAttributesOpen(true)}
                                primary={t('productAttributes')}
                            />
                            <ProductAttributesDialog
                                onClose={() => setProductAttributesOpen(false)}
                                open={productAttributesOpen}
                            />
                        </>
                    )}
                    <SidePanelButton
                        icon={<PowerSettingsNewIcon />}
                        onClick={() => {
                            onClose();
                            keycloak.logout();
                        }}
                        primary={t('auth.logout')}
                    />
                </List>
            </Box>
        </SwipeableDrawer>
    );
}

const sharedTypographyProps = {
    ...p14b,
    ...truncateCSS,
};

interface SidePanelButtonProps {
    children?: ReactNode;
    disabled?: boolean;
    icon: ReactNode;
    nested?: boolean;
    onClick?: () => void;
    primary: string;
    secondary?: string;
    selected?: boolean;
    to?: string;
}

function SidePanelButton({
    children,
    disabled,
    icon,
    onClick,
    nested,
    primary,
    secondary,
    selected,
    to,
}: SidePanelButtonProps) {
    return (
        <ListItem disablePadding>
            <ListItemButton
                {...(to && {
                    component: Link,
                    to,
                })}
                disabled={disabled}
                onClick={onClick}
                selected={selected}
                sx={{
                    borderRadius: ({ shape }) => `${shape.borderRadius}px`,
                    pl: nested ? 4 : 2,
                }}
            >
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={primary}
                    primaryTypographyProps={sharedTypographyProps}
                    secondary={secondary}
                />
                {children}
            </ListItemButton>
        </ListItem>
    );
}

interface SidePanelToggleProps {
    checked: boolean;
    icon: ReactNode;
    onChange: () => void;
    label: string;
    text: string;
}

function SidePanelToggle({
    checked,
    icon,
    onChange,
    label,
    text,
}: SidePanelToggleProps) {
    return (
        <ListItem sx={{ padding: '8px 16px' }}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText
                id={label}
                primary={text}
                primaryTypographyProps={sharedTypographyProps}
            />
            <Switch
                checked={checked}
                edge="end"
                inputProps={{
                    'aria-labelledby': label,
                }}
                onChange={onChange}
            />
        </ListItem>
    );
}
