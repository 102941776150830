import { CreateElementProps, CreatePageProps, CreateProjectProps, ElementAttributes, PageAttributes, PageTemplateProps, PopulatedPageWithElements, PopulatedPageWithPopulatedTabs, ProjectAttributes } from '@tickr/sequelize-models';
import { CreateTabProps, PopulatedTab, TabAttributes } from '@tickr/sequelize-models/src/models/tab.model';
import { createContext } from 'react';
import { PartialDeep } from 'type-fest';

export type UpdatePageProps = {
    page: PageAttributes;
} | {
    pageUuid: string;
    mergeProps: PartialDeep<PageAttributes>;
};

export interface DeleteElementProps {
    uuid: string;
    pageUuid?: string;
    tabUuid?: string;
}

export interface DeleteElementsProps {
    uuids: string[];
    pageUuid?: string;
    tabUuid?: string;
}

export interface CreateElementsProps {
    elementsToCreate: ElementAttributes[];
    pageUuid?: string;
    tabUuid?: string;
}

export type UpdateElementProps = {
    element: ElementAttributes;
} | {
    elementUuid: string;
    mergeProps: PartialDeep<ElementAttributes>;
    queryKey: string | null;
};

export type DuplicateElementProps = {
    element: ElementAttributes;
};

export interface UpdateElementsProps {
    elements: ElementAttributes[];
    pageUuid?: string;
    tabUuid?: string;
}

export interface UpdatePopulatedTabsProps {
    tabs: PopulatedTab[];
    pageUuid?: string;
}

export interface DeleteTabProps {
    uuid: string;
    pageUuid?: string;
    navToNearbyTab?: () => void;
}

export interface CollectionMutationsContextProps {
    createProject(props: CreateProjectProps): Promise<ProjectAttributes>;
    updateProject(project: ProjectAttributes): Promise<ProjectAttributes>;
    deleteProject(uuid: string): Promise<void>;

    createPage(page: CreatePageProps): Promise<PageAttributes>;
    createPageFromTemplate(props: PageTemplateProps): Promise<
    PopulatedPageWithElements | PopulatedPageWithPopulatedTabs
    >;

    updatePage(props: UpdatePageProps): Promise<PageAttributes>;
    deletePage(uuid: string): Promise<void>;
    duplicatePage(page: PageAttributes): Promise<PageAttributes>;

    createTab(tab: CreateTabProps): Promise<TabAttributes>;

    updateTab(tab: TabAttributes): Promise<TabAttributes>;
    updateTabs(props: TabAttributes[]): Promise<TabAttributes[]>;
    deleteTab(props: DeleteTabProps): Promise<string>;

    createElements(elements: CreateElementProps[]): Promise<ElementAttributes[]>;
    duplicateElement(element: ElementAttributes): Promise<ElementAttributes[]>;
    updateElement(props: UpdateElementProps): Promise<ElementAttributes>;
    updateElements(props: UpdateElementsProps): Promise<ElementAttributes[]>;
    deleteElement(props: DeleteElementProps): Promise<void>;
    deleteElements(props: DeleteElementsProps): Promise<void>;
}

export const CollectionMutationsContext = createContext<CollectionMutationsContextProps>({
    createProject: async () => ({} as ProjectAttributes),
    updateProject: async () => ({} as ProjectAttributes),
    deleteProject: async () => void 0,

    createPage: async () => ({} as PageAttributes),
    createPageFromTemplate: async () => ({} as PopulatedPageWithElements | PopulatedPageWithPopulatedTabs),

    updatePage: async () => ({} as PageAttributes),
    deletePage: async () => void 0,
    duplicatePage: async () => ({} as PageAttributes),

    createTab: async () => ({} as TabAttributes),
    updateTab: async () => ({} as TabAttributes),
    updateTabs: async () => ({} as TabAttributes[]),
    deleteTab: async () => '',

    createElements: async () => [],
    duplicateElement: async () => [],
    updateElement: async () => ({} as ElementAttributes),
    updateElements: async () => [],
    deleteElement: async () => void 0,
    deleteElements: async () => void 0,
});
