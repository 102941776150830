import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { Flex } from '../../Core/Flex';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';
import { CollapsibleFilter } from './CollapsibleFilter';
import { FilterSwitch } from './FilterSwitch';
import { retailerFilter } from './retailerFilter';

interface FilterMenuContentProps {
    allowedFilters?: Record<string, string[]>;
    linkedDatasources?: string[];
    missingFilters?: (keyof AppFilters)[];
    emptyFilters?: (keyof AppFilters)[];
    onSave?(filters: AppFilters): Promise<void>;
    typeOverrides?: Record<string, FilterDefinition['type']>;
}

export function FilterMenuContent({
    allowedFilters,
    linkedDatasources,
    missingFilters,
    emptyFilters,
    onSave,
    typeOverrides,
}: FilterMenuContentProps) {
    // const { datasourceDisplayNames, universalFilters, datasourceFilters, productAttributes } =
    //   useAvailableFilters();

    // const { myProductsValues, competitorsValues } = useStagedPAFilters();
    // const { stagedFilters } = useStagedFilters();

    // const universalDisplayFilters = FILTER_ORDER.universal.filter((filterId) =>
    //   Object.keys(stagedFilters).includes(filterId)
    // ) as (keyof AppFilters)[];

    // const productAttributeDisplayFilters = Object.keys(productAttributes).filter((filterId) =>
    //   Object.keys(stagedFilters).includes(filterId)
    // ) as (keyof AppFilters)[];

    return (
        <Flex
            direction="column"
            gap="1.4rem"
            padding="0 0.8rem"
            style={{ overflowY: 'auto' }}
            width="100%"
        >
            {/* <ChooseFilters
        allowedFilters={allowedFilters}
        linkedDatasources={linkedDatasources}
        missingFilters={missingFilters}
        typeOverrides={typeOverrides}
      /> */}
            <Flex
                direction="column"
                gap="0.5rem"
                width="100%"
            >
                <CollapsibleFilter filterDefinition={retailerFilter} initiallyOpen>
                    <FilterSwitch filterDefinition={retailerFilter} />
                </CollapsibleFilter>
                {/* <CollapsibleFilter
              key={filterId}
              filterDefinition={filterDefinition}
              initiallyOpen={Object.keys(stagedFilters).length === 1}
              isEmpty={emptyFilters?.includes(filterId) && !stagedFilters[filterId]}
              onSave={onSave}
            >
              <FilterSwitch filterDefinition={filterDefinition} />
            </CollapsibleFilter> */}
                {/* {!isEmpty(universalDisplayFilters) && <Typography variant="c-11">Universal</Typography>} */}
                {/* {universalDisplayFilters.map((filterId) => {
          const filterDefinition = cloneDeep(universalFilters[filterId]);

          if (typeOverrides?.[filterId]) {
            filterDefinition.type = typeOverrides[filterId];
          }

          if (filterId === 'cpg_owned_products') {
            filterDefinition.values = myProductsValues;
          } else if (filterId === 'cpg_competitor_products') {
            filterDefinition.values = competitorsValues;
          }

          return (
            <CollapsibleFilter
              key={filterId}
              filterDefinition={filterDefinition}
              initiallyOpen={Object.keys(stagedFilters).length === 1}
              isEmpty={emptyFilters?.includes(filterId) && !stagedFilters[filterId]}
              onSave={onSave}
            >
              <FilterSwitch filterDefinition={filterDefinition} />
            </CollapsibleFilter>
          );
        })} */}
            </Flex>
            {/* {FILTER_ORDER.datasources.map((datasourceId) => {
        const datasourceDisplayFilters = FILTER_ORDER[datasourceId].filter((filterId) =>
          Object.keys(stagedFilters).includes(filterId)
        ) as (keyof AppFilters)[];

        const relevantDatasourceFilters = datasourceFilters[datasourceId];

        if (isEmpty(datasourceDisplayFilters) || !relevantDatasourceFilters)
          return <Fragment key={`${datasourceId}-filter-content`} />;

        return (
          <Flex direction="column" gap="0.5rem" width="100%" key={`${datasourceId}-filter-content`}>
            <Typography variant="c-11">{datasourceDisplayNames[datasourceId]}</Typography>
            {datasourceDisplayFilters.map((filterId) => (
              <CollapsibleFilter
                key={filterId}
                filterDefinition={relevantDatasourceFilters[filterId] as FilterDefinition}
                isEmpty={emptyFilters?.includes(filterId)}
                onSave={onSave}
              >
                <FilterSwitch
                  filterDefinition={relevantDatasourceFilters[filterId] as FilterDefinition}
                />
              </CollapsibleFilter>
            ))}
          </Flex>
        );
      })} */}
            {/* {productAttributeDisplayFilters.length ? (
        <Flex direction="column" gap="0.5rem" width="100%">
          {!isEmpty(productAttributes) && (
            <Typography variant="c-11">{datasourceDisplayNames['productAttributes']}</Typography>
          )}
          {productAttributeDisplayFilters
            .filter((filterId) => {
              return Object.keys(stagedFilters).includes(filterId);
            })
            .map((filterId) => {
              const filterDefinition = cloneDeep(productAttributes[filterId] as FilterDefinition);

              if (typeOverrides?.[filterId]) {
                filterDefinition.type = typeOverrides[filterId];
              }

              return (
                <CollapsibleFilter
                  key={filterId}
                  filterDefinition={filterDefinition}
                  initiallyOpen={Object.keys(stagedFilters).length === 1}
                  isEmpty={emptyFilters?.includes(filterId) && !stagedFilters[filterId]}
                  onSave={onSave}
                >
                  <FilterSwitch filterDefinition={filterDefinition} />
                </CollapsibleFilter>
              );
            })}
        </Flex>
      ) : (
        <Fragment key={`${'productAttribute'}-filter-content`} />
      )} */}
        </Flex>
    );
}
