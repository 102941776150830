import { createContext } from 'react';

export interface AppContextProps {
    dashboardApiUrl?: string;
    envMode?: 'prod' | 'qa';
    publicApiUrl?: string;
    tidsApiUrl?: string;
    pricingApiRoute?: string;
    chatApiRoute?: string;
}

export const AppContext = createContext<AppContextProps>({});
