import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useUser } from '../Providers/UserProvider/useUser';
import { AppPage } from './navIcons';

export interface PageLink {
    name: string;
    pageType: SolutionType | AppPage;
    pageUuid?: string;
    selected: boolean;
    to: string;
}

export function useAppPageLinks() {
    const { t } = useTranslation();
    const { hasSmartScorecard } = useUser();
    const { pathname } = useLocation();

    const appPageLinks: PageLink[] = [
        {
            name: t('page.home'),
            pageType: 'home',
            selected: pathname === '/home',
            to: '/home',
        },
        {
            name: t('page.myWork'),
            pageType: 'myWork',
            selected: pathname === '/my-work',
            to: '/my-work',
        },
        {
            name: t('solution.outOfStockPredictions'),
            pageType: 'outOfStockPredictions',
            selected: pathname === '/out-of-stock-predictions',
            to: '/out-of-stock-predictions',
        },
        ...(hasSmartScorecard
            ? [
                {
                    name: t('page.smartScorecard'),
                    pageType: 'scorecard',
                    selected: pathname === '/scorecard',
                    to: '/scorecard',
                },
            ] as PageLink[]
            : []),
    ];

    return appPageLinks;
}
