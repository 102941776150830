import { styled, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ExpandIcon } from '../Icons/ExpandIcon';
import { useHandleOutsideClick } from '../hooks/useHandleOutsideClick';

export interface SelectDropdownValue {
    title: string;
    value: string | number;
}

interface SelectDropdownContentProps {
    disabled?: boolean;
    onChange?: (value: string | number) => void;
    placeholder?: string;
    selected?: string | number;
    titleColor?: string;
    values: SelectDropdownValue[];
    width?: string;
}

export function SelectDropdownContent({
    disabled = false,
    onChange,
    placeholder,
    selected = '',
    values,
    width = '100%',
}: SelectDropdownContentProps) {
    const { palette } = useTheme();

    const [open, setOpen] = useState(false);

    const selectedValue = values.find(({ value }) => value === selected);
    const selectedTitle = selectedValue?.title;

    const optionsWrapperRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);

    useHandleOutsideClick({
        ref: optionsWrapperRef,
        ignoreEls: [selectRef],
        onClose: () => setOpen(false),
    });

    useEffect(() => {
        if (open) {
            const modalElement = optionsWrapperRef.current;

            const focusableElements: HTMLElement[] = Array.from(modalElement?.querySelectorAll(
                'button, [tabindex]:not([tabindex="-1"])'
            ) ?? []);

            if (focusableElements.length === 0) {
                return;
            }

            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event: KeyboardEvent) => {
                if (event.key === 'Tab') {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement?.focus();
                    } else if (
                        !event.shiftKey &&
                  document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement?.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    setOpen(false);
                }
            };

            modalElement?.addEventListener('keydown', handleTabKeyPress);
            modalElement?.addEventListener('keydown', handleEscapeKeyPress);

            return () => {
                modalElement?.removeEventListener('keydown', handleTabKeyPress);
                modalElement?.removeEventListener('keydown', handleEscapeKeyPress);
            };
        }
    }, [open]);

    return (
        <Flex>
            <StyledSelect
                align="center"
                as="button"
                disabled={disabled}
                justify="space-between"
                onClick={() => setOpen((isOpen) => !isOpen)}
                ref={selectRef}
                width={width}
            >
                <Typography
                    title={selectedTitle ?? placeholder}
                    truncate
                    variant={selectedTitle ? 'p-16-r' : 'p-16-i'}
                >
                    {selectedTitle ?? placeholder}
                </Typography>
                <ExpandIcon open={open} />
            </StyledSelect>
            {open && (
                <StyledOptionsWrapper
                    direction="column"
                    padding="0.5rem"
                    ref={optionsWrapperRef}
                    width={width}
                >
                    {values.map(
                        ({
                            title,
                            value,
                        }) => (
                            <StyledOption
                                as="button"
                                key={value}
                                onClick={() => {
                                    onChange?.(value);
                                    setOpen(false);
                                }}
                                role="option"
                                value={value}
                            >
                                <Typography
                                    color={value === selected ? palette.indicator.main : palette.text.primary}
                                    title={title}
                                    truncate
                                    variant="p-16-r"
                                >
                                    {title}
                                </Typography>
                            </StyledOption>
                        )
                    )}
                </StyledOptionsWrapper>
            )}
        </Flex>
    );
}

const StyledSelect = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'as' }
)(({ theme }) => `
    height: 34px;
    background-color: ${theme.palette.background.secondaryMask};
    padding: 12px;
    border: 1px solid ${theme.palette.middleGray.main};
    border-radius: ${theme.shape.borderRadius}px;
    cursor: pointer;
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 11px;

    :focus {
        outline: 1px solid ${theme.palette.primary.main};
    }

    select > option {
        background-color: ${theme.palette.background.secondary};
    }

    :disabled {
        opacity: 0.5;
        cursor: default;
    }
`);

const StyledOptionsWrapper = styled(
    Flex,
    { shouldForwardProp: (prop) => !!prop }
)(({ theme }) => `
    top: 35px;
    position: absolute;
    background: ${theme.palette.background.secondary};
    border: 1px solid ${theme.palette.background.secondary};
    border-radius: ${theme.shape.borderRadius}px;
    z-index: 10;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-height: 400px;
    overflow: scroll;
`);

const StyledOption = styled('button')(({ theme }) => `
    display: flex;
    gap: 0.6rem;
    text-align: left;
    width: 100%;
    padding: 0.5rem;
    border-radius: ${theme.shape.borderRadius}px;
    :hover {
        background: ${theme.palette.background.secondary};
    }
    :focus-visible {
        outline: 1px solid ${theme.palette.info.main};
    }
`);
