import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { baseTheme } from './baseTheme';

export const lightTheme = createTheme(deepmerge(baseTheme,
    {
        palette: {
            mode: 'light',
            primary: {
                main: '#20b9ff', // malibu
            },
            secondary: {
                main: '#c74ddc', // lavender
                // light: '#ec8afd',
                // dark: '#8b359a',
                // contrastText: '#ffffff',
            },
            background: {
                default: '#f8fcff',
                paper: '#ffffff', // white
                secondary: '#c0d0d8', // geyser
                secondaryMask: 'rgba(0,0,0,0.1)',
                tertiary: '#545763', // trout
            },
            text: {
                primary: '#15161d',
                secondary: 'rgba(24,13,13,0.75)',
                // disabled: '#15161d',
            },
            error: {
                main: 'rgba(184,41,50,0.9)',
                contrastText: '#020202',
            },
            // warning: {
            //     main: '#ff584b',
            // },
            info: {
                main: '#545763', // trout
            },
            success: {
                main: '#4cbe4c',
            },
            divider: '#545763', // trout

            /* CUSTOM COLORS, see @tickr/ui/types/index.d.ts */
            confidenceInterval: {
                main: '#8f4aca4c', // confidence-interval
            },
            highlight: {
                main: '#bfee89', // sulu
            },
            indicator: {
                main: '#ffcd1a', // brand-secondary
                contrastText: '#000000',
            },
            middleGray: {
                main: '#545763', // trout
                contrastText: '#ffffff',
            },
            negative: {
                main: '#ff1d4d', // negative aka brand-primary
                contrastText: '#ffffff',
            },
            tooltip: {
                main: 'rgba(31, 77, 98, 1)',
            },

            primaryText: {
                main: '#15161d',
            },
            secondaryText: {
                main: 'rgba(24,13,13,0.75)',
            },
            contrastText: {
                main: '#ffffff',
            },
            turquoise: {
                main: '#45ddcf',
            },
            salmon: {
                main: '#ff8c5b',
            },
            texasRose: {
                main: '#feb74e',
            },
            portica: {
                main: '#f6e96e',
            },
            sulu: {
                main: '#bfee89',
            },
            cornflowerBlue: {
                main: '#4862ef',
            },
            tickleMePink: {
                main: '#fb7eb1',
            },

            gradient: {
                chatCpg: 'linear-gradient(90deg, #9FE1FF -26.92%, #5ECDFF -5.7%, #21B9FF 8.36%, #2E9CFA 22.93%, #3B80F5 36.9%, #4863F0 52.08%, #6D5CEA 65.49%, #9256E4 80.03%, #C74DDC 95%)',
                lavender: 'linear-gradient(124.78deg, #c74ddc 0%, #ec8afd 100%)',
                secondaryBackground: 'linear-gradient(-20deg, #c0d0d8 0%, #545763 100%)',
                sidePanel: 'linear-gradient(126.72deg, #c0d0d8 0%, #c0d0d8 100%)',
                slideCarouselRight: 'linear-gradient(270deg, #f8fcff 0%, rgba(21, 22, 29, 0) 100%)',
                slideCarouselLeft: 'linear-gradient(90deg, #f8fcff 0%, rgba(21, 22, 29, 0) 100%)',
            },
        },
    }));
