import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { MetaStream } from '../../ChatCpg/types/chatCpgTypes';
import { debugLogger } from '../../helpers/debugLogger';
import { shortenGtin } from '../../helpers/stringHelpers';
import { useCaptureTickrException } from '../useCaptureTickrException';
import { useChatCpgStore } from '../useChatCpgStore';
import { usePage } from '../usePage';
import { useReplaceReportingWeek } from '../useReplaceReportingWeek';
import { useReplaceTimestamp } from '../useReplaceTimestamp';

// interface ForecastCompleteData {
//     forecast: ForecastChartData;
//     summary_kind: 'forecast';
//     talking_points: {
//         forecast: {
//             scope_question_text: string;
//             insights_question_text: string;
//             headline: string;
//         };
//     };
// }

// type ForecastCompleteEvent = CompleteEvent<ForecastCompleteData>;

// interface ForecastStatusEvent {
//     type: 'status';
//     status: 'gathering-data' | 'data-gathered' | 'selecting-model' | 'model-selected' | 'generating-model' | 'model-generated' | 'fitting-model' | 'model-fit' | 'generating-forecast' | 'forecast-generated' | 'complete';
// }

// interface ForecastInsightEvent {
//     type: 'text';
//     text: string;
//     talking_point: 'talking-point-insight';
// }

// interface ForecastMetaDoneEvent {
//     meta: 'DONE';
//     talking_point: 'forecast';
//     type: 'text';
// }

// interface InsightMetaDoneEvent {
//     meta: 'DONE';
//     talking_point: 'talking-point-insight' | 'talking-point-scope';
//     type: 'text';
// }

// interface ForecastScopeEvent {
//     type: 'text';
//     text: string;
//     talking_point: 'talking-point-scope';
// }

// interface ForecastChartEvent {
//     data: ForecastChartData;
//     talking_point: 'forecast';
//     type: 'forecast-chart';
// }

type OutOfStockStreamEvent =
    | MetaStream;
    // | ForecastChartEvent
    // | ForecastCompleteEvent
    // | ForecastInsightEvent
    // | ForecastMetaDoneEvent
    // | ForecastScopeEvent
    // | ForecastStatusEvent
    // | InsightMetaDoneEvent;

export function useOnMessageOutOfStock() {
    const captureTickrException = useCaptureTickrException();
    const { pageUuid, summaryId } = usePage();

    const updateStreamText = useChatCpgStore((state) => state.updateStreamText);
    const replaceTimestamp = useReplaceTimestamp();
    const replaceReportingWeek = useReplaceReportingWeek();
    const formatText = (text?: string) => replaceTimestamp(replaceReportingWeek(shortenGtin(text)));

    return (
        event: EventSourceMessage,
        streamText: {
            insights: string;
        },
    ) => {
        if (event.event === 'ping' || event.data === '' || !pageUuid || !summaryId) return;

        let eventData: OutOfStockStreamEvent;

        try {
            eventData = JSON.parse(event.data);
        } catch (error) {
            captureTickrException(error);
            return;
        }

        debugLogger(eventData);

        if ('meta' in eventData) {
            // const forecastData: Partial<ForecastSummaryData> = {};

            switch (eventData.meta) {
                // case 'COMPLETE':
                //     if (!forecastSummaryData?.forecastChart) {
                //         forecastData.forecastChart = eventData.data.forecast;
                //     }

                //     if (!forecastSummaryData?.forecastInsight) {
                //         forecastData.forecastInsight =
                //            formatText(eventData.data.talking_points.forecast.insights_question_text);
                //     }

                //     if (!forecastSummaryData?.forecastScope) {
                //         forecastData.forecastScope =
                //             formatText(eventData.data.talking_points.forecast.scope_question_text);
                //     }

                //     if (!isEmpty(forecastData)) {
                //         dispatchStagedPage({
                //             type: 'commit',
                //             payload: {
                //                 settings: {
                //                     summary: {
                //                         forecast: forecastData,
                //                         isComplete: true,
                //                         isStreaming: false,
                //                     },
                //                 },
                //             },
                //         });
                //     }

                //     break;
                case 'DONE':
                    if ('type' in eventData) {
                        // switch (eventData.talking_point) {
                        //     case 'talking-point-insight':
                        //         updateStreamText(['forecast-insights', summaryId], {
                        //             text: '',
                        //             isStreaming: false,
                        //         });

                        //         break;
                        //     case 'talking-point-scope':
                        //         updateStreamText(['forecast-scope', summaryId], {
                        //             text: '',
                        //             isStreaming: false,
                        //         });

                        //         break;
                        // }
                    } else {
                        // dispatchStagedPage({
                        //     type: 'commit',
                        //     payload: {
                        //         settings: {
                        //             summary: {
                        //                 forecast: {
                        //                     forecastInsight: formatText(streamText.forecastInsight),
                        //                     forecastScope: formatText(streamText.forecastScope),
                        //                 },
                        //                 isComplete: true,
                        //                 isStreaming: false,
                        //             },
                        //         },
                        //     },
                        // });
                    }

                    break;
                case 'ERROR':
                    // updateStagedPage({
                    //     commit: true,
                    //     settings: {
                    //         summary: {
                    //             error: true,
                    //             isStreaming: false,
                    //         },
                    //     },
                    // });

                    break;
            }
        } else if ('type' in eventData) {
            // switch (eventData.type) {
            //     case 'status':
            //         break;
            //     case 'text':
            //         switch (eventData.talking_point) {
            //             case 'talking-point-insight':
            //                 updateStreamText(
            //                     ['forecast-insights', summaryId],
            //                     {
            //                         text: eventData.text,
            //                         isStreaming: true,
            //                     },
            //                     formatText
            //                 );

            //                 streamText.forecastInsight += eventData.text;

            //                 break;
            //             case 'talking-point-scope':
            //                 updateStreamText(
            //                     ['forecast-scope', summaryId],
            //                     {
            //                         text: eventData.text,
            //                         isStreaming: true,
            //                     },
            //                     formatText
            //                 );

            //                 streamText.forecastScope += eventData.text;

            //                 break;
            //         }

            //         break;
            //     case 'forecast-chart':
            //         if (!forecastSummaryData?.forecastChart) {
            //             dispatchStagedPage({
            //                 type: 'update',
            //                 payload: {
            //                     settings: {
            //                         summary: {
            //                             forecast: {
            //                                 forecastChart: eventData.data,
            //                             },
            //                         },
            //                     },
            //                 },
            //             });
            //         }

            //         break;
            // }
        }
    };
}
