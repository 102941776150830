import { useQuery } from '@tanstack/react-query';
import { TabAttributes } from '@tickr/sequelize-models/src/models/tab.model';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../Providers/ApiProvider/useApi';

export function useCurrentTabs() {
    const { pageUuid } = useParams();
    const { dashboardApi } = useApi();

    const { data: tabs = [], isLoading } = useQuery({
        queryKey: ['current-tabs', pageUuid],
        queryFn: async ({ signal }) => {
            const { data: tabs } = await dashboardApi.get<TabAttributes[]>('tabs', {
                params: {
                    pageUuid,
                },
                signal,
            });

            return tabs;
        },
        enabled: !!pageUuid,
    });

    return useMemo(() => ({
        tabs,
        isLoading,
    }), [tabs, isLoading]);
}
