import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CSSProperties, SyntheticEvent, useState } from 'react';

interface ImageWithFallbackProps {
    src?: string;
    fallbackSrc?: string;
    alt?: string;
    style?: CSSProperties;
    className?: string;
}

export function ImageWithFallback({
    fallbackSrc,
    src,
    alt,
    style,
    className,
}: ImageWithFallbackProps) {
    const [error, setError] = useState(false);

    const onError = (e: SyntheticEvent<HTMLImageElement>) => {
        setError(true);

        if (fallbackSrc) {
            e.currentTarget.src = fallbackSrc;
        }
    };

    return !error || !!fallbackSrc
        ? (
            <img
                alt={alt}
                className={className}
                onError={onError}
                src={src}
                style={style}
            />
        )
        : (
            <AccountCircleIcon
                style={{
                    width: '40px',
                    height: '40px',
                }}
            />
        );
}
