
import DoneIcon from '@mui/icons-material/Done';
import { PropsWithChildren } from 'react';
import { StreamCursor } from '../ChatCpg/StreamCursor';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';

interface SolutionPanelSectionProps {
    active: boolean;
    done: boolean;
    isStreaming?: boolean;
    title: string;
    description?: string;
}

export function SolutionPanelSection({
    active,
    description,
    done,
    isStreaming,
    title,
    children,
}: PropsWithChildren<SolutionPanelSectionProps>) {
    return (
        <>
            <Flex
                direction="column"
                gap="0.6rem"
                padding="0 0 0 1rem"
            >
                <Flex
                    align="center"
                    gap="1rem"
                    style={{ minHeight: '24px' }}
                >
                    <Typography secondary={!active} variant="h-16-b">
                        {title}
                    </Typography>
                    {done && <DoneIcon color="success" />}
                </Flex>
                {description && (
                    <Typography
                        multiline
                        secondary
                        variant="p-14-r"
                    >
                        {description}
                        {isStreaming && <StreamCursor />}
                    </Typography>
                )}
            </Flex>
            {children}
        </>
    );
}
