import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQuery } from '@tanstack/react-query';
import { useApp } from '../Providers/AppProvider/useApp';
import { debugLogger } from '../helpers/debugLogger';
import { pureMerge } from '../helpers/pureMerge';
import { useOnMessageForecast } from './onMessageHandlers/useOnMessageForecast';
import { useOnMessageOptimizePricing } from './onMessageHandlers/useOnMessageOptimizePricing';
import { useOnMessageOutOfStock } from './onMessageHandlers/useOnMessageOutOfStock';
import { useOnMessageSubstitutions } from './onMessageHandlers/useOnMessageSubstitutions';
import { useCaptureTickrException } from './useCaptureTickrException';
import { useChatCpgStore } from './useChatCpgStore';
import { usePage } from './usePage';
import { useStagedPageFns } from './useStagedPageFns';

export function useSolutionSummaryStream() {
    const captureTickrException = useCaptureTickrException();
    const { chatApiRoute, tidsApiUrl } = useApp();
    const { page, pageUuid, summaryId, settings, solutionType } = usePage();
    const { initStagedPage } = useStagedPageFns(pageUuid ?? '');

    const isComplete = settings?.summary?.isComplete;
    const clearStreamText = useChatCpgStore((state) => state.clearStreamText);
    const onMessageForecast = useOnMessageForecast();
    const onMessageOptimizePricing = useOnMessageOptimizePricing();
    const onMessageSubstitutions = useOnMessageSubstitutions();
    const onMessageOutOfStock = useOnMessageOutOfStock();

    useQuery({
        queryKey: ['solution-summary-stream', summaryId, pageUuid],
        queryFn({ signal }) {
            if (!summaryId || !pageUuid) {
                return false;
            }

            const forecastStreamText = {
                forecastInsight: '',
                forecastScope: '',
            };

            const optimizePricingStreamText = {
                headlines: {
                    competitors: '',
                    historic: '',
                    inflation: '',
                    recommendations: '',
                },
                insights: {
                    historic: '',
                    recommendations: '',
                },
            };

            const outOfStockStreamText = {
                insights: '',
            };

            const substitutionsStreamText = {
                substitutions: '',
                complements: '',
            };

            fetchEventSource(
                `${tidsApiUrl}/v1${chatApiRoute}/summary/${summaryId}`,
                {
                    method: 'GET',
                    openWhenHidden: true,
                    signal,
                    async onopen() {
                        debugLogger('SOLUTION SUMMARY STREAM OPEN');

                        initStagedPage(pureMerge(
                            page,
                            {
                                settings: {
                                    summary: {
                                        isStreaming: true,
                                    },
                                },
                            }
                        ));
                    },
                    onmessage(event) {
                        switch (solutionType) {
                            case 'forecast':
                                onMessageForecast(event, forecastStreamText);
                                break;
                            case 'optimizePricing':
                                onMessageOptimizePricing(event, optimizePricingStreamText);
                                break;
                            case 'outOfStockPredictions':
                                onMessageOutOfStock(event, outOfStockStreamText);
                                break;
                            case 'substitutions':
                                onMessageSubstitutions(event, substitutionsStreamText);
                                break;
                            default:
                                break;
                        }
                    },
                    onclose() {
                        debugLogger('SOLUTION SUMMARY STREAM CLOSED');
                    },
                    onerror(error) {
                        debugLogger('SOLUTION SUMMARY STREAM ERROR', { error });
                        captureTickrException(error);
                        clearStreamText(summaryId);
                    },
                }
            );

            return true;
        },
        enabled: !!pageUuid && !!summaryId && !isComplete && summaryId !== '123',
        staleTime: Infinity,
    });
}
