import { t } from 'i18next';
import { ReactNode } from 'react';
import { TickrDialog } from '../Dialog/TickrDialog';
import { TickrDialogContent } from '../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../Dialog/TickrDialogTitle';

interface ReportDialogProps {
    report: ReactNode;
    open: boolean;
    onClose(): void;
}

export function NewsReportDialog({ open, onClose, report }: ReportDialogProps) {
    return (
        <TickrDialog
            maxWidth="lg"
            onClose={onClose}
            open={open}
        >
            <TickrDialogTitle onClose={onClose}>{t('cardReport.cardReport')}</TickrDialogTitle>
            <TickrDialogContent>
                {report}
            </TickrDialogContent>
        </TickrDialog>
    );
}
