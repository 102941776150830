import { useTheme } from '@mui/material';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function TxtIcon(props: IconProps) {
    const { palette } = useTheme();

    return (
        <IconWrapper {...props} color="">
            <svg
                fill="none"
                height="59"
                viewBox="0 0 50 59"
                width="50"
            >
                <path
                    clipRule="evenodd"
                    d="M7.15381 25.0899V3.32068H31.2968V14.024C31.2968 15.6808 32.6399 17.024 34.2968 17.024H45V54.7383H7.15381V42.0899H32C33.6569 42.0899 35 40.7468 35 39.0899V28.0899C35 26.4331 33.6569 25.0899 32 25.0899H7.15381ZM4.15381 25.0899V3.32068C4.15381 1.66382 5.49696 0.320679 7.15381 0.320679H31.7278C32.7886 0.320679 33.806 0.742106 34.5562 1.49225L34.6978 1.63389L34.711 1.64696L46.6738 13.6098L46.6868 13.6228L46.8284 13.7645C47.5786 14.5146 48 15.5321 48 16.5929V54.7383C48 56.3952 46.6569 57.7383 45 57.7383H7.15381C5.49696 57.7383 4.15381 56.3952 4.15381 54.7383V42.0899H3C1.34315 42.0899 0 40.7468 0 39.0899V28.0899C0 26.4331 1.34315 25.0899 3 25.0899H4.15381ZM34.2968 14.024V5.4755L42.8453 14.024H34.2968Z"
                    fill={palette.info.main}
                    fillRule="evenodd"
                    opacity="0.7"
                />
                <path
                    d="M13.6934 29.7815V31.3655H11.4254V38.1815H9.76942V31.3655H7.51342V29.7815H13.6934ZM21.0139 38.1815H19.1299L17.4979 35.4455L15.8659 38.1815H13.9939L16.5619 33.8735L14.1259 29.7815H15.9979L17.4979 32.3015L18.9979 29.7815H20.8699L18.4339 33.8615L21.0139 38.1815ZM27.4981 29.7815V31.3655H25.2301V38.1815H23.5741V31.3655H21.3181V29.7815H27.4981Z"
                    fill={palette.background.default}
                />
            </svg>
        </IconWrapper>
    );
}
