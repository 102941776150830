import { StyledComponent } from '@emotion/styled';
import ClearIcon from '@mui/icons-material/Clear';
import { DialogTitle, DialogTitleProps, IconButton, styled } from '@mui/material';
import { h16b } from '../Core/typographyVariants';

const StyledDialogTitle: StyledComponent<DialogTitleProps> = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    '&.MuiDialogTitle-root': {
        ...h16b,
    },
}));

interface TickrDialogTitleProps {
    onClose?: () => void;
}

export function TickrDialogTitle({ onClose, children, ...props }: TickrDialogTitleProps & DialogTitleProps) {
    return (
        <>
            <StyledDialogTitle {...props}>
                {children}
            </StyledDialogTitle>
            {onClose && (
                <IconButton
                    onClick={onClose}
                    size="small"
                    style={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    }}
                >

                    <ClearIcon />
                </IconButton>
            )}
        </>
    );
}
