import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { Button, IconButton, useTheme } from '@mui/material';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { TickrDialog } from '../Dialog/TickrDialog';
import { TickrDialogActions } from '../Dialog/TickrDialogActions';
import { TickrDialogContent } from '../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../Dialog/TickrDialogTitle';
import { Checkboxes } from '../FormElements/Checkboxes';
import { FormElementWrapper } from '../FormElements/FormElementWrapper';
import { TextArea, TextAreaProps } from '../FormElements/TextArea';
import { FeedbackType } from '../Providers/TidsMutationsProvider/TidsMutationsContext';
import { useTidsMutations } from '../Providers/TidsMutationsProvider/useTidsMutations';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useCaptureTickrException } from '../hooks/useCaptureTickrException';
import { usePage } from '../hooks/usePage';
import { useYupValidation } from '../hooks/useYupValidation';

interface FeedbackDialogContentProps {
    chatMessageId?: string;
    feedbackType?: FeedbackType;
    talkingPointId?: string;
    text: string;
}

export function FeedbackDialog({
    chatMessageId, feedbackType, talkingPointId, text,
}: FeedbackDialogContentProps) {
    const { t } = useTranslation();
    const { palette, shape } = useTheme();
    const captureTickrException = useCaptureTickrException();
    const { string } = useYupValidation();
    const { sendAlert } = useToaster();
    const { summaryId } = usePage();

    const { postChatFeedback, postSummaryFeedback } = useTidsMutations();

    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const [isFirst, setIsFirst] = useState(true);

    if (!summaryId) return null;

    return (
        <>
            <IconButton
                color="secondaryText"
                onClick={() => setOpen(true)}
                size="small"
            >
                <ThumbDownOffAltIcon fontSize="inherit" />
            </IconButton>
            <TickrDialog
                maxWidth="lg"
                onClose={onClose}
                open={open}
            >
                <TickrDialogTitle onClose={onClose}>{t('feedback.title')}</TickrDialogTitle>
                <Formik
                    initialValues={{
                        correctedText: text,
                        errorType: [] as string[],
                        explanation: '',
                    }}
                    onSubmit={async (values) => {
                        try {
                            if (chatMessageId) {
                                await postChatFeedback({
                                    category: values.errorType.join(','),
                                    chatMessageId,
                                    feedback: values.correctedText,
                                    explanation: values.explanation,
                                    label: 'bad',
                                });
                            } else if (talkingPointId && feedbackType) {
                                await postSummaryFeedback({
                                    category: values.errorType.join(','),
                                    explanation: values.explanation,
                                    feedback: values.correctedText,
                                    talkingPointId,
                                    label: 'bad',
                                    type: feedbackType,
                                    summaryId,
                                });
                            }

                            sendAlert({
                                severity: 'success',
                                text: t('feedback.thankYou'),
                            });

                            setOpen(false);
                        } catch (e) {
                            captureTickrException(e);

                            sendAlert({
                                severity: 'error',
                                text: t('feedback.error'),
                            });
                        }
                    }}
                    validateOnChange={!isFirst}
                    validationSchema={Yup.object().shape({
                        correctedText: string
                            .required()
                            .test('is changed', t('feedback.correctedText.error'), (value) => value !== text),
                        errorType: Yup.array()
                            .of(Yup.string())
                            .test('is not empty', t('feedback.typeOfError.required'), (value) => !isEmpty(value)),
                    })}
                >
                    {({ errors, values, handleChange, handleSubmit, setFieldValue, isValid, isSubmitting }) => (
                        <>
                            <TickrDialogContent>
                                <Flex
                                    direction="column"
                                    gap="2rem"
                                    width="100%"
                                >
                                    <Flex direction="column" gap="0.8rem">
                                        <Typography style={{ paddingLeft: '8px' }} variant="h-12-b">
                                            {t('feedback.originalText')}
                                        </Typography>
                                        <Typography
                                            multiline
                                            style={{
                                                padding: '8px',
                                                backgroundColor: palette.background.secondaryMask,
                                                borderRadius: shape.borderRadius,
                                                whiteSpace: 'pre-line',
                                                lineHeight: '18px',
                                            }}
                                            variant="h-12-b"
                                        >
                                            {text}
                                        </Typography>
                                    </Flex>

                                    <FormElementWrapper
                                        error={errors.correctedText}
                                        title={t('feedback.pleaseProvideCorrectedText')}
                                    >
                                        <ControlledTextArea
                                            autoFocus
                                            className="h-12-b"
                                            id="correctedText"
                                            name="correctedText"
                                            onChange={handleChange}
                                            onFocus={function(e) {
                                                const val = e.target.value;
                                                e.target.value = '';
                                                e.target.value = val;
                                            }}
                                            value={values.correctedText}
                                        />
                                    </FormElementWrapper>
                                    <Flex padding="0 1px 0 0" width="100%">
                                        <Checkboxes
                                            error={errors.errorType as string}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'errorType',
                                                    values.errorType.includes(e.target.id)
                                                        ? values.errorType.filter((v) => v !== e.target.id)
                                                        : [...values.errorType, e.target.id]
                                                );
                                            }}
                                            selected={values.errorType}
                                            title={t('feedback.errorsPresent')}
                                            values={[
                                                {
                                                    title: t('feedback.factualErrors'),
                                                    value: 'factual',
                                                },
                                                {
                                                    title: t('feedback.mathematicalErrors'),
                                                    value: 'math',
                                                },
                                                {
                                                    title: t('feedback.logicalErrors'),
                                                    value: 'logical',
                                                },
                                                {
                                                    title: t('feedback.contextualErrors'),
                                                    value: 'contextual',
                                                },
                                                {
                                                    title: t('feedback.other'),
                                                    value: 'other',
                                                },
                                            ]}
                                        />
                                        <InfoTooltip
                                            component={() => (
                                                <>
                                                    <Flex direction="column" gap="0.4rem">
                                                        <Typography
                                                            as="h3"
                                                            style={{ textAlign: 'left' }}
                                                            variant="p-14-b"
                                                            width="100%"
                                                        >
                                                            {t('feedback.factualErrors')}
                                                        </Typography>
                                                        <Typography multiline variant="h-12-b">
                                                            {t('feedback.factualErrors.description')}
                                                        </Typography>
                                                    </Flex>
                                                    <Flex direction="column" gap="0.4rem">
                                                        <Typography
                                                            as="h3"
                                                            style={{ textAlign: 'left' }}
                                                            variant="p-14-b"
                                                            width="100%"
                                                        >
                                                            {t('feedback.mathematicalErrors')}
                                                        </Typography>
                                                        <Typography multiline variant="h-12-b">
                                                            {t('feedback.mathematicalErrors.description')}
                                                        </Typography>
                                                    </Flex>
                                                    <Flex direction="column" gap="0.4rem">
                                                        <Typography
                                                            as="h3"
                                                            style={{ textAlign: 'left' }}
                                                            variant="p-14-b"
                                                            width="100%"
                                                        >
                                                            {t('feedback.logicalErrors')}
                                                        </Typography>
                                                        <Typography multiline variant="h-12-b">
                                                            {t('feedback.logicalErrors.description')}
                                                        </Typography>
                                                    </Flex>
                                                    <Flex direction="column" gap="0.4rem">
                                                        <Typography
                                                            as="h3"
                                                            style={{ textAlign: 'left' }}
                                                            variant="p-14-b"
                                                            width="100%"
                                                        >
                                                            {t('feedback.contextualErrors')}
                                                        </Typography>
                                                        <Typography multiline variant="h-12-b">
                                                            {t('feedback.contextualErrors.description')}
                                                        </Typography>
                                                    </Flex>
                                                </>
                                            )}
                                            containerStyle={{
                                                flexDirection: 'column',
                                                gap: '1rem',
                                            }}
                                            leaveOpenOnHover
                                            maxWidth="600px"
                                            zindex={2000}
                                        >
                                            <IconButton size="small">
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </InfoTooltip>
                                    </Flex>
                                    <FormElementWrapper error={errors.explanation} title={t('feedback.explanation')}>
                                        <TextArea
                                            className="h-12-b"
                                            id="explanation"
                                            name="explanation"
                                            onChange={handleChange}
                                            value={values.explanation}
                                        />
                                    </FormElementWrapper>
                                </Flex>
                            </TickrDialogContent>
                            <TickrDialogActions>
                                <Button
                                    color="middleGray"
                                    onClick={onClose}
                                    size="small"
                                    style={{ width: '200px' }}
                                    variant="contained"
                                >
                                    {t('util.cancel')}
                                </Button>
                                <Button
                                    disabled={!isValid || isSubmitting}
                                    onClick={async () => {
                                        setIsFirst(false);

                                        if (isEmpty(errors)) {
                                            handleSubmit();
                                        }
                                    }}
                                    size="small"
                                    style={{ width: '200px' }}
                                    variant="contained"
                                >
                                    {t('util.submit')}
                                </Button>
                            </TickrDialogActions>
                        </>
                    )}
                </Formik>
            </TickrDialog>
        </>
    );
}

function ControlledTextArea(props: TextAreaProps) {
    const correctTextRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (correctTextRef.current) {
            correctTextRef.current.focus();
            correctTextRef.current.style.height = '0px';
            const scrollHeight = correctTextRef.current.scrollHeight;
            correctTextRef.current.style.height = `${scrollHeight + 10}px`;
        }
    }, [props.value]);

    return <TextArea {...props} ref={correctTextRef} />;
}
