import { styled } from '@mui/material';
import { useState } from 'react';
import { Mosaic, MosaicNode, MosaicWindow } from 'react-mosaic-component';
import 'react-mosaic-component/react-mosaic-component.css';
import { ElementProps } from '../Element/Element';
import { useDashboardSettings } from '../Providers/DashboardSettingsProvider.tsx/useDashboardSettings';
import { usePage } from '../hooks/usePage';
import { ElementToolbar } from './ElementToolbar';
import { useMosaicState } from './MosaicStateProvider.tsx/useMosaicState';
import { ToolbarControls } from './ToolbarControls';
import { ZeroStateMosaic } from './ZeroStateMosaic';

interface MosaicLayoutProps {
    initialNode: MosaicNode<string> | null;
    elementMap: Record<string, JSX.Element>;
    onRelease: (currentNode: MosaicNode<string> | null) => void;
}

export function MosaicLayout({ initialNode, elementMap, onRelease }: MosaicLayoutProps) {
    const { presentationMode } = useDashboardSettings();
    const { isStreaming } = usePage();
    const disableMosaic = presentationMode || isStreaming;
    const { setIsDragging } = useMosaicState();

    const [editingTitle, setEditingTitle] = useState<boolean>(false);

    return (
        <ThemeStyles>
            <Mosaic<string>
                initialValue={removeNodeIfNotInElementMap(initialNode, elementMap)}
                onRelease={onRelease}
                renderTile={(id, path) => {
                    return (
                        <MosaicWindow<string>
                            className={id}
                            draggable={!disableMosaic && !editingTitle}
                            path={path}
                            renderToolbar={(props) => {
                                return (
                                    <div
                                        onDragEnd={() => setIsDragging(false)}
                                        onDragStart={() => setIsDragging(true)}
                                        style={{
                                            cursor: disableMosaic ? 'default' : 'move',
                                            height: '100%',
                                            width: '100%',
                                            minHeight: '31px',
                                        }}
                                    >
                                        <ElementToolbar
                                            element={(elementMap[id]?.props as ElementProps)?.element}
                                            isChatElement={id === 'chatCpg'}
                                            setEditingTitle={setEditingTitle}
                                            title={props.title}
                                            toolbarControls={props.toolbarControls}
                                        />
                                    </div>
                                );
                            }}
                            title={elementMap[id]?.props?.element?.title}
                            toolbarControls={<ToolbarControls />}
                            // renderPreview={() => <div>{elementMap[id]}</div>}
                        >
                            {elementMap[id]}
                        </MosaicWindow>
                    );
                }}
                resize={disableMosaic ? 'DISABLED': { minimumPaneSizePercentage: 20 }}
                zeroStateView={<ZeroStateMosaic />}
            />
        </ThemeStyles>
    );
}

const removeNodeIfNotInElementMap = (
    currentNode: MosaicNode<string> | null,
    elementMap: Record<string, JSX.Element>
): MosaicNode<string> | null => {
    if (!currentNode) return null;

    if (typeof currentNode === 'string') {
        if (elementMap[currentNode]) return currentNode;
        return null;
    }

    removeNodeIfNotInElementMap(currentNode.first, elementMap);
    removeNodeIfNotInElementMap(currentNode.second, elementMap);

    return currentNode;
};

const ThemeStyles = styled('div')(({ theme }) => `
    width: 100%;
    height: 100%;

    .mosaic-window.mosaic-drop-target.chatCpg {
        padding: 1px;
        border-radius: ${theme.shape.borderRadius}px;
        background: ${theme.palette.gradient.chatCpg};
    }

    .mosaic.mosaic-blueprint-theme {
        background-color: ${theme.palette.background.default};
        color: ${theme.palette.text.primary};
    }

    .mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-body, 
    .mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-body, 
    .mosaic-window .mosaic-window-toolbar, .mosaic-preview .mosaic-window-toolbar, 
    .mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-toolbar.draggable:hover, 
    .mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-toolbar.draggable:hover {
        background: ${theme.palette.background.default};
    }

    .mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-toolbar.draggable:hover .mosaic-window-title, 
    .mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-toolbar.draggable:hover .mosaic-window-title {
        color: ${theme.palette.text.primary};
    }

    .mosaic.mosaic-blueprint-theme .mosaic-split:hover .mosaic-split-line {
        box-shadow: 0 0 0 1px ${theme.palette.text.disabled};
    }
    
    .mosaic-window .mosaic-window-toolbar, 
    .mosaic-preview .mosaic-window-toolbar, 
    .mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-toolbar, 
    .mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-toolbar {
        box-shadow: unset;
    }

    .mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-toolbar, 
    .mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-toolbar {
        border-radius: ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0;
    }
      
    .mosaic-tile, .mosaic.mosaic-blueprint-theme .mosaic-window, 
    .mosaic.mosaic-blueprint-theme .mosaic-preview {
        border-radius: ${theme.shape.borderRadius}px;
    }
      
      .mosaic.mosaic-blueprint-theme .mosaic-window .mosaic-window-body, 
      .mosaic.mosaic-blueprint-theme .mosaic-preview .mosaic-window-body {
        border-radius: 0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px;
    }
      
    .mosaic-window .mosaic-preview, 
    .mosaic-preview .mosaic-preview {
        z-index: -1;
    }
      
    .mosaic-window-toolbar {
        display: flex;
        align-items: center;
    }
      
    .mosaic-window .mosaic-window-toolbar,
    .mosaic-preview .mosaic-window-toolbar {
        height: unset;
    }
`);
