import { Trend, TrendVal } from '@tickr/sequelize-models/src/types/libBiTypes';
import findLast from 'lodash/findLast';
import { getEndOfWeekDate } from '../helpers/getEndOfWeekDate';

interface FormatTrendProps {
    trend?: ([number, number] | TrendVal)[];
    daysToAdd?: number;
}

export function formatTrend({ trend = [], daysToAdd }: FormatTrendProps) {
    return trend.reduce((trend: [number, number][], trendVal) => {
        const period = Array.isArray(trendVal) ? trendVal[0] : trendVal.period;
        const val = Array.isArray(trendVal) ? trendVal[1] : trendVal.val;

        // Remove blank days
        if (val === 0 || val === null) return trend;

        trend.push([getEndOfWeekDate(period, daysToAdd), val]);

        return trend;
    }, []);
}

export function getDiff(primaryTrend: Trend = [], secondaryTrend: Trend = []) {
    const primary = primaryTrend[primaryTrend.length - 1].val;
    const secondary = secondaryTrend[secondaryTrend.length - 1]?.val;
    return relativeChange(secondary, primary);
}

export function getLastNonZeroValue(trend: Trend = []) {
    return findLast(trend, ({ val }) => !!val)?.val ?? 0;
}

export interface ChartSeries {
    trend: [number, number][];
    diff?: number;
    legendVal?: number;
}

export const initialChartSeries = {
    trend: [],
    diff: 0,
    legendVal: 0,
};

function relativeChange(previous: number, current: number) {
    return (current - previous) / previous;
}
