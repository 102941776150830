import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton, useTheme } from '@mui/material';
import { Influencer } from '@tickr/sequelize-models/src/main';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { NumberFormatter } from '../Core/NumberFormatter';
import { Typography } from '../Core/Typography';
import { HeadCell, TickrHeadCell } from '../Table/TickrHeadCell';
import { TickrTable } from '../Table/TickrTable';
import { TickrTableBody } from '../Table/TickrTableBody';
import { TickrTableCell } from '../Table/TickrTableCell';
import { TickrTableContainer } from '../Table/TickrTableContainer';
import { TickrTableHead } from '../Table/TickrTableHead';
import { TickrTableRow } from '../Table/TickrTableRow';
import { TickrTableSortLabel } from '../Table/TickrTableSortLabel';
import { useTableSort } from '../Table/useTableSort';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { usePage } from '../hooks/usePage';
import { ProductCell } from './Cells/ProductCell';
import { ScoreCell } from './Cells/ScoreCell';

export function InfluentialCompetitors() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { optimizePricingSummaryData } = usePage();
    const influencerData = optimizePricingSummaryData?.influencers;

    const headCells: HeadCell<Influencer>[] = useMemo(() => [
        {
            label: t('metrics.product'),
            value: 'id',
        },
        {
            align: 'right',
            label: t('metrics.influenceScore'),
            value: 'influence',
        },
        {
            align: 'right',
            label: t('metrics.priceL12w'),
            value: 'price',
        },
    ], [t]);

    const influencers = useMemo(() => influencerData?.influencers ?? [], [influencerData]);

    const { order, setOrder, orderBy, setOrderBy, sortedRows } = useTableSort({
        data: influencers,
        initOrder: 'desc',
        initOrderBy: 'influence',
    });

    return (
        <Flex
            direction="column"
            height="100%"
            style={{
                minHeight: 0,
                minWidth: 0,
                overflow: 'hidden',
            }}
            width="100%"
        >
            <Flex
                justify="space-between"
                padding="6px"
                width="100%"
            >
                <Typography
                    multiline
                    secondary
                    style={{ maxWidth: '400px' }}
                    variant="p-14-r"
                >
                    {t('table.influentialCompetitors.description')}
                </Typography>
                <InfoTooltip
                    component={() => (
                        <>
                            <Typography
                                as="h2"
                                style={{ textAlign: 'left' }}
                                width="100%"
                            >
                                {t('chatCpg.howAreTheseCompetitorsIdentified')}
                            </Typography>
                            <Typography multiline variant="h-12-b">
                                {t('chatCpg.howAreTheseCompetitorsIdentified.description1')}
                            </Typography>
                            <Typography multiline variant="h-12-b">
                                {t('chatCpg.howAreTheseCompetitorsIdentified.description2')}
                            </Typography>
                        </>
                    )}
                    containerStyle={{
                        flexDirection: 'column',
                        borderRadius: '32px',
                        padding: '20px',
                        gap: '1rem',
                    }}
                    leaveOpenOnHover
                >
                    <IconButton size="small">
                        <HelpOutlineIcon fontSize="inherit" />
                    </IconButton>
                </InfoTooltip>
            </Flex>
            <TickrTableContainer>
                <TickrTable>
                    <TickrTableHead>
                        <TickrTableRow background={palette.background.default}>
                            {headCells.map(({ align, value, label }) => (
                                <TickrHeadCell
                                    align={align}
                                    key={value}
                                    order={order}
                                    orderBy={orderBy}
                                    value={value}
                                >
                                    <TickrTableSortLabel
                                        id={value}
                                        label={label}
                                        nowrap
                                        order={order}
                                        orderBy={orderBy}
                                        setOrder={setOrder}
                                        setOrderBy={setOrderBy}
                                    />
                                </TickrHeadCell>
                            ))}
                        </TickrTableRow>
                    </TickrTableHead>
                    <TickrTableBody>
                        {sortedRows.map(({ id, influence, name, price }) => (
                            <TickrTableRow key={id}>
                                <TickrTableCell
                                    component="th"
                                    id={`influential-competitor-table-${id}`}
                                    scope="row"
                                >
                                    <ProductCell
                                        id={id}
                                        maxWidth="240px"
                                        name={name}
                                    />
                                </TickrTableCell>
                                <TickrTableCell>
                                    <ScoreCell className="e2e-influence-score" score={influence} />
                                </TickrTableCell>
                                <TickrTableCell>
                                    <NumberFormatter
                                        as="p"
                                        className="e2e-influential-competitor-latest-price"
                                        numberStyle="currency"
                                        partsStyle={{ textAlign: 'right' }}
                                        val={price}
                                        variant="h-16-b"
                                    />
                                </TickrTableCell>
                            </TickrTableRow>
                        ))}
                    </TickrTableBody>
                </TickrTable>
            </TickrTableContainer>
        </Flex>
    );
}
