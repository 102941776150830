import { useTheme } from '@mui/material';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { FormElementWrapper } from './FormElementWrapper';
import { SelectDropdownContent, SelectDropdownValue } from './SelectDropdownContent';

interface DoubleSelectDropdownProps {
    disabled?: boolean;
    onChange1?: (value: string | number) => void;
    onChange2?: (value: string | number) => void;
    selected1?: string | number;
    selected2?: string | number;
    textOnly2?: boolean;
    title: string;
    titleColor?: string;
    values1: SelectDropdownValue[];
    values2: SelectDropdownValue[];
    width?: string;
    width1?: string;
    width2?: string;
}

export function DoubleSelectDropdown({
    disabled = false,
    onChange1,
    onChange2,
    selected1,
    selected2,
    textOnly2,
    title,
    titleColor,
    values1,
    values2,
    width,
    width1,
    width2,
}: DoubleSelectDropdownProps) {
    const { palette } = useTheme();

    return (
        <FormElementWrapper
            title={title}
            titleColor={titleColor}
            width={width}
        >
            <Flex align="center" gap="0.5rem">
                <SelectDropdownContent
                    disabled={disabled}
                    onChange={onChange1}
                    selected={selected1}
                    values={values1}
                    width={width1}
                />
                {textOnly2
                    ? (
                        <Typography
                            color={disabled ? palette.text.disabled : palette.text.primary}
                            variant="p-16-r"
                        >
                            {values2.find(({ value }) => value === selected2)?.title}
                        </Typography>
                    )
                    : (
                        <SelectDropdownContent
                            disabled={disabled}
                            onChange={onChange2}
                            selected={selected2}
                            values={values2}
                            width={width2}
                        />
                    )}
            </Flex>
        </FormElementWrapper>
    );
}
