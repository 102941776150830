import SendIcon from '@mui/icons-material/Send';
import { IconButton, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { TextArea } from '../FormElements/TextArea';
import { useTidsMutations } from '../Providers/TidsMutationsProvider/useTidsMutations';

export interface ChatInputV2Props {
    chatId: string;
    setChatId: Dispatch<SetStateAction<string>>;
    setMessageStreamId: Dispatch<SetStateAction<string>>;
}

export function ChatInputV2({ chatId, setChatId, setMessageStreamId }: ChatInputV2Props) {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const { postChatV2, postMessageV2 } = useTidsMutations();
    const disabled = false;

    return (
        <Formik
            initialValues={{
                message: '',
            }}
            onSubmit={({ message }, { resetForm }) => {
                if (!chatId) {
                    postChatV2({
                        message,
                        onChatCreation: setChatId,
                        onMessageCreation: setMessageStreamId,
                    });
                } else {
                    postMessageV2({
                        chatId,
                        message,
                        onMessageCreation: setMessageStreamId,
                    });
                }

                resetForm();
            }}
        >
            {({ values, handleChange, handleSubmit }) => {
                return (
                    <Flex
                        direction="column"
                        gap="0.5rem"
                        width="100%"
                    >
                        <Flex
                            align="center"
                            gap="8px"
                            width="100%"
                        >
                            <TextArea
                                className={values.message ? 'p-14-b' : 'p-14-i'}
                                height="60px"
                                id="message"
                                name="message"
                                onChange={(e) => {
                                    handleChange?.(e);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();

                                        if (!disabled) {
                                            handleSubmit();
                                        }
                                    }
                                }}
                                placeholder={t('chatCpg.input.placeholder')}
                                placeholderColor={palette.primary.dark}
                                resize="none"
                                style={{
                                    padding: '12px 48px 12px 12px',
                                }}
                                value={values.message}
                            />
                            <IconButton
                                aria-label={t('iconButton.aria.sendChatMessage')}
                                color="primary"
                                disabled={disabled}
                                onClick={() => {
                                    handleSubmit();
                                }}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                }}
                            >
                                <SendIcon />
                            </IconButton>
                        </Flex>
                    </Flex>
                );
            }}
        </Formik>
    );
}
