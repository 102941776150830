import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { NewsCard } from '@tickr/sequelize-models/src/types/libBiTypes';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocalStorage } from 'usehooks-ts';
import { Flex } from '../Core/Flex';
import { useElement } from '../Element/useElement';
import { useActiveFilters } from '../Filters/ActiveFiltersProvider/useActiveFilters';
import { useApi } from '../Providers/ApiProvider/useApi';
import { NewsPost } from './NewsPost';
import { NewsReport } from './NewsReport';

export function Posts() {
    const { palette } = useTheme();
    const element = useElement();
    const { publicApi } = useApi();
    const { combinedFilters } = useActiveFilters();
    const [irrelevantCards] = useLocalStorage<string[]>('irrelevantCards', []);

    const { data = [], isLoading } = useQuery({
        queryKey: ['bi-cards', element?.settings?.posts?.options, combinedFilters],
        queryFn: async () => {
            const { data } = await publicApi.post<NewsCard[]>(
                'data/news/list',
                {
                    filters: {
                        ...combinedFilters,
                        dates: '12w/w',
                    },
                    options: {
                        per_page: 30,
                        ...element?.settings?.posts?.options,
                    },
                }
            );

            return data
                .map((card) => {
                    card.metrics.impact = card.metrics.impact + (card.metrics.influence * 2);
                    return card;
                })
                .filter(({ metrics }) => metrics.impact > 0)
                .sort((a, b) => b.metrics.impact - a.metrics.impact);
        },
    });

    return (
        <Flex
            direction="column"
            gap="1rem"
            height={isLoading ? '100%' : 'unset'}
            style={{ overflowX: 'hidden' }}
            width="100%"
        >
            {isLoading
                ? (
                    <Skeleton
                        baseColor={palette.background.secondary}
                        containerClassName="flex-skeleton"
                        height="100%"
                        highlightColor={palette.background.tertiary}
                        width="100%"
                    />
                )
                : (
                    data
                        ?.filter((newsCard) => !irrelevantCards.includes(newsCard.clusterId))
                        ?.map((newsCard) => (
                            <NewsPost
                                key={newsCard.id ?? newsCard.clusterId}
                                news={newsCard}
                                report={<NewsReport newsCard={newsCard} />}
                            />
                        ))
                )}
        </Flex>
    );
}
