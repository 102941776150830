import { extent, line, scaleLinear } from 'd3';
import { GraphProvider, NewGraphProps } from '../Graph/GraphProvider';
import { useGraph } from '../Graph/useGraph';

interface SparklineProps extends NewGraphProps {
    sparklines: (InnerSparklineProps & { id: string })[];
    xDomain?: [number, number];
}

export function Sparkline({ containerHeight, containerPadding = '0', sparklines, xDomain }: SparklineProps) {
    return (
        <GraphProvider containerHeight={containerHeight} containerPadding={containerPadding}>
            {sparklines.map(({ id, historicData, forecastData, circleColor, color, strokeWidth }) => (
                <InnerSparkline
                    circleColor={circleColor ?? color}
                    color={color}
                    forecastData={forecastData}
                    historicData={historicData}
                    key={id}
                    strokeWidth={strokeWidth}
                    xDomain={xDomain}
                />
            ))}
        </GraphProvider>
    );
}

interface InnerSparklineProps {
    circleColor?: string;
    color?: string;
    forecastData?: [number, number][];
    historicData?: [number, number][];
    innerPadding?: [number, number];
    strokeWidth?: number;
    xDomain?: [number, number];
}

function InnerSparkline({
    historicData = [],
    forecastData = [],
    color,
    circleColor,
    innerPadding = [3, 3],
    xDomain,
    strokeWidth = 2,
}: InnerSparklineProps) {
    const { width, height } = useGraph();

    const combinedData = [...historicData, ...forecastData];

    const [xMin = 0, xMax = 0] = xDomain ?? extent(combinedData, ([x]) => x);
    const [yMin = 0, yMax = 0] = extent(combinedData, ([, y]) => y);

    const xScale = scaleLinear()
        .range([innerPadding[0], width - innerPadding[0]])
        .domain([xMin, xMax]);

    const yScale = scaleLinear()
        .range([height - innerPadding[1], innerPadding[1]])
        .domain([yMin, yMax]);

    const lineGenerator = line()
        .x(([x]) => xScale(x))
        .y(([, y]) => yScale(y));

    const historicPath = lineGenerator(historicData) ?? '';
    const forecastPath = lineGenerator(forecastData) ?? '';
    const lastPoint = combinedData?.[combinedData.length - 1];

    return (
        <g className="sparkline">
            <path
                d={historicPath}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
            />
            ;
            <path
                d={forecastPath}
                fill="none"
                stroke={color}
                strokeDasharray="6 3"
                strokeWidth={strokeWidth}
            />
            ;
            {lastPoint && (
                <circle
                    cx={xScale(lastPoint[0])}
                    cy={yScale(lastPoint[1])}
                    fill={circleColor}
                    r={3}
                />
            )}
        </g>
    );
}
