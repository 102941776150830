import { useTheme } from '@mui/material';
import { ElementAttributes } from '../../../../../sequelize-models/src/main';
import { EditableTypography } from '../../../Core/EditableTypography';
import { useCollectionMutations } from '../../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useMosaicDisabled } from '../hooks/useMosaicDisabled';

interface EditableTitleProps {
    setEditingTitle: (editing: boolean) => void;
    element: ElementAttributes;
    title: string;
}

export function EditableTitle({ element, setEditingTitle, title }: EditableTitleProps) {
    const { palette } = useTheme();
    const { mosaicDisabled } = useMosaicDisabled();
    const { updateElement } = useCollectionMutations();

    return (
        <EditableTypography
            contentEditable={!mosaicDisabled}
            focusBackground={palette.background.secondaryMask}
            initialText={title}
            max={255}
            onBlur={() => {
                setEditingTitle?.(false);
            }}
            onFocus={() => {
                setEditingTitle?.(true);
            }}
            onSave={(value) => {
                updateElement({
                    mergeProps: {
                        title: value,
                    },
                    elementUuid: element.uuid,
                    queryKey: element.tabUuid ?? element.pageUuid,
                });
            }}
            padding="0 4px"
            singleLine
            style={{
                borderRadius: '6px',
            }}
            variant="h-16-b"
        />
    );
}
