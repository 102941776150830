import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';

export const ApiContext = createContext<{
    dashboardApi: AxiosInstance;
    publicApi: AxiosInstance;
    tidsApi: AxiosInstance;
}>({
    dashboardApi: axios,
    publicApi: axios,
    tidsApi: axios,
});
