import { PageAttributes } from '../../../sequelize-models/src/main';
import { useCurrentProject } from './useCurrentProject';

export function useCurrentForecast() {
    const { currentProject } = useCurrentProject();

    const forecast = currentProject?.pages.find(
        (page) => page.inProgress && page.solutionType === 'forecast'
    ) as PageAttributes | undefined;

    return forecast;
}
