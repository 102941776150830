import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { useHeaderIconStyles } from '../../hooks/useHeaderIconStyles';
import { SharingPopoverContent } from './SharingPopoverContent';

export function SharingPopover() {
    const styles = useHeaderIconStyles();

    return (
        <PopoverProvider placement="bottom">
            <PopoverTrigger asChild>
                <IconButton
                    size="small"
                    style={styles}
                >
                    <ShareIcon fontSize="inherit" />
                </IconButton>
            </PopoverTrigger>
            <SharingPopoverContent />
        </PopoverProvider>
    );
}
