import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { SlideCarousel } from '../Core/SlideCarousel';
import { ValueBox } from '../Core/ValueBox';
import { useLegendClick } from '../hooks/useLegendClick';
import { usePage } from '../hooks/usePage';
import { HighchartsContainer } from './HighchartsContainer';
import { formatTrend } from './chartHelpers';
import { useFormatYAxisOptions } from './useFormatYAxisOptions';

export function PriceRevenueVolume() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const formatYAxisOptions = useFormatYAxisOptions();
    const { optimizePricingSummaryData } = usePage();
    const pvsChartData = optimizePricingSummaryData?.pvsChart;

    const chartData = useMemo(() => {
        return pvsChartData
            ? {
                price: {
                    trend: formatTrend({
                        trend: pvsChartData.price,
                        daysToAdd: 6,
                    }),
                },
                volume: {
                    trend: formatTrend({
                        trend: pvsChartData.volume,
                        daysToAdd: 6,
                    }),
                },
                revenue: {
                    trend: formatTrend({
                        trend: pvsChartData.sales,
                        daysToAdd: 6,
                    }),
                },
            }
            : {
                price: {
                    trend: [],
                },
                volume: {
                    trend: [],
                },
                revenue: {
                    trend: [],
                },
            };
    }, [pvsChartData]);

    const options = useMemo(() => {
        const highchartsOptions: Highcharts.Options = {
            tooltip: {
                shared: true,
                dateTimeLabelFormats: {
                    day: `${t('util.weekEnding')} %a, %b %e, %Y`,
                    hour: `${t('util.weekEnding')} %a, %b %e, %Y`,
                    millisecond: `${t('util.weekEnding')} %a, %b %e, %Y`,
                },
                borderColor: palette.info.main,
            },
            chart: {
                animation: false,
            },
            xAxis: {
                type: 'datetime',
                startOfWeek: 6,
                crosshair: true,
            },
            legend: {
                enabled: false,
            },
            yAxis: [
                formatYAxisOptions({
                    labels: {
                        style: {
                            color: palette.text.primary,
                        },
                        formatter: function() {
                            return `$${Highcharts.numberFormat(this.value as number, 2)}`;
                        },
                    },
                }),
                formatYAxisOptions({
                    opposite: true,
                    labels: {
                        formatter: function() {
                            return `$${this.axis.defaultLabelFormatter.call(this)}`;
                        },
                        style: {
                            color: palette.success.main,
                        },
                    },
                }),
                formatYAxisOptions({
                    opposite: true,
                    labels: {
                        style: {
                            color: palette.salmon.main,
                        },
                    },
                }),
            ],
            series: [
                {
                    name: t('metrics.volume'),
                    type: 'column',
                    animation: false,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, palette.salmon.main],
                            [0.05, `${palette.salmon.main}B3`], // 70% opacity
                            [0.2, `${palette.salmon.main}80`], // 50% opacity
                            [1, 'transparent'],
                        ],
                    },
                    data: chartData.volume.trend,
                    borderWidth: 0,
                    yAxis: 2,
                    tooltip: {
                        valueDecimals: 0,
                    },
                },
                {
                    name: t('metrics.revenue'),
                    type: 'area',
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, `${palette.success.main}80`], // 50% opacity
                            [1, 'transparent'],
                        ],
                    },
                    lineWidth: 2,
                    lineColor: palette.success.main,
                    data: chartData.revenue.trend,
                    tooltip: {
                        valuePrefix: '$',
                    },
                    yAxis: 1,
                    marker: {
                        fillColor: palette.success.main,
                    },
                },
                {
                    name: t('metrics.price'),
                    type: 'line',
                    color: palette.text.primary,
                    dashStyle: 'Solid',
                    // borderColor: palette.background.default,
                    // borderWidth: 1,
                    lineWidth: 2,
                    data: chartData.price.trend,
                    tooltip: {
                        valuePrefix: '$',
                    },
                    yAxis: 0,
                },
            ],
        };

        return highchartsOptions;
    }, [
        t,
        chartData,
        formatYAxisOptions,
        palette.background.default,
        palette.text.primary,
        palette.success.main,
    ]);

    const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick({ trendCount: 3 });

    return (
        <Flex
            align="stretch"
            direction="column"
            height="100%"
            justify="stretch"
            style={{
                minHeight: 0,
                minWidth: 0,
            }}
            width="100%"
        >
            <SlideCarousel width="100%">
                <ValueBox
                    allNeutral
                    color={palette.text.primary}
                    label={t('metrics.price')}
                    onClick={() => handleLegendClick('price', 2)}
                    secondary
                    style={{
                        opacity: hiddenTrends.includes('price') ? 0.5 : 1,
                    }}
                />
                <ValueBox
                    allNeutral
                    color={palette.success.main}
                    indicator="area"
                    label={t('metrics.revenue')}
                    onClick={() => handleLegendClick('revenue', 0)}
                    secondary
                    style={{
                        opacity: hiddenTrends.includes('revenue') ? 0.5 : 1,
                    }}
                />
                <ValueBox
                    allNeutral
                    color={palette.salmon.main}
                    indicator="column"
                    label={t('metrics.volume')}
                    onClick={() => handleLegendClick('volume', 1)}
                    secondary
                    style={{
                        opacity: hiddenTrends.includes('volume') ? 0.5 : 1,
                    }}
                />
            </SlideCarousel>
            <HighchartsContainer options={options} ref={chartRef} />
        </Flex>
    );
}
