import { PropsWithChildren } from 'react';
import { AppContext } from './AppContext';

interface AppProviderProps {
    dashboardApiUrl: string;
    envMode: 'prod' | 'qa';
    publicApiUrl: string;
    tidsApiUrl: string;
}

export function AppProvider({
    dashboardApiUrl,
    envMode,
    publicApiUrl,
    tidsApiUrl,
    children,
}: PropsWithChildren<AppProviderProps>): JSX.Element {
    const isTidsLocal = tidsApiUrl.includes('localhost');

    const pricingApiRoute = isTidsLocal ? '' : '/pricing';
    const chatApiRoute = isTidsLocal ? '' : '/chatcpg';

    return (
        <AppContext.Provider
            value={{
                dashboardApiUrl,
                envMode,
                publicApiUrl,
                tidsApiUrl,
                pricingApiRoute,
                chatApiRoute,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
