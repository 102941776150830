import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { FilterDefinition } from '../Filters/AvailableFiltersProvider/AvailableFiltersContext';
import { useAvailableFilters } from '../Filters/AvailableFiltersProvider/useAvailableFilters';
import { FilterSummaryLayout } from '../Filters/Components/FilterSummaryLayout';
import { useStagedFilters } from '../Filters/StagedFiltersProvider/useStagedFilters';
import { useProductAttributes } from '../Providers/ProductAttributesProvider/useProductAttributes';
import { useUser } from '../Providers/UserProvider/useUser';
import { useFilterSummary } from '../hooks/useFilterSummary';

export function ScopeOfAnalysisSectionContent() {
    const { t } = useTranslation();
    const { integrations } = useUser();

    const { stagedFilters } = useStagedFilters();
    const getFilterSummary = useFilterSummary();
    const { universalFilters, datasourceFilters, datasourceDisplayNames } = useAvailableFilters();
    const { myProducts } = useProductAttributes();

    const productSummary = getFilterSummary({
        filterValue: stagedFilters.cpg_owned_products,
        filterDefinition: {
            ...universalFilters.cpg_owned_products,
            values: myProducts,
        } as FilterDefinition,
    });

    const bannerSummary = getFilterSummary({
        filterValue: stagedFilters.cpg_banner,
        filterDefinition: datasourceFilters[integrations.includes('numerator') ? 'numerator' : 'synth']?.cpg_banner as FilterDefinition,
    });

    return (
        <Flex
            direction="column"
            gap="1rem"
            padding="0 0 0 1rem"
            width="100%"
        >
            {stagedFilters.cpg_owned_products && (
                <SelectedFilter name={t('filters.cpg_owned_products')} summary={productSummary} />
            )}
            {stagedFilters.cpg_dataset && (
                <SelectedFilter
                    name={t('filters.cpg_dataset')}
                    summary={stagedFilters.cpg_dataset
                        .split(',')
                        .map((dataSource) => datasourceDisplayNames[dataSource])
                        .join(', ')}
                />
            )}
            {stagedFilters.cpg_banner && (
                <SelectedFilter
                    name={t('filters.cpg_banner')}
                    summary={bannerSummary || t('util.allBanners')}
                />
            )}
        </Flex>
    );
}

interface SelectedFilterProps {
    name: string;
    summary: string;
}

function SelectedFilter({ name, summary }: SelectedFilterProps) {
    return (
        <Flex
            align="flex-end"
            gap="1rem"
            style={{ maxWidth: 'calc(100% - 2rem)' }}
        >
            <FilterSummaryLayout
                isValid
                name={name}
                summary={summary}
                truncate
                wrap
            />
            <DoneIcon color="success" />
        </Flex>
    );
}
