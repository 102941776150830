import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { ContextMenu } from '../Core/ContextMenu';
import { ContextMenuItem } from '../Core/ContextMenuItem';
import { PopoverContent } from '../Popover/PopoverContent';
import { usePopoverContext } from '../Popover/usePopoverContext';

interface PostPopoverContentProps {
    id: string;
}

export function PostPopoverContent({ id }: PostPopoverContentProps) {
    const { t } = useTranslation();
    const { setOpen } = usePopoverContext();
    const [irrelevantCards, setIrrelevantCards] = useLocalStorage<string[]>('irrelevantCards', []);

    const tagIrrelevant = useMutation({
        mutationFn: async (id: string) => {
            setIrrelevantCards([...irrelevantCards, id]);
            return id;
        },
    });

    return (
        <PopoverContent>
            <ContextMenu
                direction="column"
                disableX
                gap="0.5rem"
                padding="0.4rem"
                width="200px"
            >
                <ContextMenuItem
                    color="error"
                    onClick={() => {
                        setOpen(false);
                        tagIrrelevant.mutateAsync(id);
                    }}
                    startIcon={<VisibilityOffIcon color="error" />}
                >
                    {t('util.irrelevant')}
                </ContextMenuItem>
            </ContextMenu>
        </PopoverContent>
    );
}
