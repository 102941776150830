import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Flex } from '../../Core/Flex';
import { TickrDialog } from '../../Dialog/TickrDialog';
import { TickrDialogActions } from '../../Dialog/TickrDialogActions';
import { TickrDialogContent } from '../../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../../Dialog/TickrDialogTitle';
import { useAvailableFilters } from '../../Filters/AvailableFiltersProvider/useAvailableFilters';
import { FilterGroupItem } from '../../Filters/Components/FilterGroup';
import { FormElementWrapper } from '../../FormElements/FormElementWrapper';
import { SelectDropdown } from '../../FormElements/SelectDropdown';
import { SelectDropdownContent } from '../../FormElements/SelectDropdownContent';
import { TextInput } from '../../FormElements/TextInput';
import { useNotificationsDispatch } from '../../Providers/NotificationsProvider/useNotificationsDispatch';
import { useToaster } from '../../Providers/ToasterProvider/useToaster';
import { useYupValidation } from '../../hooks/useYupValidation';

type AlertConditionType = 'above' | 'below' | 'outsideOfNormalRange' | 'salesOpportunityOrThreat';

export function NewAlertDialog() {
    const { t } = useTranslation();
    const { string255 } = useYupValidation();
    const dispatchNotification = useNotificationsDispatch();
    const { sendAlert } = useToaster();

    const { datasourceFilters } = useAvailableFilters();

    const alertConditionPlaceholderDict: Record<AlertConditionType, string> = {
        above: t('alerts.condition.above.placeholder'),
        below: t('alerts.condition.below.placeholder'),
        outsideOfNormalRange: t('alerts.condition.outsideOfNormalRange.placeholder'),
        salesOpportunityOrThreat: t('alerts.condition.salesOpportunityOrThreat.placeholder'),
    };

    const [open, setOpen] = useState(false);
    const [clicked, setClicked] = useState(false);

    const onClose = () => setOpen(false);

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                size="small"
                startIcon={<AddCircleOutlineIcon fontSize="small" />}
            >
                {t('alerts.createNewAlert')}
            </Button>
            <TickrDialog
                maxWidth="lg"
                onClose={onClose}
                open={open}
            >
                <TickrDialogTitle onClose={onClose}>{t('alerts.createNewAlert')}</TickrDialogTitle>
                <Formik
                    initialValues={{
                        metric: '$sales',
                        scopeCategory: '',
                        scopeChannel: '',
                        conditionType: 'above' as AlertConditionType,
                        conditionValue: '',
                        alertChannel: 'email',
                        frequency: 'sendImmediately',
                        alertName: '',
                    }}
                    onSubmit={async (values) => {
                        onClose();

                        sendAlert({
                            severity: 'success',
                            text: t('alerts.alertCreated'),
                        });

                        setTimeout(() => {
                            dispatchNotification((notifications) => [
                                {
                                    author: 'chatCpg',
                                    alertName: values.alertName,
                                    pageUuid: '123',
                                    timestamp: Date.now(),
                                    id: '69',
                                    to: '/home',
                                    text: 'GTIN ...5346’s price of $5.24 on the West Region for Kroger, is forecasted to become suboptimal over the next 12 weeks. Decrease the price by $0.15 to increase market share .3% and increase sales by 5%',
                                    isNew: true,
                                    fakeTimestamp: '0 sec ago',
                                },
                                ...notifications,
                            ]);
                        }, 5000);

                        setClicked(false);
                    }}
                    validationSchema={Yup.object().shape({
                        alertName: string255,
                    })}
                >
                    {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
                        <>
                            {/* TODO: remove this overflow visible once the select dropdowns below are migrated to MUI */}
                            <TickrDialogContent style={{ overflow: 'visible' }}>
                                <Flex
                                    direction="column"
                                    gap="2rem"
                                    width="100%"
                                >
                                    <SelectDropdown
                                        onChange={(value) => setFieldValue('metric', value)}
                                        selected={values.metric}
                                        title={t('alerts.selectMetric')}
                                        values={[
                                            {
                                                title: t('alerts.metrics.$sales'),
                                                value: '$sales',
                                            },
                                            {
                                                title: t('alerts.metrics.volume'),
                                                value: 'volume',
                                            },
                                            {
                                                title: t('alerts.metrics.$share'),
                                                value: '$share',
                                            },
                                            {
                                                title: t('alerts.metrics.avgPrice'),
                                                value: 'avgPrice',
                                            },
                                            {
                                                title: t('alerts.metrics.recommendedPrice'),
                                                value: 'recommendedPrice',
                                            },
                                        ]}
                                        width="200px"
                                    />

                                    <FormElementWrapper title={t('alerts.scope')}>
                                        <Flex
                                            align="center"
                                            gap="0.5rem"
                                            width="100%"
                                        >
                                            <SelectDropdownContent
                                                onChange={(value) => setFieldValue('scopeCategory', value)}
                                                placeholder={t('alerts.scope.selectCategory')}
                                                selected={values.scopeCategory}
                                                values={[
                                                    {
                                                        value: 'all',
                                                        title: 'All Categories',
                                                    },
                                                    {
                                                        value: 'cereal',
                                                        title: 'Cereal',
                                                    },
                                                    {
                                                        value: 'flavoredDrinks',
                                                        title: 'Flavored Drinks',
                                                    },
                                                    {
                                                        value: 'functionalDrinks',
                                                        title: 'Functional Drinks',
                                                    },
                                                    {
                                                        value: 'organicFood',
                                                        title: 'Organic Food',
                                                    },
                                                    {
                                                        value: 'personalCare',
                                                        title: 'Personal Care',
                                                    },
                                                    {
                                                        value: 'nonSeasonalCandy',
                                                        title: 'Non Seasonal Candy',
                                                    },
                                                ]}
                                                width="250px"
                                            />
                                            {datasourceFilters.numerator?.cpg_banner && (
                                                <SelectDropdownContent
                                                    onChange={(value) => setFieldValue('scopeChannel', value)}
                                                    placeholder={t('alerts.scope.selectChannel')}
                                                    selected={values.scopeChannel}
                                                    values={datasourceFilters
                                                        .numerator?.cpg_banner.values as FilterGroupItem[]}
                                                    width="250px"
                                                />
                                            )}
                                            <Button size="small" startIcon={<AddCircleOutlineIcon />}>
                                                {t('alerts.addFilter')}
                                            </Button>
                                        </Flex>
                                    </FormElementWrapper>

                                    <Flex gap="0.5rem">
                                        <SelectDropdown
                                            error={errors.conditionType}
                                            onChange={(value) => setFieldValue('conditionType', value)}
                                            selected={values.conditionType}
                                            title={t('alerts.conditionType')}
                                            values={[
                                                {
                                                    title: t('alerts.condition.above'),
                                                    value: 'above',
                                                    // 'iconId: 'above'
                                                },
                                                {
                                                    title: t('alerts.condition.below'),
                                                    value: 'below',
                                                    // iconId: 'above',
                                                    // iconTransform: 'rotateX(180deg)',
                                                },
                                                {
                                                    title: t('alerts.condition.outsideOfNormalRange'),
                                                    value: 'outsideOfNormalRange',
                                                    // iconId: 'outsideOfNormalRange',
                                                },
                                                {
                                                    title: t('alerts.condition.salesOpportunityOrThreat'),
                                                    value: 'salesOpportunityOrThreat',
                                                    // iconId: 'salesOpportunity',
                                                },
                                            ]}
                                            width="250px"
                                        />
                                        <TextInput
                                            error={errors.conditionValue}
                                            id="conditionValue"
                                            name="conditionValue"
                                            onChange={handleChange}
                                            placeholder={alertConditionPlaceholderDict[values.conditionType]}
                                            title={t('alerts.conditionValue')}
                                            value={values.conditionValue}
                                            width="250px"
                                        />
                                    </Flex>
                                    <Flex gap="0.5rem" height="100%">
                                        <SelectDropdown
                                            error={errors.alertChannel}
                                            onChange={(value) => setFieldValue('alertChannel', value)}
                                            selected={values.alertChannel}
                                            title={t('alerts.alertChannel')}
                                            values={[
                                                {
                                                    title: t('util.email'),
                                                    value: 'email',
                                                    //  iconId: 'mail'
                                                },
                                            ]}
                                            width="250px"
                                        />
                                        <SelectDropdown
                                            error={errors.frequency}
                                            onChange={(value) => setFieldValue('frequency', value)}
                                            selected={values.frequency}
                                            title={t('alerts.alertFrequency')}
                                            values={[
                                                {
                                                    title: t('alerts.frequency.sendImmediately'),
                                                    value: 'sendImmediately',
                                                },
                                                {
                                                    title: t('alerts.frequency.dailySummary'),
                                                    value: 'dailySummary',
                                                },
                                                {
                                                    title: t('alerts.frequency.weeklySummary'),
                                                    value: 'weeklySummary',
                                                },
                                                {
                                                    title: t('alerts.frequency.earlyWarnings'),
                                                    value: 'earlyWarnings',
                                                },
                                            ]}
                                            width="250px"
                                        />
                                    </Flex>
                                    <TextInput
                                        error={errors.alertName}
                                        id="alertName"
                                        max={255}
                                        name="alertName"
                                        onChange={handleChange}
                                        placeholder={t('alerts.alertName.placeholder')}
                                        title={t('alerts.alertName')}
                                        value={values.alertName}
                                        width="508px"
                                    />
                                </Flex>
                            </TickrDialogContent>
                            <TickrDialogActions>
                                <Button
                                    color="middleGray"
                                    onClick={() => {
                                        onClose();
                                    }}
                                    size="small"
                                    style={{ width: '200px' }}
                                    variant="contained"
                                >
                                    {t('util.cancel')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        if (!clicked) {
                                            setClicked(true);
                                            handleSubmit();
                                        }
                                    }}
                                    size="small"
                                    style={{ width: '200px' }}
                                    variant="contained"
                                >
                                    {t('util.create')}
                                </Button>
                            </TickrDialogActions>
                        </>
                    )}
                </Formik>
            </TickrDialog>
        </>
    );
}
