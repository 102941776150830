import { styled, useTheme } from '@mui/material';

interface DotLoaderProps {
    color?: string;
    width?: string;
}

export function DotLoader({ color, width = '30px' }: DotLoaderProps) {
    const { palette } = useTheme();

    return <StyledDots color={color ?? palette.text.primary} width={width} />;
}

const StyledDots = styled('div')<{ width: string; color: string }>`
    width: ${({ width }) => width};
    aspect-ratio: 6;
    --_g: no-repeat radial-gradient(circle closest-side, ${({ color }) => color} 90%, #0000);
    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
    background-size: calc(100% / 3) 100%;
    animation: d7 1s infinite linear;
    @keyframes d7 {
        33% {
            background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
        }
        50% {
            background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
        }
        66% {
            background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
        }
    }
`;
