import { Dispatch, SetStateAction, createContext } from 'react';

interface TabContextInterface {
    activeTab: number;
    setActiveTab: Dispatch<SetStateAction<number>>;
    tabWidth: number;
    setTabWidth: Dispatch<SetStateAction<number>>;
}

export const TabContext = createContext<TabContextInterface>({
    activeTab: 0,
    setActiveTab: () => null,
    tabWidth: 0,
    setTabWidth: () => null,
});
