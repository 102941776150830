import HighchartsReact from 'highcharts-react-official';
import { useRef } from 'react';
import { useElement } from '../Element/useElement';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';

export function useLegendClick({ trendCount }: { trendCount: number }) {
    const { updateElement } = useCollectionMutations();
    const element = useElement();
    const hiddenTrends = element.settings?.hiddenTrends ?? [];
    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleLegendClick = (id: string, seriesIndex: number) => {
        if (hiddenTrends.includes(id)) {
            updateElement({
                element: {
                    ...element,
                    settings: {
                        ...element.settings,
                        hiddenTrends: hiddenTrends.filter((trend) => trend !== id),
                    },
                },
            });
        } else {
            updateElement({
                element: {
                    ...element,
                    settings: {
                        ...element.settings,
                        hiddenTrends: hiddenTrends.length === trendCount - 1 ? [] : [...hiddenTrends, id],
                    },
                },
            });
        }

        if (hiddenTrends.length === trendCount - 1 && !hiddenTrends.includes(id)) {
            chartRef.current?.chart.series.forEach((series) => {
                series.setVisible(true);
            });
        } else {
            chartRef.current?.chart.series[seriesIndex].setVisible(
                chartRef.current?.chart.series[seriesIndex].visible ? false : true
            );
        }
    };

    return {
        chartRef,
        hiddenTrends,
        handleLegendClick,
    };
}
