import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import 'react-mosaic-component/react-mosaic-component.css';
import { useNavigate, useParams } from 'react-router-dom';
import { TalkingPoints } from '../ChatCpg/TalkingPoints';
import { Flex } from '../Core/Flex';
import { TabMosaic } from '../MosaicLayout/TabMosaic';
import { HEADER_HEIGHT } from '../helpers/constants';
import { useCurrentTabs } from '../hooks/useCurrentTabs';

export function TalkingPointsPage() {
    const { pageUuid, tabUuid } = useParams();
    const navigate = useNavigate();
    const { tabs, isLoading } = useCurrentTabs();

    useEffect(() => {
        if (
            (!tabUuid && !isEmpty(tabs)) ||
            (tabUuid && !isEmpty(tabs) && !tabs?.find(({ uuid }) => uuid === tabUuid))
        ) {
            navigate(`/page/${pageUuid}/tab/${tabs[0].uuid}`);
        }
    }, [
        tabs,
        navigate,
        pageUuid,
        tabUuid,
    ]);

    if (isLoading) return null;

    return (
        <Flex
            gap="12px"
            height={`calc(100vh - ${HEADER_HEIGHT}px)`}
            overflow="hidden"
            style={{ minHeight: 0 }}
            width="100%"
        >
            <TalkingPoints />
            <Flex
                direction="column"
                grow="1"
                height="100%"
                overflow="scroll"
                padding="0 8px 8px 0"
            >
                <TabMosaic />
            </Flex>
        </Flex>
    );
}
