import { Checkbox, Radio, useTheme } from '@mui/material';
import { ChangeEvent } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useHighlightSearchTerm } from '../hooks/useHighlightSearchTerm';

export interface ControlLabelProps {
    checked?: boolean;
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    search?: string;
    subtitle?: string;
    title: string;
    type: 'checkbox' | 'radio';
    value: string;
}

export function ControlLabel({
    checked,
    disabled,
    onChange,
    search,
    subtitle,
    title,
    type,
    value,
}: ControlLabelProps) {
    const { palette } = useTheme();
    const highlightSearchTerm = useHighlightSearchTerm();

    return (
        <Flex
            align="center"
            as="label"
            key={value}
            padding={subtitle ? '9px 0' : 'unset'}
            style={{
                cursor: disabled ? 'default' : 'pointer',
            }}
        >
            {type === 'radio' && (
                <Radio
                    checked={checked}
                    disabled={disabled}
                    id={value}
                    onChange={onChange}
                    size="small"
                />
            )}
            {type === 'checkbox' && (
                <Checkbox
                    checked={checked}
                    disabled={disabled}
                    id={value}
                    onChange={onChange}
                    size="small"
                />
            )}
            <Flex
                direction="column"
                gap="0.2rem"
                padding={subtitle ? 'unset' : '9px 0'}
            >
                <Typography
                    color={disabled ? palette.text.disabled : palette.text.primary}
                    variant="p-14-b"
                >
                    {highlightSearchTerm(title, search)}
                </Typography>
                {subtitle && (
                    <Typography
                        color={disabled ? palette.text.disabled : palette.text.secondary}
                        variant="p-14-b"
                    >
                        {highlightSearchTerm(subtitle, search)}
                    </Typography>
                )}
            </Flex>
        </Flex>
    );
}
