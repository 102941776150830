import { useQuery } from '@tanstack/react-query';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useApp } from '../Providers/AppProvider/useApp';
import { isoToUnixTimestamp } from '../helpers/isoToUnixTimestamp';

export interface ChatObj {
    created_at: string;
    creator_id: string;
    name: string;
    uuid: string;
}

export function useChats() {
    const { chatApiRoute } = useApp();
    const { tidsApi } = useApi();

    const { data = [] } = useQuery({
        queryKey: ['chats'],
        queryFn: async ({ signal }) => {
            const { data } = await tidsApi.get<{ chats: ChatObj[] }>(`v2${chatApiRoute}/chat`, { signal });
            return data.chats.sort((a, b) => isoToUnixTimestamp(b.created_at) - isoToUnixTimestamp(a.created_at));
        },
    });

    return {
        chats: data,
    };
}
