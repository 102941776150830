import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, IconButton, styled, useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthorAvatar } from '../../Core/AuthorAvatar';
import { ContextMenu } from '../../Core/ContextMenu';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { ChatCpgIcon } from '../../Icons/ChatCpgIcon';
import { PopoverContent } from '../../Popover/PopoverContent';
import { Notification } from '../../Providers/NotificationsProvider/NotificationsContext';
import { useNotifications } from '../../Providers/NotificationsProvider/useNotifications';
import { useNotificationsDispatch } from '../../Providers/NotificationsProvider/useNotificationsDispatch';
import { Tab } from '../../Tabs/Tab';
import { TabList } from '../../Tabs/TabList';
import { TabPanel } from '../../Tabs/TabPanel';
import { TabsProvider } from '../../Tabs/TabsProvider';
import { NewAlertDialog } from './NewAlertDialog';

export function NotificationsPopoverContent() {
    const { t } = useTranslation();
    const notifications = useNotifications();
    const setNotifications = useNotificationsDispatch();

    return (
        <PopoverContent>
            <ContextMenu
                direction="column"
                disableX
                gap="1.5rem"
                padding="0.5rem"
                style={{ overflow: 'visible' }}
                width="550px"
            >
                <TabsProvider>
                    <TabList listName="tabs">
                        <Tab index={0} name={t('alerts.showAll')}>
                            {t('alerts.showAll')}
                        </Tab>
                        <Tab index={1} name={t('alerts.collaboration')}>
                            {t('alerts.collaboration')}
                        </Tab>
                        <Tab index={2} name={t('alerts.insights')}>
                            {t('alerts.insights')}
                        </Tab>
                    </TabList>
                    <TabPanel
                        index={0}
                        name={t('alerts.showAll')}
                        style={tabPanelStyle}
                    >
                        <Flex
                            align="center"
                            justify="space-between"
                            width="100%"
                        >
                            <Button
                                color="primaryText"
                                onClick={() => {
                                    setNotifications([]);
                                }}
                                size="small"
                                startIcon={<ClearIcon />}
                            >
                                {t('alerts.clearAll')}
                            </Button>
                            <NewAlertDialog />
                        </Flex>
                        <NotificationsList notifications={notifications} />
                    </TabPanel>
                    <TabPanel
                        index={1}
                        name={t('alerts.collaboration')}
                        style={tabPanelStyle}
                    >
                        <NotificationsList
                            notifications={notifications.filter(({ author }) => author !== 'chatCpg')}
                        />
                    </TabPanel>
                    <TabPanel
                        index={2}
                        name={t('alerts.insights')}
                        style={tabPanelStyle}
                    >
                        <NotificationsList
                            notifications={notifications.filter(({ author }) => author === 'chatCpg')}
                        />
                    </TabPanel>
                </TabsProvider>
            </ContextMenu>
        </PopoverContent>
    );
}

const tabPanelStyle: CSSProperties = {
    width: '100%',
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
};

interface NotificationsListProps {
    notifications: Notification[];
}

function NotificationsList({ notifications }: NotificationsListProps) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const setNotifications = useNotificationsDispatch();

    const handleLink = (notificationId: string) => {
        setNotifications(
            (notifications) => notifications.map((n) => (n.id === notificationId
                ? {
                    ...n,
                    isNew: false,
                }
                : n))
        );
    };

    return (
        <Flex
            as="ul"
            direction="column"
            gap="1rem"
            width="100%"
        >
            {notifications.map(
                ({ author, authorColor, id, isNew, isPrediction, text, to, fakeTimestamp }) => (
                    <Flex
                        align="center"
                        as="li"
                        justify="space-between"
                        key={`notification-${id}`}
                        width="100%"
                    >
                        <Flex align="center" gap="0.2rem">
                            <StyledBar color={isNew ? palette.indicator.main : palette.background.tertiary} />
                            <IconButton
                                onClick={() => {
                                    setNotifications((prev) => prev.filter((n) => n.id !== id));
                                }}
                                size="small"
                            >
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </Flex>
                        <StyledLink
                            onClick={() => {
                                handleLink(id);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleLink(id);
                                }
                            }}
                            to={to}
                        >
                            {author === 'chatCpg'
                                ? (
                                    <ChatCpgIcon size="24px" />
                                )
                                : (
                                    <AuthorAvatar
                                        author={author}
                                        background={authorColor}
                                        size="24px"
                                    />
                                )}
                            <Typography multiline style={{ maxWidth: '300px' }}>
                                {isPrediction && <span className="h-12-b">{t('alerts.prediction')}</span>}
                                {text}
                            </Typography>
                            <Typography
                                nowrap
                                secondary
                                style={{ minWidth: '100px' }}
                            >
                                {fakeTimestamp}
                                {/* {formatDate(timestamp, 'full')} */}
                            </Typography>
                            <ArrowForwardIosIcon fontSize="small" />
                        </StyledLink>
                    </Flex>
                )
            )}
        </Flex>
    );
}

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    justify-content: flex-start;
    cursor: pointer;
`;

const StyledBar = styled('div')<{ color: string }>`
    background-color: ${({ color }) => color};
    min-height: 30px;
    min-width: 2px;
`;
