import { useTheme } from '@mui/material';
import { EditableTypography } from '../Core/EditableTypography';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useElement } from '../Element/useElement';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useDashboardSettings } from '../Providers/DashboardSettingsProvider.tsx/useDashboardSettings';
import { useDateFormatter } from '../hooks/useDateFormatter';
import { usePage } from '../hooks/usePage';

export function IntroCard() {
    const { palette } = useTheme();
    const { presentationMode } = useDashboardSettings();
    const formatDate = useDateFormatter();
    const { page } = usePage();
    const { updateElement } = useCollectionMutations();
    const element = useElement();

    const {
        title = '',
        subtitle = '',
        text = '',
        footnote = '',
    } = element.settings?.introCard || {};

    if (!page) return null;

    return (
        <Flex
            direction="column"
            gap="24px"
            height="100%"
            padding="32px 48px"
            style={{
                background: palette.background.secondary,
                borderRadius: '0 0 6px 6px',
                overflow: 'auto',
            }}
            width="100%"
        >
            <Flex direction="column" gap="10px">
                <Typography as="h2" variant="h-30-b">
                    {title}
                </Typography>
                <Typography variant="h-16-b">{subtitle}</Typography>
            </Flex>
            <Typography secondary variant="h-16-b">
                {formatDate(page?.updatedAt ?? '', 'full')}
            </Typography>
            <EditableTypography
                contentEditable={!presentationMode}
                focusBackground={palette.background.default}
                initialText={text}
                onSave={(text) => {
                    updateElement({
                        mergeProps: {
                            settings: {
                                introCard: {
                                    text,
                                },
                            },
                        },
                        elementUuid: element.uuid,
                        queryKey: element.tabUuid ?? element.pageUuid,
                    });
                }}
                padding="8px 0"
                variant="p-18-r"
            />
            <Typography secondary variant="p-16-b">
                {footnote}
            </Typography>
        </Flex>
    );
}
