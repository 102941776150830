import { useMemo, useState } from 'react';
import { SortOrder, sortTableData } from './sortTableData';

interface UseTableSortProps<T> {
    initOrder: SortOrder;
    initOrderBy: keyof T;
    data: T[];
}

export function useTableSort<T>({
    initOrder,
    initOrderBy,
    data,
}: UseTableSortProps<T>) {
    const [order, setOrder] = useState<'asc' | 'desc'>(initOrder);
    const [orderBy, setOrderBy] = useState<keyof T>(initOrderBy);

    const sortedRows = useMemo(() => {
        return sortTableData(data, orderBy, order);
    }, [order, orderBy, data]);

    return {
        order,
        orderBy,
        setOrder,
        setOrderBy,
        sortedRows,
    };
}
