import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ScoreIcon from '@mui/icons-material/Score';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import { ReactNode } from 'react';

export type AppPage = 'home' | 'myWork';

export const navIcons: Record<SolutionType | AppPage, ReactNode> = {
    home: <HomeIcon />,
    myWork: <WorkOutlineIcon />,
    optimizePricing: <PriceCheckIcon />,
    outOfStockPredictions: <ProductionQuantityLimitsIcon />,
    scorecard: <ScoreIcon />,
    substitutions: <SwitchAccessShortcutIcon />,
    forecast: <InsightsIcon />,
};
