import CloseIcon from '@mui/icons-material/Close';
import { IconButton, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { usePopoverContext } from '../Popover/usePopoverContext';
import { Flex, FlexProps } from './Flex';

interface ContextMenuProps extends FlexProps {
    disableX?: boolean;
}

export function ContextMenu({ disableX, children, ...props }: PropsWithChildren<ContextMenuProps>) {
    const { shape } = useTheme();
    const { setOpen } = usePopoverContext();

    return (
        <Flex
            {...props}
            style={{
                zIndex: 102,
                borderRadius: shape.borderRadius,
                boxShadow: 'var(--shadow-400)',
                maxHeight: 'calc(100vh - 80px)',
                overflowY: 'hidden',
            }}
        >
            {!disableX && (
                <IconButton
                    onClick={() => {
                        setOpen(false);
                    }}
                    size="small"
                    style={{
                        position: 'absolute',
                        right: '0.1rem',
                        top: '0.1rem',
                        zIndex: 51,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
            {children}
        </Flex>
    );
}
