import { Flex } from '../Core/Flex';
import { ExpandButton } from './ToolbarButtons/ExpandButton';
import { RemoveButton } from './ToolbarButtons/RemoveButton';
import { useMosaic } from './useMosaic';

export function ToolbarControls() {
    const { mosaicActions } = useMosaic();
    const root = mosaicActions.getRoot();

    return (
        <Flex
            align="center"
            justify="flex-end"
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            wrap="wrap-reverse"
        >
            {root !== 'chatCpg' && (
                <>
                    {typeof root !== 'string' && <ExpandButton />}
                    <RemoveButton />
                </>
            )}
        </Flex>
    );
}
