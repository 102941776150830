import { PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';

export function SolutionPanel({ children }: PropsWithChildren) {
    return (
        <Flex
            direction="column"
            gap="1.6rem"
            overflow="hidden"
            padding="0 2rem 0 2rem"
            style={{
                gridRow: '2',
                gridColumn: '2',
            }}
            width="100%"
        >
            {children}
        </Flex>
    );
}
