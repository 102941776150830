import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, ButtonOwnProps } from '@mui/material';
import { PropsWithChildren, ReactNode, forwardRef } from 'react';
interface ContextMenuItemProps {
    className?: string;
    color?: ButtonOwnProps['color'];
    disabled?: boolean;
    onClick?: () => void;
    showPicker?: boolean;
    startIcon?: ReactNode;
}

export const ContextMenuItem = forwardRef<HTMLButtonElement, PropsWithChildren<ContextMenuItemProps>>(
    ({ onClick, disabled, color = 'primaryText', showPicker, startIcon, children }, ref) => {
        return (
            <Button
                className="h-14-b"
                color={color}
                disabled={disabled}
                endIcon={showPicker && <ChevronRightIcon />}
                onClick={onClick}
                ref={ref}
                size="medium"
                startIcon={startIcon}
                style={{
                    justifyContent: showPicker ? 'space-between' : 'left',
                    letterSpacing: '.08em',
                    textTransform: 'none',
                    textWrap: 'nowrap',
                    width: '100%',
                }}
            >
                {children}
            </Button>
        );
    }
);
