import { Placement } from '@floating-ui/react';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { usePopoverContext } from './usePopoverContext';

const staticSidePlacement: Record<Placement, string> = {
    top: 'bottom',
    'top-start': 'bottom',
    'top-end': 'bottom',
    bottom: 'top',
    'bottom-start': 'top',
    'bottom-end': 'top',
    left: 'right',
    'left-start': 'right',
    'left-end': 'right',
    right: 'left',
    'right-start': 'left',
    'right-end': 'left',
};

const arrowHypotenuse = 12;
const arrowSide = arrowHypotenuse / 2;

export const PopoverArrow = forwardRef<HTMLDivElement>((_, ref) => {
    const { palette } = useTheme();
    const { middlewareData, placement } = usePopoverContext();
    const staticPosition = staticSidePlacement[placement];
    const activePosition = middlewareData.arrow?.x ? 'left' : 'top';
    const activePositionOffset = middlewareData.arrow?.x ?? middlewareData.arrow?.y;

    return (
        <div
            ref={ref}
            style={{
                background: palette.background.tertiary,
                position: 'absolute',
                [activePosition]: `${activePositionOffset}px` ?? 0,
                [staticPosition]: `-${arrowSide}px`,
                width: `${arrowHypotenuse}px`,
                height: `${arrowHypotenuse}px`,
                transform: 'rotate(45deg)',
                zIndex: 101,
                borderRadius: '2px',
            }}
        />
    );
});
