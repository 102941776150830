/* eslint-disable @stylistic/jsx-sort-props */
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { StagedFiltersProvider } from '@tickr/ui/src/Filters/StagedFiltersProvider/StagedFiltersProvider';
import { ConnectYourData } from '@tickr/ui/src/Forecast/ConnectYourData';
import { SelectCovariates } from '@tickr/ui/src/Forecast/SelectCovariates';
import { SelectForecast } from '@tickr/ui/src/Forecast/SelectForecast';
import { Dashboard } from '@tickr/ui/src/Pages/Dashboard';
import { Home } from '@tickr/ui/src/Pages/Home';
import { MyWork } from '@tickr/ui/src/Pages/MyWork';
import { PricingAlert } from '@tickr/ui/src/Pages/PricingAlert';
import { OptimizePricingSolution } from '@tickr/ui/src/Pages/Solutions/OptimizePricingSolution';
import { SubstitutionsSolution } from '@tickr/ui/src/Pages/Solutions/SubstitutionsSolution';
import { WelcomeChatCpg } from '@tickr/ui/src/Pages/WelcomeChatCpg';
import { useUser } from '@tickr/ui/src/Providers/UserProvider/useUser';
import { SolutionLayout } from '@tickr/ui/src/Solutions/SolutionLayout';
import { useCurrentForecast } from '@tickr/ui/src/hooks/useCurrentForecast';
import { Navigate, Route, Routes } from 'react-router-dom';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export function DemoRoutes() {
    const { hasSmartScorecard } = useUser();
    const forecastPage = useCurrentForecast();

    return (
        <SentryRoutes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/my-work" element={<MyWork />} />
            <Route path="/out-of-stock-predictions" element={<Dashboard />} />
            <Route path="/page/:pageUuid" element={<Dashboard />} />
            <Route path="/page/:pageUuid/tab/:tabUuid" element={<Dashboard />} />
            <Route path="/page/:pageUuid/*" element={<Dashboard />} />
            <Route path="/page" element={<Dashboard />} />
            <Route path="/pricing-alert" element={<PricingAlert />} />
            <Route path="/solutions" element={<Navigate to="/home" />} />
            <Route path="/solutions" element={<SolutionLayout />}>
                <Route
                    path="optimize-pricing"
                    element={(
                        <StagedFiltersProvider>
                            <OptimizePricingSolution />
                        </StagedFiltersProvider>
                    )}
                />
                <Route
                    path="substitutions"
                    element={(
                        <StagedFiltersProvider>
                            <SubstitutionsSolution />
                        </StagedFiltersProvider>
                    )}
                />
                {forecastPage && <Route path="forecast/connect-your-data" element={<ConnectYourData />} />}
                {forecastPage && <Route path="forecast/select-forecast" element={<SelectForecast />} />}
                {forecastPage && <Route path="forecast/select-covariates" element={<SelectCovariates />} />}
                {forecastPage && <Route path="forecast" element={<Navigate to="connect-your-data" />} />}
                {forecastPage && <Route path="forecast/*" element={<Navigate to="connect-your-data" />} />}
            </Route>
            {hasSmartScorecard && <Route path="/scorecard" element={<Dashboard />} />}
            <Route path="/solutions/*" element={<Navigate to="/solutions" />} />
            <Route path="/welcome/chatcpg" element={<WelcomeChatCpg />} />
            <Route path="/*" element={<Navigate to="/home" />} />
        </SentryRoutes>
    );
}
