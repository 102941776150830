import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { MosaicBranch, MosaicNode, createRemoveUpdate, getLeaves, updateTree } from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { ChatButton } from '../ChatCpg/ChatButton';
import { Flex } from '../Core/Flex';
import { useDashboardSettings } from '../Providers/DashboardSettingsProvider.tsx/useDashboardSettings';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { usePage } from '../hooks/usePage';
import { useUpdatePageMosaic } from '../hooks/useUpdatePageMosaic';
import { useUpdateTabMosaic } from '../hooks/useUpdateTabMosaic';
import { RestoreDefaultButton } from './RestoreDefaultButton';

export function DashboardControls() {
    const { tabUuid } = useParams<{ tabUuid: string }>();
    const { presentationMode } = useDashboardSettings();
    const updateTabMosaic = useUpdateTabMosaic();
    const updatePageMosaic = useUpdatePageMosaic();
    const { mosaicNode: pageMosaicNode, defaultMosaic: pageDefaultMosaic, isStreaming } = usePage();
    const currentTab = useCurrentTab();
    const mosaicNode = currentTab?.settings?.mosaicNode ?? pageMosaicNode;

    const defaultMosaic = tabUuid ? currentTab?.settings?.defaultMosaic : pageDefaultMosaic;

    const isRootEqualToDefault = useMemo(
        () => isEqual(mosaicNode, defaultMosaic),
        [mosaicNode, defaultMosaic]
    );

    return (
        <Flex align="center" gap="2rem">
            {!presentationMode && !isRootEqualToDefault && <RestoreDefaultButton />}
            <ChatButton
                disabled={isStreaming}
                onClick={() => {
                    const existingNode = tabUuid
                        ? currentTab?.settings?.mosaicNode
                        : pageMosaicNode;

                    if (!existingNode) return;
                    const leaves = getLeaves(existingNode);

                    if (!leaves.includes('chatCpg')) {
                        const newNode: MosaicNode<string> = {
                            direction: 'row',
                            first: existingNode,
                            second: 'chatCpg',
                        };

                        tabUuid ? updateTabMosaic({ node: newNode }) : updatePageMosaic({ node: newNode });
                    } else {
                        const path = getPathById(existingNode, 'chatCpg');
                        if (!path) return;

                        const updatedTree = updateTree<string>(
                            existingNode,
                            [createRemoveUpdate<string>(existingNode, path)]
                        );

                        tabUuid
                            ? updateTabMosaic({ node: updatedTree })
                            : updatePageMosaic({ node: updatedTree });
                    }
                }}
            />
        </Flex>
    );
}

function getPathById(
    node: MosaicNode<string>,
    id: string,
    path: MosaicBranch[] = []
): MosaicBranch[] | null {
    if (typeof node === 'string') return node === id ? path : null;

    const firstPath = getPathById(node.first, id, [...path, 'first']);

    if (firstPath) return firstPath;

    const secondPath = getPathById(node.second, id, [...path, 'second']);

    if (secondPath) return secondPath;

    return null;
}
