import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LanguageIcon from '@mui/icons-material/Language';
import { styled, useTheme } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PageAttributes } from '@tickr/sequelize-models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { useDebounceValue } from 'usehooks-ts';
import { DotLoader } from '../ChatCpg/DotLoader';
import { SolutionButton } from '../ChatCpg/SolutionButton';
import { ChatDialog } from '../ChatCpgV2/ChatDialog';
import { Flex } from '../Core/Flex';
import { Grid } from '../Core/Grid';
import { SearchBar } from '../Core/SearchBar';
import { TickrErrorBoundary } from '../Core/TickrErrorBoundary';
import { Typography } from '../Core/Typography';
import { CollectionTile } from '../Element/CollectionTile';
import { Header } from '../Navigation/Header';
import { PageLayout } from '../PageLayout/PageLayout';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useUser } from '../Providers/UserProvider/useUser';
import { SortOrder } from '../Table/sortTableData';
import { HEADER_HEIGHT } from '../helpers/constants';
import { gradientWrapStyles } from '../helpers/gradientWrapStyles';
import { useLaunchForecastInProgress } from '../hooks/onLaunchAnalysis/useLaunchForecastInProgress';
import { useCurrentProject } from '../hooks/useCurrentProject';

export interface SortOption {
    title: string;
    value: string;
    defaultOrder: SortOrder;
}

export function Home() {
    const { t } = useTranslation();
    const { palette, shape } = useTheme();
    const navigate = useNavigate();
    const { isDemo } = useUser();
    const { dashboardApi } = useApi();
    const { currentProject } = useCurrentProject();

    const { launchForecastInProgress, isLoading: isLoadingForecast } = useLaunchForecastInProgress();

    const [search, setSearch] = useState('');
    const [debouncedSearch] = useDebounceValue(search, 500);

    const { data: recentPages = [], isLoading: isLoadingRecentPages, isFetching: isFetchingRecentPages } = useQuery({
        queryKey: ['recent-pages', debouncedSearch],
        queryFn: async ({ signal }) => {
            const { data: recentPages } = await dashboardApi.get<PageAttributes[]>('recent-pages', {
                params: {
                    search: debouncedSearch,
                },
                signal,
            });

            return recentPages;
        },
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
    });

    const [ref, { width }] = useMeasure<HTMLDivElement>();

    const noPages = currentProject && currentProject.pages.length === 0;
    const noRecentPages = recentPages.length === 0 && debouncedSearch === '' && !isLoadingRecentPages;
    const noRecentPagesFound = recentPages.length === 0 && debouncedSearch !== '' && !isLoadingRecentPages && !isFetchingRecentPages;

    return (
        <PageLayout pageTitle={t('page.home')} style={{ paddingBottom: '1rem' }}>
            <Header endChildren={<ChatDialog />} />
            <TickrErrorBoundary
                message={t('errorBoundary.page', { pageName: t('page.home') })}
                navigable={false}
                padding="1rem"
            >
                <Flex
                    direction="column"
                    gap="3rem"
                    height={`calc(100% - ${HEADER_HEIGHT}px)`}
                    padding="min(2%, 50px) 100px 0px 100px"
                    style={{
                        overflowY: 'scroll',
                        minHeight: '0px',
                        maxWidth: '1500px',
                        alignSelf: 'center',
                    }}
                    width="100%"
                >
                    <Flex
                        direction="column"
                        gap="1rem"
                        width="100%"
                    >
                        <Flex
                            align="center"
                            justify="space-between"
                            width="100%"
                        >
                            <Typography as="h2" variant="h-22-b">
                                {t('chatCpg.selectAChatCpgAiSolution')}
                            </Typography>
                            <Flex align="center" gap="0.6rem">
                                <LanguageIcon color="cornflowerBlue" />
                                <Typography secondary variant="p-14-r">{t('home.verified3rdPartySolutions')}</Typography>
                            </Flex>
                        </Flex>
                        <Flex
                            direction="column"
                            gap="1rem"
                            width="100%"
                        >
                            <Typography
                                as="h2"
                                secondary
                                style={{ letterSpacing: '1px' }}
                                variant="p-18-r"
                            >
                                {t('chatCpg.solutionSection.distribution')}
                            </Typography>
                            <Flex gap="1rem" wrap="wrap">
                                <SolutionButton
                                    disabled={!isDemo || isLoadingForecast}
                                    to="/out-of-stock-predictions"
                                >
                                    {t('solution.outOfStockPredictions')}
                                </SolutionButton>
                                <SolutionButton disabled={!isDemo || isLoadingForecast}>
                                    {t('solution.identifyExpansion')}
                                </SolutionButton>
                                <SolutionButton disabled={!isDemo || isLoadingForecast}>
                                    {t('solution.scenarioPlanner')}
                                </SolutionButton>
                            </Flex>
                            <Typography
                                as="h2"
                                secondary
                                style={{ letterSpacing: '1px' }}
                                variant="p-18-r"
                            >
                                {t('chatCpg.solutionSection.pricing')}
                            </Typography>
                            <Flex gap="1rem" wrap="wrap">
                                <SolutionButton
                                    disabled={!isDemo || isLoadingForecast}
                                    to="/solutions/optimize-pricing"
                                >
                                    {t('solution.optimizePricing')}
                                </SolutionButton>
                                <SolutionButton disabled={isLoadingForecast} onClick={launchForecastInProgress}>
                                    {t('forecast.smartForecast')}
                                </SolutionButton>
                                <SolutionButton
                                    disabled={!isDemo || isLoadingForecast}
                                >
                                    {t('solution.optimizePromotionTiming')}
                                </SolutionButton>
                                <SolutionButton
                                    disabled={!isDemo || isLoadingForecast}
                                >
                                    <Flex align="center" gap="0.6rem">
                                        {t('solution.optimizeTradeFunds')}
                                        <LanguageIcon color="cornflowerBlue" />
                                    </Flex>
                                </SolutionButton>
                                <SolutionButton disabled={!isDemo || isLoadingForecast}>
                                    {t('solution.competitivePriceIndex')}
                                </SolutionButton>
                                {/* <SolutionButton
                                    disabled={!isDemo || isLoadingForecast}
                                    to={hasSubstitutions ? '/solutions/substitutions' : '/'}
                                >
                                    {t('solution.optimizeSubstitutions')}
                                </SolutionButton> */}
                            </Flex>
                            <Typography
                                as="h2"
                                secondary
                                style={{ letterSpacing: '1px' }}
                                variant="p-18-r"
                            >
                                {t('chatCpg.solutionSection.createYourOwn')}
                            </Typography>
                            <Flex gap="1rem" wrap="wrap">
                                <SolutionButton disabled={!isDemo || isLoadingForecast}>
                                    <Flex align="center" gap="0.6rem">
                                        <AddCircleOutlineIcon color="primary" fontSize="small" />
                                        {t('solution.createNewAnalysis')}
                                    </Flex>
                                </SolutionButton>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex
                        direction="column"
                        gap="2rem"
                        width="100%"
                    >
                        <Flex
                            align="center"
                            gap="3rem"
                            style={{ minHeight: '34px' }}
                        >
                            <Typography as="h2" variant="h-22-b">
                                {t('home.recentAnalyses')}
                            </Typography>
                            <Flex align="center" gap="1rem">
                                {currentProject && currentProject.pages.length > 5 && (
                                    <>
                                        <SearchBar
                                            inputId="my-work-search"
                                            inputWidth="300px"
                                            onSearch={setSearch}
                                            placeholder={t('util.search')}
                                            poll
                                            search={search}
                                        />
                                        {isFetchingRecentPages && <DotLoader color={palette.text.secondary} />}
                                    </>
                                )}
                            </Flex>
                        </Flex>
                        {noRecentPagesFound && (
                            <Typography
                                secondary
                                variant="p-16-r"
                            >
                                {t('home.recentAnalyses.none', { search: debouncedSearch })}
                            </Typography>
                        )}
                        <Grid
                            gap="15px"
                            padding="2px 0 2px 6px"
                            ref={ref}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '144px',
                                gridAutoFlow: 'column',
                                overflowX: 'hidden',
                            }}
                            templateColumns="repeat(auto-fit, 190px)"
                            templateRows="140px"
                            width="100%"
                        >
                            {(noPages || noRecentPages) && (
                                <LinkGradientWrap
                                    to={isDemo ? '/solutions' : '/solutions/forecast/connect-your-data'}
                                >
                                    <Flex
                                        align="center"
                                        direction="column"
                                        gap="1rem"
                                        justify="center"
                                        style={{
                                            background: palette.background.default,
                                            borderRadius: shape.borderRadius,
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        <AddIcon color="primary" fontSize="large" />
                                        <Typography
                                            color={palette.primary.main}
                                            multiline
                                            textAlign="center"
                                            variant="button-12"
                                            width="100px"
                                        >
                                            {isDemo ? t('home.createNewSolution') : t('forecast.createNewForecast')}
                                        </Typography>
                                    </Flex>
                                </LinkGradientWrap>
                            )}
                            {isLoadingRecentPages && !noPages && Array(currentProject.pages.length || 3)
                                .fill(0).map((_, i) => (
                                    <Skeleton
                                        baseColor={palette.background.secondary}
                                        containerClassName="flex-skeleton"
                                        height="100%"
                                        highlightColor={palette.background.tertiary}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${i}-recent-analysis-skeleton`}
                                        style={{
                                            borderRadius: shape.borderRadius,
                                            display: 'flex',
                                        }}
                                        width="100%"
                                    />
                                ))}
                            {!isLoadingRecentPages && recentPages.slice(0, Math.floor(width / 205)).map((page) => (
                                <CollectionTile
                                    baseZindex={5}
                                    disableTooltip
                                    item={page}
                                    key={page.uuid}
                                    onClick={() => {
                                        if (page.inProgress && page.solutionType === 'forecast') {
                                            launchForecastInProgress();
                                        } else if (page.solutionType === 'scorecard') {
                                            navigate('/scorecard');
                                        } else if (page.solutionType === 'outOfStockPredictions') {
                                            navigate('/out-of-stock-predictions');
                                        } else {
                                            navigate(`/page/${page.uuid}`);
                                        }
                                    }}
                                />
                            ))}
                        </Grid>
                    </Flex>
                </Flex>
            </TickrErrorBoundary>
        </PageLayout>
    );
}

const LinkGradientWrap = styled(Link)(({ theme }) => gradientWrapStyles(theme));
