import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { TickrErrorBoundary } from '../Core/TickrErrorBoundary';
import { Header } from '../Navigation/Header';
import { PageLayout } from '../PageLayout/PageLayout';

export function SolutionLayout() {
    const { t } = useTranslation();
    const outletContext = useOutletContext();
    const { pathname } = useLocation();
    const theme = useTheme();

    const pageNames: Record<string, string> = {
        '/solutions': t('chatCpg.page.solutions'),
        '/solutions/forecast/connect-your-data': t('forecast.smartForecast'),
        '/solutions/forecast/select-forecast': t('forecast.smartForecast'),
        '/solutions/forecast/select-covariates': t('forecast.smartForecast'),
        '/solutions/out-of-stock-predictions': t('chatCpg.page.solutions.outOfStockPredictions'),
        '/solutions/optimize-pricing': t('chatCpg.page.solutions.optimizePricing'),
        '/solutions/substitutions': t('chatCpg.page.solutions.optimizeSubstitutions'),
    };

    const pageTitle = pageNames[pathname] ?? t('chatCpg.page.solutions');

    return (
        <PageLayout
            hideOutlet
            pageTitle={pageTitle}
            style={{
                background: `linear-gradient(
                    to right, 
                    ${theme.palette.background.default} 63%, 
                    ${theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white} 63% 100%
                )`,
            }}
        >
            <Header pageTitle={pageTitle} />
            <TickrErrorBoundary message={t('errorBoundary.page', { pageName: pageTitle })} padding="1rem">
                <Outlet context={outletContext} />
            </TickrErrorBoundary>
        </PageLayout>
    );
}
