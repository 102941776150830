import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { SlideCarousel } from '../Core/SlideCarousel';
import { ValueBox } from '../Core/ValueBox';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { initialComparisonChartData } from '../helpers/comparisonChartHelpers';
import { useDateTimeLabelFormats } from '../hooks/useDateTimeLabelFormats';
import { useLegendClick } from '../hooks/useLegendClick';
import { usePage } from '../hooks/usePage';
import { HighchartsContainer } from './HighchartsContainer';
import { formatTrend } from './chartHelpers';
import { useFormatYAxisOptions } from './useFormatYAxisOptions';

export function PriceComparison() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const formatYAxisOptions = useFormatYAxisOptions();
    const { optimizePricingSummaryData } = usePage();
    const dateTimeLabelFormats = useDateTimeLabelFormats();
    const influencerData = optimizePricingSummaryData?.influencers;
    const priceChartData = optimizePricingSummaryData?.priceChart;
    const pvsChartData = optimizePricingSummaryData?.pvsChart;

    const chartData = useMemo(() => {
        return influencerData && priceChartData && pvsChartData
            ? {
                myProduct: {
                    id: influencerData.id,
                    name: influencerData.name,
                    trend: formatTrend({
                        trend: pvsChartData?.price,
                        daysToAdd: 6,
                    }),
                },
                competitor1: {
                    id: influencerData.influencers[0].id,
                    name: influencerData.influencers[0].name,
                    trend: formatTrend({
                        trend: priceChartData[influencerData.influencers[0].id]?.price,
                        daysToAdd: 6,
                    }),
                },
                competitor2: {
                    id: influencerData.influencers[1].id,
                    name: influencerData.influencers[1].name,
                    trend: formatTrend({
                        trend: priceChartData[influencerData.influencers[1].id]?.price,
                        daysToAdd: 6,
                    }),
                },
            }
            : initialComparisonChartData;
    }, [
        influencerData,
        priceChartData,
        pvsChartData,
    ]);

    const options = useMemo(() => {
        const highchartsOptions: Highcharts.Options = {
            tooltip: {
                shared: true,
                dateTimeLabelFormats,
                valuePrefix: '$',
                borderColor: palette.info.main,
            },
            chart: {
                animation: false,
            },
            xAxis: {
                type: 'datetime',
                startOfWeek: 6,
                crosshair: true,
            },
            legend: {
                enabled: false,
            },
            yAxis: [
                formatYAxisOptions({
                    labels: {
                        style: {
                            color: palette.text.primary,
                        },
                        formatter: function() {
                            return `$${Highcharts.numberFormat(this.value as number, 2)}`;
                        },
                    },
                }),
            ],
            series: [
                {
                    name: t('metrics.myPrice'),
                    type: 'line',
                    color: palette.text.primary,
                    dashStyle: 'Solid',
                    lineWidth: 2,
                    data: chartData.myProduct.trend,
                },
                {
                    name: t('metrics.competitor1'),
                    type: 'line',
                    color: palette.secondary.main,
                    dashStyle: 'Solid',
                    lineWidth: 2,
                    data: chartData.competitor1?.trend,
                },
                {
                    name: t('metrics.competitor2'),
                    type: 'line',
                    color: palette.tickleMePink.main,
                    dashStyle: 'Solid',
                    lineWidth: 2,
                    data: chartData.competitor2?.trend,
                },
            ],
        };

        return highchartsOptions;
    }, [
        dateTimeLabelFormats,
        palette.info.main,
        palette.text.primary,
        palette.secondary.main,
        palette.tickleMePink.main,
        formatYAxisOptions,
        t,
        chartData.myProduct.trend,
        chartData.competitor1?.trend,
        chartData.competitor2?.trend,
    ]);

    const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick({ trendCount: 3 });

    return (
        <Flex
            align="stretch"
            direction="column"
            height="100%"
            justify="stretch"
            style={{
                minHeight: '200px',
                minWidth: 0,
            }}
            width="100%"
        >
            <SlideCarousel
                alignItems="flex-start"
                gap="0.2rem"
                height="45px"
                width="100%"
            >
                {chartData.myProduct.id && (
                    <InfoTooltip
                        isHidden={hiddenTrends.includes(chartData.myProduct.id)}
                        leaveOpenOnHover
                        text={chartData.myProduct.name}
                    >
                        <ValueBox
                            allNeutral
                            color={palette.text.primary}
                            label={t('metrics.myPrice')}
                            onClick={() => handleLegendClick(chartData.myProduct.id, 0)}
                            secondary
                            style={{
                                opacity: hiddenTrends.includes(chartData.myProduct.id) ? 0.5 : 1,
                            }}
                            subtitle={chartData.myProduct.id}
                            valueType="latest"
                        />
                    </InfoTooltip>
                )}
                {chartData.competitor1?.id !== undefined && (
                    <InfoTooltip
                        isHidden={hiddenTrends.includes(chartData.competitor1.id)}
                        leaveOpenOnHover
                        text={chartData.competitor1.name}
                    >
                        <ValueBox
                            allNeutral
                            color={palette.secondary.main}
                            label={t('metrics.competitor1')}
                            onClick={() => handleLegendClick(chartData.competitor1?.id as string, 1)}
                            secondary
                            style={{
                                opacity: hiddenTrends.includes(chartData.competitor1.id) ? 0.5 : 1,
                            }}
                            subtitle={chartData.competitor1.id}
                            // indicator="dash"
                            // valueType="latest"
                            // value={chartData.competitor1.legendVal}
                            // valueStyle="currency"
                            // valueSmall={chartData.competitor1.diff}
                            // valueSmallStyle="percentage"
                            // smallSuffix={t('metrics.vya')}
                        />
                    </InfoTooltip>
                )}
                {chartData.competitor2?.id && (
                    <InfoTooltip
                        isHidden={hiddenTrends.includes(chartData.competitor2.id)}
                        leaveOpenOnHover
                        text={chartData.competitor2.name}
                    >
                        <ValueBox
                            allNeutral
                            color={palette.tickleMePink.main}
                            label={t('metrics.competitor2')}
                            onClick={() => handleLegendClick(chartData.competitor2?.id as string, 2)}
                            secondary
                            style={{
                                opacity: hiddenTrends.includes(chartData.competitor2?.id) ? 0.5 : 1,
                            }}
                            subtitle={chartData.competitor2.id}
                            // indicator="shortDash"
                            // valueType="latest"
                            // value={chartData.competitor2.legendVal}
                            // valueStyle="currency"
                            // valueSmall={chartData.competitor2.diff}
                            // valueSmallStyle="percentage"
                            // smallSuffix={t('metrics.vya')}
                        />
                    </InfoTooltip>
                )}
                {!chartData.competitor1?.id && (
                    <ValueBox
                        color="inherit"
                        label={t('metrics.noCompetitorsFound')}
                        secondary
                        valueStyle="currency"
                    />
                )}
                {chartData.competitor1?.id && !chartData.competitor2?.id && (
                    <ValueBox
                        color="inherit"
                        label={t('metrics.no2ndCompetitorFound')}
                        secondary
                        valueStyle="currency"
                    />
                )}
            </SlideCarousel>
            <HighchartsContainer options={options} ref={chartRef} />
        </Flex>
    );
}
