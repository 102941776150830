import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { createContext, Dispatch } from 'react';

export type StagedFiltersAction =
  | {
      type: 'add';
      payload: AppFilters;
  }
  | {
      type: 'remove';
      payload: keyof AppFilters;
  }
  | {
      type: 'selectCheckbox';
      payload: {
          filterId: keyof AppFilters;
          value: string;
      };
  }
  | {
      type: 'deselectCheckbox';
      payload: {
          filterId: keyof AppFilters;
          value: string;
      };
  }
  | {
      type: 'deselectAll';
      payload: keyof AppFilters;
  };

export const StagedFiltersDispatchContext = createContext<Dispatch<StagedFiltersAction>>(
    () => null
);
