import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { p14b } from '../../Core/typographyVariants';

interface TickrToggleGroupProps<T> {
    ariaLabel: string;
    onChange: (newValue: T) => void;
    options: { label: string; value: T }[];
    value: T;
}

export function TickrToggleGroup<T extends string>({ ariaLabel, onChange, options, value }: TickrToggleGroupProps<T>) {
    return (
        <ToggleButtonGroup
            aria-label={ariaLabel}
            color="primary"
            exclusive
            onChange={(_, newValue) => {
                if (newValue !== null) {
                    onChange(newValue);
                }
            }}
            size="small"
            value={value}
        >
            {options.map((option) => (
                <ToggleButton
                    key={option.value}
                    sx={{
                        textTransform: 'none',
                        ...p14b,
                    }}
                    value={option.value}
                >
                    {option.label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}

