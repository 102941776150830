import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren, useReducer, useState } from 'react';
import { useActiveFilters } from '../ActiveFiltersProvider/useActiveFilters';
import { StagedFiltersContext } from './StagedFiltersContext';
import { StagedFiltersAction, StagedFiltersDispatchContext } from './StagedFiltersDispatchContext';

interface StagedFiltersProviderProps {
    defaultFilters?: AppFilters;
}

export function StagedFiltersProvider({
    defaultFilters,
    children,
}: PropsWithChildren<StagedFiltersProviderProps>) {
    const { activeFilters } = useActiveFilters();

    const [stagedFilters, dispatchStagedFilters] = useReducer(
        stagedFiltersReducer,
        defaultFilters ?? activeFilters
    );

    const [invalidFilters, setInvalidFilters] = useState<(keyof AppFilters)[]>([]);

    return (
        <StagedFiltersContext.Provider
            value={{
                stagedFilters,
                invalidFilters,
                setInvalidFilters,
            }}
        >
            <StagedFiltersDispatchContext.Provider value={dispatchStagedFilters}>
                {children}
            </StagedFiltersDispatchContext.Provider>
        </StagedFiltersContext.Provider>
    );
}

function stagedFiltersReducer(stagedFilters: AppFilters, stagedFiltersAction: StagedFiltersAction) {
    switch (stagedFiltersAction.type) {
        case 'add':
            return {
                ...stagedFilters,
                ...stagedFiltersAction.payload,
            };
        case 'deselectAll':
            return {
                ...stagedFilters,
                [stagedFiltersAction.payload]: '',
            };

        case 'selectCheckbox': {
            const { filterId: selectedId, value: selectedValue } = stagedFiltersAction.payload;

            const selectedValues = ((stagedFilters[selectedId] as string) ?? '')
                .split(',')
                .filter((value) => !!value);

            const newSelectedValues = [...selectedValues, selectedValue];
            return {
                ...stagedFilters,
                [selectedId]: newSelectedValues.join(','),
            };
        }

        case 'deselectCheckbox': {
            const { filterId: deselectedId, value: deselectedValue } = stagedFiltersAction.payload;
            const deselectedValues = stagedFilters[deselectedId].split(',') as string[];
            const newDeselectedValues = deselectedValues.filter((value) => value !== deselectedValue);
            return {
                ...stagedFilters,
                [deselectedId]: newDeselectedValues.join(','),
            };
        }

        case 'remove': {
            const newStagedFilters = cloneDeep(stagedFilters);
            delete newStagedFilters[stagedFiltersAction.payload];
            return newStagedFilters;
        }

        default:
            return stagedFilters;
    }
}
