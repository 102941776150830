import { useTranslation } from 'react-i18next';
import { ChangeIndicator } from '../../Core/ChangeIndicator';
import { Flex } from '../../Core/Flex';
import { NumberFormatter } from '../../Core/NumberFormatter';

interface CurrencyChangeCellProps {
    borderColor?: string;
    change: number;
    className?: string;
    style?: React.CSSProperties;
    textColor?: string;
    value: number;
}

export function CurrencyChangeCell({
    borderColor,
    change,
    className,
    style,
    textColor,
    value,
}: CurrencyChangeCellProps) {
    const { t } = useTranslation();

    return (
        <Flex
            align="center"
            className={className}
            gap="1rem"
            style={style}
        >
            <NumberFormatter
                as="p"
                color={textColor}
                customSuffix={t('util.weekly')}
                numberStyle="currencyEstimate"
                val={value}
                variant="n-14-b"
            />

            <ChangeIndicator
                borderColor={borderColor}
                numberStyle="percentage"
                textColor={textColor}
                val={change}
            />
        </Flex>
    );
}
