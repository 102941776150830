import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { usePage } from '../hooks/usePage';
import { useUpdatePageMosaic } from '../hooks/useUpdatePageMosaic';
import { useUpdateTabMosaic } from '../hooks/useUpdateTabMosaic';

export function RestoreDefaultButton() {
    const { t } = useTranslation();
    const { tabUuid } = useParams<{ tabUuid: string }>();
    const currentTab = useCurrentTab();
    const { defaultMosaic: pageDefaultMosaic } = usePage();
    const updateTabMosaic = useUpdateTabMosaic();
    const updatePageMosaic = useUpdatePageMosaic();

    const defaultMosaic = tabUuid ? currentTab?.settings?.defaultMosaic : pageDefaultMosaic;

    return (
        <InfoTooltip text={t('dashboard.controls.restoreDefault')}>
            <IconButton
                onClick={() => {
                    if (!defaultMosaic) return;

                    tabUuid
                        ? updateTabMosaic({ node: defaultMosaic })
                        : updatePageMosaic({ node: defaultMosaic });
                }}
                size="small"
            >
                <SettingsBackupRestoreIcon fontSize="inherit" />
            </IconButton>
        </InfoTooltip>
    );
}
