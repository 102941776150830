import { CSSProperties, PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { InputErrorMessage } from './InputErrorMessage';

interface FormElementWrapperProps {
    description?: string;
    error?: string;
    style?: CSSProperties;
    title?: string;
    titleColor?: string;
    width?: string;
}

export function FormElementWrapper({
    description,
    error,
    style,
    title,
    titleColor,
    width = '100%',
    children,
}: PropsWithChildren<FormElementWrapperProps>) {
    return (
    // NOTE: gap needs to be zero here, to prevent UI jumping from children
        <Flex
            direction="column"
            gap="0"
            style={style}
            width={width}
        >
            {(title || description) && (
                <Flex
                    direction="column"
                    gap="0.4rem"
                    padding="0 0 0.8rem 0"
                    width="100%"
                >
                    {title && (
                        <Typography
                            color={titleColor}
                            style={{ paddingLeft: '8px' }}
                            variant="p-14-r"
                        >
                            {title}
                        </Typography>
                    )}
                    {description && (
                        <Typography
                            secondary
                            style={{
                                paddingLeft: '8px',
                                lineHeight: '15px',
                            }}
                        >
                            {description}
                        </Typography>
                    )}
                </Flex>
            )}
            {children}
            {error && <InputErrorMessage errorMessage={error} />}
        </Flex>
    );
}
