import { styled, useTheme } from '@mui/material';
import { Circle } from './Circle';
import { Typography } from './Typography';

export interface Author {
    uuid: string;
    fullname?: string;
    username?: string;
}

interface AuthorAvatarProps {
    author?: Partial<Author>;
    imageSrc?: string;
    size?: string;
    background?: string;
    style?: React.CSSProperties;
}

export function AuthorAvatar({
    author,
    imageSrc,
    background,
    size = '32px',
    style,
}: AuthorAvatarProps) {
    const { palette } = useTheme();
    const initials = getInitials(author);

    return (
        <StyledCircle
            color={background}
            size={size}
            style={{
                ...(imageSrc
                    ? {
                        backgroundImage: `url(${imageSrc})`,
                        backgroundPosition: 'center',
                        backgroundSize: size,
                    }
                    : {}),
                ...style,
            }}
        >
            {!imageSrc && (
                <Typography
                    color={palette.getContrastText(background ?? palette.background.paper)}
                    style={{ textTransform: 'uppercase' }}
                    variant="h-12-b"
                >
                    {initials}
                </Typography>
            )}
        </StyledCircle>
    );
}

const StyledCircle = styled(
    Circle,
    { shouldForwardProp: (prop) => prop !== 'color' }
)(({ theme, color }) => ({
    background: color ?? theme.palette.background.paper,
}));

function getInitials(author?: Partial<Author>): string {
    const name = author?.fullname ?? author?.username ?? 'U';
    const names = name.split(' ');
    const firstInitial = names[0].charAt(0);
    const lastInitial = names.length > 1 ? names[names.length - 1].charAt(0) : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
}
