import { useTheme } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { AuthorAvatar } from '../Core/AuthorAvatar';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useDateFormatter } from '../hooks/useDateFormatter';

export interface UserBubbleV2Props {
    text: string;
    timestamp: number;
}

export function UserBubbleV2({ text, timestamp }: UserBubbleV2Props) {
    const { keycloak } = useKeycloak();
    const { palette } = useTheme();
    const format = useDateFormatter();

    return (
        <Flex
            align="flex-end"
            direction="column"
            gap="1rem"
            padding="1rem"
            style={{
                alignSelf: 'flex-end',
                maxWidth: '90%',
                // background: palette.background.default,
            }}
        >
            <Flex align="center" gap="0.6rem">
                <Typography secondary>{format(timestamp, 'fromNow')}</Typography>
                <AuthorAvatar
                    author={{
                        fullname: keycloak.tokenParsed?.name,
                        username: keycloak.tokenParsed?.email,
                    }}
                    background={palette.sulu.main}
                    size="24px"
                />
            </Flex>
            {text && (
                <Typography
                    secondary
                    style={{ whiteSpace: 'pre-line' }}
                    variant="p-14-r"
                >
                    {text}
                </Typography>
            )}
        </Flex>
    );
}

