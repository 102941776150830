import { styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';

const StyledBar = styled(
    Flex,
    { shouldForwardProp: (prop) => !!prop }
)(({ theme }) => `
    background: linear-gradient(90deg, ${theme.palette.negative.main} 0%, transparent 50%, ${theme.palette.success.main} 100%);
    border-radius: ${theme.shape.borderRadius}px;
`);

const Indicator = styled('div')<{ left: number }>(({ left, theme }) => `
    position: absolute;
    width: 8px;
    height: 20px;
    background-color: ${theme.palette.info.main};
    top: -8px;
    left: calc(${left}px - 4px);
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.background.secondary};
`);

interface SentimentBarProps {
    amount: number;
}

export function SentimentBar({ amount }: SentimentBarProps) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const [ref, { width }] = useMeasure<HTMLDivElement>();

    return (
        <Flex
            direction="column"
            gap="0.8rem"
            width="100%"
        >
            <Flex
                align="center"
                justify="space-between"
                width="100%"
            >
                <Typography variant="p-14-b">{t('metrics.sentiment')}</Typography>
                <Typography color={palette.primary.main} variant="n-14-b">
                    {amount}
                </Typography>
            </Flex>

            <StyledBar
                height="4px"
                ref={ref}
                width="100%"
            >
                {width !== 0 && <Indicator left={((amount + 100) / 200) * width} />}
            </StyledBar>
        </Flex>
    );
}
