import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { createContext, Dispatch } from 'react';

export type ActiveFiltersAction =
  | {
      type: 'add';
      payload: AppFilters;
  }
  | {
      type: 'replace';
      payload: AppFilters;
  }
  | {
      type: 'remove';
      payload: keyof AppFilters;
  };

export const ActiveFiltersDispatchContext = createContext<Dispatch<ActiveFiltersAction>>(
    () => null
);
