import DragHandleIcon from '@mui/icons-material/DragHandle';
import { IconButton, styled } from '@mui/material';
import { TabAttributes } from '@tickr/sequelize-models/src/models/tab.model';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import ReactGridLayout, { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useDashboardSettings } from '../Providers/DashboardSettingsProvider.tsx/useDashboardSettings';
import { useCurrentTabs } from '../hooks/useCurrentTabs';
import { usePage } from '../hooks/usePage';
import { TalkingPoint } from './TalkingPoint';

export function TalkingPoints() {
    const { t } = useTranslation();
    const { isStreaming } = usePage();
    const { tabs } = useCurrentTabs();
    const { updateTabs } = useCollectionMutations();
    const { presentationMode } = useDashboardSettings();

    const layout: Layout[] = useMemo(
        () => tabs.map(({ uuid, row }) => ({
            i: uuid,
            x: 0,
            y: row,
            w: 1,
            h: 1,
        })),
        [tabs]
    );

    const onLayoutChange = (layouts: Layout[]) => {
        if (presentationMode) return;

        const tabsToUpdate = layouts.reduce((acc: TabAttributes[], layout: Layout) => {
            const tab = cloneDeep(tabs.find(({ uuid }) => uuid === layout.i));
            const newRow = layout.y;
            if (!tab || newRow === tab.row) return acc;

            return [
                ...acc,
                {
                    ...tab,
                    row: newRow,
                },
            ];
        }, []);

        if (isEmpty(tabsToUpdate)) return;

        updateTabs(tabsToUpdate);
    };

    const [draggingId, setDraggingId] = useState<string>('');

    return (
        <Flex
            align="center"
            direction="column"
            gap="16px"
            height="calc(100% - 12px)"
            overflow="scroll"
        >
            <Flex
                direction="column"
                padding="0 12px"
                style={{
                    minWidth: '274px',
                    maxWidth: '274px',
                }}
            >
                <ReactGridLayout
                    autoSize
                    cols={1}
                    containerPadding={[0, 0]}
                    draggableHandle=".drag-handle"
                    isBounded
                    isDraggable={!presentationMode}
                    isResizable={false}
                    layout={layout}
                    margin={[0, 12]}
                    onDragStart={(_, { i }) => setDraggingId(i)}
                    onDragStop={() => setDraggingId('')}
                    onLayoutChange={onLayoutChange}
                    rowHeight={154}
                    useCSSTransforms={false}
                    width={250}
                >
                    {tabs.map((tab) => (
                        <StyledTalkingPointContainer
                            draggingId={draggingId}
                            key={tab.uuid}
                            tabId={tab.uuid}
                        >
                            <TalkingPoint tab={tab} />
                            {/* Keep DragHandle in the same file as ReactGridLayout */}
                            {!isStreaming && (
                                <IconButton
                                    aria-label={t('iconButton.aria.dragHandle')}
                                    className="drag-handle"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        cursor: draggingId ? 'grabbing' : 'grab',
                                        zIndex: 2,
                                    }}
                                >
                                    <DragHandleIcon />
                                </IconButton>
                            )}
                        </StyledTalkingPointContainer>
                    ))}
                </ReactGridLayout>
            </Flex>
        </Flex>
    );
}

const StyledTalkingPointContainer = styled('div')<{ draggingId: string; tabId: string }>`
    min-width: 250px;
    max-width: 250px;
    button {
        visibility: ${({ draggingId, tabId }) => (draggingId === tabId ? 'visible' : 'hidden')};
    }

    :hover {
        button {
            visibility: visible;        
        }
    }
`;
