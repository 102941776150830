import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import last from 'lodash/last';
import some from 'lodash/some';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { LegendSeries } from '../Core/LegendSeries';
import { getEndOfWeekDate } from '../helpers/getEndOfWeekDate';
import { usePage } from '../hooks/usePage';
import { HighchartsContainer } from './HighchartsContainer';
import { formatTrend } from './chartHelpers';
import { useFormatYAxisOptions } from './useFormatYAxisOptions';

export function PriceOptimizedRevenueTids() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const formatYAxisOptions = useFormatYAxisOptions();
    const { optimizePricingSummaryData } = usePage();
    const revenueData = optimizePricingSummaryData?.priceData;
    const rangeData = optimizePricingSummaryData?.rangeData;
    const pvsChartData = optimizePricingSummaryData?.pvsChart;
    const recommendedPrice = optimizePricingSummaryData?.recommendedPrice;

    const rangeStartDate = rangeData?.[0]?.[0];
    const indexOfRangeStartDate = revenueData?.findIndex(([x]) => x >= Number(rangeStartDate));

    const chartData = useMemo(() => {
        return revenueData && rangeData && pvsChartData && recommendedPrice
            ? {
                historicRevenue: formatTrend({
                    trend: revenueData.slice(0, indexOfRangeStartDate),
                    daysToAdd: 6,
                }),
                revenueProjection: formatTrend({
                    trend: revenueData.slice(indexOfRangeStartDate),
                    daysToAdd: 6,
                }),
                revenueProjectionArea: rangeData.map(([
                    x,
                    y,
                    y0,
                ]) => [
                    getEndOfWeekDate(x, 6),
                    y,
                    y0,
                ]),
                historicPrice: formatTrend({
                    trend: pvsChartData.price,
                    daysToAdd: 6,
                }),
                recommendedPrice,
            }
            : {
                historicRevenue: [],
                revenueProjection: [],
                revenueProjectionArea: [],
                historicPrice: [],
                recommendedPrice: 0,
            };
    }, [
        revenueData,
        rangeData,
        pvsChartData,
        recommendedPrice,
        indexOfRangeStartDate,
    ]);

    const { historicRevenue, revenueProjectionArea, revenueProjection } = chartData;

    const options = useMemo(() => {
        const slicedHistoricRevenue = historicRevenue.slice(0, historicRevenue.length);

        const lastPointInZone1 = last(slicedHistoricRevenue)?.[0] ?? 0;

        const highchartsOptions: Highcharts.Options = {
            tooltip: {
                shared: true,
                formatter: function() {
                    const isHistoric = (this.x as number) <= lastPointInZone1;

                    const priceLabel = isHistoric ? t('metrics.price') : t('metrics.optimalPrice');

                    const revenueLabel = isHistoric
                        ? t('metrics.revenueWeekly')
                        : t('metrics.forecastedRevenueWeekly');

                    return chartData
                        ? `
                            <tspan style="fontSize: 10px">${Highcharts.dateFormat(`${t('util.weekEnding')} %a, %b %e, %Y`, this.x as number)}</tspan>
                            <br>
                            <tspan style="color: ${this.points?.[0]?.color}; fill: ${this.points?.[0]?.color};">●</tspan>
                            <tspan style="fontSize: 12px">${priceLabel}: 
                                <tspan style="fontSize: 12px; fontWeight: bold;">$${Highcharts.numberFormat(this.y as number, 2)}</tspan>
                            </tspan>
                            <br>
                            <tspan style="color: ${this.points?.[1]?.color}; fill: ${this.points?.[1]?.color};">●</tspan>
                            <tspan style="fontSize: 12px">${revenueLabel}: 
                                <tspan style="fontSize: 12px; fontWeight: bold;">$${Highcharts.numberFormat(this.points?.[1]?.y as number, 2)}</tspan>
                            </tspan>
                        `
                        : `
                            <tspan style="fontSize: 10px">${Highcharts.dateFormat(`${t('util.weekEnding')} %a, %b %e, %Y`, this.x as number)}</tspan>
                            <br>
                            <tspan style="color: ${this.points?.[0]?.color}; fill: ${this.points?.[0]?.color};">●</tspan>
                            <tspan style="fontSize: 12px">${revenueLabel}: 
                                <tspan style="fontSize: 12px; fontWeight: bold;">$${Highcharts.numberFormat(this.y as number, 2)}</tspan>
                            </tspan>
                            <br>
                        `;
                },
            },
            xAxis: {
                type: 'datetime',
                startOfWeek: 6,
                crosshair: true,
            },
            ...(chartData && {
                yAxis: [
                    formatYAxisOptions({
                        labels: {
                            style: {
                                color: palette.text.primary,
                            },
                            formatter: function() {
                                return `$${Highcharts.numberFormat(this.value as number, 2)}`;
                            },
                        },
                    }),
                    formatYAxisOptions({
                        opposite: true,
                        labels: {
                            style: {
                                color: palette.success.main,
                            },
                            formatter: function() {
                                return `$${this.axis.defaultLabelFormatter.call(this)}`;
                            },
                        },
                    }),
                ],
            }),
            legend: {
                enabled: false,
            },
            series: [
                {
                    name: t('metrics.price'),
                    type: 'line',
                    color: palette.text.primary,
                    dashStyle: 'Solid',
                    data: [
                        ...(chartData?.historicPrice ?? []),
                        ...revenueProjection.map(([x]) => [x, chartData?.recommendedPrice]),
                    ],
                    zIndex: 1,
                    lineWidth: 2,
                    zoneAxis: 'x',
                    yAxis: 0,
                    zones: [
                        {
                            value: last(chartData?.historicPrice)?.[0],
                            dashStyle: 'Solid',
                        },
                        {
                            dashStyle: 'Dash',
                        },
                    ],
                },
                {
                    name: t('metrics.forecastedWeeklyRevenue'),
                    type: 'line',
                    color: palette.success.main,
                    dashStyle: 'Solid',
                    data: [...slicedHistoricRevenue, ...revenueProjection],
                    zIndex: 1,
                    lineWidth: 2,
                    ...(chartData && { yAxis: 1 }),
                    zoneAxis: 'x',
                    zones: [
                        {
                            value: last(slicedHistoricRevenue)?.[0],
                            dashStyle: 'Solid',
                        },
                        {
                            dashStyle: 'Dash',
                        },
                    ],
                },
                {
                    name: t('metrics.confidenceInterval'),
                    type: 'arearange',
                    data: some(slicedHistoricRevenue)
                        ? [
                            [
                                last(slicedHistoricRevenue)?.[0],
                                last(slicedHistoricRevenue)?.[1],
                                last(slicedHistoricRevenue)?.[1],
                            ],
                            ...revenueProjectionArea,
                        ]
                        : revenueProjectionArea,
                    linkedTo: ':previous',
                    ...(chartData && { yAxis: 1 }),
                    color: palette.confidenceInterval.main,
                    marker: {
                        enabled: false,
                    },
                    zoneAxis: 'x',
                },
            ],
        };

        return highchartsOptions;
    }, [
        historicRevenue,
        chartData,
        formatYAxisOptions,
        palette.confidenceInterval.main,
        palette.success.main,
        palette.text.primary,
        t,
        revenueProjection,
        revenueProjectionArea,
    ]);

    return (
        <Flex
            align="stretch"
            direction="column"
            height="100%"
            justify="stretch"
            width="100%"
        >
            <Flex
                gap="2rem"
                padding="0 0 1rem 0"
                width="100%"
            >
                <Flex direction="column" gap="0.2rem">
                    <LegendSeries
                        color={palette.text.primary}
                        indicator="line"
                        label={t('metrics.price')}
                    />
                    <LegendSeries
                        color={palette.success.main}
                        indicator="line"
                        label={t('metrics.revenue')}
                    />
                </Flex>
                <Flex direction="column" gap="0.2rem">
                    <LegendSeries
                        color={palette.text.primary}
                        indicator="dash"
                        label={t('metrics.optimalPrice')}
                    />
                    <LegendSeries
                        color={palette.success.main}
                        indicator="dash"
                        indicatorBackground={palette.confidenceInterval.main}
                        label={t('metrics.forecastedRevenue')}
                    />
                </Flex>
            </Flex>
            <HighchartsContainer options={options} />
        </Flex>
    );
}
