import { useQuery } from '@tanstack/react-query';
import { ElementAttributes } from '@tickr/sequelize-models';
import { useApi } from '../Providers/ApiProvider/useApi';

interface UseElementsProps {
    pageUuid?: string;
    tabUuid?: string;
    queryKey: string;
}

export function useElements({ pageUuid, tabUuid, queryKey }: UseElementsProps) {
    const { dashboardApi } = useApi();

    const { data: pageElements } = useQuery({
        queryKey: [queryKey, tabUuid ?? pageUuid],
        queryFn: async ({ signal }) => {
            if (!pageUuid) return;

            const { data } = await dashboardApi.get<ElementAttributes[]>('elements', {
                params: {
                    pageUuid,
                    tabUuid,
                },
                signal,
            });

            return data;
        },
        enabled: !!(tabUuid ?? pageUuid),
    });

    return pageElements;
}
