import { Covariate } from '@tickr/sequelize-models';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useTidsMutations } from '../../Providers/TidsMutationsProvider/useTidsMutations';
import { useCurrentProject } from '../useCurrentProject';
import { useForecastInProgress } from '../useForecastInProgress';
import { useHandleError } from '../useHandleError';

export function useLaunchForecast() {
    const { t } = useTranslation();
    const { currentProject } = useCurrentProject();
    const { postForecastSummary } = useTidsMutations();
    const { createPageFromTemplate } = useCollectionMutations();
    const navigate = useNavigate();
    const handleError = useHandleError();

    const {
        dateColumn, documentId, forecast, forecastColumn, frequency, frequencyDays, periods, userContext,
    } = useForecastInProgress();

    return async (
        { docCovariates, fredCovariates, commodityCovariates }:
        { docCovariates: Covariate[]; fredCovariates: Covariate[]; commodityCovariates: Covariate[] }
    ) => {
        let summaryId: string;

        try {
            if (
                !forecast ||
                !dateColumn ||
                !documentId ||
                !forecastColumn ||
                !frequency ||
                !frequencyDays ||
                !periods
            ) {
                throw new Error('Missing required parameters');
            }

            summaryId = (
                await postForecastSummary({
                    covariates: [...docCovariates, ...fredCovariates, ...commodityCovariates],
                    dateColumn,
                    documentId,
                    forecastColumn,
                    frequency,
                    frequencyDays,
                    periods,
                    summaryName: 'forecast',
                    userContext: userContext || '',
                })
            ).id;
        } catch (e) {
            handleError(e, t('error.launchAnalysis.createAnalysis'));
            return;
        }

        try {
            await createPageFromTemplate({
                docCovariates,
                commodityCovariates,
                fredCovariates,
                pageUuid: forecast.uuid,
                projectUuid: currentProject.uuid,
                summaryId,
                type: 'forecast',
            });

            navigate(`/page/${forecast.uuid}`);
        } catch (e) {
            handleError(e, t('error'));
            return;
        }
    };
}
