import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@mui/material';
import { PageAttributes } from '@tickr/sequelize-models';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Flex } from '../Core/Flex';
import { TickrDialog } from '../Dialog/TickrDialog';
import { TickrDialogActions } from '../Dialog/TickrDialogActions';
import { TickrDialogContent } from '../Dialog/TickrDialogContent';
import { TickrDialogTitle } from '../Dialog/TickrDialogTitle';
import { TextInput } from '../FormElements/TextInput';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCurrentProject } from '../hooks/useCurrentProject';
import { usePage } from '../hooks/usePage';
import { useYupValidation } from '../hooks/useYupValidation';
import { DeletePageDialog } from './DeletePageDialog';

interface PageSettingsDialogProps {
    open: boolean;
    onClose: () => void;
    page?: PageAttributes;
}

export function PageSettingsDialog({ open, onClose, page: pageProp }: PageSettingsDialogProps) {
    const { t } = useTranslation();
    const { deletePage, updatePage } = useCollectionMutations();
    const { string255 } = useYupValidation();
    const { page } = usePage();
    const { pageUuid: pageUuidParam } = useParams();
    const { currentProject } = useCurrentProject();
    const navigate = useNavigate();

    const currentPage = pageProp ?? page;

    const [deletePageOpen, setDeletePageOpen] = useState(false);
    const [clicked, setClicked] = useState(false);

    if (!currentPage) return null;

    return (
        <TickrDialog onClose={onClose} open={open}>
            <Formik
                initialValues={{
                    title: currentPage.title,
                }}
                onSubmit={async (values) => {
                    setClicked(true);

                    updatePage({
                        mergeProps: {
                            title: values.title,
                        },
                        pageUuid: currentPage.uuid,
                    });

                    onClose();
                }}
                validateOnChange
                validationSchema={Yup.object().shape({
                    title: string255.required(t('form.error.required')),
                })}
            >
                {({ errors, handleChange, handleSubmit, values, isValid }) => {
                    return (
                        <>
                            <TickrDialogTitle onClose={onClose}>{t('pageSettings.modalTitle')}</TickrDialogTitle>
                            <TickrDialogContent>
                                <Flex
                                    direction="column"
                                    gap="1.5rem"
                                    width="100%"
                                >
                                    <TextInput
                                        error={errors.title}
                                        id="title"
                                        max={255}
                                        name="title"
                                        onChange={handleChange}
                                        placeholder={t('pageSettings.pageTitle.placeholder')}
                                        required
                                        title={t('pageSettings.pageTitle')}
                                        value={values.title}
                                        width="400px"
                                    />
                                    <Button
                                        color="error"
                                        onClick={() => setDeletePageOpen(true)}
                                        size="small"
                                        startIcon={<DeleteOutlineIcon color="error" fontSize="inherit" />}
                                    >
                                        {t('pageSettings.deletePage')}
                                    </Button>
                                    <DeletePageDialog
                                        onCancel={() => {
                                            setDeletePageOpen(false);
                                        }}
                                        onDelete={() => {
                                            deletePage(currentPage.uuid);

                                            if (pageUuidParam && currentPage.uuid === pageUuidParam) {
                                                const finishedPages = currentProject.pages
                                                    .filter(({ inProgress }) => !inProgress);

                                                const totalPages = finishedPages.length;

                                                const pageToDeleteIdx = finishedPages
                                                    .findIndex(({ uuid }) => uuid === currentPage.uuid);

                                                const nearestPageIdx = pageToDeleteIdx === 0 ? 1 : pageToDeleteIdx - 1;
                                                const navTo = totalPages === 1 ? '/home' : `/page/${finishedPages[nearestPageIdx].uuid}`;

                                                navigate(navTo);
                                            }

                                            setDeletePageOpen(false);
                                        }}
                                        open={deletePageOpen}
                                    />
                                </Flex>
                            </TickrDialogContent>
                            <TickrDialogActions>
                                <Button
                                    color="middleGray"
                                    onClick={onClose}
                                    size="small"
                                    style={{
                                        padding: '5px 10px',
                                        width: 'min(200px, 50%)',
                                    }}
                                    variant="contained"
                                >
                                    {t('util.cancel')}
                                </Button>
                                <Button
                                    disabled={!isValid}
                                    onClick={() => {
                                        if (clicked) return;
                                        setClicked(true);
                                        handleSubmit();
                                    }}
                                    size="small"
                                    style={{
                                        padding: '5px 10px',
                                        width: 'min(200px, 50%)',
                                    }}
                                    variant="contained"
                                >
                                    {t('util.apply')}
                                </Button>
                            </TickrDialogActions>
                        </>
                    );
                }}
            </Formik>
        </TickrDialog>
    );
}
