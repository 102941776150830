import { PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';

export function SolutionFooter({ children }: PropsWithChildren) {
    return (
        <Flex
            align="flex-end"
            padding="0 6.5% 0 6.5%"
            style={{
                gridRow: '3',
                gridColumn: '1',
            }}
            width="100%"
        >
            {children}
        </Flex>
    );
}
