import { LicenseInfo } from '@mui/x-license-pro';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { TickrErrorBoundary } from '@tickr/ui/src/Core/TickrErrorBoundary';
import { MuiThemeProvider } from '@tickr/ui/src/Providers/MuiThemeProvider/MuiThemeProvider';
import { keycloak } from '@tickr/ui/src/helpers/keycloak';
import '@tickr/ui/styles/Fonts.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { LoginLoader } from './LoginLoader';

LicenseInfo.setLicenseKey(
    '4f2c18fdb6fccd8caba89fbe27d69ca0Tz03NjIyNixFPTE3MjgzMTgwMTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

createRoot(document.getElementById('root')!).render(
    <MuiThemeProvider>
        <TickrErrorBoundary
            fullscreen
            message="Uh oh! Something went wrong. Support has been notified. Please try reloading the page."
            navigable={false}
            padding="1rem"
        >
            <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={{ onLoad: 'login-required' }}
                LoadingComponent={<LoginLoader />}
            >
                <StrictMode>
                    <App />
                </StrictMode>
            </ReactKeycloakProvider>
        </TickrErrorBoundary>
    </MuiThemeProvider>
);
