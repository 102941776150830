import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';

export function ZeroStateMosaic() {
    return (
        <Flex height="100%">
            <Typography as="h1">Zero state view</Typography>
        </Flex>
    );
}
