import * as React from 'react';
import { TooltipContext } from './TooltipContext';
import { TooltipOptions, useTooltip } from './useTooltip';

export function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const arrowRef = React.useRef<HTMLDivElement>(null);

    const tooltip = useTooltip({
        arrowRef,
        ...options,
    });

    return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}
