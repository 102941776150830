
export function DatasemblyIcon() {
    return (
        <img
            src="/datasemblyLogo.png"
            style={{
                width: '30px',
            }}
        />
    );
}
