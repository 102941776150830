import { PropsWithChildren, useState } from 'react';
import { MosaicStateContext } from './MosaicStateContext';

export function MosaicStateProvider({ children }: PropsWithChildren) {
    const [isDragging, setIsDragging] = useState(false);

    return (
        <MosaicStateContext.Provider
            value={{
                isDragging,
                setIsDragging,
            }}
        >
            {children}
        </MosaicStateContext.Provider>
    );
}
