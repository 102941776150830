import { Toolbar, useTheme } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';

interface TickrToolbarProps {
    containerBackground?: string;
    size?: 'small' | 'medium';
    style?: CSSProperties;
}

export function TickrToolbar(
    { containerBackground, size ='medium', style, children }: PropsWithChildren<TickrToolbarProps>
) {
    const { palette } = useTheme();

    return (
        <div
            style={{
                backgroundColor: containerBackground ?? palette.background.default,
                position: 'sticky',
                top: 0,
                zIndex: 1,
            }}
        >

            <Toolbar
                style={style}
                sx={({ palette, shape }) => ({
                    pl: {
                        sm: size === 'medium' ? 2 : 0,
                    },
                    bgcolor: palette.background.secondary,
                    borderRadius: `${shape.borderRadius}px ${shape.borderRadius}px 0 0`,
                })}
            >
                {children}
            </Toolbar>
        </div>
    );
}
