import { styled, useTheme } from '@mui/material';
import { ElementAttributes, PageAttributes } from '@tickr/sequelize-models';
import { useState } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { DataIcon } from '../Icons/DataIcon';
import { PageIcon } from '../Icons/PageIcon';
import { InfoTooltip } from '../Tooltips/InfoTooltip';

interface CollectionTileProps {
    disableTooltip?: boolean;
    item: ElementAttributes | PageAttributes;
    baseZindex?: number;
    onClick: () => void;
}

export function CollectionTile({ disableTooltip, item, onClick, baseZindex = 1 }: CollectionTileProps) {
    const { palette } = useTheme();

    const inProgress = !!(item as PageAttributes).inProgress;
    const isElement = !!(item as ElementAttributes)?.type;

    const [descriptionTooltipOpen, setDescriptionTooltipOpen] = useState(false);

    return (
        <InfoTooltip
            disabled={disableTooltip}
            onOpenChange={setDescriptionTooltipOpen}
            open={descriptionTooltipOpen}
            text={item.description || item.title}
            zindex={baseZindex + 1}
        >
            <StyledFlex
                as="button"
                direction="column"
                gap="0.2rem"
                height="100%"
                onClick={onClick}
                padding="8px 10px"
                width="100%"
            >
                <Typography
                    as="h4"
                    lineClamp={2}
                    textAlign="left"
                    title={item.title}
                    width="100%"
                >
                    {item.title}
                </Typography>
                {isElement && (
                    <DataIcon
                        color={palette.secondary.main}
                        size="50px"
                        style={{
                            position: 'absolute',
                            left: 'calc(50% - 20px)',
                            top: 'calc(50% - 10px)',
                            zIndex: 0,
                        }}
                    />
                )}
                {!isElement && !item.isTemplate && (
                    <PageIcon
                        color={inProgress ? palette.primary.main : palette.secondary.main}
                        size="50px"
                        style={{
                            position: 'absolute',
                            left: 'calc(50% - 20px)',
                            top: 'calc(50% - 10px)',
                            zIndex: 0,
                        }}
                    />
                )}
            </StyledFlex>
        </InfoTooltip>
    );
}

const StyledFlex = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'as' }
)(({ theme }) => `
    min-width: 190px;
    height: 140px;
    background: ${theme.palette.background.secondary};
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.background.tertiary};

    :hover {
        border: 1px solid ${theme.palette.text.disabled};
    }
`);
