import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { baseTheme } from './baseTheme';

export const darkTheme = createTheme(deepmerge(baseTheme,
    {
        palette: {
            mode: 'dark',
            primary: {
                main: '#20b9ff', // malibu aka action
                contrastText: '#000000',
            },
            secondary: {
                main: '#c74ddc', // lavender
            },
            background: {
                default: '#15161d', // woodsmoke
                paper: '#2c353f',
                secondary: '#1f262f', // clay
                secondaryMask: 'rgba(0,0,0,0.5)',
                tertiary: '#2c3741', // space
            },
            text: {
                primary: '#ffffff',
                secondary: 'rgba(255,255,255,0.7)',
                disabled: 'rgba(255,255,255,0.5)',
            },
            error: {
                main: '#ff584b',
                // main: '#b82932', // tim's new suggestion
            },
            // warning: {
            //     main: '#ff584b',
            // },
            info: {
                main: '#c0d0d8', // geyser
            },
            success: {
                main: '#5fee5f', // positive
                // main: '#5fee5f', // tim's new suggestion
            },
            divider: '#2c3741', // space

            /* CUSTOM COLORS, see @tickr/ui/types/index.d.ts */
            // blackMask: {
            //     50: 'rgba(0, 0, 0, 0.5)',
            // },
            confidenceInterval: {
                main: '#8f4aca4c', // confidence-interval
            },
            highlight: {
                main: '#bfee89', // sulu
            },
            indicator: {
                main: '#ffcd1a', // brand-secondary
                contrastText: '#000000',
            },
            middleGray: {
                main: '#545763', // trout
                contrastText: '#ffffff',
            },
            negative: {
                main: '#ff1d4d', // negative aka brand-primary
                contrastText: '#ffffff',
            },
            tooltip: {
                main: 'rgba(31, 77, 98, 1)',
            },

            primaryText: {
                main: '#ffffff',
            },
            secondaryText: {
                main: 'rgba(255,255,255,0.7)',
            },
            contrastText: {
                main: '#000000',
            },
            turquoise: {
                main: '#45ddcf',
            },
            salmon: {
                main: '#ff8c5b',
            },
            texasRose: {
                main: '#feb74e',
            },
            portica: {
                main: '#f6e96e',
            },
            sulu: {
                main: '#bfee89',
            },
            cornflowerBlue: {
                main: '#4862ef',
            },
            tickleMePink: {
                main: '#fb7eb1',
            },

            gradient: {
                chatCpg: 'linear-gradient(90deg, #9fe1ff 5.23%, #5ecdff 20.85%, #21b9ff 31.2%, #2e9cfa 41.94%,#3b80f5 52.22%, #4863f0 63.4%, #6d5cea 73.27%, #9256e4 83.98%, #c74ddc 95%)',
                lavender: 'linear-gradient(124.78deg, #c74ddc 0%, #ec8afd 100%)',
                secondaryBackground: 'linear-gradient(-20deg, #1f262f 0%, #2c3741 100%)',
                sidePanel: 'linear-gradient(126.72deg, #1c2531 0%, #151721 100%)',
                slideCarouselRight: 'linear-gradient(270deg, #15161d 0%, rgba(21, 22, 29, 0) 100%)',
                slideCarouselLeft: 'linear-gradient(90deg, #15161d 0%, rgba(21, 22, 29, 0) 100%)',
            },
        },
    }));
