import { FloatingFocusManager, FloatingPortal, UseTransitionStylesProps, useMergeRefs, useTransitionStyles } from '@floating-ui/react';
import { useTheme } from '@mui/material';
import omit from 'lodash/omit';
import { HTMLProps, forwardRef } from 'react';
import { PopoverArrow } from './PopoverArrow';
import { usePopoverContext } from './usePopoverContext';

interface PopoverContentProps extends HTMLProps<HTMLDivElement> {
    hideArrow?: boolean;
    position?: 'fixed' | 'absolute';
    transitionProps?: UseTransitionStylesProps;
    zIndex?: number;
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
    function PopoverContent(
        { hideArrow, transitionProps = { duration: 100 }, position, zIndex = 1000, ...props },
        propRef
    ) {
        const { palette, shape } = useTheme();
        const { context: floatingContext, arrowRef, ...context } = usePopoverContext();
        const ref = useMergeRefs([context.refs.setFloating, propRef]);
        const { isMounted, styles } = useTransitionStyles(floatingContext, transitionProps);

        return (
            <FloatingPortal>
                {isMounted && (
                    <FloatingFocusManager
                        context={floatingContext}
                        initialFocus={999}
                        modal
                    >
                        <div
                            aria-describedby={context.descriptionId}
                            aria-labelledby={context.labelId}
                            ref={ref}
                            style={{
                                position: position ?? context.strategy,
                                top: context.y ?? 0,
                                left: context.x ?? 0,
                                width: 'max-content',
                                zIndex,
                                backgroundColor: palette.background.tertiary,
                                borderRadius: shape.borderRadius,
                                ...props.style,
                                ...styles,
                            }}
                            {...context.getFloatingProps(omit(props, 'arrowRef'))}
                        >
                            <>
                                {props.children}
                                {!hideArrow && <PopoverArrow ref={arrowRef} />}
                            </>
                        </div>
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
        );
    }
);
