import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, useTheme } from '@mui/material';
import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import camelCase from 'lodash/camelCase';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { truncateCSS } from '../Core/truncateCSS';
import { ExpandIcon } from '../Icons/ExpandIcon';
import { AppPage, navIcons } from './navIcons';
import { useAppPageLinks } from './useAppPageLinks';
import { useDashboardLinks } from './useDashboardLinks';

export function NavMenu() {
    const { t } = useTranslation();
    const { palette, shape } = useTheme();
    const { pathname } = useLocation();
    const { pageUuid } = useParams();

    const dashboardLinks = useDashboardLinks();
    const appPageLinks = useAppPageLinks();

    const allLinks = [...appPageLinks, ...dashboardLinks];

    const activePageId = pageUuid ?? camelCase(pathname.slice(1));

    const activePageIndex = allLinks.findIndex(
        (page) => page.pageUuid === activePageId || page.pageType === activePageId
    );

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    return (
        <Flex align="center" gap="0.5rem">
            <Flex align="center" gap="2px">
                <IconButton
                    color="primaryText"
                    component={Link}
                    disabled={activePageIndex === -1 || activePageIndex === 0 || !allLinks?.[activePageIndex - 1]?.to}
                    size="small"
                    style={{
                        borderRadius: `${shape.borderRadius}px 0 0 ${shape.borderRadius}px`,
                        width: '32px',
                        height: '28px',
                        background: palette.background.tertiary,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    to={allLinks?.[activePageIndex - 1]?.to}
                >
                    <ArrowBackIosNewIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    color="primaryText"
                    component={Link}
                    disabled={activePageIndex === -1 || !allLinks?.[activePageIndex + 1]?.to}
                    size="small"
                    style={{
                        borderRadius: `0px ${shape.borderRadius}px ${shape.borderRadius}px 0px`,
                        width: '32px',
                        height: '28px',
                        background: palette.background.tertiary,
                    }}
                    to={allLinks?.[activePageIndex + 1]?.to ?? '#'}
                >
                    <ArrowForwardIosIcon fontSize="inherit" />
                </IconButton>
            </Flex>

            <Button
                aria-controls="page-nav-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="listbox"
                aria-label={t('util.page.title')}
                color="primaryText"
                endIcon={<ExpandIcon open={!!anchorEl} />}
                id="pages-button"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                sx={{
                    borderRadius: `${shape.borderRadius}px`,
                    gap: '0',
                    maxHeight: '32px',
                    textTransform: 'none',
                }}
            >
                <Typography
                    style={{ maxWidth: '450px' }}
                    truncate
                    variant="h-22-b"
                >
                    {allLinks[activePageIndex].name}
                </Typography>
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="page-nav-menu"
                MenuListProps={{
                    'aria-labelledby': 'pages-button',
                    role: 'listbox',
                    sx: {
                        background: ({ palette }) => palette.background.secondary,
                    },
                }}
                onClose={() => setAnchorEl(null)}
                open={open}
            >
                {appPageLinks.map(({ name, pageType, pageUuid, selected, to }) => {
                    return (
                        <NavMenuItem
                            key={pageType}
                            name={name}
                            pageType={pageType}
                            pageUuid={pageUuid}
                            selected={selected}
                            setAnchorEl={setAnchorEl}
                            to={to}
                        />
                    );
                })}
                {dashboardLinks.length > 0 && <Divider />}
                {dashboardLinks.map(({ name, pageType, pageUuid, selected, to }) => (
                    <NavMenuItem
                        key={pageUuid}
                        name={name}
                        pageType={pageType}
                        pageUuid={pageUuid}
                        selected={selected}
                        setAnchorEl={setAnchorEl}
                        to={to}
                    />
                ))}
            </Menu>
        </Flex>
    );
}

interface NavMenuItemProps {
    name: string;
    pageType: AppPage | SolutionType;
    pageUuid?: string;
    selected: boolean;
    setAnchorEl: (value: HTMLElement | null) => void;
    to: string;
}

function NavMenuItem({ name, pageType, pageUuid, selected, setAnchorEl, to }: NavMenuItemProps) {
    return (
        <MenuItem
            component={Link}
            key={pageUuid ?? pageType}
            onClick={() => {
                setAnchorEl(null);
            }}
            selected={selected}
            to={to}
        >
            <ListItemIcon>
                {navIcons[pageType]}
            </ListItemIcon>
            <Typography
                style={{
                    maxWidth: '450px',
                    ...truncateCSS,
                }}
                title={name}
                variant="p-14-b"
            >
                {name}
            </Typography>
        </MenuItem>
    );
}
