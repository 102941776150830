import { useTranslation } from 'react-i18next';
import { SuggestionChip } from '../ChatCpg/SuggestionChip';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';

interface ZeroStateChatV2Props {
    onClick: (message: string) => void;
}

export function SampleQuestions({ onClick }: ZeroStateChatV2Props) {
    const { t } = useTranslation();

    const sampleQuestions = [
        t('chatCpg.textToSql.sample.1'),
        t('chatCpg.textToSql.sample.2'),
        t('chatCpg.textToSql.sample.3'),
        t('chatCpg.textToSql.sample.4'),
        t('chatCpg.textToSql.sample.5'),
        t('chatCpg.textToSql.sample.6'),
        t('chatCpg.textToSql.sample.7'),
        t('chatCpg.textToSql.sample.8'),
        t('chatCpg.textToSql.sample.9'),
        t('chatCpg.textToSql.sample.10'),
    ];

    return (
        <Flex
            align="center"
            direction="column"
            gap="1rem"
            overflow="hidden"
            width="100%"
        >
            <Flex
                align="center"
                direction="column"
                gap="0.5rem"
            >
                <Typography as="h2" variant="h-22-b">{t('chatCpg.selectAQuestion')}</Typography>
                <Typography variant="p-14-r">{t('chatCpg.orWriteYourOwn')}</Typography>
            </Flex>
            <Flex
                direction="column"
                gap="0.8rem"
                overflow="scroll"
            >
                {sampleQuestions.map((message) => (
                    <SuggestionChip
                        key={message}
                        onClick={() => onClick(message)}
                        style={{ textAlign: 'left' }}
                        text={message}
                    />
                ))}
            </Flex>
        </Flex>
    );
}
