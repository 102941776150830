import { Button } from '@mui/material';
import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../Core/Flex';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { useCaptureTickrException } from '../../hooks/useCaptureTickrException';
import { useActiveFiltersDispatch } from '../ActiveFiltersProvider/useActiveFiltersDispatch';
import { useStagedFilters } from '../StagedFiltersProvider/useStagedFilters';

interface FilterMenuFooterProps {
    onSave(filters: AppFilters): Promise<void>;
    filterId?: keyof AppFilters;
}

export function FilterMenuFooter({ filterId, onSave }: FilterMenuFooterProps) {
    const { t } = useTranslation();
    const captureTickrException = useCaptureTickrException();
    const { setOpen } = usePopoverContext();
    const dispatchActiveFilters = useActiveFiltersDispatch();

    const { stagedFilters, invalidFilters } = useStagedFilters();

    const isApplyDisabled = filterId
        ? invalidFilters.includes(filterId) || !stagedFilters[filterId]
        : Object.keys(stagedFilters).some((filterId) => !stagedFilters[filterId as keyof AppFilters]) ||
      !isEmpty(invalidFilters);

    return (
        <Flex
            align="center"
            gap="1rem"
            padding="0.8rem"
            style={{
                boxShadow: '0px -1px 0.5rem rgba(0, 0, 0, 0.2)',
            }}
            width="100%"
        >
            <Button
                color="middleGray"
                onClick={() => setOpen(false)}
                size="small"
                style={{ width: '50%' }}
                variant="contained"
            >
                {t('util.cancel')}
            </Button>
            <Button
                disabled={isApplyDisabled}
                onClick={async () => {
                    try {
                        await onSave(stagedFilters);

                        dispatchActiveFilters({
                            type: 'replace',
                            payload: stagedFilters,
                        });
                    } catch (e) {
                        captureTickrException(e);
                    }

                    setOpen(false);
                }}
                size="small"
                style={{ width: '50%' }}
                variant="contained"
            >
                {t('util.apply')}
            </Button>
        </Flex>
    );
}
