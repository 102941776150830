import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton } from '@mui/material';
import { useMosaic } from '../useMosaic';
import { useMosaicWindow } from '../useMosaicWindow';

export function ExpandButton() {
    const { mosaicActions } = useMosaic();
    const { mosaicWindowActions } = useMosaicWindow();

    return (
        <IconButton
            onClick={() => {
                mosaicActions.expand(mosaicWindowActions.getPath());
            }}
            size="small"
            style={{
                width: '24px',
                height: '24px',
            }}
        >
            <OpenInFullIcon fontSize="inherit" />
        </IconButton>
    );
}
