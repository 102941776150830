import { FormElementWrapper } from './FormElementWrapper';
import { SelectDropdownContent, SelectDropdownValue } from './SelectDropdownContent';

export interface SelectDropdownProps {
    disabled?: boolean;
    description?: string;
    error?: string;
    onChange?: (value: string | number) => void;
    placeholder?: string;
    selected?: string | number;
    title?: string;
    titleColor?: string;
    values: SelectDropdownValue[];
    width?: string;
}

export function SelectDropdown({
    description,
    disabled,
    error,
    onChange,
    placeholder,
    selected,
    title,
    titleColor,
    values,
    width,
}: SelectDropdownProps) {
    return (
        <FormElementWrapper
            description={description}
            error={error}
            title={title}
            titleColor={titleColor}
            width={width}
        >
            <SelectDropdownContent
                disabled={disabled}
                onChange={onChange}
                placeholder={placeholder}
                selected={selected}
                values={values}
                width={width}
            />
        </FormElementWrapper>
    );
}
