import { useNavigate } from 'react-router-dom';
import { useCurrentForecast } from './useCurrentForecast';

export function useForecastInProgressLink(type: 'button'): () => void;
export function useForecastInProgressLink(): string;

export function useForecastInProgressLink(type: 'link' | 'button' = 'link'): string | (() => void) {
    const navigate = useNavigate();
    const forecast = useCurrentForecast();

    const link = !forecast?.settings.document?.data
        ? '/solutions/forecast/connect-your-data'
        : !forecast?.settings.document?.docCovariates
            ? '/solutions/forecast/select-forecast'
            : '/solutions/forecast/select-covariates';

    if (type === 'button') {
        return () => navigate(link);
    }

    return link;
}
