import { keyframes, styled } from '@mui/material';
import { IconProps } from '../Core/IconWrapper';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';

type StreamingIconProps = SpinWrapperProps & IconProps;

export function StreamingIcon({ isStreaming, iconSize = 'sm', ...props }: StreamingIconProps) {
    return (
        <SpinWrapper iconSize={iconSize} isStreaming={isStreaming}>
            <ChatCpgIcon
                {...props}
            />
        </SpinWrapper>
    );
}

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

interface SpinWrapperProps {
    isStreaming: boolean;
    iconSize?: 'lg' | 'sm';
}

const SpinWrapper = styled('div')<StreamingIconProps>(({ isStreaming }) => ({
    animation: isStreaming ? `${spin} 2.5s linear infinite` : 'none',
}));
