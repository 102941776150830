import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Frequency, Interval } from '@tickr/sequelize-models';
import { AxiosResponse } from 'axios';
import { PropsWithChildren, useMemo } from 'react';
import { useApi } from '../ApiProvider/useApi';
import { useApp } from '../AppProvider/useApp';
import { Conversation } from '../ChatBoxProvider/ChatBoxContext';
import { useUser } from '../UserProvider/useUser';
import { ChatFeedbackProps, FeedbackResponse, IdResponse, PostChatProps, PostChatV2Props, PostDocumentProps, PostForecastSummaryProps, PostMessageV2Props, PostSearchCovariatesProps, PostSummaryFeedbackProps, PostSummaryProps, TidsMutationsContext } from './TidsMutationsContext';

export function TidsMutationsProvider({ children }: PropsWithChildren) {
    const { chatApiRoute } = useApp();
    const { tidsApi } = useApi();
    const { isNumerator } = useUser();
    const queryClient = useQueryClient();

    const postChatV2 = useMutation({
        mutationFn: async ({ message, onChatCreation, onMessageCreation }: PostChatV2Props) => {
            const { data } = await tidsApi.post<IdResponse>(`v2${chatApiRoute}/chat`);
            onChatCreation(data.id);

            return {
                chatId: data.id,
                message,
                onMessageCreation,
            };
        },
        onMutate: async () => {},
        onSuccess: async ({ chatId, message, onMessageCreation }) => {
            queryClient.invalidateQueries({ queryKey: ['chats'] });

            await postMessageV2.mutateAsync({
                chatId,
                message,
                onMessageCreation,
            });

            // const existingChats = queryClient.getQueryData<ChatObj[]>(['chats']) ?? [];
            // const optimisticIndex = existingChats.findIndex(({ uuid }) => uuid === 'new-chat');

            // if (optimisticIndex > -1) {
            //     existingChats[optimisticIndex] = {
            //         name: 'New Chat',
            //         uuid: chatId,
            //         created_at: new Date().toISOString(),
            //         creator_id: 'me',
            //     };

            //     queryClient.setQueryData<ChatObj[]>(['chats'], () => {
            //         return existingChats;
            //     });
            // }
        },
        onError: () => {},
    });

    const postMessageV2 = useMutation({
        mutationFn: async ({ chatId, context = [], message, onMessageCreation }: PostMessageV2Props) => {
            const { data } = await tidsApi.post<IdResponse>(`v2${chatApiRoute}/chat/${chatId}/message`, {
                message,
                context,
            });

            onMessageCreation(data.id);

            return {
                chatId,
                messageId: data.id,
            };
        },
        onMutate: async () => {},
        onSuccess: ({ chatId }) => {
            queryClient.invalidateQueries({ queryKey: ['chat-history', chatId] });
        },
        onError: () => {},
    });

    const deleteChatV2 = useMutation({
        mutationFn: async (chatId: string) => {
            const { data } = await tidsApi.delete<IdResponse>(`v2${chatApiRoute}/chat/${chatId}`);
            return data.id;
        },
        onMutate: async () => {},
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['chats'] });
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: ['chats'] });
        },
    });

    const postChat = useMutation({
        mutationFn: async ({ message, model = 'gpt-4', summaryId }: PostChatProps) => {
            const { data } = await tidsApi.post<IdResponse>(`v1${chatApiRoute}/summary/${summaryId}/chat`, {
                question: message,
                model,
            });

            return data;
        },
        onMutate: async ({ message, summaryId }) => {
            const existingConversation = queryClient.getQueryData<Conversation>(['chat-history', summaryId]) ?? [];

            queryClient.setQueryData<Conversation>(['chat-history', summaryId], () => {
                return [
                    {
                        id: 'new post',
                        sender: 'HUMAN',
                        status: 'complete',
                        text: message,
                        timestamp: Date.now(),
                        type: 'message',
                    },
                    ...existingConversation,
                ];
            });
        },
        onSuccess: (summaryId) => {
            queryClient.invalidateQueries({ queryKey: ['chat-history', summaryId] });
        },
        onError: (err, { summaryId }) => {
            queryClient.invalidateQueries({ queryKey: ['chat-history', summaryId] });
        },
    });

    const postChatFeedback = useMutation({
        mutationFn: async ({ category, explanation, feedback, label, chatMessageId }: ChatFeedbackProps) => {
            const { data } = await tidsApi.post<FeedbackResponse>(
                `v1${chatApiRoute}/summary/chat/${chatMessageId}/feedback`,
                {
                    category,
                    explanation,
                    feedback,
                    label,
                }
            );

            return data;
        },
    });

    const postDocument = useMutation({
        mutationFn: async ({ file, summaryId }: PostDocumentProps) => {
            const params: PostDocumentParams = {
                mime: file.type,
                name: file.name,
                ms_id: 1,
            };

            if (summaryId) {
                params['summary_id'] = summaryId;
            }

            const { data } = await tidsApi.postForm<IdResponse>(
                `v1${chatApiRoute}/document`,
                {
                    doc: file,
                },
                {
                    params,
                }
            );

            return data;
        },
        onMutate: async ({ file, summaryId }) => {
            if (summaryId) {
                const existingConversation = queryClient.getQueryData<Conversation>(['chat-history', summaryId]) ?? [];

                queryClient.setQueryData<Conversation>(['chat-history', summaryId], () => {
                    return [
                        {
                            id: 'new-file',
                            mime: file.type,
                            name: file.name,
                            status: 'uploading',
                            summary: null,
                            timestamp: Date.now(),
                            type: 'file',
                        },
                        ...existingConversation,
                    ];
                });
            }
        },
        onError: (err, { summaryId }) => {
            queryClient.invalidateQueries({ queryKey: ['chat-history', summaryId] });
        },
    });

    const postForecastSummary = useMutation({
        mutationFn: async ({
            covariates,
            periods,
            frequency,
            frequencyDays,
            dateColumn,
            forecastColumn,
            documentId,
            userContext,
        }: PostForecastSummaryProps) => {
            const { data } = await tidsApi.post<IdResponse>(`v1${chatApiRoute}/summary`, {
                model: 'gpt-4',
                summary_kind: 'forecast',
                params: {
                    covariates,
                    periods,
                    frequency,
                    frequency_days: frequencyDays,
                    date_col: dateColumn,
                    forecast_col: forecastColumn,
                    doc_uuid: documentId,
                    user_context: userContext,
                },
            });

            if (!data.id) {
                throw data;
            }

            return data;
        },
    });

    const postSearchCovariates = useMutation({
        mutationFn: async ({
            dateColumn,
            documentId,
            forecastColumn,
            frequency,
            frequencyDays,
            interval,
            periods,
            userContext,
        }: PostSearchCovariatesProps) => {
            const { data } = await tidsApi.post<never, AxiosResponse<IdResponse>, PostSearchCovariatesBody>(
                `v1${chatApiRoute}/tabular-document/${documentId}/search-covariates`,
                {
                    date_col: dateColumn,
                    periods,
                    interval,
                    forecast_col: forecastColumn,
                    frequency,
                    frequency_days: frequencyDays,
                    user_context: userContext,
                }
            );

            return data;
        },
    });

    const postSummary = useMutation({
        mutationFn: async ({
            banner,
            channel,
            gtins = ['00034000002405'],
            model = 'gpt-4',
            summaryName,
            weeks = 12,
        }: PostSummaryProps) => {
            const { data } = await tidsApi.post<IdResponse>(`v1${chatApiRoute}/summary`, {
                model,
                summary_kind: summaryName,
                params: {
                    until: '2022-RW47',
                    weeks,
                    gtins,
                    ...(banner && { banner }),
                    ...(channel && { channel }),
                    dataset: isNumerator ? 'numerator' : 'synth',
                },
            });

            if (!data.id) {
                throw data;
            }

            return data;
        },
    });

    const postSummaryFeedback = useMutation({
        mutationFn: async ({
            category,
            explanation,
            feedback,
            talkingPointId,
            label,
            type,
            summaryId,
        }: PostSummaryFeedbackProps) => {
            const { data } = await tidsApi.post<FeedbackResponse>(
                `v1${chatApiRoute}/summary/${summaryId}/feedback`,
                {
                    category,
                    explanation,
                    feedback,
                    label,
                    talking_point: talkingPointId,
                    type,
                }
            );

            return data;
        },
    });

    const tidsMutations = useMemo(
        () => ({
            deleteChatV2: deleteChatV2.mutateAsync,
            postChat: postChat.mutateAsync,
            postChatFeedback: postChatFeedback.mutateAsync,
            postChatV2: postChatV2.mutateAsync,
            postDocument: postDocument.mutateAsync,
            postForecastSummary: postForecastSummary.mutateAsync,
            postMessageV2: postMessageV2.mutateAsync,
            postSearchCovariates: postSearchCovariates.mutateAsync,
            postSummary: postSummary.mutateAsync,
            postSummaryFeedback: postSummaryFeedback.mutateAsync,
        }),
        [
            deleteChatV2.mutateAsync,
            postChat.mutateAsync,
            postChatFeedback.mutateAsync,
            postChatV2.mutateAsync,
            postForecastSummary.mutateAsync,
            postMessageV2.mutateAsync,
            postSearchCovariates.mutateAsync,
            postSummary.mutateAsync,
            postSummaryFeedback.mutateAsync,
            postDocument.mutateAsync,
        ]
    );

    return (
        <TidsMutationsContext.Provider value={tidsMutations}>
            {children}
        </TidsMutationsContext.Provider>
    );
}

interface PostDocumentParams {
    mime: string;
    name: string;
    ms_id: number;
    summary_id?: string;
}

interface PostSearchCovariatesBody {
    date_col: string;
    periods: number;
    frequency: Frequency;
    forecast_col: string;
    frequency_days?: number;
    interval: Interval;
    user_context: string;
}
