import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconOwnProps } from '@mui/material';

interface ExpandIconProps {
    color?: IconOwnProps['color'];
    open: boolean;
}

export function ExpandIcon({ color, open }: ExpandIconProps) {
    return (
        <ExpandLessIcon
            color={color}
            style={{
                transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.3s',
            }}
        />
    );
}
