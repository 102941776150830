import { GlobalStyles, PaletteMode, ThemeProvider, useMediaQuery } from '@mui/material';
import { CSSProperties, PropsWithChildren, useMemo, useReducer } from 'react';
import { TogglePaletteModeContext } from './TogglePaletteModeContext';
import { darkTheme } from './themes/darkTheme';
import { lightTheme } from './themes/lightTheme';

const sharedReset: CSSProperties = {
    margin: 0,
    fontSize: 'unset',
    fontWeight: 'unset',
};

export function MuiThemeProvider({
    children,
}: PropsWithChildren) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const [paletteMode, togglePaletteMode] = useReducer(
        (mode: PaletteMode) => (mode === 'light' ? 'dark' : 'light'),
        // prefersDarkMode ? 'dark' : 'light'
        'dark'
    );

    const theme = useMemo(() => (paletteMode === 'dark' ? darkTheme : lightTheme), [paletteMode]);

    const outlineStyle: CSSProperties = { outline: `1px solid ${theme.palette.info.main}` };

    return (
        <ThemeProvider theme={theme}>
            <TogglePaletteModeContext.Provider value={togglePaletteMode}>
                <GlobalStyles
                    styles={{
                        a: {
                            borderRadius: theme.shape.borderRadius,
                            color: theme.palette.text.primary,
                            cursor: 'pointer',
                            textDecoration: 'none',
                        },
                        'a:active': {
                            filter: 'brightness(0.6)',
                        },
                        'a:disabled': {
                            cursor: 'default',
                            filter: 'brightness(0.5)',
                        },
                        'a:focus-visible': outlineStyle,
                        'a:hover': {
                            filter: 'brightness(0.8)',
                        },
                        body: {
                            fontFamily: ['Gilroy-Regular', 'sans-serif'],
                            margin: 0,
                            overflow: 'hidden',
                            padding: 0,
                            WebkitFontSmoothing: 'antialiased',
                            MozOsxFontSmoothing: 'grayscale',
                        },
                        button: {
                            background: 'none',
                            border: 'none',
                            boxSizing: 'border-box',
                            color: theme.palette.text.primary,
                            cursor: 'pointer',
                            margin: 0,
                            padding: 0,
                        },
                        'button:active:enabled': {
                            filter: 'brightness(0.6)',
                        },
                        'button:disabled': {
                            cursor: 'default',
                            filter: 'brightness(0.5)',
                        },
                        'button:focus-visible': outlineStyle,
                        'button:hover:enabled': {
                            filter: 'brightness(0.8)',
                        },
                        'div[role="button"]:focus-visible': outlineStyle,
                        code: {
                            fontFamily: ['source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace'],
                        },
                        div: {
                            boxSizing: 'border-box',
                        },
                        input: {
                            ...sharedReset,
                            border: 'none',
                            boxSizing: 'border-box',
                            padding: 0,
                        },
                        h1: sharedReset,
                        'h1:focus-visible': outlineStyle,
                        h2: sharedReset,
                        'h2:focus-visible': outlineStyle,
                        h3: sharedReset,
                        'h3:focus-visible': outlineStyle,
                        h4: sharedReset,
                        'h4:focus-visible': outlineStyle,
                        h5: sharedReset,
                        'h5:focus-visible': outlineStyle,
                        h6: sharedReset,
                        'h6:focus-visible': outlineStyle,
                        li: sharedReset,
                        p: sharedReset,
                        'p:focus-visible': outlineStyle,
                        'div:focus': { outline: 'none' },
                        textarea: {
                            ...sharedReset,
                            boxSizing: 'border-box',
                        },
                        th: sharedReset,
                        ul: {
                            marginBlockStart: 0,
                            marginBlockEnd: 0,
                        },
                        '::-webkit-scrollbar': {
                            height: 3,
                            width: 3,
                        },
                        '::-webkit-scrollbar-corner': {
                            background: 'inherit',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: theme.palette.middleGray.main,
                            borderRadius: 2,
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                            background: theme.palette.info.main,
                        },
                        '::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                        },
                    }}
                />
                {children}
            </TogglePaletteModeContext.Provider>
        </ThemeProvider>
    );
}
