import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { MyProduct, ScoredProduct, SubstitutionsSummaryData } from '@tickr/sequelize-models';
import isEmpty from 'lodash/isEmpty';
import { ChatCpgEvent, CompleteEvent } from '../../ChatCpg/types/chatCpgTypes';
import { debugLogger } from '../../helpers/debugLogger';
import { shortenGtin } from '../../helpers/stringHelpers';
import { useCaptureTickrException } from '../useCaptureTickrException';
import { useChatCpgStore } from '../useChatCpgStore';
import { usePage } from '../usePage';
import { useReplaceReportingWeek } from '../useReplaceReportingWeek';
import { useReplaceTimestamp } from '../useReplaceTimestamp';
import { useStagedPageFns } from '../useStagedPageFns';

type SubstitutionsEvents =
  | {
      type: 'substitutions-data';
      gtin: string;
      talking_point: 'substitutions';
      data: {
          top_substitutions: ScoredProduct[];
          my_product: MyProduct;
      };
  }
  | {
      type: 'complements-data';
      gtin: string;
      talking_point: 'complements';
      data: {
          top_complements: ScoredProduct[];
          my_product: MyProduct;
      };
  };

type SubstitutionsCompleteEvent = CompleteEvent<{
    complements: { headline: string } & {
        [gtin: string]: {
            'complements-data': { headline: string } & {
                top_complements: ScoredProduct[];
                my_product: MyProduct;
            };
            recommendations: string;
        };
    };

    substitutions: { headline: string } & {
        [gtin: string]: {
            'substitutions-data': {
                top_substitutions: ScoredProduct[];
                my_product: MyProduct;
            };
            recommendations: string;
        };
    };
}>;

type SubstitutionsStreamEvent =
    | ChatCpgEvent
    | SubstitutionsCompleteEvent
    | SubstitutionsEvents;

export function useOnMessageSubstitutions() {
    const captureTickrException = useCaptureTickrException();
    const { pageUuid, summaryId, substitutionSummaryData } = usePage();
    const { updateStagedPage } = useStagedPageFns(pageUuid ?? '');

    const updateStreamText = useChatCpgStore((state) => state.updateStreamText);
    const replaceTimestamp = useReplaceTimestamp();
    const replaceReportingWeek = useReplaceReportingWeek();

    const formatText = (text?: string) => replaceTimestamp(replaceReportingWeek(shortenGtin(text)));

    return (
        event: EventSourceMessage,
        streamText: {
            substitutions: string;
            complements: string;
        }
    ) => {
        if (event.event === 'ping' || event.data === '' || !summaryId || !pageUuid) return;

        let eventData: SubstitutionsStreamEvent;

        try {
            eventData = JSON.parse(event.data);
        } catch (error) {
            captureTickrException(error);
            return;
        }

        debugLogger(eventData);

        if ('meta' in eventData) {
            let myProductGtin: string;
            const substitutionsData: Partial<SubstitutionsSummaryData> = {};

            switch (eventData.meta) {
                case 'ACK':
                    break;
                case 'DONE':
                    if ('type' in eventData) {
                        switch (eventData.type) {
                            case 'headline':
                                updateStreamText([`${eventData.talking_point}-headline`, summaryId], {
                                    text: '',
                                    isStreaming: false,
                                });

                                break;
                            case 'text':
                                updateStreamText([`${eventData.talking_point}-insight`, summaryId], {
                                    text: '',
                                    isStreaming: false,
                                });

                                break;
                        }
                    } else {
                        updateStagedPage({
                            commit: true,
                            settings: {
                                summary: {
                                    isComplete: true,
                                    isStreaming: false,
                                    substitutions: {
                                        headlines: {
                                            complements: streamText.complements,
                                            substitutions: streamText.substitutions,
                                        },
                                    },
                                },
                            },
                        });
                    }

                    break;
                case 'COMPLETE':
                    myProductGtin = Object.keys(eventData.data.substitutions).filter((key) => key !== 'headline')[0];

                    if (!substitutionSummaryData?.substitutions) {
                        substitutionsData.myProduct = eventData.data.substitutions[myProductGtin]?.['substitutions-data']?.my_product;
                        substitutionsData.substitutions = eventData.data.substitutions[myProductGtin]?.['substitutions-data'].top_substitutions;

                        if (!substitutionSummaryData?.activeSubstitutionGtin) {
                            substitutionsData.activeSubstitutionGtin = substitutionsData.substitutions?.[0].gtin;
                        }
                    }

                    if (!substitutionSummaryData?.complements) {
                        substitutionsData.complements = eventData.data.complements[myProductGtin]?.['complements-data'].top_complements;

                        if (!substitutionSummaryData?.activeComplementGtin) {
                            substitutionsData.activeComplementGtin = substitutionsData.complements?.[0].gtin;
                        }
                    }

                    if (!substitutionSummaryData?.headlines?.substitutions ||
                        !substitutionSummaryData?.headlines?.complements
                    ) {
                        substitutionsData.headlines = {
                            complements: eventData.data.complements.headline,
                            substitutions: eventData.data.substitutions.headline,
                        };
                    }

                    if (!isEmpty(substitutionsData)) {
                        updateStagedPage({
                            commit: true,
                            settings: {
                                summary: {
                                    isComplete: true,
                                    isStreaming: false,
                                    substitutions: substitutionsData,
                                },
                            },
                        });
                    }

                    break;

                case 'ERROR':
                    updateStagedPage({
                        commit: true,
                        settings: {
                            summary: {
                                error: true,
                                isStreaming: false,
                            },
                        },
                    });

                    break;
            }
        } else if ('type' in eventData) {
            switch (eventData.type) {
                case 'headline':
                    updateStreamText(
                        [`${eventData.talking_point}-headline`, summaryId],
                        {
                            text: eventData.text,
                            isStreaming: true,
                        },
                        formatText
                    );

                    streamText[eventData.talking_point as 'substitutions' | 'complements'] += eventData.text;

                    break;
                case 'text':
                    break;
                case 'substitutions-data':
                    if (!substitutionSummaryData?.substitutions) {
                        updateStagedPage({
                            settings: {
                                summary: {
                                    isStreaming: true,
                                    substitutions: {
                                        myProduct: eventData.data.my_product,
                                        substitutions: eventData.data.top_substitutions,
                                        activeSubstitutionGtin: eventData.data.top_substitutions[0].gtin,
                                    },
                                },
                            },
                        });
                    }

                    break;
                case 'complements-data':
                    if (!substitutionSummaryData?.complements) {
                        updateStagedPage({
                            settings: {
                                summary: {
                                    isStreaming: true,
                                    substitutions: {
                                        complements: eventData.data.top_complements,
                                        activeComplementGtin: eventData.data.top_complements[0].gtin,
                                    },
                                },
                            },
                        });
                    }

                    break;
                default:
                    debugLogger({ unknown: eventData });
                    break;
            }
        }
    };
}
