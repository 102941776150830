import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaStream } from '../../ChatCpg/types/chatCpgTypes';
import { debugLogger } from '../../helpers/debugLogger';
import { replaceDoubleEscape, shortenGtin } from '../../helpers/stringHelpers';
import { useCaptureTickrException } from '../../hooks/useCaptureTickrException';
import { useChatCpgStore } from '../../hooks/useChatCpgStore';
import { usePage } from '../../hooks/usePage';
import { useReplaceReportingWeek } from '../../hooks/useReplaceReportingWeek';
import { useReplaceTimestamp } from '../../hooks/useReplaceTimestamp';
import { useApi } from '../ApiProvider/useApi';
import { useApp } from '../AppProvider/useApp';
import { useTidsMutations } from '../TidsMutationsProvider/useTidsMutations';
import { ChatBoxContext, Conversation, FileItem, FileStatus, FileSummary, MessageItem, MessageStatus, RawFile, RawMessage } from './ChatBoxContext';
import { ChatBoxFormContext } from './ChatBoxFormContext';
import { useChatBox } from './useChatBox';

type ChatStreamEvent =
  | MetaStream
  | {
      data: {
          document: RawFile;
      };
      type: 'document';
  }
  | {
      status: MessageStatus | FileStatus;
      type: 'status';
  }
  | {
      text: string;
      type: 'summary';
  }
  | {
      talking_point: 'chat';
      text: string;
      type: 'answer';
  };

interface ChatHistoryData {
    id: string;
    messages: RawMessage[];
    files: RawFile[];
}

export function ChatBoxProvider({ children }: PropsWithChildren) {
    const captureTickrException = useCaptureTickrException();
    const { t } = useTranslation();
    const { tidsApiUrl, chatApiRoute } = useApp();
    const { tidsApi } = useApi();
    const { isStreaming, summaryId } = usePage();
    const queryClient = useQueryClient();

    const replaceTimestamp = useReplaceTimestamp();
    const replaceReportingWeek = useReplaceReportingWeek();

    const clearStreamText = useChatCpgStore((state) => state.clearStreamText);
    const updateStreamText = useChatCpgStore((state) => state.updateStreamText);

    const formatText = (text?: string) => replaceReportingWeek(
        replaceTimestamp(replaceDoubleEscape(shortenGtin(text)))
    );

    const [chatInput, setChatInput] = useState('');

    const [chatStreamId, setChatStreamId] = useState('');
    const [fileStreamId, setFileStreamId] = useState('');

    const [chatScrollPosition, setChatScrollPosition] = useState(0);

    useQuery({
        queryKey: ['chat-stream', summaryId, chatStreamId],
        queryFn({ signal }) {
            if (!summaryId) return false;

            fetchEventSource(
                `${tidsApiUrl}/v1${chatApiRoute}/summary/${summaryId}/chat/${chatStreamId}`,
                {
                    method: 'GET',
                    openWhenHidden: true,
                    signal,
                    async onopen() {
                        debugLogger('CHAT STREAM OPEN');
                    },
                    onmessage(event) {
                        if (event.event === 'ping' || event.data === '') return;

                        const streamData: ChatStreamEvent = JSON.parse(event.data);

                        if ('meta' in streamData) {
                            if (streamData.meta === 'ACK') {
                                const conversation = queryClient.getQueryData<Conversation>(['chat-history', summaryId]) ?? [];

                                if (conversation[0]?.id !== 'new-chat-stream') {
                                    queryClient.setQueryData<Conversation>(['chat-history', summaryId], () => {
                                        return [
                                            {
                                                id: 'new-chat-stream',
                                                sender: 'AI',
                                                status: 'generating',
                                                text: '',
                                                type: 'message',
                                                timestamp: Date.now(),
                                            },
                                            ...conversation,
                                        ];
                                    });
                                }
                            }
                        } else {
                            if ('text' in streamData) {
                                const { text } = streamData;

                                updateStreamText(
                                    [chatStreamId, summaryId],
                                    {
                                        text,
                                        isStreaming: true,
                                    },
                                    formatText
                                );
                            }
                        }
                    },
                    onclose() {
                        debugLogger('CHAT STREAM CLOSED');
                        queryClient.invalidateQueries({ queryKey: ['chat-history', summaryId] });
                    },
                    onerror(error) {
                        debugLogger('CHAT STREAM ERROR', { error });
                        captureTickrException(error);
                        clearStreamText(chatStreamId);
                        setChatStreamId('');
                        queryClient.invalidateQueries({ queryKey: ['chat-history', summaryId] });
                    },
                }
            );

            return true;
        },
        enabled: !!chatStreamId && !!summaryId && summaryId !== '123',
        staleTime: Infinity,
    });

    useQuery({
        queryKey: ['file-stream', summaryId, fileStreamId],
        queryFn({ signal }) {
            if (!summaryId) return false;

            fetchEventSource(`${tidsApiUrl}/v1${chatApiRoute}/document/${fileStreamId}`, {
                method: 'GET',
                openWhenHidden: true,
                signal,
                async onopen() {
                    debugLogger('FILE STREAM OPEN');
                },
                onmessage(event) {
                    if (event.event === 'ping' || event.data === '') return;

                    let eventData: ChatStreamEvent;

                    try {
                        eventData = JSON.parse(event.data);
                    } catch (error) {
                        captureTickrException(error);
                        return;
                    }

                    if ('meta' in eventData) {
                        switch (eventData.meta) {
                            case 'ACK':
                                break;
                                // case 'COMPLETE':
                                //   break;
                            case 'ERROR':
                                setFileStreamId('');
                                break;
                            default:
                                break;
                        }
                    } else {
                        if (eventData.type === 'document') {
                            const { document } = eventData.data;

                            const conversation = queryClient.getQueryData<Conversation>(['chat-history', summaryId]) ?? [];

                            const updatedConversation: Conversation = conversation?.map((item) => (item.id === 'new-file'
                                ? {
                                    ...item,
                                    mime: document.mime,
                                    name: document.name,
                                    id: document.id,
                                    status: 'uploading',
                                    summary: null,
                                    timestamp: document.timestamp * 1000,
                                    type: 'file',
                                }
                                : item));

                            queryClient.setQueryData<Conversation>(
                                ['chat-history', summaryId],
                                () => updatedConversation
                            );
                        } else if ('text' in eventData) {
                            const { text } = eventData;

                            updateStreamText(
                                [fileStreamId, summaryId],
                                {
                                    text,
                                    isStreaming: true,
                                },
                                formatText
                            );
                        }

                        if (eventData.type === 'status') {
                            const { status } = eventData;

                            const conversation = queryClient.getQueryData<Conversation>(['chat-history', summaryId]) ?? [];

                            const updatedConversation: Conversation = conversation
                                .map((item) => (item.id === fileStreamId
                                    ? {
                                        ...(item as FileItem),
                                        status: status as FileStatus,
                                    }
                                    : item));

                            queryClient.setQueryData<Conversation>(
                                ['chat-history', summaryId],
                                () => updatedConversation
                            );
                        }
                    }
                },
                onclose() {
                    debugLogger('FILE STREAM CLOSED');
                    queryClient.invalidateQueries({ queryKey: ['chat-history', summaryId] });
                },
                onerror(error) {
                    debugLogger('FILE STREAM ERROR', { error });
                    captureTickrException(error);
                    setFileStreamId('');
                    clearStreamText(fileStreamId);
                    queryClient.invalidateQueries({ queryKey: ['chat-history', summaryId] });
                },
            });

            return true;
        },
        enabled: !!fileStreamId && !!summaryId,
        staleTime: Infinity,
    });

    const { data: conversation = [] } = useQuery<Conversation>({
        queryKey: ['chat-history', summaryId],
        queryFn: async ({ signal }) => {
            const { data } = await tidsApi.get<ChatHistoryData>(`v1${chatApiRoute}/summary/${summaryId}/chat`, { signal });

            const messages: MessageItem[] = data.messages.map(({ timestamp, text, ...message }) => ({
                text: formatText(text),
                timestamp: timestamp * 1000,
                type: 'message',
                ...message,
            }));

            const files: FileItem[] = data.files.map(({ timestamp, ...file }) => {
                let parsedSummary: FileSummary | null;

                try {
                    parsedSummary = file.summary ? (JSON.parse(file.summary) as FileSummary) : null;
                } catch (error) {
                    parsedSummary = {
                        summary: file.summary ?? t('chatCpg.chat.error'),
                        questions: [],
                    };
                }

                return {
                    ...file,
                    timestamp: timestamp * 1000,
                    type: 'file',
                    summary: parsedSummary,
                };
            });

            if (
                files[files.length - 1] &&
                files[files.length - 1]?.status !== 'complete' &&
                files[files.length - 1]?.status !== 'error'
            ) {
                setFileStreamId(files[files.length - 1].id);
            }

            const chatHistory = [...messages, ...files].sort((a, b) => b.timestamp - a.timestamp);

            setFileStreamId('');
            setChatStreamId('');
            return chatHistory;
        },
        enabled: !!summaryId && !isStreaming && summaryId !== '123',
        staleTime: Infinity,
        // WP Note: onSuccess was removed from React Query v5 so I added them above, which may not be the correct move but it seems to work
        // onSuccess() {
        //     setFileStreamId('');
        //     setChatStreamId('');
        // },
    });

    const fileList = useMemo(() => {
        return conversation.filter((item) => item.type === 'file') as FileItem[];
    }, [conversation]);

    const chatCpgValues = useMemo(
        () => ({
            chatInput,
            chatScrollPosition,
            chatStreamId,
            conversation,
            fileList,
            fileStreamId,
            setChatInput,
            setChatScrollPosition,
            setChatStreamId,
            setFileStreamId,
        }),
        [
            chatInput,
            chatStreamId,
            conversation,
            fileList,
            fileStreamId,
            chatScrollPosition,
        ]
    );

    return (
        <ChatBoxContext.Provider value={chatCpgValues}>
            <ChatBoxFormProvider>{children}</ChatBoxFormProvider>
        </ChatBoxContext.Provider>
    );
}

function ChatBoxFormProvider({ children }: PropsWithChildren) {
    const captureTickrException = useCaptureTickrException();
    const { summaryId } = usePage();

    const { postChat } = useTidsMutations();
    const { chatInput, setChatStreamId, setChatInput, chatScrollPosition, setChatScrollPosition } = useChatBox();

    const [viewConversation, setViewConversation] = useState(true);
    const knowledgeBaseChatInputRef = useRef<HTMLTextAreaElement>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const historyRef = useRef<HTMLDivElement>(null);

    const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
        initialValues: {
            message: chatInput,
        },
        onSubmit: async (values, { resetForm }) => {
            if (!values.message || !summaryId) return;

            resetForm();

            setViewConversation(true);

            if (chatScrollPosition <= 0) {
                // Need to set this to reset state for
                // the overflowAnchor property to work.
                setChatScrollPosition(0);
                // Then scroll to the bottom (top, lol) of the chat window.
                historyRef.current?.scrollTo(0, 0);
            }

            try {
                const { id } = await postChat({
                    message: values.message,
                    summaryId,
                });

                setChatStreamId(id);
            } catch (e) {
                captureTickrException(e);
            }

            inputRef.current?.focus();
        },
        onReset: () => {
            setChatInput('');
        },
    });

    const setMessage = useCallback(
        (message: string) => {
            setFieldValue('message', message);
        },
        [setFieldValue]
    );

    const onClickSuggestion = useCallback(
        (message: string) => {
            inputRef.current?.focus();
            setMessage(message);
        },
        [setMessage]
    );

    const onClickSuggestionKnowledgeBase = useCallback(
        (message: string) => {
            knowledgeBaseChatInputRef.current?.focus();
            setMessage(message);
        },
        [setMessage]
    );

    const memoizedValue = useMemo(
        () => ({
            handleChange,
            handleSubmit,
            historyRef,
            inputRef,
            knowledgeBaseChatInputRef,
            onClickSuggestion,
            onClickSuggestionKnowledgeBase,
            setMessage,
            setViewConversation,
            values,
            viewConversation,
        }),
        [
            handleChange,
            handleSubmit,
            onClickSuggestion,
            onClickSuggestionKnowledgeBase,
            setMessage,
            values,
            viewConversation,
        ]
    );

    return (
        <ChatBoxFormContext.Provider value={memoizedValue}>{children}</ChatBoxFormContext.Provider>
    );
}
