import { StyledComponent } from '@emotion/styled';
import { TableRow, TableRowProps, styled } from '@mui/material';

interface TickrTableRowProps {
    background?: string;
    highlight?: boolean;
    highlightColor?: string;
    invertStripes?: boolean;
}

export const TickrTableRow: StyledComponent<TableRowProps, TickrTableRowProps> = styled(
    TableRow,
    { shouldForwardProp: (prop) => prop !== 'invertStripes' }
)<TickrTableRowProps>(
    ({ background, highlight, highlightColor, invertStripes, theme }) => {
        const backgroundStripes = {
            '&:nth-of-type(even)': {
                backgroundColor: background ?? (invertStripes
                    ? theme.palette.background.secondary
                    : theme.palette.background.default),
            },
            '&:nth-of-type(odd)': {
                backgroundColor: background ?? (invertStripes
                    ? theme.palette.background.default
                    : theme.palette.background.secondary),
            },
        };

        return ({
            ...backgroundStripes,
            // ...(!selected && backgroundStripes),
            ...(highlight && {
                boxShadow: `inset 0 0 0 1px ${highlightColor ?? theme.palette.primary.main}`,
                borderRadius: theme.shape.borderRadius,
            }),
        });
    }
);
