import { Dispatch, SetStateAction, createContext } from 'react';

export interface MosaicStateContextProps {
    isDragging: boolean;
    setIsDragging: Dispatch<SetStateAction<boolean>>;
}

export const MosaicStateContext = createContext<MosaicStateContextProps>({
    isDragging: false,
    setIsDragging: () => null,
});
