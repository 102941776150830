import { useTheme } from '@mui/material';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TickrErrorBoundary } from '../Core/TickrErrorBoundary';
import { Typography } from '../Core/Typography';
import { ActiveFiltersProvider } from '../Filters/ActiveFiltersProvider/ActiveFiltersProvider';
import { DashboardControls } from '../MosaicLayout/DashboardControls';
import { Header } from '../Navigation/Header';
import { PageSwitch } from '../Page/PageSwitch';
import { PageLayout } from '../PageLayout/PageLayout';
import { FilterMenuPopover } from '../Popovers/FilterMenuPopover/FilterMenuPopover';
import { SharingPopover } from '../Popovers/SharingPopover/SharingPopover';
import { ChatBoxProvider } from '../Providers/ChatBoxProvider/ChatBoxProvider';
import { OPTIMISTIC_PAGE_ID } from '../Providers/CollectionMutationsProvider/CollectionMutationsProvider';
import { DashboardSettingsProvider } from '../Providers/DashboardSettingsProvider.tsx/DashboardSettingsProvider';
import { useUser } from '../Providers/UserProvider/useUser';
import { getFirstTabUuid } from '../helpers/getFirstTabId';
import { useCurrentProject } from '../hooks/useCurrentProject';
import { usePage } from '../hooks/usePage';
import { useSavePageFilter } from '../hooks/useSavePageFilter';
import { useSolutionSummaryStream } from '../hooks/useSolutionSummaryStream';

export function Dashboard() {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isDemo } = useUser();
    const { currentProject } = useCurrentProject();
    const { error, pageUuid, page, isOutOfStockPredictions, isScorecard } = usePage();
    const onSaveFilter = useSavePageFilter({ page });

    useSolutionSummaryStream();

    useEffect(() => {
        // If the user has no pages, redirect to the home page
        if (currentProject.uuid && currentProject.pages.length === 0) {
            navigate('/home');
        }

        const optimisticExists = !!currentProject.pages.find((page) => page.uuid === OPTIMISTIC_PAGE_ID);

        if (isOutOfStockPredictions && pathname !== '/out-of-stock-predictions') {
            navigate('/out-of-stock-predictions');
        }

        if (isScorecard && pathname !== '/scorecard') {
            navigate('/scorecard');
        }

        if (
            pageUuid &&
            !isEmpty(currentProject.pages) &&
            !page &&
            !optimisticExists // if optimistic page exists, don't auto nav. wait on route for the real id before navigating
        ) {
            const tabUuid = getFirstTabUuid(currentProject.pages[0]?.tabs ?? []);

            const url = tabUuid
                ? `/page/${currentProject.pages[0].uuid}/tab/${tabUuid}`
                : `/page/${currentProject.pages[0].uuid}`;

            if (!tabUuid && currentProject.pages[0].solutionType === 'scorecard') {
                url === '/scorecard';
            }

            if (!tabUuid && currentProject.pages[0].solutionType === 'outOfStockPredictions') {
                url === '/out-of-stock-predictions';
            }

            navigate(url);
        }
    });

    return (
        <ActiveFiltersProvider
            filterScope={page?.filterDef?.activeFilters ?? {}}
            key={pageUuid}
        >
            <ChatBoxProvider>
                <DashboardSettingsProvider>
                    <PageLayout pageTitle={page?.title ?? ''}>
                        <Header
                            endChildren={<DashboardControls />}
                            middleChildren={error && <Typography color={palette.error.main} variant="c-14">{t('error.unknown')}</Typography>}
                        >
                            {isScorecard && <FilterMenuPopover onSave={onSaveFilter} />}
                            {isDemo && <SharingPopover />}
                        </Header>
                        <TickrErrorBoundary message={t('errorBoundary.page', { pageName: page?.title })} padding="1rem">
                            <PageSwitch />
                        </TickrErrorBoundary>
                    </PageLayout>
                </DashboardSettingsProvider>
            </ChatBoxProvider>
        </ActiveFiltersProvider>
    );
}
