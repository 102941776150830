import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { keyframes, styled, useTheme } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { AuthorAvatar } from '../Core/AuthorAvatar';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { DocIcon } from '../Icons/DocIcon';
import { DocxIcon } from '../Icons/DocxIcon';
import { EmlIcon } from '../Icons/EmlIcon';
import { HtmlIcon } from '../Icons/HtmlIcon';
import { PdfIcon } from '../Icons/PdfIcon';
import { PptIcon } from '../Icons/PptIcon';
import { PptxIcon } from '../Icons/PptxIcon';
import { TxtIcon } from '../Icons/TxtIcon';
import { useDateFormatter } from '../hooks/useDateFormatter';

export interface UserBubbleProps {
    id: string;
    fileName?: string;
    text?: string;
    timestamp: number;
}

export function UserBubble({ id, fileName, text, timestamp }: UserBubbleProps) {
    const { keycloak } = useKeycloak();
    const { palette, shape } = useTheme();
    const format = useDateFormatter();

    const fileExt = fileName?.slice(fileName.lastIndexOf('.') + 1).toLowerCase();

    return (
        <Flex
            align="flex-end"
            direction="column"
            gap="1rem"
            padding="1rem"
            style={{
                alignSelf: 'flex-end',
                maxWidth: '90%',
            }}
        >
            <Flex align="center" gap="0.6rem">
                <Typography secondary>{format(timestamp, 'fromNow')}</Typography>
                <AuthorAvatar
                    author={{
                        fullname: keycloak.tokenParsed?.name,
                        username: keycloak.tokenParsed?.email,
                    }}
                    background={palette.sulu.main}
                    size="20px"
                />
            </Flex>
            {text && (
                <Typography
                    secondary
                    style={{ whiteSpace: 'pre-line' }}
                    variant="p-14-r"
                >
                    {text}
                </Typography>
            )}
            {fileName && (
                <Flex
                    align="center"
                    gap="0.5rem"
                    padding="8px 12px"
                    style={{
                        background: palette.cornflowerBlue.main,
                        borderRadius: shape.borderRadius,
                        height: '52px',
                    }}
                >
                    {id === 'new-file'
                        ? (
                            <BlinkWrapper>
                                <CloudUploadIcon />
                            </BlinkWrapper>
                        )
                        : fileExt === 'doc'
                            ? <DocIcon size="36px" />
                            : fileExt === 'docx'
                                ? <DocxIcon size="36px" />
                                : fileExt === 'eml'
                                    ? <EmlIcon size="36px" />
                                    : fileExt === 'html'
                                        ? <HtmlIcon size="36px" />
                                        : fileExt === 'pdf'
                                            ? <PdfIcon size="36px" />
                                            : fileExt === 'ppt'
                                                ? <PptIcon size="36px" />
                                                : fileExt === 'pptx'
                                                    ? <PptxIcon size="36px" />
                                                    : fileExt === 'txt'
                                                        ? <TxtIcon size="36px" />
                                                        : <></>
                    }
                    <Flex direction="column">
                        <Typography
                            lineClamp={2}
                            title={fileName}
                            variant="p-14-b"
                        >
                            {fileName}
                        </Typography>
                        <Typography secondary variant="h-12-b">
                            {fileName.slice(fileName.lastIndexOf('.') + 1).toUpperCase()}
                        </Typography>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
}

const blink = keyframes`
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
`;

const BlinkWrapper = styled('div')(() => ({
    animation: `${blink} 1s infinite`,
}));

