/* eslint-disable @stylistic/jsx-sort-props */
import { TickrErrorBoundary } from '@tickr/ui/src/Core/TickrErrorBoundary';
import { ToasterDisplay } from '@tickr/ui/src/Core/ToasterDisplay';
import { AvailableFiltersProvider } from '@tickr/ui/src/Filters/AvailableFiltersProvider/AvailableFiltersProvider';
import { ApiProvider } from '@tickr/ui/src/Providers/ApiProvider/ApiProvider';
import { CollectionMutationsProvider } from '@tickr/ui/src/Providers/CollectionMutationsProvider/CollectionMutationsProvider';
import { NotificationsProvider } from '@tickr/ui/src/Providers/NotificationsProvider/NotificationsProvider';
import { ProductAttributesProvider } from '@tickr/ui/src/Providers/ProductAttributesProvider/ProductAttributesProvider';
import { TidsMutationsProvider } from '@tickr/ui/src/Providers/TidsMutationsProvider/TidsMutationsProvider';
import { ToasterProvider } from '@tickr/ui/src/Providers/ToasterProvider/ToasterProvider';
import { UserProvider } from '@tickr/ui/src/Providers/UserProvider/UserProvider';
import { useTranslation } from 'react-i18next';
import { WebRoutes } from './WebRoutes';

export function Pages() {
    const { t } = useTranslation();

    return (
        <ToasterProvider>
            <ToasterDisplay />
            <ApiProvider>
                <UserProvider>
                    <TickrErrorBoundary
                        fullscreen
                        message={t('error.app')}
                        padding="1rem"
                    >
                        <ProductAttributesProvider>
                            <AvailableFiltersProvider>
                                <TidsMutationsProvider>
                                    <NotificationsProvider>
                                        <CollectionMutationsProvider>
                                            <WebRoutes />
                                        </CollectionMutationsProvider>
                                    </NotificationsProvider>
                                </TidsMutationsProvider>
                            </AvailableFiltersProvider>
                        </ProductAttributesProvider>
                    </TickrErrorBoundary>
                </UserProvider>
            </ApiProvider>
        </ToasterProvider>
    );
}
