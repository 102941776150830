import { styled, useTheme } from '@mui/material';
import { ElementAttributes } from '@tickr/sequelize-models';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { TickrErrorBoundary } from '../Core/TickrErrorBoundary';
import { ActiveFiltersProvider } from '../Filters/ActiveFiltersProvider/ActiveFiltersProvider';
import { ElementContext } from './ElementContext';
import { ElementSwitch } from './ElementSwitch';

export interface ElementProps {
    element: ElementAttributes;
}

export const Element = memo(({ element }: ElementProps) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    return (
        <TickrErrorBoundary message={t('errorBoundary.element', { elementName: element.title })}>
            <ElementContext.Provider value={element}>
                <ActiveFiltersProvider filterScope={element.filterDef.activeFilters ?? {}}>
                    <ElementWrapperFlex
                        border={`1px solid ${element.settings?.isCard ? 'inherit' : palette.background.tertiary}`}
                        direction="column"
                        height="100%"
                        isCard={element.settings?.isCard}
                        width="100%"
                    >
                        <ElementContent>
                            <ElementSwitch />
                        </ElementContent>
                    </ElementWrapperFlex>
                </ActiveFiltersProvider>
            </ElementContext.Provider>
        </TickrErrorBoundary>
    );
});

const ElementWrapperFlex = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'border' && prop !== 'isCard' }
)<{ isCard?: boolean; border?: string }>(({ border, isCard, theme }) => `
    background: ${theme.palette.background.default};
    border-radius: 0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px;
    border-right: ${border};
    border-left: ${border};
    border-bottom: ${border};
    padding: ${isCard ? 0 : '8px'};
`);

const ElementContent = styled('div')`
    overflow-y: auto;
    width: 100%;
    height: 100%;
    flex-grow: 1;
`;
