import { Placement } from '@floating-ui/react';
import { useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';
import { Typography, Variant } from '../Core/Typography';
import { Tooltip } from '../Tooltip/Tooltip';
import { TooltipContent } from '../Tooltip/TooltipContent';
import { TooltipTrigger } from '../Tooltip/TooltipTrigger';

interface InfoTooltipProps {
    background?: string;
    component?(): JSX.Element;
    containerStyle?: React.CSSProperties;
    disabled?: boolean;
    initialOpen?: boolean;
    isHidden?: boolean;
    leaveOpenOnHover?: boolean;
    maxWidth?: string;
    onOpenChange?: (open: boolean) => void;
    open?: boolean;
    placement?: Placement;
    text?: string;
    typographyVariant?: Variant;
    zindex?: number;
}

export function InfoTooltip({
    background: backgroundProp,
    component,
    containerStyle,
    disabled,
    initialOpen,
    isHidden,
    leaveOpenOnHover,
    maxWidth = '400px',
    onOpenChange,
    open,
    placement = 'bottom',
    text,
    typographyVariant,
    zindex,
    children,
}: PropsWithChildren<InfoTooltipProps>) {
    const { palette, shape } = useTheme();

    const background = backgroundProp ?? palette.tooltip.main;

    return (
        <Tooltip
            background={background}
            fallbackPlacements={['top']}
            initialOpen={initialOpen}
            leaveOpenOnHover={leaveOpenOnHover}
            placement={placement}
            {...(open !== undefined && onOpenChange && {
                open,
                onOpenChange,
            })}
            {...(disabled && { open: false })}
        >
            <TooltipTrigger asChild>{children}</TooltipTrigger>
            <TooltipContent isHidden={isHidden} zindex={zindex}>
                <Flex
                    align="center"
                    padding="0.5rem"
                    style={{
                        minWidth: '30px',
                        borderRadius: shape.borderRadius,
                        background,
                        maxWidth,
                        padding: '1rem',
                        ...containerStyle,
                    }}
                    width="100%"
                >
                    {!component && text && (
                        <Typography
                            multiline
                            style={{
                                wordBreak: 'break-word',
                            }}
                            variant={typographyVariant}
                        >
                            {text}
                        </Typography>
                    )}
                    {component && component()}
                </Flex>
            </TooltipContent>
        </Tooltip>
    );
}
