import { styled } from '@mui/material';

interface DividerProps {
    color?: string;
    solid?: boolean;
    vertical?: boolean;
    stroke?: string;
}

export const Divider = styled('div')<DividerProps>(({ theme, vertical, solid, color, stroke }) => `
    border-bottom: ${`${vertical ? '0px' : stroke ?? '1px'} ${solid ? 'solid' : 'dashed'} ${color ?? theme.palette.divider}`};
    border-right: ${`${vertical ? stroke ?? '1px' : '0px'} ${solid ? 'solid' : 'dashed'} ${color ?? theme.palette.divider}`};
    width: ${vertical ? '3px' : '100%'};
    height: ${vertical ? '100%' : '1px'};
`);
