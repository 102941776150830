import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';

interface StoreLevelOosGraphProps {
    outOfStockWeeks: boolean[];
}

export function StoreLevelOosGraph({ outOfStockWeeks }: StoreLevelOosGraphProps) {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const totalWeeks = outOfStockWeeks.length;
    const oosCount = outOfStockWeeks.filter(Boolean).length;

    return (
        <Flex align="center" gap="0.5rem">

            <Flex gap="1px">
                {outOfStockWeeks.map((isOutOfStock, index) => (
                    <div
                    // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        style={{
                            width: '10px',
                            height: '20px',
                            background: isOutOfStock ? palette.error.main : palette.success.main,
                        }}
                    />
                ))}
            </Flex>
            <Typography>
                {totalWeeks === 1
                    ? t('outOfStock.xOfXWeek', {
                        oosCount,
                        totalWeeks,
                    })
                    : t('outOfStock.xOfXWeeks', {
                        oosCount,
                        totalWeeks,
                    })
                }
            </Typography>
        </Flex>
    );
}
