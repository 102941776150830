import { useTheme } from '@mui/material';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function DocxIcon(props: IconProps) {
    const { palette } = useTheme();

    return (
        <IconWrapper {...props} color="">
            <svg
                fill="none"
                height="59"
                viewBox="0 0 50 59"
                width="50"
            >
                <path
                    clipRule="evenodd"
                    d="M7.15381 24.9084V3.13916H31.2968V13.8425C31.2968 15.4993 32.6399 16.8425 34.2968 16.8425H45V54.5568H7.15381V41.9084H32C33.6569 41.9084 35 40.5652 35 38.9084V27.9084C35 26.2515 33.6569 24.9084 32 24.9084H7.15381ZM4.15381 24.9084V3.13916C4.15381 1.48231 5.49696 0.13916 7.15381 0.13916H31.7278C32.7886 0.13916 33.806 0.560588 34.5562 1.31073L34.6978 1.45237L34.711 1.46544L46.6738 13.4282L46.6868 13.4413L46.8284 13.583C47.5786 14.3331 48 15.3505 48 16.4114V54.5568C48 56.2136 46.6569 57.5568 45 57.5568H7.15381C5.49696 57.5568 4.15381 56.2136 4.15381 54.5568V41.9084H3C1.34315 41.9084 0 40.5652 0 38.9084V27.9084C0 26.2515 1.34315 24.9084 3 24.9084H4.15381ZM34.2968 13.8425V5.29398L42.8453 13.8425H34.2968Z"
                    fill={palette.primary.main}
                    fillRule="evenodd"
                    opacity="0.7"
                />
                <path
                    d="M5.17155 29.95C7.39105 29.95 9.04705 31.7095 9.04705 33.975C9.04705 36.2405 7.39105 38 5.17155 38H1.97455V29.95H5.17155ZM5.17155 36.482C6.56305 36.482 7.52905 35.4585 7.52905 33.975C7.52905 32.4915 6.56305 31.468 5.17155 31.468H3.56155V36.482H5.17155ZM13.9793 38.161C11.6563 38.161 9.80479 36.321 9.80479 33.975C9.80479 31.6175 11.6563 29.789 13.9793 29.789C16.3023 29.789 18.1653 31.6175 18.1653 33.975C18.1653 36.321 16.3023 38.161 13.9793 38.161ZM13.9793 36.6085C15.4398 36.6085 16.5783 35.516 16.5783 33.975C16.5783 32.4225 15.4398 31.33 13.9793 31.33C12.5188 31.33 11.3803 32.4225 11.3803 33.975C11.3803 35.516 12.5188 36.6085 13.9793 36.6085ZM23.0987 38.161C20.6607 38.161 18.9127 36.321 18.9127 33.975C18.9127 31.6175 20.6607 29.789 23.0987 29.789C24.5707 29.789 25.8702 30.5365 26.5602 31.6865L25.1917 32.48C24.8007 31.767 24.0187 31.33 23.0987 31.33C21.5232 31.33 20.4882 32.4225 20.4882 33.975C20.4882 35.516 21.5232 36.6085 23.0987 36.6085C24.0187 36.6085 24.8122 36.1715 25.1917 35.47L26.5602 36.2635C25.8817 37.4135 24.5822 38.161 23.0987 38.161ZM33.6309 38H31.8254L30.2614 35.378L28.6974 38H26.9034L29.3644 33.8715L27.0299 29.95H28.8239L30.2614 32.365L31.6989 29.95H33.4929L31.1584 33.86L33.6309 38Z"
                    fill={palette.background.default}
                />
            </svg>
        </IconWrapper>
    );
}
