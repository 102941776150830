import { Typography } from '../../Core/Typography';

interface ScoreCellProps {
    className?: string;
    score: number;
}

export function ScoreCell({ className, score }: ScoreCellProps) {
    return (
        <Typography
            as="p"
            className={className}
            textAlign="right"
            variant="h-16-b"
        >
            {score.toFixed()}
            <Typography
                as="span"
                secondary
                variant="p-12-r"
            >
                /100
            </Typography>
        </Typography>
    );
}
