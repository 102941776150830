import * as React from 'react';
import { PopoverContext } from './PopoverContext';
import { PopoverOptions, usePopover } from './usePopover';

export function PopoverProvider({
    children,
    modal = false,
    ...restOptions
}: {
    children: React.ReactNode;
} & PopoverOptions) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const arrowRef = React.useRef<HTMLDivElement>(null);

    const popover = usePopover({
        modal,
        arrowRef,
        ...restOptions,
    });

    return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
}
