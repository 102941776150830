import { useTranslation } from 'react-i18next';
import { InsightCard } from '../Cards/InsightCard';
import { IntroCard } from '../Cards/IntroCard';
import { SalesPerformanceInsightsWeb } from '../Cards/SalesPerformanceInsightsWeb';
import { CategoryVsCpi } from '../Charts/CategoryVsCpi';
import { CompetitorsPriceVsMyVolume } from '../Charts/CompetitorsPriceVsMyVolume';
import { ForecastChart } from '../Charts/ForecastChart';
import { PriceComparison } from '../Charts/PriceComparison';
import { PriceOptimizedRevenueTids } from '../Charts/PriceOptimizedRevenueTids';
import { PriceRevenueVolume } from '../Charts/PriceRevenueVolume';
import { ProjectedRevenue } from '../Charts/ProjectedRevenue';
import { Posts } from '../Posts/Posts';
import { useError } from '../Providers/ErrorProvider/useError';
import { ComplementsTable } from '../Tables/ComplementsTable';
import { InfluentialCompetitors } from '../Tables/InfluentialCompetitors';
import { OutOfStockPredictions } from '../Tables/OutOfStockPredictions/OutOfStockPredictions';
import { SalesPerformanceWeb } from '../Tables/SalesPerformance/SalesPerformanceWeb';
import { SubstitutesTable } from '../Tables/SubstitutesTable';
import { useElement } from './useElement';

export function ElementSwitch() {
    const { t } = useTranslation();
    const element = useElement();
    const { setError } = useError();

    switch (element.type) {
        case 'categoryVsCpi':
            return <CategoryVsCpi />;
        case 'competitorsPriceVsMyVolume':
            return <CompetitorsPriceVsMyVolume />;
        case 'complementsTable':
            return <ComplementsTable />;
        case 'forecastChart':
            return <ForecastChart />;
        case 'influentialCompetitors':
            return <InfluentialCompetitors />;
        case 'insightCard':
            return <InsightCard />;
        case 'introCard':
            return <IntroCard />;
        case 'outOfStockPredictions':
            return <OutOfStockPredictions />;
        case 'posts':
            return <Posts />;
        case 'priceComparison':
            return <PriceComparison />;
        case 'priceOptimizedRevenueTids':
            return <PriceOptimizedRevenueTids />;
        case 'priceRevenueVolume':
            return <PriceRevenueVolume />;
        case 'projectedRevenue':
            return <ProjectedRevenue />;
        case 'salesPerformance':
            return <SalesPerformanceWeb />;
        case 'salesPerformanceInsights':
            return <SalesPerformanceInsightsWeb />;
        case 'substitutesTable':
            return <SubstitutesTable />;
        default:
            setError(t('error.elementNotFound', { type: element.type }));
            throw new Error(`Element type not found: ${element.type}`);
    }
}
