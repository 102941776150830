import { useQuery } from '@tanstack/react-query';
import { PopulatedPage, PopulatedProject } from '@tickr/sequelize-models';
import { useMemo } from 'react';
import { useApi } from '../Providers/ApiProvider/useApi';

interface UseCurrentProjectResponse {
    currentProject: PopulatedProject;
    isLoading: boolean;
    isNewProject: boolean;
}

export const useCurrentProject = (): UseCurrentProjectResponse => {
    const { dashboardApi } = useApi();

    const { data: currentProject = { pages: [] as PopulatedPage[] } as PopulatedProject, isLoading } = useQuery({
        queryKey: ['current-project'],
        queryFn: async ({ signal }) => {
            const { data = [] } = await dashboardApi.get<PopulatedProject[]>('projects', {
                params: {
                    populate: ['pages'],
                },
                signal,
            });

            return data[0];
        },
    });

    return useMemo(() => ({
        currentProject,
        isLoading,
        isNewProject: currentProject.isNew,
    }), [currentProject, isLoading]);
};
