import { useTheme } from '@mui/material';
import { Circle } from '../../Core/Circle';

interface InvalidFilterIndicatorProps {
    absolute?: boolean;
    top?: string;
    right?: string;
    onClick?: () => void;
}

export function InvalidFilterIndicator({
    absolute,
    top = 'unset',
    right = 'unset',
    onClick,
}: InvalidFilterIndicatorProps) {
    const theme = useTheme();

    return (
        <Circle
            color={theme.palette.warning.main}
            onClick={onClick}
            size={absolute ? '10px' : '10px'}
            style={{
                top: top,
                right: right,
                ...(absolute && { position: 'absolute' }),
            }}
        />
    );
}
