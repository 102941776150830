import HomeIcon from '@mui/icons-material/Home';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, styled } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { ErrorBoundary } from '@sentry/react';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ErrorProvider } from '../Providers/ErrorProvider/ErrorProvider';
import { useError } from '../Providers/ErrorProvider/useError';
import { useUser } from '../Providers/UserProvider/useUser';
import { Flex } from './Flex';
import { Typography } from './Typography';

interface TickrErrorBoundaryProps {
    fullscreen?: boolean;
    message: string;
    navigable?: boolean;
    navigateText?: string;
    navigateTo?: string;
    padding?: string;
    reloadable?: boolean;
}

export function TickrErrorBoundary(
    {
        fullscreen,
        message,
        navigable = true,
        navigateTo,
        navigateText,
        padding,
        reloadable = true,
        children,
    }: PropsWithChildren<TickrErrorBoundaryProps>
) {
    const user = useUser();

    return (
        <ErrorProvider>
            <ErrorBoundary
                beforeCapture={(scope) => {
                    scope.setUser({
                        id: user.userId,
                    });
                }}
                fallback={(
                    <ErrorBox
                        fullscreen={fullscreen}
                        message={message}
                        navigable={navigable}
                        navigateText={navigateText}
                        navigateTo={navigateTo}
                        padding={padding}
                        reloadable={reloadable}
                    />
                )}
            >
                {children}
            </ErrorBoundary>
        </ErrorProvider>
    );
}

const StyledFlex = styled(
    Flex,
    { shouldForwardProp: () => true }
)(({ theme }) => ({
    border: `1px dashed ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadius,
}));

interface ErrorBoxProps {
    fullscreen?: boolean;
    message: string;
    navigable?: boolean;
    navigateText?: string;
    navigateTo?: string;
    padding?: string;
    reloadable?: boolean;
}

function ErrorBox({ fullscreen, message, navigable, navigateText, navigateTo, padding, reloadable }: ErrorBoxProps) {
    const { t } = useTranslation();
    const { error } = useError();
    const { keycloak } = useKeycloak();

    return (
        <StyledFlexBackground
            height={fullscreen ? '100vh' : '100%'}
            padding={padding}
            width="100%"
        >
            <StyledFlex
                align="center"
                direction="column"
                gap="2rem"
                height="100%"
                justify="center"
                padding="2rem"
                width="100%"
            >
                <Typography
                    as="h3"
                    style={{ textAlign: 'center' }}
                    variant="p-16-r"
                >
                    {error ?? message}
                </Typography>
                {(reloadable || navigable) && (
                    <Flex gap="2rem">
                        {reloadable && (
                            <Button
                                color="primaryText"
                                onClick={() => {
                                    window.location.reload();
                                }}
                                startIcon={<RefreshIcon />}
                            >
                                {t('util.reloadThePage')}
                            </Button>
                        )}
                        {navigable && (
                            <Button
                                component={Link}
                                startIcon={<HomeIcon />}
                                to={navigateTo ?? '/home'}
                            >
                                {navigateText ?? t('util.returnHome')}
                            </Button>
                        )}
                        <Button
                            color="error"
                            onClick={() => keycloak.logout()}
                            startIcon={<PowerSettingsNewIcon />}
                        >
                            {t('auth.logout')}
                        </Button>
                    </Flex>
                )}
            </StyledFlex>
        </StyledFlexBackground>
    );
}

const StyledFlexBackground = styled(Flex, { shouldForwardProp: () => true })(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));
