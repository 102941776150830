import { createContext } from 'react';

export interface GraphContextProps {
    width: number;
    height: number;
}

export const GraphContext = createContext<GraphContextProps>({
    width: 0,
    height: 0,
});
