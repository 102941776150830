import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton } from '@mui/material';
import { Covariate } from '@tickr/sequelize-models';
import { Dispatch, SetStateAction } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ControlLabel } from '../FormElements/ControlLabel';
import { FormGroupSwitch } from '../FormElements/FormGroupSwitch';
import { InfoTooltip } from '../Tooltips/InfoTooltip';

interface InfluencersSectionProps {
    editMode: boolean;
    info?: string;
    selected: Covariate[];
    setSelected: Dispatch<SetStateAction<Covariate[]>>;
    title: string;
    values: Covariate[];
}

export function InfluencersSection({
    editMode, info, selected, setSelected, title, values,
}: InfluencersSectionProps) {
    return (
        <Flex
            direction="column"
            gap="1rem"
        >
            <Flex align="center" gap="1rem">
                <Typography
                    as="h3"
                    secondary
                    variant="h-22-b"
                >
                    {title}
                </Typography>
                {info && (
                    <InfoTooltip
                        maxWidth="500px"
                        text={info}
                        typographyVariant="p-14-r"
                    >
                        <IconButton>
                            <HelpOutlineIcon />
                        </IconButton>
                    </InfoTooltip>
                )}
            </Flex>
            {!editMode && (
                <Flex
                    direction="column"
                    gap={editMode ? '0' : '0.6rem'}
                    padding="0 0 0 1rem"
                >
                    {values.map(({ seriesId, title }) => (
                        <Typography key={seriesId} variant="p-16-r">{title}</Typography>
                    ))}
                </Flex>
            )}
            {editMode && (
                <FormGroupSwitch type="checkbox">
                    {values.map((covariate) => {
                        const isSelected = selected.find((s) => s.seriesId === covariate.seriesId) !== undefined;

                        return (
                            <ControlLabel
                                checked={isSelected}
                                key={covariate.seriesId}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelected([...selected, covariate]);
                                    } else {
                                        setSelected(selected.filter(
                                            ({ seriesId }) => covariate.seriesId !== seriesId
                                        ));
                                    }
                                }}
                                title={covariate.title}
                                type="checkbox"
                                value={covariate.seriesId}
                            />
                        );
                    })}
                </FormGroupSwitch>
            )}
        </Flex>
    );
}
