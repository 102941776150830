import { ElementAttributes } from '@tickr/sequelize-models';
import { useMemo } from 'react';
import { ChatCpgElement } from '../ChatCpg/ChatCpgElement';
import { Element } from '../Element/Element';

export function useElementMap(elements: ElementAttributes[] = []) {
    return useMemo(() => {
        return {
            chatCpg: <ChatCpgElement />,
            ...elements.reduce((acc, element) => {
                acc[element.uuid] = <Element element={element} />;
                return acc;
            }, {} as Record<string, JSX.Element>),
        };
    }, [elements]);
}
