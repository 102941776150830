
import { styled } from '@mui/material';
import { CSSProperties, ElementType, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StreamCursor } from '../ChatCpg/StreamCursor';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { Typography, Variant } from './Typography';

interface EditableTypographyProps {
    as?: ElementType;
    color?: string;
    contentEditable: boolean;
    focusBackground?: string;
    initialText: string;
    isStreaming?: boolean;
    max?: number;
    onBlur?: () => void;
    onFocus?: () => void;
    onSave: (value: string) => void;
    padding?: string;
    singleLine?: boolean;
    style?: CSSProperties;
    variant?: Variant;
}

export function EditableTypography({
    as,
    color,
    contentEditable,
    focusBackground = 'inherit',
    initialText,
    isStreaming,
    max = Infinity,
    onBlur,
    onFocus,
    onSave,
    padding = '4px',
    singleLine,
    style,
    variant = 'p-12-r',
}: EditableTypographyProps) {
    const { t } = useTranslation();
    const { sendAlert } = useToaster();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current || isStreaming) return;

        // WP Note: Solves a duplicating text bug when edited text is saved
        if (ref.current.textContent !== initialText) {
            ref.current.textContent = initialText;
        }
    }, [initialText, isStreaming]);

    return (
        <StyledTypography
            as={as}
            color={color}
            contentEditable={contentEditable && !isStreaming}
            focusBackground={focusBackground}
            multiline={!singleLine}
            onBlur={(e) => {
                onBlur?.();

                const trimmedText = e.currentTarget.textContent?.trim();

                if (!trimmedText || trimmedText === initialText) {
                    e.currentTarget.textContent = initialText;
                    return;
                }

                if (trimmedText?.length > max) {
                    sendAlert({
                        text: t('form.error.max', { max }),
                        severity: 'error',
                    });

                    e.currentTarget.textContent = initialText;
                    return;
                }

                onSave(trimmedText);
            }}
            onFocus={onFocus}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && (singleLine || !e.shiftKey)) {
                    e.currentTarget.blur();
                }
            }}
            ref={ref}
            style={{
                padding,
                ...style,
            }}
            textAlign="left"
            variant={variant}
        >
            {initialText}
            {isStreaming && <StreamCursor />}
        </StyledTypography>
    );
}

const StyledTypography = styled(
    Typography,
    { shouldForwardProp: (prop) => prop !== 'focusBackground' }
)<{ focusBackground: string }>(({ focusBackground, theme }) => `
    border-radius: ${theme.shape.borderRadius}px;
    white-space: pre-wrap;

    :focus {
        background: ${focusBackground};
    }
`);
