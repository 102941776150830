import { MosaicNode } from 'react-mosaic-component';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCurrentTab } from './useCurrentTab';

export interface UpdateMosaicProps {
    node: MosaicNode<string> | null;
    updateDefaultMosaic?: boolean;
}

export const useUpdateTabMosaic = () => {
    const { updateTab } = useCollectionMutations();
    const currentTab = useCurrentTab();

    return async ({ node, updateDefaultMosaic }: UpdateMosaicProps) => {
        if (!currentTab) return;

        let updatedNode = node;

        if (node === null) {
            updatedNode = 'chatCpg';
        }

        const tab = await updateTab({
            ...currentTab,
            settings: {
                ...currentTab.settings,
                mosaicNode: updatedNode,
                ...(updateDefaultMosaic && { defaultMosaic: updatedNode }),
            },
        });

        return tab;
    };
};
