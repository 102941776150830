import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { SlideCarousel } from '../Core/SlideCarousel';
import { ValueBox } from '../Core/ValueBox';
import { getUnixFromIsoWeek } from '../helpers/getUnixFromIsoWeek';
import { useDateTimeLabelFormats } from '../hooks/useDateTimeLabelFormats';
import { useLegendClick } from '../hooks/useLegendClick';
import { usePage } from '../hooks/usePage';
import { HighchartsContainer } from './HighchartsContainer';
import { useFormatYAxisOptions } from './useFormatYAxisOptions';

export function CategoryVsCpi() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const dateTimeLabelFormats = useDateTimeLabelFormats();
    const { optimizePricingSummaryData } = usePage();
    const inflationChartData = optimizePricingSummaryData?.inflationChart;
    const formatYAxisOptions = useFormatYAxisOptions();

    const chartData = useMemo(() => {
        return {
            category: {
                trend: inflationChartData?.category.map(([x, y]) => [getUnixFromIsoWeek(x), y]) ?? [],
            },
            cpilfens: {
                trend: inflationChartData?.cpilfens.map(([x, y]) => [getUnixFromIsoWeek(x), y]) ?? [],
            },
        };
    }, [inflationChartData]);

    const options = useMemo(() => {
        const highchartsOptions: Highcharts.Options = {
            tooltip: {
                shared: true,
                dateTimeLabelFormats,
                borderColor: palette.info.main,
            },
            chart: {
                animation: false,
            },
            xAxis: {
                type: 'datetime',
                startOfWeek: 6,
                crosshair: true,
            },
            legend: {
                enabled: false,
            },
            yAxis: [
                formatYAxisOptions({
                    opposite: false,
                    labels: {
                        style: {
                            color: palette.turquoise.main,
                        },
                    },
                }),
                formatYAxisOptions({
                    labels: {
                        style: {
                            color: palette.cornflowerBlue.main,
                        },
                    },
                    opposite: true,
                }),
            ],
            series: [
                {
                    name: t('metrics.categoryPriceIndex'),
                    type: 'line',
                    color: palette.turquoise.main,
                    dashStyle: 'Solid',
                    lineWidth: 2,
                    data: chartData.category.trend,
                    yAxis: 0,
                    tooltip: {
                        valueDecimals: 2,
                    },
                },
                {
                    name: t('metrics.cpilfens'),
                    type: 'line',
                    color: palette.cornflowerBlue.main,
                    dashStyle: 'Solid',
                    lineWidth: 2,
                    data: chartData.cpilfens.trend,
                    yAxis: 1,
                    tooltip: {
                        valueDecimals: 1,
                    },
                },
            ],
        };

        return highchartsOptions;
    }, [
        t,
        chartData,
        dateTimeLabelFormats,
        formatYAxisOptions,
        palette.cornflowerBlue.main,
        palette.turquoise.main,
        palette.info.main,
    ]);

    const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick({
        trendCount: 2,
    });

    return (
        <Flex
            align="stretch"
            direction="column"
            height="100%"
            justify="stretch"
            style={{
                minHeight: 0,
                minWidth: 0,
            }}
            width="100%"
        >
            <SlideCarousel width="100%">
                <ValueBox
                    allNeutral
                    color={palette.turquoise.main}
                    label={t('metrics.categoryPriceIndex')}
                    onClick={() => handleLegendClick('categoryPriceIndex', 0)}
                    secondary
                    style={{
                        opacity: hiddenTrends.includes('categoryPriceIndex') ? 0.5 : 1,
                    }}
                />
                <ValueBox
                    allNeutral
                    color={palette.cornflowerBlue.main}
                    label={t('metrics.cpilfens')}
                    onClick={() => handleLegendClick('cpilfens', 1)}
                    secondary
                    style={{
                        opacity: hiddenTrends.includes('cpilfens') ? 0.5 : 1,
                    }}
                />
            </SlideCarousel>
            <HighchartsContainer options={options} ref={chartRef} />
        </Flex>
    );
}
