import TuneIcon from '@mui/icons-material/Tune';
import { IconButton } from '@mui/material';
import { AppFilters } from '@tickr/sequelize-models/src/types/filterTypes';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { useHeaderIconStyles } from '../../hooks/useHeaderIconStyles';
import { FilterMenuPopoverContent } from './FilterMenuPopoverContent';

interface FilterMenuPopoverProps {
    onSave(filters: AppFilters): Promise<void>;
}

export function FilterMenuPopover({ onSave }: FilterMenuPopoverProps) {
    const styles = useHeaderIconStyles();

    return (
        <PopoverProvider fallbackPlacements={[]} placement="bottom-start">
            <PopoverTrigger asChild>
                <IconButton size="small" style={styles}>
                    <TuneIcon fontSize="inherit" />
                </IconButton>
            </PopoverTrigger>
            <FilterMenuPopoverContent onSave={onSave} />
        </PopoverProvider>
    );
}
