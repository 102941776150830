import { styled, useTheme } from '@mui/material';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';

interface FilterSummaryLayoutProps {
    name: string;
    summary: string;
    isValid: boolean;
    truncate?: boolean;
    isButton?: boolean;
    wrap?: boolean;
}

export function FilterSummaryLayout({
    name,
    summary,
    isButton,
    isValid,
    truncate,
    wrap,
}: FilterSummaryLayoutProps) {
    const theme = useTheme();

    return (
        <StyledFlex
            direction="column"
            isButton={isButton}
            style={{
                textAlign: 'left',
            }}
            {...(truncate && { width: '100%' })}
        >
            <Typography
                nowrap
                secondary
                variant="p-12-r"
            >
                {name}
            </Typography>
            <Typography
                color={isValid ? theme.palette.text.primary : theme.palette.warning.main}
                nowrap={!wrap}
                truncate={truncate}
                variant="h-12-b"
                {...(truncate && { width: '100%' })}
            >
                {summary}
            </Typography>
        </StyledFlex>
    );
}

const StyledFlex = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'isButton' }
)<{ isButton?: boolean }>`
    :hover {
        opacity: ${({ isButton }) => (isButton ? 0.7 : 1)};
    }
`;
