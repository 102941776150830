/* eslint-disable @stylistic/jsx-sort-props */
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { ConnectYourData } from '@tickr/ui/src/Forecast/ConnectYourData';
import { SelectCovariates } from '@tickr/ui/src/Forecast/SelectCovariates';
import { SelectForecast } from '@tickr/ui/src/Forecast/SelectForecast';
import { WelcomeForecast } from '@tickr/ui/src/Forecast/WelcomeForecast';
import { Dashboard } from '@tickr/ui/src/Pages/Dashboard';
import { Home } from '@tickr/ui/src/Pages/Home';
import { MyWork } from '@tickr/ui/src/Pages/MyWork';
import { SolutionLayout } from '@tickr/ui/src/Solutions/SolutionLayout';
import { useCurrentForecast } from '@tickr/ui/src/hooks/useCurrentForecast';
import { useCurrentProject } from '@tickr/ui/src/hooks/useCurrentProject';
import { Navigate, Route, Routes } from 'react-router-dom';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export function ForecastRoutes() {
    const { isNewProject } = useCurrentProject();
    const forecastPage = useCurrentForecast();

    return (
        <SentryRoutes>
            <Route path="/" element={<Navigate to={isNewProject ? '/welcome/forecast' : '/home'} />} />
            {!isNewProject && <Route path="/home" element={<Home />} />}
            {!isNewProject && <Route path="/my-work" element={<MyWork />} />}
            <Route path="/out-of-stock-predictions" element={<Dashboard />} />
            <Route path="/page/:pageUuid" element={<Dashboard />} />
            <Route path="/page/:pageUuid/*" element={<Dashboard />} />
            <Route path="/page" element={<Dashboard />} />
            <Route path="/solutions" element={<Navigate to={forecastPage ? '/solutions/forecast' : '/welcome/forecast'} />} />
            <Route path="/solutions/*" element={<Navigate to={forecastPage ? '/solutions/forecast' : '/welcome/forecast'} />} />
            {forecastPage && (
                <Route path="/solutions" element={<SolutionLayout />}>
                    <Route path="forecast/connect-your-data" element={<ConnectYourData />} />
                    <Route path="forecast/select-forecast" element={<SelectForecast />} />
                    <Route path="forecast/select-covariates" element={<SelectCovariates />} />
                    <Route path="forecast" element={<Navigate to="connect-your-data" />} />
                    <Route path="forecast/*" element={<Navigate to="connect-your-data" />} />
                </Route>
            )}
            <Route path="/welcome/forecast" element={<WelcomeForecast />} />
            <Route path="/*" element={<Navigate to={isNewProject ? '/welcome/forecast' : '/home'} />} />
        </SentryRoutes>
    );
}
