import { PropsWithChildren, ReactNode } from 'react';
import { Flex } from '../../../Core/Flex';
import { useMosaicDisabled } from '../hooks/useMosaicDisabled';

interface ToolbarWrapperProps {
    showGap?: boolean;
    toolbarControls: ReactNode;
}

export function ToolbarWrapper({ children, showGap, toolbarControls }: PropsWithChildren<ToolbarWrapperProps>) {
    const { mosaicDisabled } = useMosaicDisabled();

    return (
        <Flex
            align="center"
            justify="space-between"
            width="100%"
        >
            <Flex
                align="center"
                gap={showGap ? '0.5rem' : 'unset'}
                grow="1"
                style={{
                    minHeight: '22px',
                    maxWidth: 'calc(100% - 20px)',
                }}
            >
                {children}
            </Flex>
            {!mosaicDisabled && toolbarControls}
        </Flex>
    );
}
