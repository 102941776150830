import { Button, useTheme } from '@mui/material';
import { MouseEventHandler, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { p16r } from '../Core/typographyVariants';

interface SolutionButtonProps {
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    to?: string;
}

export function SolutionButton({
    disabled, onClick, to, children,
}: PropsWithChildren<SolutionButtonProps>) {
    const { palette, shape } = useTheme();

    return (
        <Button
            {...(to && {
                component: Link,
                to,
            })}
            disabled={disabled}
            disableFocusRipple
            onClick={onClick}
            size="small"
            style={{
                background: palette.gradient.chatCpg,
                borderRadius: shape.borderRadius,
                color: palette.text.primary,
                filter: disabled
                    ? palette.mode === 'dark'
                        ? 'brightness(0.5)'
                        : 'opacity(0.5)'
                    : undefined,
                ...p16r,
                minHeight: '30px',
                padding: '1px',
                textTransform: 'none',
            }}
            variant="contained"
        >
            <div
                style={{
                    background: palette.background.default,
                    borderRadius: shape.borderRadius,
                    padding: '0 1rem',
                }}
            >
                {children}
            </div>
        </Button>
    );
}
