import { useTheme } from '@mui/material';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { CsvIcon } from '../Icons/CsvIcon';

interface SampleDataIconProps {
    disabled: boolean;
    label: string;
    onClick: () => void;
}

export function SampleDataIcon({ disabled, label, onClick }: SampleDataIconProps) {
    const { shape } = useTheme();

    return (
        <Flex
            align="center"
            as="button"
            direction="column"
            disabled={disabled}
            gap="1rem"
            onClick={onClick}
            padding="0.4rem"
            style={{
                borderRadius: shape.borderRadius,
            }}
        >
            <CsvIcon size="50px" />
            <Typography secondary variant="h-14-b">{label}</Typography>
        </Flex>
    );
}
