import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';
import { usePage } from '../hooks/usePage';
import { StreamingIcon } from './StreamingIcon';
import { SuggestionChip } from './SuggestionChip';

export function ZeroStateChat() {
    const { t } = useTranslation();
    const { isStreaming, solutionType } = usePage();
    const { conversation } = useChatBox();
    const { setViewConversation } = useChatBoxForm();

    const sharedDetailsSuggestions = [t('chatCpg.suggestion.demographicMakeup'), t('chatCpg.suggestion.marketShare')];

    const suggestionDetailsLookup: Record<SolutionType, string[]> = {
        forecast: [
            t('chatCpg.suggestion.1'),
            t('forecast.chat.howWasThisForecastCreated'),
            t('forecast.chat.whatOtherFactors'),
            t('forecast.chat.whichExternalFactor'),
        ],
        optimizePricing: [
            t('chatCpg.suggestion.1'),
            t('chatCpg.suggestion.2'),
            t('chatCpg.suggestion.3'),
            t('chatCpg.suggestion.4'),
            t('chatCpg.suggestion.5'),
            t('chatCpg.suggestion.6'),
        ],
        outOfStockPredictions: sharedDetailsSuggestions,
        scorecard: sharedDetailsSuggestions,
        substitutions: sharedDetailsSuggestions,
    };

    const suggestionsDetails = solutionType ? suggestionDetailsLookup[solutionType] : [];

    return (
        <Flex
            align="center"
            direction="column"
            gap="2rem"
            height="100%"
            justify="center"
            style={{ overflow: 'auto' }}
            width="100%"
        >
            <StreamingIcon isStreaming={!!isStreaming} size="36px" />
            <Flex
                align="center"
                direction="column"
                gap="0.5rem"
                width="100%"
            >
                <Typography variant="p-14-b">{t('chatCpg.selectAQuestionRelevant')}</Typography>
                {suggestionsDetails.map((suggestion) => (
                    <SuggestionChip
                        key={suggestion}
                        style={{ maxWidth: '350px' }}
                        text={suggestion}
                    />
                ))}
            </Flex>
            {conversation.length > 0 && (
                <Button
                    color="primaryText"
                    onClick={() => setViewConversation(true)}
                    startIcon={<ArrowBackIcon />}
                    variant="outlined"
                >
                    {t('chatCpg.backToConversation')}
                </Button>
            )}
        </Flex>
    );
}
