import { useTheme } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Flex } from '../Core/Flex';

interface PageLayoutProps {
    hideOutlet?: boolean;
    pageTitle: string;
    style?: CSSProperties;
}

export function PageLayout({
    hideOutlet,
    pageTitle,
    style,
    children,
}: PropsWithChildren<PageLayoutProps>) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const outletContext = useOutletContext();
    const titleText = t('helmet.title', { page: pageTitle });

    return (
        <Flex
            direction="column"
            height="100vh"
            overflow="hidden"
            style={{
                backgroundColor: palette.background.default,
                ...style,
            }}
            width="100%"
        >
            <Helmet>
                <title>{titleText}</title>
            </Helmet>
            {children}
            {!hideOutlet && <Outlet context={outletContext} />}
        </Flex>
    );
}
