import { Button, useTheme } from '@mui/material';
import { Covariate } from '@tickr/sequelize-models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { LogoLoadingMessage } from '../Solutions/RunAnalysisLoading';
import { SolutionContent } from '../Solutions/SolutionContent';
import { SolutionFooter } from '../Solutions/SolutionFooter';
import { SolutionGrid } from '../Solutions/SolutionGrid';
import { SolutionHeader } from '../Solutions/SolutionHeader';
import { SolutionPanel } from '../Solutions/SolutionPanel';
import { SolutionPanelHeader } from '../Solutions/SolutionPanelHeader';
import { SolutionPanelSection } from '../Solutions/SolutionPanelSection';
import { useLaunchForecast } from '../hooks/onLaunchAnalysis/useLaunchForecast';
import { useForecastInProgress } from '../hooks/useForecastInProgress';
import { InfluencersSection } from './InfluencerSection';
import { PreviewDataDialog } from './PreviewDataDialog';
import { useStagedPageCommitListener } from './useStagedPageCommitListener';

export function SelectCovariates() {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const {
        docCovariates,
        forecast,
        fredCovariates,
        commodityCovariates,
        summary,
    } = useForecastInProgress();

    const launchForecast = useLaunchForecast();

    useStagedPageCommitListener(forecast?.uuid ?? '');

    const [selectedFredCovariates, setSelectedFredCovariates] = useState<Covariate[]>(
        fredCovariates ?? []
    );

    const [selectedCommodityCovariates, setSelectedCommodityCovariates] = useState<Covariate[]>(
        commodityCovariates ?? []
    );

    const [selectedDocCovariates, setSelectedDocCovariates] = useState<Covariate[]>(docCovariates ?? []);

    const [processing, setProcessing] = useState(false);
    const [editMode, setEditMode] = useState(false);

    if (
        !docCovariates ||
        !fredCovariates ||
        !commodityCovariates
    ) {
        return null;
    }

    return (
        <SolutionGrid>
            <SolutionHeader
                description={t('forecast.influentialFactors.description')}
                title={t('forecast.influentialFactors')}
            />
            <SolutionContent
                style={{
                    paddingTop: '2rem',
                }}
            >
                <Flex direction="column" gap="2rem">
                    <Flex
                        direction="column"
                        gap="2rem"
                        width="100%"
                    >
                        <InfluencersSection
                            editMode={editMode}
                            info={t('forecast.externalEconomicInfluencers.info')}
                            selected={selectedFredCovariates}
                            setSelected={setSelectedFredCovariates}
                            title={t('forecast.externalEconomicInfluencers')}
                            values={fredCovariates}
                        />
                        <InfluencersSection
                            editMode={editMode}
                            info={t('forecast.commodityInfluencers.info')}
                            selected={selectedCommodityCovariates}
                            setSelected={setSelectedCommodityCovariates}
                            title={t('forecast.commodityInfluencers')}
                            values={commodityCovariates}
                        />
                        <InfluencersSection
                            editMode={editMode}
                            selected={selectedDocCovariates}
                            setSelected={setSelectedDocCovariates}
                            title={t('forecast.influentialMetricsFromYourFile')}
                            values={docCovariates}
                        />
                    </Flex>

                    {!editMode && (
                        <Typography
                            as="button"
                            color={palette.primary.main}
                            onClick={() => {
                                setEditMode((editMode) => !editMode);
                            }}
                            variant="p-16-r"
                        >
                            {t('forecast.addOrRemoveInfluentialFactors')}
                        </Typography>
                    )}

                </Flex>
            </SolutionContent>
            <SolutionFooter>
                <Flex
                    align="center"
                    gap="1rem"
                    width="100%"
                >
                    <Button
                        color="middleGray"
                        component={Link}
                        disabled={processing}
                        size="small"
                        style={{ width: '200px' }}
                        to="/solutions/forecast/select-forecast"
                        variant="contained"
                    >
                        {t('util.back')}
                    </Button>
                    <Button
                        disabled={processing}
                        onClick={async () => {
                            setProcessing(true);

                            await launchForecast({
                                docCovariates: selectedDocCovariates,
                                fredCovariates: selectedFredCovariates,
                                commodityCovariates: selectedCommodityCovariates,
                            });

                            setProcessing(false);
                        }}
                        size="small"
                        style={{ width: '200px' }}
                        variant="contained"
                    >
                        {t('util.runAnalysis')}
                    </Button>
                    {processing && <LogoLoadingMessage message={t('forecast.loading.generatingForecast')} />}
                </Flex>
            </SolutionFooter>
            <SolutionPanel>
                <SolutionPanelHeader
                    text={t('solution.secondOfTwoSteps')}
                />
                <SolutionPanelSection
                    active={false}
                    description={summary}
                    done
                    title={t('forecast.1connectYourData')}
                >
                    <PreviewDataDialog />
                </SolutionPanelSection>
                <SolutionPanelSection
                    active
                    done
                    title={t('forecast.2selectScope')}
                />
            </SolutionPanel>
        </SolutionGrid>
    );
}
