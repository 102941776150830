import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import last from 'lodash/last';
import truncate from 'lodash/truncate';
import { useMemo } from 'react';
import { Flex } from '../Core/Flex';
import { ValueBox } from '../Core/ValueBox';
import { useElement } from '../Element/useElement';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useLegendClick } from '../hooks/useLegendClick';
import { usePage } from '../hooks/usePage';
import { HighchartsContainer } from './HighchartsContainer';
import { useFormatYAxisOptions } from './useFormatYAxisOptions';

export function ForecastChart() {
    const { palette } = useTheme();
    const { page, forecastSummaryData } = usePage();
    const element = useElement();
    const formatYAxisOptions = useFormatYAxisOptions();

    const COLORS = {
        0: palette.secondary.main,
        1: palette.turquoise.main,
        2: palette.cornflowerBlue.main,
        3: palette.texasRose.main,
        4: palette.sulu.main,
        5: palette.portica.main,
    };

    const forecastColumn = page?.settings.document?.forecastColumn ?? '';
    const historic = forecastSummaryData?.forecastChart?.historic;
    const forecast = forecastSummaryData?.forecastChart?.forecast;

    const onChartCovariates = element.settings?.forecastChart?.onChartCovariates ?? [];

    const fredCovariates = element.settings?.forecastChart?.fredCovariates ?? [];
    const commodityCovariates = element.settings?.forecastChart?.commodityCovariates ?? [];

    const fredDict = [
        ...fredCovariates,
        ...commodityCovariates,
    ]?.reduce(
        (dict, covariate) => ({
            ...dict,
            [covariate.seriesId]: covariate.title,
        }), {} as Record<string, string>,
    );

    const seriesIds: string[] = [forecastColumn, ...onChartCovariates].filter((k) => k !== undefined);

    const series = seriesIds
        .map((seriesId, i) => {
            const forecastTrend = forecast?.[seriesId] ?? [];
            const historicTrend = historic?.[seriesId].slice(-2 * forecastTrend.length) ?? [];

            return {
                historicTrend,
                forecastTrend,
                valueStyle: 'real',
                label: fredDict?.[seriesId] ?? seriesId,
                seriesId,
                color: COLORS[i as keyof typeof COLORS],
            };
        })
        .sort((a, b) => {
            if (a.seriesId === forecastColumn) return -1;
            return a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase());
        });

    const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick({
        trendCount: series.length,
    });

    const options = useMemo(() => {
        const highchartsOptions: Highcharts.Options = {
            tooltip: {
                shared: true,
            },
            lang: {
                thousandsSep: ',',
            },
            xAxis: {
                type: 'datetime',
                startOfWeek: 6,
                crosshair: true,
            },
            yAxis: series.map(({ color }, i) => formatYAxisOptions({
                opposite: i >= series.length / 2,
                labels: {
                    style: {
                        color,
                    },
                    // formatter: function() {
                    //     return `$${Highcharts.numberFormat(this.value as number, this.value as number < 1000 ? 2 : 0)}`;
                    // },
                },
            })),
            legend: {
                enabled: false,
            },
            series: series.map(({
                color,
                forecastTrend,
                historicTrend,
                label,
                seriesId,
            }, i) => ({
                name: label,
                type: 'line',
                color,
                dashStyle: 'Solid',
                data: [...historicTrend, ...forecastTrend],
                lineWidth: 2,
                visible: !hiddenTrends.includes(seriesId),
                yAxis: i,
                zIndex: 1,
                zoneAxis: 'x',
                zones: [
                    {
                        value: last(historicTrend)?.[0],
                        dashStyle: 'Solid',
                    },
                    {
                        dashStyle: 'Dash',
                    },
                ],
            })),
        };

        return highchartsOptions;
    }, [hiddenTrends, series, formatYAxisOptions]);

    if (!forecast || !historic) return null;

    return (
        <Flex
            align="stretch"
            direction="column"
            height="100%"
            justify="stretch"
            width="100%"
        >
            <Flex
                padding="0 0 1rem 0"
                style={{
                    rowGap: '0',
                    columnGap: '1rem',
                }}
                width="100%"
                wrap="wrap"
            >
                {series.map(({ label, seriesId, color }, i) => {
                    const infoText = seriesId === label ? `${label} from ${page?.settings?.document?.name}` : `${seriesId}: ${label}`;
                    const valueText = truncate(label, { length: 50 });

                    return (
                        <InfoTooltip
                            key={label}
                            text={infoText}
                        >
                            <ValueBox
                                color={color}
                                label={valueText}
                                onClick={() => handleLegendClick(seriesId, i)}
                                secondary
                                style={{
                                    opacity: hiddenTrends.includes(seriesId) ? 0.5 : 1,
                                }}
                            />
                        </InfoTooltip>
                    );
                })}
            </Flex>
            <HighchartsContainer options={options} ref={chartRef} />
        </Flex>
    );
}
