import { useLocation } from 'react-router-dom';
import { OPTIMISTIC_PAGE_ID } from '../Providers/CollectionMutationsProvider/CollectionMutationsProvider';
import { getFirstTabUuid } from '../helpers/getFirstTabId';
import { useCurrentProject } from '../hooks/useCurrentProject';
import { useForecastInProgressLink } from '../hooks/useForecastInProgressLink';
import { usePage } from '../hooks/usePage';
import { PageLink } from './useAppPageLinks';

export function useDashboardLinks() {
    const { currentProject } = useCurrentProject();
    const toForecastInProgress = useForecastInProgressLink();
    const { pageUuid } = usePage();
    const { pathname } = useLocation();

    const dashboardLinks = [
        ...currentProject.pages.reduce((pages, { inProgress, uuid, tabs, title, solutionType }) => {
            if (uuid === OPTIMISTIC_PAGE_ID || solutionType === 'outOfStockPredictions' || solutionType === 'scorecard') {
                return pages;
            }

            const to = inProgress
                ? toForecastInProgress
                : `/page/${uuid}${getFirstTabUuid(tabs ?? [])
                    ? `/tab/${getFirstTabUuid(tabs ?? [])}`
                    : ''}`;

            pages.push({
                name: title,
                pageType: solutionType,
                pageUuid: uuid,
                selected: inProgress ? to === pathname : pageUuid === uuid,
                to,
            });

            return pages;
        }, [] as PageLink[]),
    ];

    return dashboardLinks;
}
