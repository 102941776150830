import { createContext } from 'react';
import { FilterGroupItem } from '../../Filters/Components/FilterGroup';

export interface ProductAttributesContextProps {
    myProducts: FilterGroupItem[];
    myCompetitors: FilterGroupItem[];
}

export const ProductAttributesContext = createContext<ProductAttributesContextProps>({
    myProducts: [],
    myCompetitors: [],
});
