import { usePage } from '../hooks/usePage';
import { StandardPage } from './StandardPage';
import { TalkingPointsPage } from './TalkingPointsPage';

export function PageSwitch() {
    const { layoutType } = usePage();

    switch (layoutType) {
        case 'talkingPoints':
            return <TalkingPointsPage />;
        case 'standard':
            return <StandardPage />;
        default:
            return <></>;
    }
}
