import { CSSProperties, ChangeEvent } from 'react';
import { ControlLabel } from './ControlLabel';
import { FormElementWrapper } from './FormElementWrapper';
import { FormGroupSwitch } from './FormGroupSwitch';

export interface CheckboxItem {
    disabled?: boolean;
    title: string;
    value: string;
}

interface CheckboxesProps {
    description?: string;
    disabled?: boolean;
    error?: string;
    groupStyle?: CSSProperties;
    onChange?(e: ChangeEvent<HTMLInputElement>): void;
    placeholder?: string;
    selected?: string[];
    title: string;
    values: CheckboxItem[];
}

export function Checkboxes({
    description,
    error,
    groupStyle,
    onChange,
    selected,
    title,
    values,
}: CheckboxesProps) {
    return (
        <FormElementWrapper
            description={description}
            error={error}
            title={title}
        >
            <FormGroupSwitch style={groupStyle} type="checkbox">
                {values.map(({ title, value, disabled }) => (
                    <ControlLabel
                        checked={selected?.includes(value)}
                        disabled={disabled}
                        key={value}
                        onChange={onChange}
                        title={title}
                        type="checkbox"
                        value={value}
                    />
                ))}
            </FormGroupSwitch>
        </FormElementWrapper>
    );
}
