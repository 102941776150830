import omit from 'lodash/omit';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StagedPage } from '../hooks/useChatCpgStore';
import { useStagedPageFns } from '../hooks/useStagedPageFns';

export function useStagedPageCommitListener(pageUuid: string) {
    const navigate = useNavigate();
    const { updatePage } = useCollectionMutations();
    const stagedPage = useChatCpgData<StagedPage>(pageUuid);
    const { resetStagedPage } = useStagedPageFns(pageUuid);

    useEffect(() => {
        async function commitForecast() {
            try {
                if (stagedPage?.commit) {
                    delete stagedPage.commit;

                    await updatePage({ page: omit(stagedPage, ['commit', 'navigateTo', 'preventReset']) });

                    if (stagedPage?.navigateTo) {
                        resetStagedPage();
                        navigate(stagedPage.navigateTo);
                    }

                    if (!stagedPage?.preventReset) {
                        resetStagedPage();
                    }
                }
            } catch (error) {
                // TODO handle error
            }
        }

        commitForecast();
    }, [
        pageUuid,
        navigate,
        stagedPage,
        updatePage,
        resetStagedPage,
    ]);
}
