import { useTheme } from '@mui/material';
import { Divider } from '../../../Core/Divider';

export function ToolbarDivider() {
    const { palette } = useTheme();

    return (
        <Divider
            color={palette.background.tertiary}
            solid
            stroke="1px"
        />
    );
}
