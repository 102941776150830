import { CSSProperties } from 'react';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';

export interface ProductCellProps {
    id: string;
    name: string;
    maxWidth?: string;
    style?: CSSProperties;
}

export function ProductCell({ name, id, maxWidth = '250px', style }: ProductCellProps) {
    return (
        <Flex
            direction="column"
            gap="0.2rem"
            style={style}
        >
            <Typography nowrap variant="p-14-b">{`GTIN ${id}`}</Typography>
            <Typography
                secondary
                style={{ maxWidth }}
                title={name}
                truncate
                variant="p-12-r"
            >
                {name}
            </Typography>
        </Flex>
    );
}
