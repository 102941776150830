import { CSSProperties, PropsWithChildren } from 'react';
import { Grid } from '../Core/Grid';
import { HEADER_HEIGHT } from '../helpers/constants';

interface SolutionGridProps {
    hideFooter?: boolean;
    style?: CSSProperties;
}

export function SolutionGrid({ hideFooter, style, children }: PropsWithChildren<SolutionGridProps>) {
    return (
        <Grid
            height={`calc(100vh - ${HEADER_HEIGHT}px)`}
            padding="1rem 0 2rem 0"
            style={style}
            templateColumns="63fr 37fr"
            templateRows={hideFooter ? '90px auto' : '90px auto 50px'}
            width="100%"
        >
            {children}
        </Grid>
    );
}
