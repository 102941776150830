import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';

interface ChatButtonProps {
    disabled?: boolean;
    onClick: () => void;
}

export function ChatButton({ disabled, onClick }: ChatButtonProps) {
    const { t } = useTranslation();

    return (
        <GradientWrap
            disabled={disabled}
            onClick={onClick}
        >
            <StyledFlex
                align="center"
                gap="8px"
                padding="2px 8px"
            >
                <ChatCpgIcon size="20px" />
                <Typography variant="button-12">{t('chatCpg.chat')}</Typography>
            </StyledFlex>
        </GradientWrap>
    );
}

const StyledFlex = styled(Flex, { shouldForwardProp: (prop) => !!prop })(({ theme }) => ({
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    minHeight: '30px',
}));

const GradientWrap = styled('button')(({ theme }) => ({
    padding: '1px',
    background: theme.palette.gradient.chatCpg,
    borderRadius: theme.shape.borderRadius,
}));

