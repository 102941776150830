import { useTheme } from '@mui/material';

export function useHighlightSearchTerm() {
    const { palette } = useTheme();

    return (text: string, search?: string) => {
        if (!search) return text;
        const parts = text.split(new RegExp(`(${search})`, 'i'));
        let isFirstMatch = true;

        return (
            <span>
                {parts.map((part, i) => {
                    if (isFirstMatch && part.toLowerCase() === search.toLowerCase()) {
                        isFirstMatch = false;

                        return (
                        // eslint-disable-next-line react/no-array-index-key
                            <mark key={part + i} style={{ background: palette.highlight.main }}>
                                {part}
                            </mark>
                        );
                    }

                    return part;
                })}
            </span>
        );
    };
}
