import { styled, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Circle } from './Circle';
import { Typography } from './Typography';

const StyledCircle = styled(
    Circle,
    { shouldForwardProp: () => true }
)`
    position: absolute;
`;

interface IndicatorProps {
    top?: string;
    left?: string;
    right?: string;
    align?: 'right' | 'left';
    color?: string;
    textColor?: string;
    zindex?: string | number;
    onClick?: () => void;
}

export function Indicator({
    top = 'unset',
    left = 'unset',
    right = 'unset',
    color,
    textColor,
    zindex = 'unset',
    onClick,
    children,
}: PropsWithChildren<IndicatorProps>) {
    const theme = useTheme();

    return (
        <StyledCircle
            color={color ?? theme.palette.indicator.main}
            onClick={onClick}
            size="12px"
            style={{
                position: 'absolute',
                zIndex: zindex,
                top: top,
                left: left,
                right: right,
                minWidth: '12px',
                minHeight: '12px',
                maxWidth: '12px',
                maxHeight: '12px',
            }}
        >
            <Typography color={textColor ?? theme.palette.indicator.contrastText} variant="n-12-b">
                {children}
            </Typography>
        </StyledCircle>
    );
}
