import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton, SvgIconOwnProps, useTheme } from '@mui/material';
import { AlertProps } from '../Providers/ToasterProvider/ToasterContext';
import { Flex } from './Flex';
import { Typography } from './Typography';

export function Alert({ title, text, severity = 'info', onDismiss }: AlertProps) {
    const { palette, shape } = useTheme();
    let borderColor: string;
    let iconColor: SvgIconOwnProps['color'];

    switch (severity) {
        case 'info':
            borderColor = palette.primary.main;
            iconColor = 'primary';
            break;
        case 'success':
            borderColor = palette.success.main;
            iconColor = 'success';
            break;
        case 'warning':
            borderColor = palette.warning.main;
            iconColor = 'warning';
            break;
        case 'error':
            borderColor = palette.error.main;
            iconColor = 'error';
            break;
    }

    return (
        <Flex
            align="center"
            gap="1rem"
            justify="space-between"
            overflow="hidden"
            padding="1rem"
            style={{
                border: `1px solid ${borderColor}`,
                background: palette.background.default,
                borderRadius: shape.borderRadius,
            }}
            width="100%"
        >
            <Flex align="center" gap="1rem">
                {severity === 'info'
                    ? <InfoIcon color={iconColor} />
                    : severity === 'success'
                        ? <DoneIcon color={iconColor} />
                        : severity === 'warning'
                            ? <WarningAmberIcon color={iconColor} />
                            : <ErrorOutlineIcon color={iconColor} />
                }
                <Flex direction="column" gap="1rem">
                    {title && (
                        <Typography as="h5" variant="c-11">
                            {title}
                        </Typography>
                    )}
                    <Typography>{text}</Typography>
                </Flex>
            </Flex>
            {onDismiss && (
                <IconButton
                    color="secondaryText"
                    onClick={onDismiss}
                    size="small"
                >
                    <ClearIcon />
                </IconButton>
            )}
        </Flex>
    );
}
