import { useTheme } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { ElementAttributes } from '../../../sequelize-models/src/main';
import { ChatCpgToolbar } from './ElementToolbars/ChatCpgToolbar';
import { DefaultElementToolbar } from './ElementToolbars/DefaultElementToolbar';
import { ForecastChartToolbar } from './ElementToolbars/ForecastChartToolbar';
import { InsightCardToolbar } from './ElementToolbars/InsightCardToolbar';

interface ElementToolbarProps {
    element?: ElementAttributes;
    isChatElement: boolean;
    setEditingTitle: Dispatch<SetStateAction<boolean>>;
    title: string;
    toolbarControls: ReactNode;
}

export function ElementToolbar({
    setEditingTitle, element, isChatElement, title, toolbarControls,
}: ElementToolbarProps) {
    const { palette } = useTheme();

    if (isChatElement) {
        return <ChatCpgToolbar toolbarControls={toolbarControls} />;
    }

    if (!element) {
        return null;
    }

    switch (element.type) {
        case 'insightCard':
            return (
                <InsightCardToolbar
                    element={element}
                    setEditingTitle={setEditingTitle}
                    title={title}
                    toolbarControls={toolbarControls}
                />
            );
        case 'introCard':
            return (
                <DefaultElementToolbar
                    background={palette.background.secondary}
                    element={element}
                    setEditingTitle={setEditingTitle}
                    title={title}
                    toolbarControls={toolbarControls}
                />
            );
        case 'forecastChart':
            return (
                <ForecastChartToolbar
                    element={element}
                    setEditingTitle={setEditingTitle}
                    title={title}
                    toolbarControls={toolbarControls}
                />
            );
        default:
            return (
                <DefaultElementToolbar
                    element={element}
                    setEditingTitle={setEditingTitle}
                    title={title}
                    toolbarControls={toolbarControls}
                />
            );
    }
}
