import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material';
import { useState } from 'react';
import { Flex } from './Flex';

const SearchInput = styled('input')<{ width?: string }>(({ theme, width }) => `
    background-color: ${theme.palette.background.secondary};
    border-radius: ${theme.shape.borderRadius}px;
    color: ${theme.palette.text.primary};
    font-size: 14px;
    height: 100%;
    padding: 0.45rem 0.45rem 0.45rem 30px;
    width: ${width ?? '250px'};
    border: 1px solid ${theme.palette.background.tertiary};
    :focus {
        outline: 1px solid ${theme.palette.info.main};
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,    
    :-webkit-autofill:active {
        -webkit-text-fill-color: ${theme.palette.text.primary};
        -webkit-box-shadow: 0 0 0 20px ${theme.palette.background.secondary} inset;
        border: 1px solid ${theme.palette.middleGray.main};
        caret-color: ${theme.palette.text.primary};
    }
`);

interface SearchBarProps {
    autofocus?: boolean;
    inputId: string;
    onSearch(search: string): void;
    search?: string;
    placeholder?: string;
    poll?: boolean;
    inputWidth?: string;
}

export function SearchBar({
    autofocus,
    inputId,
    onSearch,
    search = '',
    placeholder,
    poll,
    inputWidth,
}: SearchBarProps) {
    const [text, setText] = useState(search);

    return (
        <Flex
            align="center"
            data-testid="search-bar"
            gap="0.5rem"
            width={inputWidth ?? '100%'}
        >
            <SearchIcon
                color="primaryText"
                fontSize="small"
                style={{
                    position: 'absolute',
                    left: 5,
                }}
            />
            <SearchInput
                autoFocus={autofocus}
                id={inputId}
                onChange={(e) => {
                    setText(e.target.value);
                    poll && onSearch(e.target.value);
                }}
                onKeyDown={(e) => e.key === 'Enter' && onSearch?.(text)}
                placeholder={placeholder}
                value={search}
                width={inputWidth}
            />
        </Flex>
    );
}
