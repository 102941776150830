import { TableCell, TableCellProps } from '@mui/material';
import { ReactNode } from 'react';
import { SortOrder } from './sortTableData';

export interface HeadCell<T> {
    align?: TableCellProps['align'];
    label: string;
    value: keyof T;
}

interface TickrHeadCellProps<T> {
    align?: TableCellProps['align'];
    children?: ReactNode;
    order?: SortOrder;
    orderBy?: T;
    padding?: TableCellProps['padding'];
    value?: T;
}

export function TickrHeadCell<T extends string>(
    { align, children, order, orderBy, padding, value }: TickrHeadCellProps<T>
) {
    return (
        <TableCell
            align={align}
            padding={padding}
            sortDirection={orderBy === value ? order : false}
            sx={{
                borderBottom: 'none',
            }}
        >
            {children}
        </TableCell>
    );
}
