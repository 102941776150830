import { styled } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';
import { Typography } from '../Core/Typography';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';

interface SuggestionChipProps {
    onClick?: (message: string) => void;
    style?: React.CSSProperties;
    text: string;
    textAlign?: CSSProperties['textAlign'];
}

export function SuggestionChip({
    children,
    onClick,
    text,
    textAlign = 'center',
    style,
}: PropsWithChildren<SuggestionChipProps>) {
    const { onClickSuggestion } = useChatBoxForm();

    return (
        <StyledTypography
            as="button"
            multiline
            onClick={() => (onClick ? onClick(text) : onClickSuggestion(text))}
            style={style}
            textAlign={textAlign}
            variant="h-12-b"
        >
            {children ?? text}
        </StyledTypography>
    );
}

const StyledTypography = styled(
    Typography,
    { shouldForwardProp: (prop) => !!prop }
)(({ theme }) => `
    border-radius: ${theme.shape.borderRadius}px;
    padding: 4px 12px;
    border: 1px solid ${theme.palette.secondary.dark};

    :focus-visible {
        outline: 1px solid ${theme.palette.info.main};
    }
`);
