import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { InflationChartData, InfluencerData, OptimizePricingSummaryData, PriceChartData, PriceData, PriceVolumeSalesData, RangeData } from '@tickr/sequelize-models';
import isEmpty from 'lodash/isEmpty';
import { OptimizePricingTalkingPoints } from '../../../../sequelize-models/src/types/chatCpgTypes';
import { ChatCpgEvent, CompleteEvent, GenericCpgEvent, TalkingPointData } from '../../ChatCpg/types/chatCpgTypes';
import { debugLogger } from '../../helpers/debugLogger';
import { pureMerge } from '../../helpers/pureMerge';
import { shortenGtin } from '../../helpers/stringHelpers';
import { useCaptureTickrException } from '../useCaptureTickrException';
import { useChatCpgStore } from '../useChatCpgStore';
import { usePage } from '../usePage';
import { useReplaceReportingWeek } from '../useReplaceReportingWeek';
import { useReplaceTimestamp } from '../useReplaceTimestamp';
import { useStagedPageFns } from '../useStagedPageFns';

type OptimizePricingEvents =
  | {
      type: 'inflation-chart';
      data: InflationChartData;
  }
  | ({
      type: 'influencers';
      data: InfluencerData;
  } & GenericCpgEvent)
  | ({
      type: 'price-chart';
      data: PriceChartData;
  } & GenericCpgEvent)
  | {
      type: 'price-data';
      data: [number, number][];
  }
  | ({
      type: 'pvs-chart';
      data: PriceVolumeSalesData;
  } & GenericCpgEvent)
  | ({
      type: 'range-data';
      data: [number, number, number][];
  } & GenericCpgEvent)
  | ({
      type: 'recommended-price';
      data: number;
  } & GenericCpgEvent);

interface OptimizePricingData {
    competitors: TalkingPointData<CompleteCompetitors>;
    historic: TalkingPointData<CompleteHistoric>;
    inflation: TalkingPointData<CompleteInflation>;
    recommendations: TalkingPointData<CompleteRecommendations>;
}

interface CompleteHistoric {
    'pvs-chart': PriceVolumeSalesData;
    summary: string;
}

interface CompleteInflation {
    'inflation-chart': InflationChartData;
    summary: string;
}

interface CompleteCompetitors {
    influencers: InfluencerData;
    'price-chart': PriceChartData;
    summary: string;
}

interface CompleteRecommendations {
    'price-data': PriceData;
    'range-data': RangeData;
    'recommended-price': number;
    recommendations: string;
}

type OptimizePricingCompleteEvent = CompleteEvent<OptimizePricingData>;

type OptimizePricingStreamEvent =
    | ChatCpgEvent
    | OptimizePricingEvents
    | OptimizePricingCompleteEvent;

export function useOnMessageOptimizePricing() {
    const captureTickrException = useCaptureTickrException();
    const updateStreamText = useChatCpgStore((state) => state.updateStreamText);
    const { summaryId, page } = usePage();
    const { updateStagedPage } = useStagedPageFns(page?.uuid ?? '');

    const replaceTimestamp = useReplaceTimestamp();
    const replaceReportingWeek = useReplaceReportingWeek();

    const formatText = (text?: string) => replaceTimestamp(replaceReportingWeek(shortenGtin(text)));

    return (
        event: EventSourceMessage,
        streamText: {
            headlines: {
                competitors: string;
                historic: string;
                inflation: string;
                recommendations: string;
            };
            insights: {
                historic: string;
                recommendations: string;
            };
        }
    ) => {
        if (event.event === 'ping' || event.data === '' || !summaryId) return;

        let eventData: OptimizePricingStreamEvent;

        try {
            eventData = JSON.parse(event.data);
        } catch (error) {
            captureTickrException(error);
            return;
        }

        debugLogger(eventData);

        if ('meta' in eventData) {
            const optimizePricingData: Partial<OptimizePricingSummaryData> = {};
            let gtin;

            switch (eventData.meta) {
                case 'ACK':
                    break;
                case 'DONE':
                    if ('type' in eventData) {
                        switch (eventData.type) {
                            case 'headline':
                                updateStreamText([`${eventData.talking_point}-headline`, summaryId], {
                                    text: '',
                                    isStreaming: false,
                                });

                                break;
                            case 'text':
                                updateStreamText([`${eventData.talking_point}-insights`, summaryId], {
                                    text: '',
                                    isStreaming: false,
                                });

                                break;
                        }
                    } else {
                        updateStagedPage({
                            commit: true,
                            settings: {
                                summary: {
                                    isComplete: true,
                                    isStreaming: false,
                                    optimizePricing: {
                                        headlines: {
                                            competitors: formatText(streamText.headlines.competitors),
                                            historic: formatText(streamText.headlines.historic),
                                            inflation: formatText(streamText.headlines.inflation),
                                            recommendations: formatText(streamText.headlines.recommendations),
                                        },
                                        insights: {
                                            historic: formatText(streamText.insights.historic),
                                            recommendations: formatText(streamText.insights.recommendations),
                                        },
                                    },
                                },
                            },
                        });
                    }

                    break;
                case 'COMPLETE':
                    gtin = Object.keys(eventData.data.competitors).filter(
                        (key) => key !== 'headline'
                    )[0];

                    if (!page?.settings.summary?.optimizePricing?.headlines?.competitors) {
                        optimizePricingData.headlines = pureMerge(
                            optimizePricingData.headlines ?? {},
                            {
                                competitors: formatText(
                                    (eventData.data.competitors[gtin] as CompleteCompetitors)?.summary
                                ),
                            }
                        );
                    }

                    if (!page?.settings.summary?.optimizePricing?.headlines?.historic) {
                        optimizePricingData.headlines = pureMerge(
                            optimizePricingData.headlines ?? {},
                            {
                                historic: formatText((eventData.data.historic[gtin] as CompleteHistoric)?.summary),
                            }
                        );
                    }

                    if (!page?.settings.summary?.optimizePricing?.headlines?.inflation) {
                        optimizePricingData.headlines = pureMerge(
                            optimizePricingData.headlines ?? {},
                            {
                                inflation: formatText(eventData.data.inflation.headline),
                            }
                        );
                    }

                    if (!page?.settings.summary?.optimizePricing?.headlines?.recommendations) {
                        optimizePricingData.headlines = pureMerge(
                            optimizePricingData.headlines ?? {},
                            {
                                recommendations: formatText(eventData.data.recommendations.headline),
                            }
                        );
                    }

                    if (!page?.settings.summary?.optimizePricing?.insights?.historic) {
                        optimizePricingData.insights = pureMerge(
                            optimizePricingData.insights ?? {},
                            {
                                historic: formatText(
                                    (eventData.data.historic[gtin] as CompleteHistoric)?.summary
                                ),
                            }
                        );
                    }

                    if (!page?.settings.summary?.optimizePricing?.insights?.recommendations) {
                        optimizePricingData.insights = pureMerge(
                            optimizePricingData.insights ?? {},
                            {
                                recommendations: formatText(
                                    (eventData.data.recommendations[gtin] as CompleteRecommendations)?.recommendations
                                ),
                            }
                        );
                    }

                    if (!page?.settings.summary?.optimizePricing?.pvsChart) {
                        optimizePricingData.pvsChart = (eventData.data.historic[gtin] as CompleteHistoric)?.['pvs-chart'];
                    }

                    if (!page?.settings.summary?.optimizePricing?.priceChart) {
                        optimizePricingData.priceChart = (eventData.data.competitors[gtin] as CompleteCompetitors)?.['price-chart'];
                    }

                    if (!page?.settings.summary?.optimizePricing?.influencers) {
                        optimizePricingData.influencers =
                            (eventData.data.competitors[gtin] as CompleteCompetitors)?.influencers;
                    }

                    if (!page?.settings.summary?.optimizePricing?.inflationChart) {
                        optimizePricingData.inflationChart = (eventData.data.inflation[gtin] as CompleteInflation)?.['inflation-chart'];
                    }

                    if (!page?.settings.summary?.optimizePricing?.rangeData) {
                        optimizePricingData.rangeData = (eventData.data.recommendations[gtin] as CompleteRecommendations)?.['range-data'];
                    }

                    if (!page?.settings.summary?.optimizePricing?.recommendedPrice) {
                        optimizePricingData.recommendedPrice = (eventData.data.recommendations[gtin] as CompleteRecommendations)?.['recommended-price'];
                    }

                    if (!page?.settings.summary?.optimizePricing?.priceData) {
                        optimizePricingData.priceData = (eventData.data.recommendations[gtin] as CompleteRecommendations)?.['price-data'];
                    }

                    if (!isEmpty(optimizePricingData)) {
                        updateStagedPage({
                            commit: true,
                            settings: {
                                summary: {
                                    optimizePricing: optimizePricingData,
                                    isComplete: true,
                                    isStreaming: false,
                                },
                            },
                        });
                    }

                    break;

                case 'ERROR':
                    updateStagedPage({
                        commit: true,
                        settings: {
                            summary: {
                                error: true,
                                isStreaming: false,
                            },
                        },
                    });

                    break;
            }
        } else if ('type' in eventData) {
            switch (eventData.type) {
                case 'headline':
                    updateStreamText(
                        [`${eventData.talking_point}-headline`, summaryId],
                        {
                            text: eventData.text,
                            isStreaming: true,
                        },
                        formatText
                    );

                    streamText.headlines[eventData.talking_point as Exclude<OptimizePricingTalkingPoints, 'intro'>] += eventData.text;

                    break;
                case 'text':
                    updateStreamText(
                        [`${eventData.talking_point}-insights`, summaryId],
                        {
                            text: eventData.text,
                            isStreaming: true,
                        },
                        formatText
                    );

                    streamText.insights[eventData.talking_point as 'historic' | 'recommendations'] += eventData.text;

                    break;
                case 'inflation-chart':
                    updateStagedPage({
                        settings: {
                            summary: {
                                optimizePricing: {
                                    inflationChart: eventData.data,
                                },
                            },
                        },
                    });

                    break;
                case 'influencers':
                    updateStagedPage({
                        settings: {
                            summary: {
                                optimizePricing: {
                                    influencers: eventData.data,
                                },
                            },
                        },
                    });

                    break;
                case 'price-data':
                    updateStagedPage({
                        settings: {
                            summary: {
                                optimizePricing: {
                                    priceData: eventData.data,
                                },
                            },
                        },
                    });

                    break;
                case 'price-chart':
                    updateStagedPage({
                        settings: {
                            summary: {
                                optimizePricing: {
                                    priceChart: eventData.data,
                                },
                            },
                        },
                    });

                    break;
                case 'pvs-chart':
                    updateStagedPage({
                        settings: {
                            summary: {
                                optimizePricing: {
                                    pvsChart: eventData.data,
                                },
                            },
                        },
                    });

                    break;
                case 'range-data':
                    updateStagedPage({
                        settings: {
                            summary: {
                                optimizePricing: {
                                    rangeData: eventData.data,
                                },
                            },
                        },
                    });

                    break;
                case 'recommended-price':
                    updateStagedPage({
                        settings: {
                            summary: {
                                optimizePricing: {
                                    recommendedPrice: eventData.data,
                                },
                            },
                        },
                    });

                    break;

                default:
                    debugLogger({ unknown: eventData });
                    break;
            }
        }
    };
}
