import { useTheme } from '@mui/material';
import * as Highcharts from 'highcharts';
import { PropsWithChildren, useMemo } from 'react';
import { ChartOptionsContext } from './ChartOptionsContext';

export function ChartOptionsProvider({ children }: PropsWithChildren) {
    const { palette } = useTheme();

    const chartOptions: Highcharts.Options = useMemo(() => ({
        accessibility: {
            // enabled: false,
        },
        lang: {
            thousandsSep: ',',
        },
        chart: {
            animation: false,
            backgroundColor: palette.background.default,
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                },
            },
        },
        title: {
            text: '',
            style: {
                color: palette.text.primary,
            },
        },
        subtitle: {
            style: {
                color: palette.text.primary,
            },
        },
        legend: {
            itemStyle: {
                color: palette.text.primary,
            },
            itemHoverStyle: {
                color: palette.text.secondary,
            },
        },
        xAxis: {
            title: {
                text: '',
                style: { color: palette.text.primary },
            },
            labels: {
                style: {
                    color: palette.text.primary,
                    fontSize: '12px',
                },
            },
            gridLineColor: palette.background.secondary,
            className: 'h-12-b',
        },
        yAxis: {
            title: {
                text: '',
                style: { color: palette.text.primary },
            },
            labels: {
                style: {
                    color: palette.text.primary,
                    fontSize: '12px',
                },
            },
            gridLineColor: palette.background.secondary,
            opposite: true,
            className: 'h-12-b',
        },
        tooltip: {
            backgroundColor: palette.background.default,
            valueDecimals: 2,
            style: {
                color: palette.text.primary,
            },
            dateTimeLabelFormats: {
                day: '%a, %b %e, %Y',
                hour: '%a, %b %e, %Y',
            },
        },
        credits: {
            enabled: false,
        },
    }), [palette.background.default, palette.background.secondary, palette.text.secondary, palette.text.primary]);

    return <ChartOptionsContext.Provider value={chartOptions}>{children}</ChartOptionsContext.Provider>;
}
