import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { Header } from '../Navigation/Header';
import { PageLayout } from '../PageLayout/PageLayout';
import { LogoLoadingMessage } from '../Solutions/RunAnalysisLoading';
import { useLaunchForecastInProgress } from '../hooks/onLaunchAnalysis/useLaunchForecastInProgress';

export function WelcomeForecast() {
    const { t } = useTranslation();
    const { launchForecastInProgress, isLoading } = useLaunchForecastInProgress();

    return (
        <PageLayout pageTitle={t('page.welcome')}>
            <Header pageTitle={t('page.welcome')} />
            <Flex
                direction="column"
                gap="5rem"
                padding="0 1rem 3rem max(20%, 1rem)"
                style={{
                    margin: 'auto 0',
                    overflow: 'auto',
                }}
            >
                <Flex direction="column" gap="2rem">
                    <ChatCpgIcon size="150px" />
                    <Flex direction="column" gap="1rem">
                        <Flex direction="column">
                            <Typography as="h1">{t('forecast.newUser.welcome')}</Typography>
                            <Typography as="h2" variant="h-22-b">
                                {t('forecast.newUser.tickrForecastingSolution')}
                            </Typography>
                        </Flex>
                        <Typography style={{ maxWidth: '400px' }} variant="p-14-r">
                            {t('forecast.newUser.cpgBusinessPlanning')}
                        </Typography>
                        <Flex align="center" gap="1rem">
                            <Button
                                disabled={isLoading}
                                onClick={launchForecastInProgress}
                                size="small"
                                style={{ width: '170px' }}
                                variant="contained"
                            >
                                {t('util.letsGo')}
                            </Button>
                            {isLoading && <LogoLoadingMessage message={t('util.settingUp')} />}
                        </Flex>
                    </Flex>
                </Flex>
                <Typography
                    multiline
                    style={{ maxWidth: '400px' }}
                    variant="p-14-r"
                >
                    {t('forecast.newUser.description')}
                </Typography>
            </Flex>
        </PageLayout>
    );
}
