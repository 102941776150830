import { styled } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useContext } from 'react';
import { Flex } from '../Core/Flex';
import { TabContext } from './TabsContext';

const StyledHighlight = styled(
    motion.div,
    { shouldForwardProp: (prop) => prop !== 'padding' && prop !== 'width' }
)<{ width: number; padding: string }>(({ theme, width, padding }) => `
    position: absolute;
    background-color: ${theme.palette.background.tertiary};
    width: ${width}px;
    height: calc(100% - 2 * ${padding});
    border-radius: ${theme.shape.borderRadius}px;
    top: ${padding};
`);

const StyledFlex = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'padding' }
)<{ padding: string }>(({ theme, padding }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.shape.borderRadius,
    padding: `${padding}px`,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.background.secondaryMask}`,
    width: '100%',
}));

interface TabListProps {
    listName: string;
}

export function TabList({ listName, children }: PropsWithChildren<TabListProps>) {
    const padding = 3;

    const { activeTab, tabWidth } = useContext(TabContext);

    const left = padding + activeTab * tabWidth;

    return (
        <StyledFlex
            aria-label={`${listName} Tabs`}
            padding={`${padding}px`}
            role="tablist"
        >
            <AnimatePresence>
                <StyledHighlight
                    animate={{ left: `${left}px` }}
                    initial={{ left: `${padding}px` }}
                    padding={`${padding}px`}
                    transition={{ bounce: 0 }}
                    width={tabWidth}
                />
            </AnimatePresence>
            {children}
        </StyledFlex>
    );
}
