import { SolutionType } from '@tickr/sequelize-models/src/types/chatCpgTypes';
import { useLocation, useParams } from 'react-router-dom';
import { useCurrentProject } from './useCurrentProject';
import { useElements } from './useElements';

export function useCurrentElements() {
    const { pathname } = useLocation();
    const { currentProject } = useCurrentProject();
    const { pageUuid: paramPageUuid, tabUuid } = useParams();

    let dedicatedSolution: SolutionType | undefined;

    if (pathname === '/out-of-stock-predictions') {
        dedicatedSolution = 'outOfStockPredictions';
    } else if (pathname === '/scorecard') {
        dedicatedSolution = 'scorecard';
    }

    const pageUuid = dedicatedSolution
        ? currentProject.pages.find((page) => page.solutionType === dedicatedSolution)?.uuid
        : paramPageUuid;

    return useElements({
        pageUuid,
        queryKey: 'current-elements',
        tabUuid,
    });
}
