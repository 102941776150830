import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from '../Core/ContextMenu';
import { ContextMenuItem } from '../Core/ContextMenuItem';
import { Flex } from '../Core/Flex';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { PopoverContent } from '../Popover/PopoverContent';
import { PopoverProvider } from '../Popover/PopoverProvider';
import { PopoverTrigger } from '../Popover/PopoverTrigger';
import { usePopoverContext } from '../Popover/usePopoverContext';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { ChatCpgTimestamp } from './ChatCpgTimestamp';
import { FeedbackButtons } from './FeedbackButtons';
import { StreamingIcon } from './StreamingIcon';

interface ChatBubbleHeaderProps {
    date?: number;
    hideFeedback?: boolean;
    id: string;
    isStreaming?: boolean;
    text: string | null;
}

export function ChatBubbleHeader({
    date,
    hideFeedback,
    id,
    isStreaming = false,
    text,
}: ChatBubbleHeaderProps) {
    const { t } = useTranslation();

    return (
        <Flex
            align="center"
            justify="space-between"
            width="100%"
        >
            <Flex
                align="center"
                gap="0.4rem"
            >
                {!isStreaming && text && (
                    <PopoverProvider placement="top-start">
                        <PopoverTrigger asChild>
                            <IconButton aria-label={t('iconButton.aria.chatBubbleContextMenu')} size="small">
                                <ChatCpgIcon />
                            </IconButton>
                        </PopoverTrigger>
                        <ChatBubblePopoverContent text={text} />
                    </PopoverProvider>
                )}
                {!isStreaming && !text && <ChatCpgIcon />}

                {isStreaming && <StreamingIcon isStreaming={isStreaming} />}

                <ChatCpgTimestamp
                    isStreaming={isStreaming}
                    timestamp={date}
                />
            </Flex>
            {!hideFeedback && !isStreaming && text && (
                <Flex>
                    <FeedbackButtons
                        chatMessageId={id}
                        isEdited={false}
                        isStreaming={isStreaming}
                        talkingPointId={id}
                        text={text}
                    />
                </Flex>
            )}
        </Flex>
    );
}

interface ChatBubblePopoverContentProps {
    text: string;
}

function ChatBubblePopoverContent({ text }: ChatBubblePopoverContentProps) {
    const { t } = useTranslation();
    const { setOpen } = usePopoverContext();
    const { sendAlert } = useToaster();

    return (
        <PopoverContent>
            <ContextMenu
                direction="column"
                disableX
                padding="0.4rem"
                width="200px"
            >
                <ContextMenuItem
                    onClick={() => {
                        navigator.clipboard.writeText(text);

                        sendAlert({
                            text: t('chatCpg.bubbleMenu.textCopied'),
                            severity: 'success',
                        });

                        setOpen(false);
                    }}
                    startIcon={<ContentPasteIcon />}
                >
                    {t('chatCpg.bubbleMenu.copyText')}
                </ContextMenuItem>
            </ContextMenu>
        </PopoverContent>
    );
}
