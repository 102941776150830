import { useMergeRefs } from '@floating-ui/react';
import * as React from 'react';
import { useTooltipContext } from './useTooltipContext';

export const TooltipTrigger = React.forwardRef<
    HTMLElement,
    React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const childrenRef = (children as any).ref;

    const ref = useMergeRefs([
        context?.refs.setReference,
        propRef,
        childrenRef,
    ]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && React.isValidElement(children)) {
        return React.cloneElement(
            children,
            context?.getReferenceProps({
                ref,
                ...props,
                ...children.props,
                'data-state': context.open ? 'open' : 'closed',
            })
        );
    }

    return (
        <button
            data-state={context?.open ? 'open' : 'closed'} // The user can style the trigger based on the state
            ref={ref}
            {...context?.getReferenceProps(props)}
        >
            {children}
        </button>
    );
});
