import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { IconButton } from '@mui/material';
import { Indicator } from '../../Core/Indicator';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { useNotifications } from '../../Providers/NotificationsProvider/useNotifications';
import { useHeaderIconStyles } from '../../hooks/useHeaderIconStyles';
import { NotificationsPopoverContent } from './NotificationsPopoverContent';

export function NotificationsPopover() {
    const notifications = useNotifications();
    const unreadNotificationCount = notifications.filter(({ isNew }) => isNew).length;
    const styles = useHeaderIconStyles();

    return (
        <PopoverProvider placement="bottom">
            <PopoverTrigger asChild>
                <IconButton size="small" style={styles}>
                    <NotificationsNoneIcon fontSize="small" />
                    {unreadNotificationCount > 0 && (
                        <Indicator right="3px" top="3px">
                            {unreadNotificationCount}
                        </Indicator>
                    )}
                </IconButton>
            </PopoverTrigger>
            <NotificationsPopoverContent />
        </PopoverProvider>
    );
}
