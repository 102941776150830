import { TableContainer } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

interface TickrTableContainerProps {
    children: ReactNode;
    style?: CSSProperties;
}

export function TickrTableContainer({ children, style }: TickrTableContainerProps) {
    return (
        <TableContainer
            style={{
                zIndex: 0,
                ...style,
            }}
        >
            {children}
        </TableContainer>
    );
}
