import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';
import { FilterGroup } from './FilterGroup';
import { GTINFilter } from './GTINFilter';

interface FilterSwitchProps {
    filterDefinition: FilterDefinition;
}

export function FilterSwitch({ filterDefinition }: FilterSwitchProps) {
    const { type, filterId, values } = filterDefinition;

    switch (type) {
        case 'gtin':
            return <GTINFilter filterId={filterId} values={values} />;
        case 'checkbox':
            return (
                <FilterGroup
                    filterId={filterId}
                    type="checkbox"
                    values={values ?? []}
                />
            );
        case 'radio':
            return (
                <FilterGroup
                    filterId={filterId}
                    type="radio"
                    values={values ?? []}
                />
            );
        case 'range':
            return <p>Range Filter Here</p>;
        case 'search':
            return <p>Search Filter Here</p>;
        default:
            return <p>Unknown Filter Type</p>;
    }
}
