import { GridTreeNodeWithRender,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector } from '@mui/x-data-grid-pro';

export function useCountDescendants(rowNode: GridTreeNodeWithRender) {
    const apiRef = useGridApiContext();
    const descendentCount = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const rawCount = descendentCount[rowNode.id];

    if (!rawCount) return {
        owned: 0,
        competitors: 0,
    };

    const owned = Object.keys(descendentCount).filter(
        (id) => id.includes(rowNode.id as string) && id.slice(-5) === '/true'
    ).length;

    const competitors = Object.keys(descendentCount).filter(
        (id) => id.includes(rowNode.id as string) && id.slice(-6) === '/false'
    ).length;

    return {
        owned,
        competitors,
    };
}
