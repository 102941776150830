import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NumberFormatter } from '../Core/NumberFormatter';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { HeadCell, TickrHeadCell } from '../Table/TickrHeadCell';
import { TickrTable } from '../Table/TickrTable';
import { TickrTableBody } from '../Table/TickrTableBody';
import { TickrTableCell } from '../Table/TickrTableCell';
import { TickrTableContainer } from '../Table/TickrTableContainer';
import { TickrTableHead } from '../Table/TickrTableHead';
import { TickrTableRow } from '../Table/TickrTableRow';
import { TickrTableSortLabel } from '../Table/TickrTableSortLabel';
import { useTableSort } from '../Table/useTableSort';
import { usePage } from '../hooks/usePage';
import { ProductCell } from './Cells/ProductCell';
import { ScoreCell } from './Cells/ScoreCell';

interface Complement {
    id: string;
    name: string;
    complementScore: number;
    totalSales: number;
}

export function ComplementsTable() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { pageUuid, substitutionSummaryData, isStreaming } = usePage();
    const { updatePage } = useCollectionMutations();

    const complements: Complement[] = substitutionSummaryData?.complements?.map(
        ({ gtin, itemDescription, score, L12WRevenue }) => ({
            id: gtin,
            name: itemDescription,
            complementScore: score,
            totalSales: L12WRevenue,
        })
    ) ?? [];

    const headCells: HeadCell<Complement>[] = [
        {
            value: 'id',
            label: t('chatCpg.substitutions.substitute'),
        },
        {
            value: 'complementScore',
            label: t('chatCpg.substitutions.complementScore'),
            align: 'right',
        },
        {
            value: 'totalSales',
            label: t('chatCpg.substitutions.l12wTotalSales'),
            align: 'right',
        },
    ];

    const { order, orderBy, setOrder, setOrderBy, sortedRows } = useTableSort({
        data: complements,
        initOrder: 'desc',
        initOrderBy: 'complementScore',
    });

    if (!pageUuid) {
        return null;
    }

    return (
        <TickrTableContainer>
            <TickrTable>
                <TickrTableHead>
                    <TickrTableRow background={palette.background.default}>
                        {headCells.map(({ value: id, label, align }) => (
                            <TickrHeadCell
                                align={align}
                                key={id}
                                order={order}
                                orderBy={orderBy}
                                value={id}
                            >
                                <TickrTableSortLabel
                                    id={id}
                                    label={label}
                                    nowrap
                                    order={order}
                                    orderBy={orderBy}
                                    setOrder={setOrder}
                                    setOrderBy={setOrderBy}
                                />
                            </TickrHeadCell>
                        ))}
                    </TickrTableRow>
                </TickrTableHead>
                <TickrTableBody>
                    {sortedRows.map((row) => {
                        const isSelected = row.id === substitutionSummaryData?.activeComplementGtin;

                        return (
                            <TickrTableRow
                                aria-checked={isSelected}
                                highlight={isSelected}
                                highlightColor={palette.indicator.main}
                                hover={!isStreaming}
                                key={row.id}
                                onClick={isStreaming
                                    ? undefined
                                    : () => updatePage({
                                        mergeProps: {
                                            settings: {
                                                summary: {
                                                    substitutions: {
                                                        activeComplementGtin: row.id,
                                                    },
                                                },
                                            },
                                        },
                                        pageUuid,
                                    })}
                                sx={{ cursor: isStreaming ? 'default' : 'pointer' }}
                            >
                                <TickrTableCell
                                    component="th"
                                    id={`complement-${row.id}`}
                                    scope="row"
                                >
                                    <ProductCell
                                        id={row.id}
                                        maxWidth="500px"
                                        name={row.name}
                                    />
                                </TickrTableCell>
                                <TickrTableCell align="right">
                                    <ScoreCell score={row.complementScore} />
                                </TickrTableCell>
                                <TickrTableCell align="right">
                                    <NumberFormatter
                                        as="p"
                                        numberStyle="currencyEstimate"
                                        val={row.totalSales}
                                        variant="h-16-b"
                                    />
                                </TickrTableCell>
                            </TickrTableRow>
                        );
                    })}
                </TickrTableBody>
            </TickrTable>
        </TickrTableContainer>

    );
}
