import { IconButton } from '@mui/material';
import { KnowledgeRepoIcon } from '../../Icons/KnowledgeRepoIcon';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { KnowledgeBasePopoverContent } from './KnowledgeBasePopoverContent';

export function KnowledgeBasePopover() {
    return (
        <PopoverProvider placement="bottom-start">
            <PopoverTrigger asChild>
                <IconButton
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <KnowledgeRepoIcon size="20px" />
                </IconButton>
            </PopoverTrigger>
            <KnowledgeBasePopoverContent />
        </PopoverProvider>
    );
}
