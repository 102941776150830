import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductDataRetailerFilters } from '../../Solutions/ProductDataRetailerFilters';
import { ScopeOfAnalysisSectionContent } from '../../Solutions/ProductDataRetailerSectionContent';
import { SolutionGrid } from '../../Solutions/SolutionGrid';
import { SolutionHeader } from '../../Solutions/SolutionHeader';
import { SolutionPanel } from '../../Solutions/SolutionPanel';
import { SolutionPanelHeader } from '../../Solutions/SolutionPanelHeader';
import { SolutionPanelSection } from '../../Solutions/SolutionPanelSection';

const stepFilterMap = {
    1: 'cpg_owned_products',
    2: 'cpg_dataset',
    3: 'cpg_banner',
} as const;

export function SubstitutionsSolution() {
    const { t } = useTranslation();
    const [step, setStep] = useState<1 | 2 | 3>(1);
    const activeFilter = stepFilterMap[step];

    const stepHeaderMap = {
        1: t('chatCpg.whichProduct'),
        2: t('chatCpg.selectOneOrMoreDataSources'),
        3: t('chatCpg.selectABanner'),
    };

    return (
        <SolutionGrid>
            <SolutionHeader
                description={step === 1 ? t('chatCpg.whichProduct.description') : undefined}
                title={stepHeaderMap[step]}
            />
            <ProductDataRetailerFilters
                activeFilter={activeFilter}
                setStep={setStep}
                solutionType="substitutions"
                step={step}
            />
            <SolutionPanel>
                <SolutionPanelHeader text={t('solution.firstStep')} />
                <SolutionPanelSection
                    active={step !== 3}
                    done={step === 3}
                    title={t('chatCpg.1selectScope')}
                >
                    <ScopeOfAnalysisSectionContent />
                </SolutionPanelSection>
            </SolutionPanel>
        </SolutionGrid>
    );
}
