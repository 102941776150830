import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { DocumentCompleteData, ForecastDocument } from '@tickr/sequelize-models';
import omit from 'lodash/omit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApp } from '../Providers/AppProvider/useApp';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { debugLogger } from '../helpers/debugLogger';
import { useChatCpgStore } from './useChatCpgStore';
import { useForecastInProgress } from './useForecastInProgress';
import { useHandleError } from './useHandleError';
import { useStagedPage } from './useStagedPage';
import { useStagedPageFns } from './useStagedPageFns';

type DocumentStatusEvent = {
    type: 'status';
    status: 'validating' | 'validated' | 'summarizing';
};

type DocumentErrorEvent = {
    type: 'error';
    data: {
        error: string;
    };
};

interface DocumentSummaryChunkEvent {
    type: 'text';
    talking_point: 'file-summary-chunk';
    text: string;
}

interface DocumentDocumentEvent {
    type: 'document';
    data: {
        document: ForecastDocument;
    };
}

interface DocumentSummaryEvent {
    type: 'summary';
    data: {
        file_summary: string;
    };
}

interface DocumentDataEvent {
    type: 'document-data';
    data: DocumentCompleteData;
}

interface DocumentStreamCompleteEvent {
    meta: 'COMPLETE';
    type: 'complete-response';
    data: DocumentCompleteData;
}

interface MetaEvent {
    meta: 'ERROR' | 'DONE';
}

type DocumentStreamEvent =
    | DocumentDataEvent
    | DocumentDocumentEvent
    | DocumentErrorEvent
    | DocumentStatusEvent
    | DocumentStreamCompleteEvent
    | DocumentSummaryEvent
    | DocumentSummaryChunkEvent
    | MetaEvent;

interface UseDocumentStreamProps {
    onError?: () => void;
}

export function useDocumentStream({ onError }: UseDocumentStreamProps = {}) {
    const { t } = useTranslation();
    const { chatApiRoute, tidsApiUrl } = useApp();
    const { errorMsg, forecast, documentId, isDocumentComplete } = useForecastInProgress();
    const { sendAlert } = useToaster();
    const queryClient = useQueryClient();
    const handleError = useHandleError();
    const { stagedPage } = useStagedPage(forecast?.uuid ?? '');
    const { initStagedPage, updateStagedPage } = useStagedPageFns(forecast?.uuid ?? '');

    const deleteStagedDocument = useChatCpgStore((state) => state.deleteStagedDocument);
    const updateStreamText = useChatCpgStore((state) => state.updateStreamText);

    const handleDocumentError = useCallback((errorMsg: string) => {
        if (!forecast?.uuid) return;

        deleteStagedDocument(forecast?.uuid);
        setCount(0);
        onError?.();
        handleError(new Error(errorMsg), errorMsg);
    }, [deleteStagedDocument, forecast?.uuid, onError, handleError]);

    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!forecast?.uuid || !documentId || count === 0) return;

        if (count < 10) {
            queryClient.invalidateQueries({ queryKey: ['document-stream', documentId, forecast?.uuid] });
        } else {
            handleDocumentError(t('error.documentStream.404'));
        }
    }, [
        count,
        documentId,
        forecast?.uuid,
        handleDocumentError,
        handleError,
        onError,
        queryClient,
        t,
    ]);

    useQuery({
        queryKey: ['document-stream', documentId, forecast?.uuid],
        async queryFn() {
            if (!documentId || !forecast?.uuid) return false;

            const controller = new AbortController();

            fetchEventSource(
                `${tidsApiUrl}/v1${chatApiRoute}/tabular-document/${documentId}?channel=tab-document`,
                {
                    method: 'GET',
                    signal: controller.signal,
                    async onopen(response) {
                        debugLogger('DOCUMENT STREAM OPEN', response);

                        if (response.status === 404) {
                            throw new Error('404');
                        } else {
                            setCount(0);
                        }

                        if (!stagedPage) {
                            initStagedPage(forecast);
                        }
                    },
                    onmessage(event) {
                        if (event.event === 'ping' || event.data === '') return;

                        const streamData: DocumentStreamEvent = JSON.parse(event.data);

                        debugLogger(streamData);

                        if ('meta' in streamData) {
                            switch (streamData.meta) {
                                case 'ERROR':
                                    throw new Error('unknown');

                                case 'COMPLETE':
                                    updateStreamText(
                                        ['document-summary', documentId],
                                        {
                                            text: '',
                                            isStreaming: false,
                                        }
                                    );

                                    updateStagedPage({
                                        commit: true,
                                        navigateTo: '/solutions/forecast/select-forecast',
                                        settings: {
                                            document: {
                                                isValidated: true,
                                                ...streamData.data.document,
                                                ...omit(streamData.data, 'document'),
                                            },
                                        },
                                    });

                                    break;
                            }
                        } else {
                            if ('type' in streamData) {
                                switch (streamData.type) {
                                    case 'text':
                                        updateStreamText(
                                            ['document-summary', documentId],
                                            {
                                                text: streamData.text,
                                                isStreaming: true,
                                            }
                                        );

                                        break;
                                    case 'status':
                                        switch (streamData.status) {
                                            case 'validating':
                                                return;
                                            case 'validated':
                                                updateStagedPage({
                                                    settings: {
                                                        document: {
                                                            isValidated: true,
                                                        },
                                                    },
                                                });

                                                return;
                                            case 'summarizing':
                                                return;
                                        }

                                        break;
                                    case 'error':
                                        sendAlert({
                                            severity: 'error',
                                            showX: true,
                                            text: streamData.data.error,
                                            timeout: 10000,
                                        });

                                        updateStagedPage({
                                            commit: true,
                                            settings: {
                                                document: {
                                                    isValidated: false,
                                                    errorMsg: streamData.data.error,
                                                },
                                            },
                                        });

                                        onError?.();

                                        controller.abort();
                                        break;
                                    case 'document':
                                        updateStagedPage({
                                            settings: {
                                                document: streamData.data.document,
                                            },
                                        });

                                        return;

                                    case 'summary':
                                        updateStreamText(
                                            ['document-summary', documentId],
                                            {
                                                text: '',
                                                isStreaming: false,
                                            }
                                        );

                                        updateStagedPage({
                                            settings: {
                                                document: {
                                                    summary: streamData.data.file_summary,
                                                },
                                            },
                                        });

                                        return;

                                    case 'document-data':
                                        updateStagedPage({
                                            commit: true,
                                            navigateTo: '/solutions/forecast/select-forecast',
                                            settings: {
                                                document: streamData.data,
                                            },
                                        });

                                        return;
                                }
                            }
                        }
                    },
                    onclose() {
                        debugLogger('DOCUMENT STREAM CLOSED');
                    },
                    onerror(error: Error) {
                        if (error.message === '404') {
                            setCount((count) => count + 1);
                            throw new Error('close stream');
                        } else {
                            handleDocumentError(t('error.documentStream'));
                            throw new Error('close stream');
                        }
                    },
                }
            );

            return true;
        },
        enabled: !!documentId && !isDocumentComplete && !errorMsg,
        staleTime: Infinity,
    });
}
