const h36b = {
    fontFamily: 'Gilroy-Bold, sans-serif',
    fontSize: '36px',
    fontWeight: 'bold',
};

export const h22b = {
    fontFamily: 'Gilroy-Bold, sans-serif',
    fontSize: '22px',
    fontWeight: 'bold',
    letterSpacing: '0.04em',
};

export const h16b = {
    fontFamily: 'Gilroy-Bold, sans-serif',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.04em',
};

export const h14b = {
    fontFamily: 'Gilroy-Bold, sans-serif',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.04em',
};

export const p16r = {
    fontFamily: 'Gilroy-Regular, sans-serif',
    fontSize: '16px',
};

export const p14b = {
    fontFamily: 'Gilroy-Regular, sans-serif',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.02em',
};

export const p12b = {
    fontFamily: 'Gilroy-Bold, sans-serif',
    fontSize: '12px',
    fontWeight: 'bold',
};
