import { styled, useTheme } from '@mui/material';
import { CSSProperties, ElementType, useMemo } from 'react';
import { useLocalization } from '../Providers/LocalizationProvider/useLocalization';
import { NumberStyle, numberStyleHelper } from '../helpers/numberStyleHelper';
import { Typography, TypographyProps, Variant } from './Typography';

interface NumberFormatterProps {
    as: ElementType;
    className?: string;
    color?: string;
    containerStyle?: CSSProperties;
    customSuffix?: string;
    customSuffixStyle?: CSSProperties;
    numberStyle?: NumberStyle;
    partsStyle?: CSSProperties;
    prefixStyle?: CSSProperties;
    showChange?: boolean;
    suffixStyle?: CSSProperties;
    val?: number;
    valueStyle?: CSSProperties;
    variant?: Variant;
}

export function NumberFormatter({
    as = 'p',
    color: colorProp,
    containerStyle,
    customSuffix = '',
    customSuffixStyle,
    numberStyle = 'real',
    partsStyle,
    prefixStyle,
    showChange,
    suffixStyle,
    val: rawValue = NaN,
    valueStyle,
    variant,
    className,
}: NumberFormatterProps) {
    const t = useLocalization();
    const { palette } = useTheme();
    const color = colorProp ?? palette.text.primary;

    const displayValue = useMemo<string>(() => {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const parts = currencyFormatter.formatToParts(showChange ? Math.abs(rawValue) : rawValue);

        switch (numberStyleHelper(rawValue, numberStyle).numberFormat) {
            case 'currency':
                return parts
                    .filter((part) => part.type !== 'currency')
                    .map((part) => part.value)
                    .join('');
            case 'currencyEstimate':
                return numberStyleHelper(Math.abs(rawValue), numberStyle).display();
            default:
                return numberStyleHelper(showChange ? Math.abs(rawValue) : rawValue, numberStyle).display();
        }
    }, [
        rawValue,
        numberStyle,
        showChange,
    ]);

    const prefix = useMemo<string>(() => {
        if (!isFinite(rawValue)) return '';

        switch (numberStyleHelper(rawValue, numberStyle).numberFormat) {
            case 'currency':
            case 'currencyEstimate':
                if (!showChange && Number(displayValue) * rawValue < 0 ? -1 : 1 < 0) return '-$';
                return '$';
            default:
                return '';
        }
    }, [
        numberStyle,
        rawValue,
        showChange,
        displayValue,
    ]);

    const suffixScale = useMemo(() => {
        if (!isFinite(rawValue)) return '';

        switch (numberStyleHelper(rawValue, numberStyle).numberFormat) {
            case 'hh:mm:ss':
                return '';
            case 'mm:ss':
                return '';
        }

        switch (numberStyleHelper(rawValue, numberStyle).scale()) {
            case 't':
                return t('numbers.thousand');
            case 'm':
                return t('numbers.million');
            case 'b':
                return t('numbers.billion');
            case 'tr':
                return t('numbers.trillion');
            case 'p':
                return t('numbers.peta');
            case 'ex':
                return t('numbers.exa');
            default:
                return '';
        }
    }, [
        rawValue,
        numberStyle,
        t,
    ]);

    const suffixSymbol = useMemo(() => {
        if (!isFinite(rawValue)) return '';

        switch (numberStyleHelper(rawValue, numberStyle).numberFormat) {
            case 'percentage':
                return '%';
            case 'percentagePoint':
                return t('metrics.percentagePoints');
        }
    }, [
        rawValue,
        numberStyle,
        t,
    ]);

    return as === 'tspan' || as === 'span'
        ? (
            <NumberParts
                as={as}
                className={className}
                customSuffix={customSuffix}
                customSuffixStyle={{
                    color,
                    ...partsStyle,
                    ...customSuffixStyle,
                }}
                displayValue={displayValue}
                numberStyle={numberStyle}
                prefix={prefix}
                prefixStyle={{
                    color,
                    ...partsStyle,
                    ...prefixStyle,
                }}
                rawValue={rawValue}
                showChange={showChange}
                suffixScale={suffixScale}
                suffixStyle={{
                    color,
                    ...partsStyle,
                    ...suffixStyle,
                }}
                suffixSymbol={suffixSymbol}
                valueStyle={{
                    color,
                    ...partsStyle,
                    ...valueStyle,
                }}
                variant={variant}
            />
        )
        : (
            <Typography
                as={as}
                color={color}
                style={containerStyle}
                variant={variant}
            >
                <NumberParts
                    as="span"
                    className={className}
                    customSuffix={customSuffix}
                    customSuffixStyle={{
                        color,
                        ...partsStyle,
                        ...customSuffixStyle,
                    }}
                    displayValue={displayValue}
                    numberStyle={numberStyle}
                    prefix={prefix}
                    prefixStyle={{
                        color,
                        ...partsStyle,
                        ...prefixStyle,
                    }}
                    rawValue={rawValue}
                    showChange={showChange}
                    suffixScale={suffixScale}
                    suffixStyle={{
                        color,
                        ...partsStyle,
                        ...suffixStyle,
                    }}
                    suffixSymbol={suffixSymbol}
                    valueStyle={{
                        color,
                        ...partsStyle,
                        ...valueStyle,
                    }}
                />
            </Typography>
        );
}

interface PartProps extends TypographyProps {
    as: 'span' | 'tspan';
}

const Prefix = styled(Typography)<PartProps>`
    padding-right: 0.1em;
`;

const Value = styled(Typography)<PartProps>``;

const Suffix = styled(Typography)<PartProps>`
    font-size: max(12px, 0.5em);
    padding-left: 0.1em;
`;

const getSign = (rawValue: number, displayValue: string) => (isFinite(rawValue) &&
    Number(displayValue) !== 0 &&
    (rawValue > 0 ? '+' : rawValue < 0 ? '-' : '')) ||
  '';

interface NumberPartsProps {
    as: 'span' | 'tspan';
    variant?: Variant;
    prefixStyle: CSSProperties;
    rawValue: number;
    displayValue: string;
    prefix: string;
    showChange?: boolean;
    valueStyle: CSSProperties;
    suffixStyle: CSSProperties;
    numberStyle: NumberStyle;
    suffixScale: string;
    suffixSymbol?: string;
    customSuffixStyle: CSSProperties;
    customSuffix: string;
    className?: string;
}

function NumberParts({
    as,
    variant,
    prefixStyle,
    showChange,
    rawValue,
    displayValue,
    prefix = '',
    valueStyle,
    suffixStyle,
    numberStyle,
    suffixScale,
    suffixSymbol = '',
    customSuffixStyle,
    customSuffix,
    className,
}: NumberPartsProps) {
    const sign = showChange ? getSign(rawValue, displayValue) : '';
    const fullPrefix = sign + prefix;

    return (
        <>
            {fullPrefix && (
                <Prefix
                    as={as}
                    className={variant}
                    style={prefixStyle}
                >
                    {fullPrefix}
                </Prefix>
            )}
            {displayValue && (
                <Value
                    as={as}
                    className={[variant, className].filter((val) => val).join(' ')}
                    style={valueStyle}
                >
                    {displayValue}
                </Value>
            )}
            {(suffixSymbol || suffixScale) && (
                <Suffix
                    as={as}
                    className={variant}
                    style={suffixStyle}
                >
                    {numberStyle !== 'currency' && suffixScale}
                    {suffixSymbol}
                </Suffix>
            )}
            {customSuffix && (
                <Suffix
                    as={as}
                    className={variant}
                    style={customSuffixStyle}
                >
                    {(suffixScale || suffixSymbol) && ' '}
                    {` ${customSuffix}`}
                </Suffix>
            )}
        </>
    );
}
