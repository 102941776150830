import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { Typography } from '../../Core/Typography';

export function HeaderCell(props: GridColumnHeaderParams) {
    return (
        <Typography
            secondary
            style={{
                maxWidth: '150px',
                whiteSpace: 'normal',
                lineHeight: '18px',
            }}
            variant="p-12-r"
        >
            {props.colDef.headerName}
        </Typography>
    );
}
