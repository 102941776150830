import { styled } from '@mui/material';

interface MediaImageProps {
    src: string;
    alt: string;
    className?: string;
}

const StyledImage = styled('img')`
    position: relative;
    width: 100%;
    border-radius: 4px;
`;

export function MediaImage({ src, alt }: MediaImageProps) {
    return <StyledImage alt={alt} src={src} />;
}
