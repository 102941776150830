import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { KnowledgeBasePopover } from '../../ChatCpg/KnowledgeBasePopover/KnowledgeBasePopover';
import { StreamingIcon } from '../../ChatCpg/StreamingIcon';
import { Typography } from '../../Core/Typography';
import { useUser } from '../../Providers/UserProvider/useUser';
import { usePage } from '../../hooks/usePage';
import { OuterToolbarWrapper } from './Components/OuterToolbarWrapper';
import { ToolbarDivider } from './Components/ToolbarDivider';
import { ToolbarWrapper } from './Components/ToolbarWrapper';

interface ChatCpgToolbar {
    toolbarControls: ReactNode;
}

export function ChatCpgToolbar({ toolbarControls }: ChatCpgToolbar) {
    const { t } = useTranslation();
    const { isStreaming } = usePage();
    const { isDemo } = useUser();

    return (
        <OuterToolbarWrapper
            id="chatCpg"
        >
            <ToolbarWrapper showGap toolbarControls={toolbarControls}>
                <StreamingIcon isStreaming={isStreaming} size="20px" />
                <Typography
                    as="h2"
                    style={{
                        maxWidth: 'calc(100% - 40px)',
                    }}
                    variant="h-16-b"
                >
                    {t('chatCpg')}
                </Typography>

                {isDemo && <KnowledgeBasePopover />}

            </ToolbarWrapper>
            <ToolbarDivider />
        </OuterToolbarWrapper>
    );
}
