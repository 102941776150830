import { useUser } from '@tickr/ui/src/Providers/UserProvider/useUser';
import { useCurrentProject } from '@tickr/ui/src/hooks/useCurrentProject';
import { DemoRoutes } from './DemoRoutes';
import { ForecastRoutes } from './ForecastRoutes';
import { LoginLoader } from './LoginLoader';

export function WebRoutes() {
    const { isLoading } = useCurrentProject();
    const { isDemo } = useUser();

    if (isLoading) {
        return <LoginLoader text="Loading environment" />;
    }

    if (isDemo) {
        return <DemoRoutes />;
    }

    return <ForecastRoutes />;
}
