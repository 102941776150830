import dayjs, { extend } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';

extend(duration);

export interface NumberOptions {
    showChange?: boolean;
    precision?: number;
    smallest?: number;
    showTrailingZeros?: boolean;
}

export interface FullNumberOptions extends NumberOptions {
    numberFormat: NumberFormat;
}

export type NumberFormat =
  | 'currency'
  | 'currencyEstimate'
  | 'percentage'
  | 'percentagePoint'
  | 'int'
  | 'real'
  | 'hh:mm:ss'
  | 'mm:ss';

export type NumberStyle = NumberFormat | FullNumberOptions;

interface NumberStyleHelpers {
    /**
   * @description
   * Rounds numbers to a fixed level of precision where return value is not less then `smallest`.
   *
   * @example
   * numberStyle(12341234, 'int').precision({ precision: 2 smallest:0 }) === 12000000
   * numberStyle(-12341234, 'int').precision({ precision: 2 smallest:0 }) === 0
   */
    precision(precisionProps: { smallest?: number; precision?: number }): number;
    /**
   * @description
   * Returns rounded version of `val` without messing with decimal places.
   *
   * @example
   * numberStyle(0.987654893, 'percentage').round() === 0.99
   */
    round(): number;
    /**
   * @description
   * Returns the val as a string as it would be displayed in the ui without symbols.
   * Display returns a string because you should not do math on it to avoid rounding errors.
   *
   * @example
   * numberStyle(0.99, 'percentage').display() === "99"
   */
    display(): string;

    /**
   * @description
   * Used to extract the short scale from a number.
   */
    scale(): 't' | 'm' | 'b' | 'tr' | 'p' | 'ex' | undefined;

    /**
   * @description
   * Used to extract the `NumberFormat` from a `NumberStyle` property.
   */
    numberFormat: NumberFormat;

    addNumberOptions(options: NumberOptions): FullNumberOptions;
}

const defaultTrailingZeros: Record<NumberFormat, boolean> = {
    currency: true,
    currencyEstimate: false,
    percentage: true,
    percentagePoint: true,
    int: false,
    real: false,
    'hh:mm:ss': true,
    'mm:ss': true,
};

const defaultPrecision: Record<NumberFormat, number> = {
    percentage: 1,
    percentagePoint: 1,
    currency: 2,
    int: 3,
    real: 3,
    currencyEstimate: 3,
    'hh:mm:ss': 1,
    'mm:ss': 1,
};

export function numberStyleHelper(
    val: number = NaN,
    style: NumberStyle = 'real'
): NumberStyleHelpers {
    const numberFormat: NumberFormat = typeof style === 'string' ? style : style.numberFormat;

    const precision = (style as NumberOptions).precision ?? defaultPrecision[numberFormat] ?? 3;
    const smallest = (style as NumberOptions).smallest ?? 0.0001;

    const showTrailingZeros =
    (style as NumberOptions).showTrailingZeros ?? defaultTrailingZeros[numberFormat];

    return {
        numberFormat,
        addNumberOptions(options) {
            return typeof style === 'object'
                ? {
                    ...style,
                    ...options,
                }
                : {
                    numberFormat,
                    ...options,
                };
        },
        precision({ precision: overRidePrecision = precision, smallest: overRideSmallest = smallest }) {
            if (isNaN(val) || !isNumber(val) || !isFinite(val)) return val;
            const absValue = Math.abs(numberFormat === 'int' ? Math.round(val) : val);

            if (absValue < Math.pow(10, 3) && numberFormat === 'int') {
                return absValue;
            }

            if (val === 0 || absValue < overRideSmallest) return 0;

            if (absValue < 0.0001) return 0;
            if (absValue < 0.001) return parseFloat(parseFloat(absValue.toFixed(4)).toPrecision(1));
            if (absValue < 0.01) return parseFloat(parseFloat(absValue.toFixed(3)).toPrecision(2));

            return parseFloat(val.toPrecision(overRidePrecision));
        },
        scale() {
            const num =
        style === 'percentagePoint' || style === 'percentage'
            ? numberStyleHelper(val * 100, style).round()
            : numberStyleHelper(val, style).round();

            const abs = Math.abs(num);

            if (abs >= 1_000_000_000_000) {
                return 'tr';
            } else if (abs >= 1_000_000_000) {
                return 'b';
            } else if (abs >= 1_000_000) {
                return 'm';
            } else if (abs >= 1_000) {
                return 't';
            }
        },
        display() {
            let num = numberStyleHelper(val, style).round();

            if (isNaN(num) || !isNumber(num) || !isFinite(num)) {
                return '-';
            }

            switch (numberFormat) {
                case 'hh:mm:ss':
                    return dayjs.duration(val, 'second').format('HH:mm:ss');
                case 'mm:ss':
                    return dayjs.duration(val, 'second').format('mm:ss');
                case 'percentage':
                    num *= 100;
                    num = scaleNumber(val, style, num);
                    break;
                case 'percentagePoint':
                    num *= 100;
                    num = scaleNumber(val, style, num);
                    break;

                default:
                    num = scaleNumber(val, style, num);
            }

            let fixed: string;

            switch (numberFormat) {
                case 'percentage':
                case 'percentagePoint':
                    switch (num) {
                        case 100:
                            fixed = num.toFixed(0);
                            break;
                        default:
                            fixed = num.toFixed(precision);
                    }

                    break;

                default:
                    fixed = num.toFixed(precision);
            }

            if (!showTrailingZeros) {
                /*
          This regex removes trailing zeros by capturing groups () in the string that are not related to the trailing zeros ($1 - $6).
          It is designed to primarily handle three separate cases. Each case is separated by | and wrapped with ^$.
          1. numbers without a decimal
          2. numbers with a decimal and only trailing zeros (decimal is not captured)
          3. numbers with a decimal and other digits before the trailing zeros (decimal and non-zero trailing digits are captured)
          The capturing group at the beginning of each option (\D?[\d,]+) captures non-digit prefixes, such as currency symbols, and comma separated digits.
          The capturing group at the end of each option (\D*) captures non-digit suffixes (e.g. % , K, M, etc.)
        */
                return fixed.replace(
                    /^(\D?[\d,]+)(\D*)$|^(\D?[\d,]+)\.0*(\D*)$|^(\D?[\d,]+\.[0-9]*?)0*(\D*)$/g, // lol
                    '$1$2$3$4$5$6'
                );
            }

            return fixed;
        },
        round() {
            const currencyFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            const stringVal = currencyFormatter
                .formatToParts(val)
                .reduce(
                    (string, { type, value }) => (type === 'integer' || type === 'decimal' || type === 'fraction'
                        ? string + value
                        : string),
                    ''
                );

            switch (numberFormat) {
                case 'currency':
                    return parseFloat(stringVal);

                case 'currencyEstimate':
                    return numberStyleHelper(val, 'int').precision({
                        precision,
                        smallest,
                    });

                case 'percentage': {
                    const percent = val * 100;

                    if (percent >= 99.95 && percent < 1000) {
                        return Math.round(percent * 10) / 10 / 100;
                    } else if (percent >= 999.95) {
                        return (
                            numberStyleHelper(Math.round(percent * 10) / 10, 'percentage').precision({
                                smallest: 0.1,
                                precision: 3,
                            }) / 100
                        );
                    }

                    return Math.round(percent * 10) / 1000;
                }

                case 'percentagePoint': {
                    const percent = val * 100;

                    if (percent >= 999.95) {
                        return (
                            numberStyleHelper(Math.round(percent * 10) / 10, 'percentagePoint').precision({
                                smallest: 0.1,
                                precision: 3,
                            }) / 100
                        );
                    }

                    return parseFloat((Math.round(percent * 10) / 10).toFixed(1)) / 100;
                }

                case 'int': {
                    return numberStyleHelper(val, 'int').precision({
                        precision,
                        smallest,
                    });
                }

                case 'real':
                    return numberStyleHelper(val, 'real').precision({
                        precision,
                        smallest,
                    });

                default: {
                    return numberStyleHelper(val, 'real').precision({
                        precision,
                        smallest,
                    });
                }
            }
        },
    };
}

function scaleNumber(val: number, style: NumberStyle, num: number) {
    switch (numberStyleHelper(val, style).scale()) {
        case 't':
            return (num /= 1_000);
        case 'm':
            return (num /= 1_000_000);
        case 'b':
            return (num /= 1_000_000_000);
        case 'tr':
            return (num /= 1_000_000_000_000);
        default:
            return num;
    }
}
