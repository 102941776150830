import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from '../../Core/ContextMenu';
import { ContextMenuItem } from '../../Core/ContextMenuItem';
import { PopoverContent } from '../../Popover/PopoverContent';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { useChatBoxForm } from '../../Providers/ChatBoxProvider/useChatBoxForm';
import { ResourceCenterDialog } from '../ResourceCenterDialog';

export function KnowledgeBasePopoverContent() {
    const { t } = useTranslation();
    const { setOpen } = usePopoverContext();
    const { setViewConversation } = useChatBoxForm();

    const [resourceCenterOpen, setResourceCenterOpen] = useState(false);

    return (
        <PopoverContent>
            <ContextMenu
                direction="column"
                disableX
                padding="0.5rem"
                width="240px"
            >
                <ContextMenuItem onClick={() => setResourceCenterOpen(true)} showPicker>
                    {t('chatCpg.resourceCenter')}
                </ContextMenuItem>
                <ResourceCenterDialog onClose={() => setResourceCenterOpen(false)} open={resourceCenterOpen} />
                <ContextMenuItem showPicker>
                    {t('chatCpg.privateDataRepository')}
                </ContextMenuItem>
                <ContextMenuItem showPicker>
                    {t('chatCpg.customTuning')}
                </ContextMenuItem>
                <ContextMenuItem
                    onClick={() => {
                        setViewConversation(false);
                        setOpen(false);
                    }}
                    showPicker
                >
                    {t('chatCpg.exampleQuestions')}
                </ContextMenuItem>

            </ContextMenu>
        </PopoverContent>
    );
}
