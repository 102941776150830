import { IconButton } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { MouseEventHandler } from 'react';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { ExpandIcon } from '../../Icons/ExpandIcon';
import { useLocalization } from '../../Providers/LocalizationProvider/useLocalization';
import { useCountDescendants } from './useCountDescendants';

export function ProductGroupCell(props: GridRenderCellParams) {
    const t = useLocalization();
    const { id, field, rowNode, row } = props;
    const isGroup = rowNode.type === 'group';
    const isLeaf = rowNode.type === 'leaf';
    const isOwnedProduct = isLeaf && row.isOwned;
    const apiRef = useGridApiContext();
    const { owned, competitors } = useCountDescendants(rowNode);

    const handleClick: MouseEventHandler = (event) => {
        if (!isGroup) return;
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    return (
        <Flex
            align="center"
            gap="0.2rem"
            style={{ paddingLeft: `calc(${rowNode.depth} * 15px)` }}
        >
            {isGroup
                ? (
                    <IconButton onClick={handleClick} size="small">
                        <ExpandIcon open={!!rowNode.childrenExpanded} />
                    </IconButton>
                )
                : (
                    <div style={{ minWidth: '24px' }} />
                )}
            <Flex direction="column">
                {isGroup
                    ? (
                        <>
                            <Typography variant="h-12-b">{props.value}</Typography>
                            <Typography>{t('salesPerformance.ownedProductsCount', { count: owned })}</Typography>
                            <Typography secondary>
                                {t('salesPerformance.competitorProductsCount', { count: competitors })}
                            </Typography>
                        </>
                    )
                    : (
                        <>
                            <Typography secondary={!isOwnedProduct} variant="h-12-b">
                                {isLeaf && typeof rowNode.groupingKey === 'string'
                                    ? rowNode.groupingKey.slice(0, 15)
                                    : ''}
                            </Typography>
                            <Typography secondary={!isOwnedProduct}>
                                {isLeaf && typeof rowNode.groupingKey === 'string'
                                    ? rowNode.groupingKey.slice(15)
                                    : ''}
                            </Typography>
                        </>
                    )}
            </Flex>
        </Flex>
    );
}
