export const multilineCSS = {
    'button-12': '1.33em',
    'p-22-b': '1.33em',
    'p-22-r': '1.33em',
    'p-18-r': '1.33em',
    'p-18-i': '1.33em',
    'p-16-r': '1.33em',
    'p-16-b': '1.33em',
    'p-16-i': '1.33em',
    'h-16-b': '1.33em',
    'p-14-b': '18px',
    'p-14-r': '18px',
    'p-14-i': '18px',
    'h-12-b': '16px',
    'p-12-r': '16px',
    'p-12-i': '16px',
};
