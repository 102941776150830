import { styled, useTheme } from '@mui/material';
import isFinite from 'lodash/isFinite';
import { CSSProperties } from 'react';
import { NumberStyle, numberStyleHelper } from '../helpers/numberStyleHelper';
import { roundToTenths } from '../helpers/roundToTenths';
import { NumberFormatter } from './NumberFormatter';
import { Typography, TypographyProps } from './Typography';

interface ChangeIndicatorProps {
    allNeutral?: boolean;
    borderColor?: string;
    customSuffix?: string;
    invertColor?: boolean;
    minWidth?: string;
    numberStyle: NumberStyle;
    nowrap?: boolean;
    style?: CSSProperties;
    suffix?: string;
    textColor?: string;
    val?: number;
}

export function ChangeIndicator({
    allNeutral,
    borderColor: borderColorProp,
    customSuffix,
    invertColor = false,
    minWidth,
    nowrap = true,
    numberStyle,
    style,
    suffix,
    textColor: textColorProp,
    val = NaN,
}: ChangeIndicatorProps) {
    const { palette } = useTheme();
    const textColor = textColorProp ?? palette.text.primary;

    const roundedVal = roundToTenths(val * 100);
    const isPositive = roundedVal != 0 ? (invertColor ? val < 0 : val > 0) : false;
    const isNegative = roundedVal != 0 ? (invertColor ? val > 0 : val < 0) : false;

    let borderColor: string;

    if (isFinite(roundedVal) && isNegative) {
        borderColor = palette.negative.main;
    } else if (isFinite(roundedVal) && isPositive) {
        borderColor = palette.success.main;
    } else {
        borderColor = palette.background.tertiary;
    }

    if (allNeutral) {
        borderColor = palette.background.tertiary;
    }

    return (
        <StyledTypography
            borderColor={!isNaN(val) ? borderColorProp ?? borderColor : 'transparent'}
            data-testid="change-value"
            minWidth={minWidth}
            nowrap={nowrap}
            style={style}
            variant="n-12-b"
        >
            <NumberFormatter
                as="span"
                color={textColor}
                customSuffix={customSuffix}
                numberStyle={numberStyleHelper(val, numberStyle).addNumberOptions({ showChange: true })}
                showChange
                val={val}
            />
            {suffix && (
                <Typography
                    as="span"
                    color={textColor}
                    style={{ paddingLeft: '.5rem' }}
                    variant="p-12-i"
                >
                    {suffix}
                </Typography>
            )}
        </StyledTypography>
    );
}

interface StyledTypographyProps extends TypographyProps {
    borderColor: string;
    minWidth?: string;
}

const StyledTypography = styled(
    Typography,
    { shouldForwardProp: (prop) => prop !== 'borderColor' && prop !== 'minWidth' }
)<StyledTypographyProps>(({ theme, minWidth, borderColor }) => `
    min-width: ${minWidth};
    border: ${`1px solid ${borderColor}`};
    border-radius: ${theme.shape.borderRadius}px;
    padding: 2px 8px;
    width: fit-content;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`);
