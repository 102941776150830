import { styled, useTheme } from '@mui/material';
import { ChangeEvent, FocusEvent, HTMLInputTypeAttribute, KeyboardEvent } from 'react';
import { Typography } from '../Core/Typography';
import { FormElementWrapper } from './FormElementWrapper';

interface TextInputProps {
    autoComplete?: string;
    name: string;
    id: string;
    title?: string;
    titleColor?: string;
    error?: string;
    value?: string | number;
    description?: string;
    width?: string;
    required?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onSubmit?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    placeholder?: string;
    type?: HTMLInputTypeAttribute;
    max?: number;
    min?: number;
    disabled?: boolean;
    background?: string;
}

export function TextInput({
    autoComplete,
    title,
    name,
    id,
    description,
    width = '100%',
    error,
    value = '',
    required,
    onChange,
    onSubmit,
    onFocus,
    onBlur,
    placeholder,
    type = 'text',
    max,
    min,
    disabled,
    background,
    titleColor,
}: TextInputProps) {
    const { palette } = useTheme();

    return (
        <FormElementWrapper
            description={description}
            error={error}
            title={title}
            titleColor={titleColor}
            width={width}
        >
            <StyledInput
                autoComplete={autoComplete}
                background={background}
                className={value ? 'p-18-r' : 'p-18-i'}
                disabled={disabled}
                error={error}
                id={id}
                max={max}
                min={min}
                name={name}
                onBlur={onBlur}
                onChange={(e) => {
                    onChange?.(e);
                }}
                onFocus={onFocus}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') onSubmit?.(e);
                }}
                placeholder={placeholder}
                required={required}
                type={type}
                value={value}
                width={width}
            />
            {type === 'text' && max && (
                <Typography
                    color={error ? palette.error.main : palette.text.secondary}
                    style={{
                        position: 'absolute',
                        right: 12,
                        bottom: -18,
                    }}
                >
                    {value?.toString().length}
                    /
                    {max}
                </Typography>
            )}
        </FormElementWrapper>
    );
}

const StyledInput = styled('input')<Partial<TextInputProps>>(({
    background,
    error,
    theme,
    width,
}) => `
    background-color: ${background ?? theme.palette.background.secondaryMask};
    border: 1px solid ${error ? theme.palette.error.main : theme.palette.middleGray.main};
    border-radius: ${theme.shape.borderRadius}px;
    color: ${theme.palette.text.primary};
    height: 34px;
    padding: 0 12px;
    width: ${width};
    
    :disabled {
        opacity: 0.5;
    }

    :focus {
        border: 1px solid ${error ? theme.palette.error.main : theme.palette.primary.main};
        outline: 1px solid ${error ? theme.palette.error.main : theme.palette.primary.main};
    }

    ::placeholder {
        color: ${theme.palette.text.secondary};
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${theme.palette.background.secondary} inset;
        -webkit-text-fill-color: ${theme.palette.text.primary};
        border: 1px solid ${theme.palette.middleGray.main};
        caret-color: ${theme.palette.text.primary};
    }



    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`);
