import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQuery } from '@tanstack/react-query';
import { Covariate, CovariateCompleteData, RawCovariate } from '@tickr/sequelize-models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaStream } from '../ChatCpg/types/chatCpgTypes';
import { useApp } from '../Providers/AppProvider/useApp';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { debugLogger } from '../helpers/debugLogger';
import { useCaptureTickrException } from './useCaptureTickrException';
import { useForecastInProgress } from './useForecastInProgress';

type CovariatesStatusEvent = {
    type: 'status';
    status: 'searching-covariates' | 'searching-covariates-complete' | 'retrieving-datasets' | 'analyzing-datasets' | 'analyzing-datasets-complete' | 'stats-reranker' | 'stats-reranker-complete' | 'complete';
};

type CovariateDataEvent = {
    type: 'covariate-search';
    data: CovariateCompleteData;
};

type CovariatesStreamEvent = CovariatesStatusEvent | CovariateDataEvent | MetaStream;

interface UseCovariatesStreamProps {
    onComplete: () => void;
    onError: () => void;
}

export function useCovariatesStream({
    onComplete,
    onError,
}: UseCovariatesStreamProps) {
    const { t } = useTranslation();
    const captureTickrException = useCaptureTickrException();
    const { tidsApiUrl, chatApiRoute } = useApp();
    const { updatePage } = useCollectionMutations();
    const { documentId, forecast, enableCovariateSearch, isCovariateSearchComplete } = useForecastInProgress();

    const [status, setStatus] = useState('');

    useQuery(
        {
            queryKey: ['covariates-stream', documentId, forecast?.uuid],
            queryFn({ signal }) {
                if (!documentId || !forecast?.uuid) return false;

                fetchEventSource(
                    `${tidsApiUrl}/v1${chatApiRoute}/tabular-document/${documentId}?channel=tab-document-cov-search`,
                    {
                        method: 'GET',
                        signal,
                        async onopen() {
                            debugLogger('COVARIATES STREAM OPEN');
                        },
                        onmessage(event) {
                            if (event.event === 'ping' || event.data === '') return;
                            const streamData: CovariatesStreamEvent = JSON.parse(event.data);

                            debugLogger(streamData);

                            if ('type' in streamData) {
                                switch (streamData.type) {
                                    case 'status':

                                        switch (streamData.status) {
                                            case 'searching-covariates':
                                                break;
                                            case 'searching-covariates-complete':
                                                break;
                                            case 'retrieving-datasets':
                                                setStatus(t('forecast.loading.retrievingDatasets'));
                                                break;
                                            case 'analyzing-datasets':
                                                setStatus(t('forecast.loading.analyzingDatasets'));
                                                break;
                                            case 'stats-reranker':
                                                setStatus(t('forecast.loading.rankingDataSets'));
                                                break;
                                            case 'stats-reranker-complete':
                                                break;
                                            case 'complete':
                                                break;
                                        }

                                        break;
                                    case 'covariate-search':
                                        updatePage({
                                            pageUuid: forecast.uuid,
                                            mergeProps: {
                                                settings: {
                                                    document: {
                                                        docCovariates: mapCovariates(streamData.data.doc_covariates),
                                                        fredCovariates: mapCovariates(streamData.data.fred_covariates),
                                                        commodityCovariates: mapCovariates(
                                                            streamData.data.commodity_covariates
                                                        ),
                                                        enableCovariateSearch: false,
                                                    },
                                                },
                                            },
                                        });

                                        setStatus('complete');

                                        onComplete?.();

                                        break;
                                }
                            }

                            if ('meta' in streamData) {
                                switch (streamData.meta) {
                                    case 'ERROR':
                                        setStatus('');
                                        throw new Error('unknown');
                                }
                            }
                        },
                        onclose() {
                            debugLogger('COVARIATES STREAM CLOSED');
                        },
                        onerror(error) {
                            debugLogger('COVARIATES STREAM ERROR', { error });

                            captureTickrException(error);

                            if (error.message === 'unknown') {
                                onError();

                                updatePage({
                                    pageUuid: forecast.uuid,
                                    mergeProps: {
                                        settings: {
                                            document: {
                                                enableCovariateSearch: false,
                                                errorMsg: 'Error searching for covariates.',
                                            },
                                        },
                                    },
                                });

                                throw new Error('unknown');
                            }
                        },
                    }
                );

                return true;
            },
            enabled: !!documentId && !!forecast?.uuid && !isCovariateSearchComplete && enableCovariateSearch,
            staleTime: Infinity,
        }
    );

    return {
        status,
        setStatus,
    };
}

function mapCovariates(rawCovariates: RawCovariate[]): Covariate[] {
    return rawCovariates.map((covariate) => {
        const result: Covariate = {
            impact: covariate.impact,
            seriesId: covariate.series_id,
            title: covariate.title,
        };

        if (covariate.frequency !== undefined) result.frequency = covariate.frequency;
        if (covariate.impact_llm !== undefined) result.impactLlm = covariate.impact_llm;
        if (covariate.impact_stats !== undefined) result.impactStats = covariate.impact_stats;
        if (covariate.mape !== undefined) result.mape = covariate.mape;
        if (covariate.optimal_lags !== undefined) result.optimalLags = covariate.optimal_lags;
        if (covariate.rrf_score !== undefined) result.rrfScore = covariate.rrf_score;
        if (covariate.seasonal_adjustment !== undefined) result.seasonalAdjustment = covariate.seasonal_adjustment;
        if (covariate.units !== undefined) result.units = covariate.units;

        return result;
    });
}
