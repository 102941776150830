import { styled } from '@mui/material';
import { CSSProperties, ElementType, PropsWithChildren } from 'react';

export type FlexRules =
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'baseline';

export type FlexProps = PropsWithChildren<{
    align?: CSSProperties['alignItems'];
    as?: ElementType;
    borderBottom?: string;
    direction?: CSSProperties['flexDirection'];
    disabled?: boolean;
    gap?: string;
    grow?: CSSProperties['flexGrow'];
    height?: string;
    htmlFor?: string;
    justify?: FlexRules;
    margin?: string;
    overflow?: CSSProperties['overflow'];
    padding?: string;
    style?: CSSProperties;
    width?: string;
    wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
}>;

export const Flex = styled('div')<FlexProps>`
    position: relative;
    display: flex;
    overflow: ${({ overflow }) => overflow ?? 'unset'};
    padding: ${({ padding }) => padding ?? '0'};
    margin: ${({ margin }) => margin ?? '0'};
    width: ${({ width }) => width ?? 'unset'};
    height: ${({ height }) => height ?? 'unset'};
    gap: ${({ gap }) => gap ?? 'unset'};
    flex-wrap: ${({ wrap }) => wrap ?? 'nowrap'};
    justify-content: ${({ justify }) => justify ?? 'flex-start'};
    align-items: ${({ align }) => align ?? 'flex-start'};
    flex-direction: ${({ direction }) => (direction ?? 'row')};
    flex-grow: ${({ grow }) => grow ?? '0'};
`;
