import { styled, useTheme } from '@mui/material';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';

interface ProgressBarProps {
    name: string;
    amount: number;
    min?: number;
    max?: number;
    fillColor?: string;
    backgroundColor?: string;
}

const StyledFill = styled('div')<{ width: string; color: string }>(({ color, width, theme }) => `
    width: ${width};
    height: 100%;
    background-color: ${color};
    border-radius: ${theme.shape.borderRadius}px;
`);

const StyledBackground = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'color' }
)<{ color: string }>(({ color, theme }) => `
    background-color: ${color};
    border-radius: ${theme.shape.borderRadius}px;
`);

export function ProgressBar({
    name,
    amount,
    min = 0,
    max = 100,
    fillColor: fillColorProp,
    backgroundColor,
}: ProgressBarProps) {
    const { palette } = useTheme();
    const fillColor = fillColorProp ?? palette.primary.main;

    return (
        <Flex
            direction="column"
            gap="0.5rem"
            width="100%"
        >
            <Flex
                align="center"
                justify="space-between"
                width="100%"
            >
                <Typography variant="p-14-b">{name}</Typography>
                <Typography color={fillColor} variant="n-14-b">
                    {amount}
                </Typography>
            </Flex>

            <StyledBackground
                color={backgroundColor ?? palette.background.secondary}
                height="4px"
                width="100%"
            >
                <StyledFill color={fillColor} width={`${(amount / (max - min)) * 100}%`} />
            </StyledBackground>
        </Flex>
    );
}
