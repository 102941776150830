import { styled, useTheme } from '@mui/material';
import { EditableTypography } from '../Core/EditableTypography';
import { Flex } from '../Core/Flex';
import { useElement } from '../Element/useElement';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useDashboardSettings } from '../Providers/DashboardSettingsProvider.tsx/useDashboardSettings';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { usePage } from '../hooks/usePage';

export function InsightCard() {
    const { presentationMode } = useDashboardSettings();
    const element = useElement();
    const { palette } = useTheme();

    const backgrounds = {
        'forecast-insights': palette.background.secondary,
        'historic-insights': palette.background.secondary,
        'recommendations-insights': `${palette.cornflowerBlue.main}80`, // 50% opacity hex
    };

    const focusBackgrounds = {
        'forecast-insights': palette.background.secondaryMask,
        'historic-insights': palette.background.secondaryMask,
        'recommendations-insights': palette.background.secondaryMask,
    };

    const {
        storeKey = '',
        text: elementText,
    } = element.settings?.insightCard || {};

    const {
        forecastSummaryData,
        isStreaming,
        settings: pageSettings,
        solutionType,
        summaryId = '',
    } = usePage();

    const { updateElement } = useCollectionMutations();

    const insightStream = useChatCpgData<StreamingText>([storeKey, summaryId]);

    if (!pageSettings || !summaryId || !solutionType) {
        return <></>;
    }

    let pageText: string | undefined;

    if (solutionType === 'optimizePricing') {
        pageText = pageSettings?.summary?.[solutionType]?.insights?.[storeKey.replace('-insights', '') as 'historic' | 'recommendations'];
    } else if (solutionType === 'forecast') {
        pageText = forecastSummaryData?.forecastInsight;
    }

    const initialText = elementText || pageText || insightStream?.text || '';

    return (
        <StyledFlex
            background={backgrounds[storeKey as keyof typeof backgrounds]}
            direction="column"
            height="100%"
            padding="6px"
            width="100%"
        >
            <EditableTypography
                contentEditable={!isStreaming && !presentationMode}
                focusBackground={focusBackgrounds[storeKey as keyof typeof focusBackgrounds]}
                initialText={initialText}
                isStreaming={isStreaming && insightStream?.isStreaming !== false}
                onSave={(text) => {
                    updateElement({
                        mergeProps: {
                            settings: {
                                insightCard: {
                                    text,
                                    updatedAt: Date.now(),
                                },
                            },
                        },
                        elementUuid: element.uuid,
                        queryKey: element.tabUuid ?? element.pageUuid,
                    });
                }}
                variant="p-14-b"
            />
        </StyledFlex>
    );
}

const StyledFlex = styled(
    Flex,
    { shouldForwardProp: (prop) => prop !== 'background' }
)<{ background?: string }>(({ background, theme }) => `
    min-height: 0;
    overflow: auto;
    background: ${background ?? theme.palette.background.secondary};
    border-radius: 0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px;
`);
